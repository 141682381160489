<template>
<!-- Add New Service Popup Model -->
<div id="book-area" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="top:10%;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">Book Facility</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="card-body">
                   <form class="form" @submit.prevent="createbooking">
                                               <div class="col-sm-12">
                                                  <div class="row">
                                                         <div class="col-md-12 ">
                                                             <div class="form-group">
                                                                 <label>Choose Your Facility</label>
                                                                 <div class="col-12">
                                                                  <select  class="form-control" v-model="facility_type" @change="get_facility_type($event.target.selectedIndex)" :class="{ error: v$.facility_type.$error }">
                                                                     <option>Select Facility</option>
                                                                     <option v-for="item in facility_list" :value="item">{{item['name']}}</option>
                                                                  </select>
                                                                 </div>

                                                             </div>
                                                         </div>
                                                     </div>
                                                     <div class="row">
                                                         <div class="col-md-12 ">
                                                             <div class="form-group">
                                                                 <label>Date</label>
                                                                 <div class="col-12">
                                                                     <input id="book-date" class="form-control edit-date" v-model="date" type="date" :class="{ error: v$.date.$error }" ref="inputdatebooking">
                                                                 </div>
                                                             </div>
                                                         </div>
                                                     </div>
                                                     <div class="row">
                                                         <div class="col-md-12 ">
                                                             <div class="form-group">
                                                                 <label>Start Time</label>
                                                                 <div class="col-12">
                                                                   <vue-timepicker format="h:mm a"    v-model="start_time" :class="{ error: v$.start_time.$error }"></vue-timepicker>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                     </div>
                                                     <div class="row">
                                                         <div class="col-md-12 ">
                                                             <div class="form-group">
                                                                 <label>End Time</label>
                                                                 <div class="col-12">
                                                                     <vue-timepicker format="h:mm a"    v-model="end_time" :class="{ error: v$.end_time.$error }"></vue-timepicker>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                     </div>
                                                     <div v-if="condition == true" class="col-md-12" style="padding: 15px;">
                                                         <span style="font-size:13px;color:red;">The end time must be higher than start time!</span>
                                                     </div>
                                                     <div class="col-sm-12">
                                                         <div class="form-group">
                                                             <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                                                 <button type="submit" class="btn btn-success mr-2">Submit</button>
                                                                 <button type="button" class="btn btn-danger mr-2" data-dismiss="modal" aria-hidden="true">Cancel</button>
                                                             </div>
                                                         </div>
                                                     </div>

                                               </div>

                                           </form>
           </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import VueTimepicker from 'vue3-timepicker'
import {mapState} from 'vuex'
import { required } from '@vuelidate/validators'
export default {
  name: 'CreateBooking',
  components: {
    VueTimepicker

  },

  setup () {
    return { v$: useVuelidate() }
  },
  computed:{
      ...mapState('facility',['facility_list'])


    },
    validations () {
      return {
        facility_type: { required,
        sUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === 'Select Facility') return false
        else return true


         }  },
        date: { required },
        start_time: { required },
        end_time: { required },
      }
    },
    mounted(){
      this.disable_date();
    },
  created: function(){
   this.$store.dispatch('facility/list_facility')
  },
  data(){
     return{
       facility_type: 'Select Facility',
       facility: '',
        date: '',
        start_time: '',
        end_time: '',
        condition: false

     }

   },
  methods:{
     get_facility_type(selectedIndex)
    {
      var selectedIdea = this.facility_list[selectedIndex-1];

      this.facility_list.forEach((item) => {
       if (selectedIdea.name == item.name){
        this.facility = item.id
        }
      // do something
      });

    },
    disable_date(){
      var date = new
      Date().toISOString().slice(0,10);
      $(this.$refs.inputdatebooking).attr('min',date);
    },
    createbooking(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        if (this.end_time < this.start_time){
           this.condition = true
        }
        else{
      const facility = new URLSearchParams()
      this.$store.commit('spinner/showLoading', true)
      facility.append('common_facilities_id', this.facility)
      facility.append('book_date', this.date)
      facility.append('flat_id', localStorage.flat_id)
      facility.append('end_time', this.end_time)
      facility.append('start_time', this.start_time)
      facility.append('user_id', localStorage.user_id)
      this.$store.dispatch('facility/createfacility',facility)
      }
    }

    }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
