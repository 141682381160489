<template>
<body class="skin-default fixed-layout">

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper">
  <SuperAdminHeader/>
  <SuperAdminSidebar/>
  <div class="page-wrapper">
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <div class="row page-titles">
        <div class="col-md-5 align-self-center">
          <h4 class="text-themecolor">Trail/Paid Subscription Report</h4>
        </div>
        <div class="col-md-7 align-self-center text-right">
          <div class="d-flex justify-content-end align-items-center">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link :to="{ name: 'SuperAdminHome' }">Home</router-link></li>
              <li class="breadcrumb-item active">Trail/Paid Subscription Report</li>
            </ol>
            <!-- <button type="button" class="btn btn-info d-none d-lg-block m-l-15"><i class="fa fa-plus-circle"></i>
            Create New</button> -->
          </div>
        </div>
      </div>
      <!-- ============================================================== -->
      <!-- End Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- Info box -->
      <!-- ============================================================== -->
      <div class="row" >
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row" style="margin-top: 25px !important;">
                <div class="col-sm-3">

                  <div class="col-md-12 ">
                    <div class="form-group">
                      <div class="col-12">
                        <h6>Category</h6>
                        <select class="form-control custom-select" v-model="report_type" >
                          <option value="trail" selected="selected">Trail Summary</option>
                          <option value="paid">Paid Summary</option>
                        </select>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="col-md-12 ">
                    <div class="form-group">
                      <div class="col-12">
                        <button type="button" class="btn btn-info d-lg-block m-l-15" style="margin-left: 0px;margin-top:25px;" @click="getTrailPaidSubscriptionDetails">
                          Generate Report</button>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="row" style="margin-top: 25px !important; margin-left: 10px;">
                    <div v-if="activity_summary.length > 0">
                          <h4><b>Activity Summary Report Of {{activity_summary[i].building_name}} For The Year {{activity_summary[i].year}}</b></h4>
                      </div>

                  </div>

                  <div v-if="activity_summary.length > 0">
                   <div class="card-body" >
                     <div class="table-responsive">
                       <table class="table table-striped">
                         <thead v-if="report_type == 'trail'">
                           <tr>
                             <th>Month</th>
                             <th >Apartments on Trial</th>
                             <th>Users on Trial</th>
                             <th>Active Users on Trial</th>
                             <th>Revenue Billed</th>
                             <th>Revenue Collected</th>
                             <th>Admin Payments Made</th>
                             <th>Complaints Requested</th>
                             <th >Complaints Completed</th>
                             <th >Service Requested</th>
                             <th >Service Completed</th>
                             <th >Parking Requested</th>
                             <th >Parking Alloted</th>
                           </tr>
                         </thead>
                         <thead v-else>
                           <tr>
                             <th>Month</th>
                             <th >Apartments Subscribed</th>
                             <th>Users Subscribed</th>
                             <th>Active Users Subscribed</th>
                             <th>Revenue Billed</th>
                             <th>Revenue Collected</th>
                             <th>Admin Payments Made</th>
                             <th>Complaints Requested</th>
                             <th >Complaints Completed</th>
                             <th >Service Requested</th>
                             <th >Service Completed</th>
                             <th >Parking Requested</th>
                             <th >Parking Alloted</th>
                           </tr>
                         </thead>
                         <tbody>
                           <tr v-for=" val in activity_summary[i].data" :key="val.month">
                             <td>{{val.month}}</td>
                             <td>{{val.total_appartments}}</td>
                             <td>{{val.total_users}}</td>
                             <td>{{val.total_active_users}}</td>
                             <td>{{val.revenue_billed}}</td>
                             <td>{{val.revenue_collected}}</td>
                             <td>{{val.admin_revenue_paid}}</td>
                             <td>{{val.complaints_requested}}</td>
                             <td>{{val.complaints_completed}}</td>
                             <td>{{val.service_requested}}</td>
                             <td>{{val.service_completed}}</td>
                             <td>{{val.parking_requested}}</td>
                             <td>{{val.parking_filled}}</td>
                           </tr>
                       </tbody>
                       </table>
                     </div>
                       <div class="row">

                                 <div class="col-md-3">
                                   <button type="button" class="btn btn-success d-lg-block " @click="generatePdfTrailPaidSubscriptionReport()">
                                     <i class="fa fa-download" aria-hidden="true"></i> Download Report</button>
                                   </div>
                                   <div class="col-md-2">
                                   <button type="button" class="btn btn-info d-lg-block " @click="nextPage()">
                                     Next Page</button>
                                   </div>
                                   <div class="col-md-2">
                                   <button type="button" class="btn btn-info d-lg-block " @click="previousPage()">
                                     Previous Page</button>
                                   </div>
                               </div>

                   </div>
                 </div>
                 <div v-else>
                   <div style ="padding-left:15px;padding-right:15px; width:100%">
                                     <div class="alert alert-dark">
                                         <span>No data to show</span>
                                     </div>
                   </div>
                 </div>
                 <div >
                 </div>



                </div>
              </div>
            </div>


            <!-- /.modal-dialog -->
          </div>
          <!-- ============================================================== -->
          <!-- End Info box -->
          <!-- ============================================================== -->

          <!-- ============================================================== -->
          <!-- Review -->
          <!-- ============================================================== -->

          <!-- ============================================================== -->
          <!-- End Review -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Comment - chats -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- End Comment - chats -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- End Page Content -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Right sidebar -->
          <!-- ============================================================== -->
          <!-- .right-sidebar -->

          <!-- ============================================================== -->
          <!-- End Right sidebar -->
          <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
      </div>
      <!-- ============================================================== -->
      <!-- End Page wrapper  -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- footer -->
      <!-- ============================================================== -->
      <footer class="footer">
        © 2020 www.ontash.net
      </footer>
      <!-- ============================================================== -->
      <!-- End footer -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Wrapper -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- All Jquery -->
    <!-- ============================================================== -->

  </body>
</template>

<script>
// @ is an alias to /src
import SuperAdminHeader from '@/components/headers/SuperAdminHeader.vue'
import SuperAdminSidebar from '@/components/sidebars/SuperAdminSidebar.vue'
import {mapState} from 'vuex'
import moment from 'moment'
export default {
  name: 'SuperAdminTrailPaidSummary',
  components: {
    SuperAdminHeader,
    SuperAdminSidebar
  },

  computed:{
    ...mapState('building',['activity_summary'])
  },
  data(){
    return{
      report_type: "trail",
      i: 0
    }

  },
  mounted(){

  },

  methods:{
    getTrailPaidSubscriptionDetails(){
      const get_report = new URLSearchParams()
      get_report.append('report_type', this.report_type)
      this.$store.dispatch('building/getTrailPaidSubscriptionDetails',get_report)
    },

    generatePdfTrailPaidSubscriptionReport(){
      const gen_report = new URLSearchParams()
      gen_report.append('report_type', this.report_type)
      this.$store.dispatch('building/generatePdfTrailPaidSubscriptionReport',gen_report)
    },
    nextPage(){
      if (this.i < this.activity_summary.length-1 ){
        this.i++
      }
    },
    previousPage(){
      if (this.i > 0 ){
        this.i--
      }
    }
  },
  watch: {

  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
