import axios from 'axios'
import baseurl from './axioscredentials'
axios.defaults.withCredentials = true
import router from '@/router'
const bank = {
  namespaced: true,
  state: {
    bank: [],
    banksdetails: [],
    building_name: '',
    bene_id: '',
    bene_name: '',
    bene_email: '',
    bene_phone: '',
    bank_account: '',
    ifsc: '',
    vpa: '',
    card_no: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    pincode: ''
  },
  getters: {

  },
  mutations: {
    SET_BANKS(state,data){
      state.bank = data.bank
    },
    SET_BUILDING_BANKS(state,data){
      state.banksdetails = data.banks
    },
    SET_BUILDING_BANK(state,data){
      state.building_name = data.building_name,
      state.bene_id = data.bank.bene_id,
      state.bene_name = data.bank.bene_name,
      state.bene_email = data.bank.bene_email,
      state.bene_phone = data.bank.bene_phone,
      state.bank_account = data.bank.bank_account,
      state.ifsc = data.bank.ifsc,
      state.vpa = data.bank.vpa,
      state.card_no = data.bank.card_no,
      state.address1 = data.bank.address1,
      state.address2 = data.bank.address2,
      state.city = data.bank.city,
      state.state = data.bank.state,
      state.pincode = data.bank.pincode
    }

  },
  actions: {
    getBanks({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/admin_banks',{building_id: localStorage.building_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_BANKS',res.data)
        }).catch(error => console.log(error))
    },


    createBank({ commit, state},bank){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/create_admin_bank',bank,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: 'AdminBanks'})
          localStorage.status = "created"
        }).catch(error => console.log(error))
    },

    deleteBank({ commit, state},bank){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/delete_admin_bank',bank,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: 'AdminBanks'})
          localStorage.status = "deleted"
        }).catch(error => console.log(error))
    },

    getBuildingBanks({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_building_banks',{},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_BUILDING_BANKS',res.data)
        }).catch(error => console.log(error))
    },
    getBuildingBank({ commit, state},bank_id){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_building_bank',bank_id,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_BUILDING_BANK',res.data)
        }).catch(error => console.log(error))
    }
  }
}
export default bank
