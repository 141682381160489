import axios from 'axios'
import baseurl from './axioscredentials'
axios.defaults.withCredentials = true
import router from '@/router'
const report = {
  namespaced: true,
  state: {
    reportdetails: []

  },
  getters: {

  },
  mutations: {
    SET_REPORT_DETAILS(state,data){
      state.reportdetails = data.report_details
    },
    updateportdetails(state,payload){
      state.reportdetails = payload
    }

  },
  actions: {
    getReportDetails({ commit, state},gen_report){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_report_details',gen_report,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_REPORT_DETAILS',res.data)
        }).catch(error => console.log(error))
    },

    generatePdfReport({ commit, state},gen_report){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/generate_pdf_report',gen_report,{headers:{'Authorization':localStorage.token},responseType: 'blob'})
      .then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'PaymentReport.pdf');
        document.body.appendChild(fileLink);
        fileLink.click();
    }).catch((error) => {
        console.log(error)
      })
    }
  }
}
export default report
