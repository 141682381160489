<template>
  <body class="skin-default fixed-layout">s

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->


        <div id="main-wrapper">
          <UserHeader/>
          <UserSidebar/>
            <div class="page-wrapper">
                <div class="container-fluid">
                        <div id="confirm-payment" class="modal1" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="top:23%;">

                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title"  style="margin-left: 5%;opacity: 60%;" id="myModalLabel">Confirm Your Payment</h4>
                                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                                    </div>
                                    <div class="card-body" style="padding: 10px 50px;">
                                        <div class="row" style="justify-content: center;">
                                            <span>To proceed to payment, Click on the link button.<br>
                                                 <button type="submit" class="btn btn-success mr-2 validate"  @click="goToEvents()" data-dismiss="modal">CLICK HERE</button>
                                            </span>                                            
                                        </div>
                                            <hr>
                                            <div class="col-sm-12" style="text-align: center;">
                                                <div class="form-group">
                                                    <div class="custom-control custom-checkbox mr-sm-2">
                                                        <div class="row">
                                                            <div class="col-sm-12">
                                                               <button type="submit" class="btn btn-danger mr-2" data-dismiss="modal" @click="goToWallet()">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                </div>
                                <!-- /.modal-content -->
                            </div>
                          </div>
                            <!-- /.modal-dialog -->
                    </div>
                </div>

            <footer class="footer">
                © 2020 www.ontash.net
            </footer>
        </div>
    </body>




</template>

<script>
// @ is an alias to /src
import UserSidebar from '@/components/sidebars/UserSidebar.vue'
import UserHeader from '@/components/headers/UserHeader.vue'
export default {
  name: 'ConfirmPayment',
  components: {
    UserSidebar,
    UserHeader,
  },
  data() {
    return{
      confirm: true,
      payment_link: this.$route.params.payment_link,
      order_id: this.$route.params.order_id
    }
  },
  methods:{
    goToEvents(){
       localStorage["order_id"]=this.order_id
       location.href= this.payment_link
     },
     goToWallet(){
       this.$router.push('/mywallet')
     }
  }
}
</script>
