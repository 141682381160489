<template>
<!-- Add New Service Popup Model -->
<div id="myModal" class="modal fade in" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">Create New Group</h4>
                <button type="button" class="close" data-dismiss="modal"
                    aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form class="form" @submit.prevent="addgroup">
                    <div class="form-group">
                        <label class="col-md-12">Name of the Group</label>
                        <div class="col-md-12">
                            <input type="text" v-model="group_name" class="form-control" placeholder="Enter Name of the Group" :class="{ error: v$.group_name.$error }">
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="form-group">
                            <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                <button type="submit" class="btn btn-success mr-2">Submit</button>
                                <button type="button" class="btn btn-danger mr-2"
                                    data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
        </form>
         </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'CreateGroup',
   setup () {
    return { v$: useVuelidate() }
  },
   data(){
    return{
      group_name: '',
    }

  },
   validations () {
    return {

      group_name: { required },
    }
  },
  methods: {
    addgroup()
    {
      this.v$.$touch()
      if(!this.v$.$invalid) {
      this.$store.commit('spinner/showLoading', true)
      const add_grp = new URLSearchParams()
      add_grp.append('group_name', this.group_name)
      add_grp.append('building_id',localStorage.building_id)
      this.$store.dispatch('adminflatgroup/add_new_grp',add_grp)
     }
    }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
