<template>
<!-- Add New Service Popup Model -->
<div id="add-service-request" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="top:10%;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">Complaints</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="card-body">
                    <form class="form" @submit.prevent="createComplaint">
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label for="example-text-input" class="col-4 col-form-label" style="font-weight: 500;">Complaint Topic</label>
                                    <div class="col-12">
                                      <input type="text" class="form-control" v-model="topic" placeholder="Enter Topic" :class="{ error: v$.topic.$error }">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Description</label>
                                    <div class="col-12">
                                        <textarea class="form-control" rows="5" placeholder="Enter Description" v-model="description" :class="{ error: v$.description.$error }"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                        <button type="submit" class="btn btn-success mr-2">Submit</button>
                                        <button type="button" class="btn btn-danger mr-2" data-dismiss="modal" aria-hidden="true">Cancel</button>
                                    </div>
                                </div>
                            </div>

                        </form>
           </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'CreateComplaint',
  setup () {
    return { v$: useVuelidate() }
  },
  components: {

  },
  data(){
    return{
      topic: '',
      description: ''
    }

  },
  validations () {
    return {
      topic: { required }, // Matches this.firstName
      description: { required } // Matches this.lastName
    }
  },
  methods:{
    createComplaint(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const complaint = new URLSearchParams()
        complaint.append('topic', this.topic)
        complaint.append('description', this.description)
        complaint.append('flat_id', localStorage.flat_id)
        this.$store.dispatch('complaint/createComplaint',complaint)
      }
    }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
