import axios from 'axios'
import baseurl from './axioscredentials'
axios.defaults.withCredentials = true
import router from '@/router'
const payment = {
  namespaced: true,
  state: {
    wallet_balance: '',
    payment_list: []

  },
  getters: {

  },
  mutations: {
    set_payment(state,data){
      state.total_debited = data.total_debited
      state.payment_records = data.payment_records
      state.serv_req = data.serv_req
      state.complaints = data.complaints

    },
    set_resident_payment(state,data){
      state.paid_amount = data.paid_amount
      state.pending_amount = data.pending_amount
      state.all_payments = data.all_payments
      state.payment_list = data.all_payments
      state.paid_payments = data.paid_payments
      state.pending_payments = data.pending_payments
      state.payment_groups = data.payment_groups
      state.all_payment_count = data.all_payment_count
      state.active_flats = data.active_flats
      state.appartment_user = data.appartment_user
      state.payment_group_members = data.payment_group_members
    },
    set_single_payment(state,data){
     state.payment_details = data.payment_details
     if (!jQuery.isEmptyObject((data.serv_req))){
       state.ref = data.serv_req
       state.type = "Service_Request"
       console.log(data.serv_req)
     }
     else if (!jQuery.isEmptyObject((data.complaint_array))){
       state.ref = data.complaint_array
       state.type = "Complaint"
     }
     state.serv_req = data.serv_req
     state.complaints = data.complaint_array
     state.payment_id = data.payment_details.id
     state.date = data.payment_details.payment_date
     state.amount = data.payment_details.paid_amount
     state.status = data.payment_details.status
     state.payee_name = data.payment_details.payee_name
     state.payee_phone = data.payment_details.payee_contact_no
     state.estimate_amount = data.payment_details.estimate_amount
    },
    set_single_user_payment(state,data){
     state.topic = data.payment_details[0].topic
     state.amount = data.payment_details[0].amount

    },
    sortBystatus(state,payload){
      if (payload == 1){
      state.payment_list = state.paid_payments
      }
      else if (payload == 0){
        state.payment_list = state.pending_payments
      }
    },

     update_status(state,data){
     state.payment_status = data.status
    },

    updatedate(state,payload){
      state.date = payload
    },
    updateamount(state,payload){
      state.amount = payload
    },
    updatestatus(state,payload){
      state.status = payload
    },
    updatename(state,payload){
      state.payee_name = payload
    },
    updatephone(state,payload){
      state.payee_phone = payload
    },
    updateestimate(state,payload){
      state.estimate_amount = payload
    },

  },
  actions: {

    mypayment({ commit, state}){
       this.dispatch('token/VerifyTokenExpiry', null, {root:true})
       const pay = new URLSearchParams()
       pay.append('flat_id', localStorage["flat_id"])
       pay.append('building_id',localStorage.building_id)
        axios.post(baseurl+'/api/v1/mypayments',pay,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
         commit('set_payment',res.data)
        }).catch(error => console.log(error))

    },
    residents_payment({ commit, state}){
       this.dispatch('token/VerifyTokenExpiry', null, {root:true})
       var building_id = localStorage["building_id"]
        axios.post(baseurl+'/api/v1/admin_user_payments', {building_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
         commit('set_resident_payment',res.data)
        }).catch(error => console.log(error))

    },
    createpayAdmin({ commit, state},pay){
        this.dispatch('token/VerifyTokenExpiry', null, {root:true})
        axios.post(baseurl+'/api/v1/create_adminpayment', pay,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
        localStorage.status = "Done"
        router.go({ name: 'adminmypayment'})
        }).catch(error => console.log(error))

    },
    get_payment_detail({ commit, state},payment_id){
        this.dispatch('token/VerifyTokenExpiry', null, {root:true})
        axios.post(baseurl+'/api/v1/get_payment_details', payment_id,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
        commit('set_single_payment',res.data)
        }).catch(error => console.log(error))

    },
    update_payment({ commit, state},pay){
        this.dispatch('token/VerifyTokenExpiry', null, {root:true})
        axios.post(baseurl+'/api/v1/update_admin_payment', pay,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
        router.go({ name: 'adminmypayment'})
        }).catch(error => console.log(error))

    },
    create_individual_payment({ commit, state},pay){
        this.dispatch('token/VerifyTokenExpiry', null, {root:true})
        axios.post(baseurl+'/api/v1/create_admin_individual_payment', pay,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
        router.go({ name: 'adminpayment'})
        localStorage.status = "user"
        }).catch(error => console.log(error))

    },
    get_order_details({ commit, state},pay){
        this.dispatch('token/VerifyTokenExpiry', null, {root:true})
        axios.post(baseurl+'/api/v1/update_on_payment_success_web', pay,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
        commit('update_status',res.data)
        }).catch(error => console.log(error))

    },
    group_payment({ commit, state},pay){
        this.dispatch('token/VerifyTokenExpiry', null, {root:true})
        axios.post(baseurl+'/api/v1/create_admin_group_payment', pay,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
         router.go({ name: 'adminpayment'})
         localStorage.status = "group"
        }).catch(error => console.log(error))

    },
    get_user_payment({ commit, state},payment_id){
        axios.post(baseurl+'/api/v1/get_user_payment_details', payment_id,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
        commit('set_single_user_payment',res.data)
        }).catch(error => console.log(error))

    },
    make_user_payment({ commit, state},payment){
        axios.post(baseurl+'/api/v1/settle_payment_from_wallet', payment,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
        router.go({ name: 'Wallet'})
        }).catch(error => console.log(error))

    },

  }
}
export default payment
