<template>
<aside class="left-sidebar">
<!-- Sidebar scroll-->
  <div class="scroll-sidebar">
  <!-- User Profile-->
  <div class="user-profile">
  <div class="user-pro-body">
  <div>
    <img :src="profilepic" alt="user-img" class="img-circle" style="width:60%;" data-toggle="modal" data-target="#edit-profile-pic" @click="viewImageUpload">
  </div>
  <div class="dropdown">

    <a href="javascript:void(0)" class="dropdown-toggle u-dropdown link hide-menu" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
      {{user_name}}
      <span class="caret"><br/>{{building_name}}</span>
    </a>
  <div class="dropdown-menu animated flipInY">
    <!-- text-->
    <router-link :to="{ name: 'AdminProfile' }">
      <a href="javascript:void(0)" class="dropdown-item">
        <i class="ti-user"></i> My Profile</a>
    </router-link>
            <div class="dropdown-item" @click="logoutUser">
              <i class="fa fa-power-off"></i>Logout</div>
              <!-- text-->
            </div>
          </div>
        </div>
      </div>
      <!-- Sidebar navigation-->
      <nav class="sidebar-nav">
        <ul id="sidebarnav">
          <li>
            <router-link :to="{ name: 'AdminUserList' }" :replace="true" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa fas fa-users"></i>
              <span class="hide-menu">Flats/Groups</span>
              <span v-if="admin_flatgroup_count >= 1" :class="[admin_flatgroup_count >= 1 ? ['badge','badge-pill','badge-cyan','ml-auto'] : '']">{{admin_flatgroup_count}}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'AdminComplaints' }"  :replace="true" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa fas fa-list-alt"></i>
              <span class="hide-menu">Complaints</span>
              <span v-if="admin_complaint_count >= 1" :class="[admin_complaint_count >= 1 ? ['badge','badge-pill','badge-cyan','ml-auto'] : '']">{{admin_complaint_count}}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'AdminService' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa fas fa-cogs"></i>
              <span class="hide-menu">Services</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'AdminServiceRequests' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa fas fa-user-plus"></i>
              <span class="hide-menu">Service Requests</span>
              <span v-if="admin_servicerequest_count >= 1" :class="[admin_servicerequest_count >= 1 ? ['badge','badge-pill','badge-cyan','ml-auto'] : '']">{{admin_servicerequest_count}}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'AdminMessage' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="ti-email"></i>
              <span class="hide-menu">Message Broadcast</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'AdminNotifications' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa far fa-bell"></i>
              <span class="hide-menu">Notifications
              <span v-if="unread_count >= 1" :class="[unread_count >= 1 ? ['badge','badge-pill','badge-cyan','ml-auto'] : '']">{{unread_count}}</span>
              </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'AdminMyPayment' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa far fa-credit-card"></i>
              <span class="hide-menu">My Payments</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'AdminPayment' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa far fa-credit-card"></i>
              <span class="hide-menu">Residents Payments</span>
              <span v-if="admin_residencepayment_count >= 1" :class="[admin_residencepayment_count >= 1 ? ['badge','badge-pill','badge-cyan','ml-auto'] : '']">{{admin_residencepayment_count}}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'AdminVisitorPass' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa far fa-address-card"></i>
              <span class="hide-menu">Visitor Pass</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'AdminBanks' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa far fa-credit-card"></i>
              <span class="hide-menu">My Bank</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'AdminSubscriptionCharge' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa far fa-credit-card"></i>
              <span class="hide-menu">Subscription Charge</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'AdminEvent' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa far fa-calendar-alt"></i>
              <span class="hide-menu">Events</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'AdminCommonFacilities' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa far fa-building"></i>
              <span class="hide-menu">Common Facilities</span>
              <span v-if="admin_facility_count >= 1" :class="[admin_facility_count >= 1 ? ['badge','badge-pill','badge-cyan','ml-auto'] : '']">{{admin_facility_count}}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'AdminDirectory' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa fas fa-address-book"></i>
              <span class="hide-menu">Phone Directory</span>
            </router-link>
          </li>
            <li>
            <router-link :to="{ name: 'AdminParking' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fas fa-parking"></i>
              <span class="hide-menu">Parking</span>
              <span v-if="admin_parking_count >= 1" :class="[admin_parking_count >= 1 ? ['badge','badge-pill','badge-cyan','ml-auto'] : '']">{{admin_parking_count}}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'AdminReports' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
               <i class="fa fas fa-copy"></i>
              <span class="hide-menu">Reports</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'AdminReportIssue' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa fas fa-bug "></i>
              <span class="hide-menu">Report Issue</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'AdminHelpAndFeedback' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa fas fa-question-circle"></i>
              <span class="hide-menu">Help & Feedback</span>
            </router-link>
          </li>
        </ul>
      </nav>
      <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
  </aside>
  <div v-if="imagemodal == true">
    <ProfilePicture :image_url = 'profilepic'></ProfilePicture>
  </div>
</template>

<script>
// @ is an alias to /src
import ProfilePicture from '@/components/sidebars/ProfilePicture.vue'
import {mapState,mapActions} from 'vuex'
export default {
  name: 'AdminSidebar',
  components: {
    ProfilePicture
  },
  data(){
    return{
      flat_name: localStorage.flat_name,
      building_name:localStorage.building_name,
      user_name:localStorage.user_name,
      imagemodal: false
    }

  },
  computed:{
    ...mapState('profile',['profilepic']),
    ...mapState('notification',['unread_count','admin_flatgroup_count','admin_complaint_count','admin_servicerequest_count',
                      'admin_facility_count','admin_residencepayment_count','admin_parking_count'])

  },
  mounted(){
    this.getProfileImage();
    this.get_notification()

  },
  methods:{
    ...mapActions('profile',['getProfileImage']),

    logoutUser(){
      this.$store.dispatch('login/logoutUser')
    },
    viewImageUpload(){
      this.imagemodal = true;
    },
    get_notification(){
      this.$store.dispatch('notification/list_notification_admin')
    }

  }
}
</script>

<style>
    @media screen and (min-width: 992px) {
        .flat{
    display:none;
    color:#01c0c8!important;
    width: 100%;
    padding:6px 25px;
    }
    .flat:hover{
    color:tomato!important;
}
}
@media screen and (max-width: 600px){
    .flat{
    display:inline-flex !important;
    color:#01c0c8!important;
    width: 100%;
    padding:6px 25px !important;
}
}
@media screen and (max-width: 768px) {
    .flat{
    display:inline-flex !important;
    color:#01c0c8!important;
    width: 100%;
    padding:6px 25px !important;
}
}
</style>
