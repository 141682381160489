<template>
<body class="skin-default fixed-layout">
   <div id="main-wrapper">
      <AdminHeader/>
      <AdminSidebar/>
      <div class="page-wrapper">
         <div class="container-fluid">
            <div class="row page-titles">
               <div class="col-md-5 align-self-center">
                  <h4 class="text-themecolor">Message Broadcast</h4>
               </div>
                 <div class="alert alert-info signout_create banner-text" role="alert">
                    <span>{{alert_message}}</span>
                </div>
            </div>

            <div class="row">
               <div class="col-12">
                  <div class="card">
                     <!-- .left-right-aside-column-->
                     <div class="contact-page-aside">
                        <!-- .left-aside-column-->
                        <div class="left-aside bg-light-part mobscroll" style="overflow: auto;">
                           <ul class="list-style-none">
                              <li class="box-label"><a href="javascript:void(0)" @click="change_type('All Users')">All Users
                                 <span>{{users_count}}</span></a>
                              </li>
                              <li class="divider"></li>
                              <div v-for="item in groups " :key ="item.id" >
                                        <li><a href="javascript:void(0)"  @click="change_type(item.name)" >{{item.name}}<span>{{item.group_member_count}}</span></a></li>
                                  </div>
                           </ul>
                        </div>
                        <!-- /.left-aside-column-->
                        <div class="right-aside ">
                           <div class="right-page-header">
                              <div class="d-flex">
                                 <div class="align-self-center">
                                    <h4 class="card-title m-t-10">{{group_type}}</h4>
                                 </div>
                              </div>
                           </div>
                           <!-- Add Contact Popup Model -->
                           <div class="table-responsive m-t-40">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">Message</label>
                                 <input type="text" class="form-control" id="exampleInputEmail1" v-model="msg" placeholder="Enter Your Message Title"   :class="{ error: v$.msg.$error }" >
                              </div>
                              <div class="form-group">
                                 <label for="exampleFormControlTextarea1">Message Description</label>
                                 <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="Description" placeholder="Enter Your Message Description"  :class="{ error: v$.Description.$error }"  ></textarea>
                              </div>
                              <div class="form-check">
                              </div>
                              <button type="button" class="btn btn-success" @click="create_message">Send message</button>
                           </div>
                           <!-- .left-aside-column-->
                        </div>
                        <!-- /.left-right-aside-column-->
                     </div>
                     <div>
                        <div class="col-md-5 align-self-center">
                           <h4 class="card-title m-t-10">Send Messages</h4>
                        </div>
                        <div v-if="message.length <= 0">
                          <div style ="padding-left:15px;padding-right:15px; width:100%">
                                            <div class="alert alert-dark">
                                                <span>No data to show</span>
                                            </div>
                          </div>
                        </div>
                        <div v-else>
                        <div class="table-responsive">
                           <table id="myTable" class="table table-striped" ref="tables">
                              <thead>
                                 <tr>
                                    <th>All Users/Group</th>
                                    <th>Send Date</th>
                                    <th>Message</th>
                                    <th>Message Description</th>
                                 </tr>
                              </thead>
                              <tbody>
                                   <tr v-for="item in message " :key ="item.id">
                                    <td>{{ item.groupname }}</td>
                                    <td>{{ format_date(item.created_at) }}</td>
                                    <td>{{ item.message }}</td>
                                    <td>{{ item.description }}</td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <footer class="footer">
        © 2020 www.ontash.net
      </footer>
   </div>
</body>
</template>
<script>

// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import AdminHeader from '@/components/headers/AdminHeader.vue'
import AdminSidebar from '@/components/sidebars/AdminSidebar.vue'
import {mapState} from 'vuex'
import moment from 'moment'

export default {
  name: 'AdminMessage',
  components: {
    AdminHeader,
    AdminSidebar
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data(){
      return{
       group_type: 'All Users',
       grp_type: '',
       msg: '',
       Description: '',
       alert_message: ''
      }

    },
  computed:{
    ...mapState('messages',['message','groups','users_count']),
  },
  mounted(){
    this.showalerts();
  },
  created(){
    this.get_message();

  },
  validations () {
      return {
        Description: { required }, // Matches this.firstName
        msg: { required } // Matches this.lastName
      }
    },
  methods: {

     format_date(value){
       if (value) {
         return moment(String(value)).format('DD-MM-YYYY')
        }
    },
    get_message(){
      this.$store.dispatch('messages/get_messages')
    },
    change_type(name){
       this.group_type = name
    },
    create_message(){
       this.v$.$touch()
      if(!this.v$.$invalid) {
        const new_message = new URLSearchParams()
        new_message.append('building_id', localStorage["building_id"])
        new_message.append('groupname', this.group_type)
        new_message.append('description', this.Description)
        new_message.append('message', this.msg)
        localStorage.type = this.group_type
        this.$store.dispatch('messages/create_new_message',new_message)
      }
    },
    showalerts(){
        if (localStorage.status == "created")
        {
          this.alert_message = "Message Successfully Send to "+localStorage.type+" group"
          $('.signout_create').delay(500).slideDown(500);
          $('.signout_create').delay(4000).slideUp(500);
          localStorage.removeItem('status')
        }

      }

  },
  watch: {
     message() {
      $(this.$refs.tables).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.tables).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    },
    groups(){

    }
  }
}
</script>
<style scoped>
  .banner-text{
    font-weight:400;
    position:absolute;
    width: 98%;
    margin-top: -5px;
    z-index: 10;
    text-align: center;
    display: none;
  }
  .error {
    border: 1px solid red;
  }
</style>
