<template>
  <body class="skin-default fixed-layout">s

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->


        <div id="main-wrapper">
          <AdminHeader/>
          <AdminSidebar/>
            <div class="page-wrapper">
                <div class="container-fluid">
                          <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title"  style="margin-left: 5%;opacity: 60%;" id="">Confirm Your Payment</h4>

                                    </div>
                                    <div class="card-body" style="padding: 10px 50px;">
                                        <div class="row" style="justify-content: center;">
                                            <span>To proceed to payment, Click on the link button.<br>
                                                 <button type="button" class="btn btn-success mr-2 "  @click="goToEvents">CLICK HERE</button>
                                            </span>

                                        </div>
                                            <hr>
                                            <div class="col-sm-12" style="text-align: center;">
                                                <div class="form-group">
                                                    <div class="custom-control custom-checkbox mr-sm-2">
                                                        <div class="row">
                                                            <div class="col-sm-12">
                                                               <button type="button" class="btn btn-danger mr-2" >Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                </div>
                                <!-- /.modal-content -->
                            </div>
                            <!-- /.modal-dialog -->

                    </div>
                </div>

            <footer class="footer">
                © 2020 www.ontash.net
            </footer>
        </div>
    </body>




</template>

<script>
// @ is an alias to /src
import AdminHeader from '@/components/headers/AdminHeader.vue'
import AdminSidebar from '@/components/sidebars/AdminSidebar.vue'
export default {
  name: 'AdminConfirmPayment',
  components: {
    AdminHeader,
    AdminSidebar,
  },
  data() {
    return{
      payment_link: this.$route.params.payment_link,
    }
  },
  methods:{
    goToEvents(){
       location.href= this.payment_link
      }
  }
}
</script>
