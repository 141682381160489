<template>
  <html>
  <body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <AdminHeader/>
      <AdminSidebar/>
      <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
          <!-- ============================================================== -->
          <!-- Bread crumb and right sidebar toggle -->
          <!-- ============================================================== -->
          <div class="row page-titles">
            <div class="col-md-5 align-self-center">
              <h4 class="text-themecolor">Service Requests</h4>
            </div>
            <div class="alert alert-success signout_create banner-text" role="alert">
                <span><strong>Success!</strong> You have successfully created a new service request</span>
            </div>
            <div class="alert alert-success signout_update banner-text" role="alert">
                <span><strong>Success!</strong> You have successfully updated the service request details</span>
            </div>
            <div class="alert alert-danger signout_delete banner-text" role="alert">
                <span><strong>Deleted!</strong> You have successfully deleted the service request</span>
            </div>
            <div class="alert alert-success status_updated banner-text" role="alert">
                <span><strong>Success!</strong> You have successfully changed the service request status</span>
            </div>
            <div class="col-md-7 align-self-center text-right">
              <div class="d-flex justify-content-end align-items-center">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><router-link :to="{ name: 'UserHome' }">Home</router-link></li>
                  <li class="breadcrumb-item active">Service Request</li>
                </ol>
                <button type="button" class="btn btn-info d-lg-block m-l-15" data-toggle="modal" data-target="#add-service-request" @click="loadCreateForm">
                  + Create
                </button>

              </div>
            </div>
          </div>
          <div v-if="createform === true">
            <AdminCreateServiceRequest></AdminCreateServiceRequest>
          </div>

            <!-- ============================================================== -->
            <!-- End Bread crumb and right sidebar toggle -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- Start Page Content -->
            <!-- ============================================================== -->
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <!-- Column -->
                      <div class="col-md-6 col-lg-3 col-xlg-3" @click="sortByStatusAdmin(1)">
                        <div class="card">
                          <div class="box label-info text-center">
                            <h1 class="font-light text-white">{{new_count}}</h1>
                            <h6 class="text-white">New</h6>
                          </div>
                        </div>
                      </div>
                      <!-- Column -->
                      <div class="col-md-6 col-lg-3 col-xlg-3" @click="sortByStatusAdmin(2)">
                        <div class="card">
                          <div class="box label-success text-center" >
                            <h1 class="font-light text-white">{{pending_count}}</h1>
                            <h6 class="text-white">Pending</h6>
                          </div>
                        </div>
                      </div>
                      <!-- Column -->
                      <div class="col-md-6 col-lg-3 col-xlg-3" @click="sortByStatusAdmin(3)">
                        <div class="card">
                          <div class="box label-danger text-center">
                            <h1 class="font-light text-white">{{complete_count}}</h1>
                            <h6 class="text-white">Complete</h6>
                          </div>
                        </div>
                      </div>
                      <!-- Column -->
                      <div class="col-md-6 col-lg-3 col-xlg-3" @click="sortByStatusAdmin(4)">
                        <div class="card">
                          <div class="box label-warning text-center">
                            <h1 class="font-light text-white">{{closed_count}}</h1>
                            <h6 class="text-white">Closed</h6>
                          </div>
                        </div>
                      </div>
                      <!-- Column -->
                    </div>
                    <div v-if="admin_servicerequests.length <= 0">
                      <div style ="padding-left:15px;padding-right:15px; width:100%">
                                        <div class="alert alert-dark">
                                            <span>No data to show</span>
                                        </div>
                      </div>
                    </div>
                    <div v-else>
                    <div class="table-responsive">
                      <table id = "myTable" class="table table-striped" ref="tables">
                        <thead>
                          <tr>
                            <th>Invoice</th>
                            <th>User/Group</th>
                            <th>Service</th>
                            <th>Service Date</th>
                            <th>Service Time</th>
                            <th>Description</th>
                            <th class="text-center">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="servicerequest in admin_servicerequests" :key="servicerequest.service_id">
                            <td @click="loadUpdateForm(servicerequest.service_id,servicerequest.status_id)" data-toggle="modal" data-target="#edit-service-request">
                              <a  class="link"> SR#{{servicerequest.service_id}}</a>
                            </td>
                            <td>{{servicerequest.appartment_group}}</td>
                            <td>{{servicerequest.service_name}}</td>
                            <td><span class="text-muted">{{format_date(servicerequest.service_date)}}</span></td>
                            <td>{{servicerequest.service_time}}</td>
                            <td>{{servicerequest.description}}</td>
                            <td class="text-center" v-if="servicerequest.status_id == '1' " @click="loadStatusForm(servicerequest.service_id,servicerequest.appartment_group)" data-toggle="modal" data-target="#change-status-servrequest">
                              <div class="label label-table label-info">New</div>
                            </td>
                            <td class="text-center" v-else-if="servicerequest.status_id == '2' " @click="loadStatusForm(servicerequest.service_id,servicerequest.appartment_group)" data-toggle="modal" data-target="#change-status-servrequest">
                              <div class="label label-table label-success">Pending</div>
                            </td>
                            <td class="text-center" v-else-if="servicerequest.status_id == '3' " @click="loadStatusForm(servicerequest.service_id,servicerequest.appartment_group)" data-toggle="modal" data-target="#change-status-servrequest">
                              <div class="label label-table label-danger">Complete</div>
                            </td>
                            <td class="text-center" v-else="servicerequest.status_id == '4' " @click="loadStatusForm(servicerequest.service_id,servicerequest.appartment_group)" data-toggle="modal" data-target="#change-status-servrequest">
                              <div class="label label-table label-warning">Closed</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="updateform === true">
              <AdminUpdateServiceRequest :servicerequest_id='servicerequest_id' :status_id='status_id'/>
            </div>
            <div v-if="statusform === true">
              <AdminUpdateServiceStatus :servicerequest_id='servicerequest_id' :flat_group_number='flat_group_number'/>
            </div>
            <!-- ============================================================== -->
            <!-- End PAge Content -->
            <!-- ============================================================== -->
          </div>
          <!-- ============================================================== -->
          <!-- End Container fluid  -->
          <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer">
          © 2020 www.ontash.net
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
      </div>
      <!-- ============================================================== -->
      <!-- End Wrapper -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
    </body>

    </html>
  </template>

<script>
// @ is an alias to /src
import AdminHeader from '@/components/headers/AdminHeader.vue'
import AdminSidebar from '@/components/sidebars/AdminSidebar.vue'
import AdminCreateServiceRequest from '@/components/service_requests/AdminCreateServiceRequest.vue'
import AdminUpdateServiceRequest from '@/components/service_requests/AdminUpdateServiceRequest.vue'
import AdminUpdateServiceStatus from '@/components/service_requests/AdminUpdateServiceStatus.vue'
import moment from 'moment'
import {mapState,mapActions,mapMutations} from 'vuex'
export default {
  name: 'ServiceRequests',
  components: {
    AdminHeader,
    AdminSidebar,
    AdminCreateServiceRequest,
    AdminUpdateServiceRequest,
    AdminUpdateServiceStatus
  },
  computed:{
    ...mapState('servicerequest',['new_count']),
    ...mapState('servicerequest',['pending_count']),
    ...mapState('servicerequest',['complete_count']),
    ...mapState('servicerequest',['closed_count']),
    ...mapState('servicerequest',['admin_servicerequests']),
    ...mapState('servicerequest',['status'])
  },
  data(){
    return{
      createform: false,
      updateform: false,
      statusform: false,
      servicerequest_id: '',
      status_id: '',
      flat_group_number: ''
    }

  },
  mounted(){
    this.getAdminServiceRequests();
    this.showalerts();
  },
  methods:{
    ...mapActions('servicerequest',['getAdminServiceRequests']),
    ...mapMutations('servicerequest',['sortByStatusAdmin']),
    loadCreateForm(){
      this.createform = true
    },
    loadUpdateForm(servicerequest_id,status_id){
      const taskid = new URLSearchParams()
      taskid.append('task_id', servicerequest_id)
      taskid.append('page', "ServiceRequest")
      taskid.append('building_id', localStorage.building_id)
      this.$store.dispatch('notification/change_notification_status_admin_taskid',taskid)
      this.updateform = true
      this.servicerequest_id = servicerequest_id
      this.status_id = status_id
    },
    loadStatusForm(serv_id,flat_group_number){
      this.statusform = true
      this.servicerequest_id = serv_id
      this.flat_group_number = flat_group_number
    },
    format_date(value){
       if (value) {
         return moment(String(value)).format('DD-MM-YYYY')
        }
    },
    showalerts(){
      if (localStorage.status == "created")
      {
        $('.signout_create').delay(500).slideDown(500);
        $('.signout_create').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "updated")
      {
        $('.signout_update').delay(500).slideDown(500);
        $('.signout_update').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "deleted")
      {
        $('.signout_delete').delay(500).slideDown(500);
        $('.signout_delete').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "status_updated")
      {
        $('.status_updated').delay(500).slideDown(500);
        $('.status_updated').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
    }
  },
  watch: {
    admin_servicerequests() {
      $(this.$refs.tables).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.tables).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    }
  }
}
</script>
<style scoped>
.banner-text{
  font-weight:400;
  position:absolute;
  width: 98%;
  margin-top: -5px;
  z-index: 10;
  text-align: center;
  display: none;
}
</style>
