<template>
<!-- Add New Service Popup Model -->
<div id="book-parking" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="top:10%;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">Create Parking</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="card-body">
                   <form class="form" @submit.prevent="createparking">
                                               <div class="col-sm-12">
                                                     <div class="row">
                                                         <div class="col-md-12 ">
                                                             <div class="form-group">
                                                                 <label>Name</label>
                                                                 <input v-model="name"  :class="{ error: v$.name.$error }" class="form-control"  placeholder="Enter Name" >
                                                             </div>
                                                         </div>
                                                     </div>
                                                        <div class="row">
                                                         <div class="col-md-12 ">
                                                             <div class="form-group">
                                                              <label>Category</label>
                                                                <select class="form-control custom-select" v-model="category" :class="{ error: v$.category.$error }">
                                                                    <option  >Select Category</option>
                                                                    <option value="Appartment Reserved">Appartment Reserved</option>
                                                                    <option value="Appartment Additional">Appartment Additional</option>
                                                                    <option value="Common Parking">Common Parking</option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                    </div>
                                                     <div class="col-sm-12">
                                                         <div class="form-group">
                                                             <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                                                 <button type="submit" class="btn btn-success mr-2">Submit</button>
                                                                 <button type="button" class="btn btn-danger mr-2" data-dismiss="modal" aria-hidden="true">Cancel</button>
                                                             </div>
                                                         </div>
                                                     </div>

                                               </div>

                                           </form>
           </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import VueTimepicker from 'vue3-timepicker'
import {mapState} from 'vuex'
import { required } from '@vuelidate/validators'
export default {
  name: 'CreateParking',
  components: {
    VueTimepicker

  },

  setup () {
    return { v$: useVuelidate() }
  },
    validations () {
      return {
     
        name: { required },
         category: { required,
       isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === 'Select Category') return false
        else return true


         }


      },
        
      }
    },
    mounted(){
      
    },
  created: function(){
   
  },
  data(){
     return{
       name: '',
       category: 'Select Category'

     }

   },
  methods:{
     
    createparking(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const parking = new URLSearchParams()
        parking.append('building_id', localStorage.building_id)
        parking.append('name', this.name)
        parking.append('category', this.category)
        this.$store.dispatch('parking/create_parking',parking)

     }

    }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
