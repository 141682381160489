<template>
	<body class="skin-default fixed-layout">
		<!-- ============================================================== -->
	    <!-- Main wrapper - style you can find in pages.scss -->
	    <!-- ============================================================== -->
	    <div id="main-wrapper">
	    	<!-- Page wrapper  -->
	        <!-- ============================================================== -->
	        <UserHeader/>
	        <UserSidebar/>
	        <div class="page-wrapper">
	            <!-- ============================================================== -->
	            <!-- Container fluid  -->
	            <!-- ============================================================== -->
	            <div class="container-fluid">
	                <!-- ============================================================== -->
	                <!-- Bread crumb and right sidebar toggle -->
	                <!-- ============================================================== -->

	                <div class="row page-titles">
                        <div class="alert alert-success signout_create banner-text" role="alert">
                            <span><strong>Success!</strong> You have successfully booked a facility</span>
                        </div>
                         <div class="alert alert-danger signout_delete banner-text" role="alert">
                            <span><strong>Deleted!</strong> You have successfully deleted the booked facility</span>
                        </div>
                        <div class="alert alert-success signout_update banner-text" role="alert">
                            <span><strong>Success!</strong> You have successfully updated the booked facility</span>
                        </div>
												<div class="alert alert-warning flat_approval banner-text" role="alert">
						                <span><strong>Warning!</strong> Your account is not activated</span>
						            </div>
	                    <div class="col-md-5 align-self-center">
	                        <h4 class="text-themecolor">Common Area</h4>
	                    </div>
	                    <div class="col-md-7 align-self-center text-right">
	                        <div class="d-flex justify-content-end align-items-center">
	                            <ol class="breadcrumb">
	                                <li class="breadcrumb-item"><router-link :to="{ name: 'UserHome' }">Home</router-link></li>
	                                <li class="breadcrumb-item active">Common facilities</li>
	                            </ol>
	                            <button type="button" class="btn btn-info d-none d-lg-block m-l-15" data-toggle="modal"
	                                data-target="#book-area" @click="loadCreateForm">
	                                + Book Facility</button>
	                        </div>
	                    </div>
	                </div>
	                <!-- ============================================================== -->
                <!-- End Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Info box -->
                <!-- ============================================================== -->
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row m-t-40">
                                    <!-- Column -->
                                </div>
																<div v-if="booked_list.length <= 0">
		                              <div style ="padding-left:15px;padding-right:15px; width:100%">
		                                                <div class="alert alert-dark">
		                                                    <span>No data to show</span>
		                                                </div>
		                              </div>
		                            </div>
		                            <div v-else>
                                <div class="table-responsive" id="table_areas">
                                    <table id="myTable" class="table  table-striped" ref="tables">
                                        <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th>Common Area</th>
                                                <th>Date</th>
                                                <th>Start Time</th>
                                                <th>End Time</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in booked_list " :key ="item.id">
                                             <td>{{ item.id }}</td>
                                             <td>{{ find_name(item.common_facilities_id) }}</td>
                                             <td>{{ format_date(item.booked_for) }}</td>
                                             <td>{{ find_time(item.start_time) }}</td>
                                             <td>{{ find_time(item.end_time) }}</td>
                                              <td  v-if="item.status == 'accepted'" ><div class="label label-table label-warning" data-toggle="modal"
                                                        data-target="#add-area">Accepted</div></td>
                                             <td  v-else-if="item.status == 'pending'" ><div class="label label-table label-success" data-toggle="modal"
                                                        data-target="#add-area">Pending</div></td>
                                             <td v-if="item.status == 'pending'">
                                                    <div @click="loadUpdateForm(item.id,item.starting_time,item.ending_time)" class="label label-table label-info" data-toggle="modal"
                                                        data-target="#update-booking">Edit</div>
                                                    <div @click="deletebooking(item.id)" class="label label-table label-danger" data-toggle="modal" data-target="#confirm-delete">Delete</div>
                                             </td>
                                             <td v-else></td>
                                            </tr>
                                        </tbody>
                                    </table>
																	</div>
                                </div>
                                <div v-if="createform === true">
                                  <CreateBooking></CreateBooking>
                                </div>

                                <div v-if="updateform == true">
                                  <UpdateBooking :booking_id='booking_id' :starting_time='starting_time' :ending_time='ending_time'></UpdateBooking>
                                </div>
                                <div v-if="deleteform === true">
                                  <DeleteBooking></DeleteBooking>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ============================================================== -->
                <!-- End Info box -->
                <!-- ============================================================== -->
            </div>
        </div>
        <footer class="footer">
            © 2020 www.ontash.net
        </footer>
    </div>
	</body>
</template>

<script>

	// @ is an alias to /src
	import UserSidebar from '@/components/sidebars/UserSidebar.vue'
	import UserHeader from '@/components/headers/UserHeader.vue'
  import CreateBooking from '@/components/facility/CreateBooking.vue'
  import UpdateBooking from '@/components/facility/UpdateBooking.vue'
  import DeleteBooking from '@/components/facility/DeleteBooking.vue'
  import {mapState,mapActions} from 'vuex'
  import moment from 'moment'
	export default {
		name: 'CommonFacilities',
		components: {UserSidebar, UserHeader,CreateBooking,UpdateBooking,DeleteBooking},
    computed:{
        ...mapState('facility',['booked_list','facility_list']),
				...mapState('flat',['appartmentstatus'])

    },
    data(){
      return{
        createform: false,
        updateform: false,
        deleteform: false,
        booking_id: '',
				starting_time: '',
				ending_time: ''
      }
    },
    created: function(){
       const notify = new URLSearchParams()
      notify.append('page', 'CommonFacility')
      notify.append('flat_id',localStorage.flat_id)
      this.$store.dispatch('notification/change_notification_status_user_task_id',notify)
     this.$store.dispatch('facility/list_facility')

    },
    mounted(){
      this.showalerts();
			this.getAppartmentUserStatus();
    },
    methods: {
				...mapActions('flat',['getAppartmentUserStatus']),
        find_name(value){
           if (value) {
             this.facility_list.forEach(function(item){
              if (item.id == value){
                value = item.name
              }
             });
            }
            return value
        },
        find_time(value){
           if (value) {
              value = value.split("T")[1]
              const parts = value.split(":");

              if (parts[0] > 12)
              {
              return +parts[0]-12 + " : " +parts[1]+' PM' ;
              }
              else if (parts[0] == 12){
              return +parts[0]+ " : " +parts[1]+' PM' ;
              }
              else
              {
                if (parts[0] == 0)
                {
                  return +"12"+ " : " +parts[1]+' AM' ;
                }
                else{
                  return +parts[0]+ " : " +parts[1]+' AM' ;
                }

              }
            }

        },
        loadCreateForm(){
					if (this.appartmentstatus == "active"){
	          this.createform = true
	        }
	        else{
	          $('.flat_approval').delay(500).slideDown(500);
	          $('.flat_approval').delay(4000).slideUp(500);
	        }
        },
        deletebooking(id){
           this.deleteform = true
           this.booking_id = id
           localStorage["booking_id"]=this.booking_id
        },
        format_date(value){
           if (value) {

             return moment(String(value)).format('DD-MM-YYYY')
            }
        },
        loadUpdateForm(id,strt_time,end_time){
						//moment(data.booked_slots[0].start_time,'hh:mm A')
						this.starting_time = moment(strt_time,'hh:mm A').format('hh:mm A')
						this.ending_time = moment(end_time,'hh:mm A').format('hh:mm A')
						this.booking_id = id
            this.updateform = true

        },
        showalerts(){
          if (localStorage.status == "created")
          {
            $('.signout_create').delay(500).slideDown(500);
            $('.signout_create').delay(4000).slideUp(500);
            localStorage.removeItem('status')
          }
          else if (localStorage.status == "updated")
          {
            $('.signout_update').delay(500).slideDown(500);
            $('.signout_update').delay(4000).slideUp(500);
            localStorage.removeItem('status')
          }
          else if (localStorage.status == "deleted")
          {
            $('.signout_delete').delay(500).slideDown(500);
            $('.signout_delete').delay(4000).slideUp(500);
            localStorage.removeItem('status')
          }
        }
     },
     watch: {
	      booked_list() {
	        $(this.$refs.tables).DataTable().destroy();
	        this.$nextTick(() => {
	          $(this.$refs.tables).DataTable({
	             destroy: true,
	            "ordering":false
	          });
	        });
	      }
    }
	}

    </script>
