<template>
  <html>
  <body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <UserHeader/>
      <UserSidebar/>
      <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
          <!-- ============================================================== -->
          <!-- Bread crumb and right sidebar toggle -->
          <!-- ============================================================== -->
          <div class="row page-titles">
            <div class="col-md-5 align-self-center">
              <h4 class="text-themecolor">Our Group Members</h4>
            </div>
            <div class="col-md-7 align-self-center text-right">
              <div class="d-flex justify-content-end align-items-center">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><router-link :to="{ name: 'UserHome' }">Home</router-link></li>
                  <li class="breadcrumb-item "><router-link :to="{ name: 'UserGroups' }">Our Groups</router-link></li>
                </ol>
              </div>
            </div>
          </div>
          <!-- ============================================================== -->
          <!-- End Bread crumb and right sidebar toggle -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Info box -->
          <!-- ============================================================== -->
          <div class="row el-element-overlay">
            <div class="col-sm-12 ">
              <div class="row" style="padding:0px 40px;">
                <div class="card card-body">
                  <h4 class="" style="display:flex;">
                    <span class="group-head">{{groupdetails.name}}</span>
                    <div class="form-group" style="display:flex;margin-left:auto;margin-bottom:-15px;">
                    </div>
                  </h4>
                </div>
              </div>
              <div class="row">
                <div class="row" style="display:flex;">
                  <div class="col-lg-3 col-md-6 member" style="padding:0px 50px;;" v-for="groupmember in groupmemeberdetails" :key="groupmember.user_id">
                    <div class="card" @click="showProfile(groupmember.profile_privacy,groupmember.user_id)" v-bind:data-toggle="[groupmember.profile_privacy == false ? 'modal' : '']" v-bind:data-target="[groupmember.profile_privacy == false ? '#privacy-alert' : '']">
                      <div class="el-card-item">
                        <div class="el-card-avatar el-overlay-1">
                          <img :src="groupmember.profile_picture" alt="user" style="width:100%"/>
                        </div>
                        <div class="el-card-content">
                          <h4 class="fonthead">{{groupmember.username}}</h4>
                          <small>{{groupmember.appartment_number}}</small>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="privacy-alert" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="top:20%;" ref="privacymodal">
              <div class="modal-dialog">
                  <div class="modal-content">
                      <div class="modal-header" style="border-bottom: none;!important">
                          <h4 class="modal-title"  style="margin-left: 5%;opacity: 60%;" id="myModalLabel"></h4>
                          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                      </div>
                       <div class="card-body" style="padding: 10px 50px;">
                          <div class="row" style="justify-content: center;">
                              <span class="total_payment"><img src="@/assets/images/private.png" class="private-account"></span>
                          </div>

                              <div class="col-sm-12 private-text" >
                                  <span><strong>Privacy Issue!</strong> The profile is locked!</span>
                              </div>
                          </div>

                  </div>
                  <!-- /.modal-content -->
              </div>
              <!-- /.modal-dialog -->
          </div>

        <!-- ============================================================== -->
        <!-- End Info box -->
        <!-- ============================================================== -->

        <!-- ============================================================== -->
        <!-- Review -->
        <!-- ============================================================== -->

        <!-- ============================================================== -->
        <!-- End Review -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Comment - chats -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- End Comment - chats -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- End Page Content -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Right sidebar -->
        <!-- ============================================================== -->
        <!-- .right-sidebar -->

        <!-- ============================================================== -->
        <!-- End Right sidebar -->
        <!-- ============================================================== -->
      </div>
      <!-- ============================================================== -->
      <!-- End Container fluid  -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->
    <footer class="footer">
      © 2020 www.ontash.net
    </footer>
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->
  </div>
  <!-- ============================================================== -->
  <!-- End Wrapper -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- All Jquery -->
  <!-- ============================================================== -->

</body>

</html>
</template>

<script>
// @ is an alias to /src
import UserHeader from '@/components/headers/UserHeader.vue'
import UserSidebar from '@/components/sidebars/UserSidebar.vue'
import {mapState,mapActions} from 'vuex'
export default {
  name: 'UserGroupMembers',
  components: {
    UserHeader,
    UserSidebar
  },
  computed:{
    ...mapState('group',['groupdetails','groupmemeberdetails'])
  },
  data(){
    return{

    }

  },
  mounted(){
    this.getUserGroupMembers();
  },
  methods:{
    getUserGroupMembers(){
      const groupmembers = new URLSearchParams()
      groupmembers.append('flat_id', this.$route.params.flat_id)
      groupmembers.append('group_id', this.$route.params.group_id)
      this.$store.dispatch('group/getUserGroupMembers',groupmembers)
    },
    showProfile(profile_privacy,user_id){
      if (profile_privacy == true){
        this.$router.push({ name: 'UserGroupMemberDetails', params: { user_id: user_id} })
      }
    }
  }
}
</script>
