<template>
  <div id="nav">

  </div>
  <router-view/>
  <spinner-loader v-if="loading"></spinner-loader>
</template>

<script>
import SpinnerLoader from '@/components/spinner/SpinnerLoader'
import {mapState} from 'vuex'

export default {
  name: 'App',
  components: {
    SpinnerLoader
  },
  computed:{
    ...mapState('spinner',['loading'])
  }
}
</script>
