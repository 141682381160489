import { createRouter, createWebHistory } from 'vue-router'
//login module imports
import Login from '../views/login/Login.vue'
import RequestOtp from '../views/login/RequestOtp.vue'
import SelectRole from '../views/login/SelectRole.vue'
import DeletePolicy from '../views/login/DeletePolicy.vue'
//user module imports
import AddFlat from '../views/login/AddFlat.vue'
import AddFlatUser from '../views/login/AddFlatUser.vue'
import SelectFlat from '../views/login/SelectFlat.vue'
import UserHome from '../views/user/UserHome.vue'
import UserPayments from '../views/user/UserPayments.vue'
import Wallet from '../views/user/Wallet.vue'
import CommonFacilities from '../views/user/CommonFacilities.vue'
import UserComplaints from '../views/user/UserComplaints.vue'
import UserServiceRequests from '../views/user/UserServiceRequests.vue'
import UserGroups from '../views/user/UserGroups.vue'
import UserGroupMembers from '../views/user/UserGroupMembers.vue'
import UserGroupMemberDetails from '../views/user/UserGroupMemberDetails.vue'
import UserEvent from '../views/user/UserEvent.vue'
import PhoneDirectory from '../views/user/PhoneDirectory.vue'
import UserNotification from '../views/user/UserNotification.vue'
import UserAbout from '../views/user/UserAbout.vue'
import UserTermsAndConditions from '../views/user/UserTermsAndConditions.vue'
import UserHelpAndFeedback from '../views/user/UserHelpAndFeedback.vue'
import UserProfile from '../views/profile/UserProfile.vue'
import ConfirmPayment from '../views/user/ConfirmPayment'
import WalletStatus from '../views/user/WalletStatus'
import UserVisitorPass from '../views/user/UserVisitorPass'
import UserReportIssue from '../views/user/UserReportIssue'

//admin module imports
import AdminHome from '../views/admin/AdminHome.vue'
import AdminProfile from '../views/profile/AdminProfile.vue'
import AdminUserList from '../views/admin/AdminUserList.vue'
import AdminComplaints from '../views/admin/AdminComplaints.vue'
import AdminServiceRequests from '../views/admin/AdminServiceRequests.vue'
import AdminService from '../views/admin/AdminService.vue'
import AdminEvent from '../views/admin/AdminEvent.vue'
import AdminDirectory from '../views/admin/AdminDirectory.vue'
import AdminMessage from '../views/admin/AdminMessage.vue'
import AdminCommonFacilities from '../views/admin/AdminCommonFacilities.vue'
import AdminNotifications from '../views/admin/AdminNotifications.vue'
import AdminAbout from '../views/admin/AdminAbout.vue'
import AdminHelpAndFeedback from '../views/admin/AdminHelpAndFeedback.vue'
import AdminTermsAndConditions from '../views/admin/AdminTermsAndConditions.vue'
import AdminBanks from '../views/admin/AdminBanks.vue'
import AdminSubscriptionCharge from '../views/admin/AdminSubscriptionCharge'
import AdminMyPayment from '../views/admin/AdminMyPayment'
import AdminPayment from '../views/admin/AdminPayment'
import AdminConfirmPayment from '../views/admin/AdminConfirmPayment'
import AdminVisitorPass from '../views/admin/AdminVisitorPass'
import AdminReportIssue from '../views/admin/AdminReportIssue'
//report module imports
import AdminReports from '../views/report/AdminReports.vue'
import AdminParking from '../views/admin/AdminParking.vue'
//super admin routes
import SuperAdminHome from '../views/superadmin/SuperAdminHome.vue'
import SuperAdminHelpAndFeedback from '../views/superadmin/SuperAdminHelpAndFeedback.vue'
import SuperAdminAbout from '../views/superadmin/SuperAdminAbout.vue'
import SuperAdminTermsAndConditions from '../views/superadmin/SuperAdminTermsAndConditions.vue'
import SuperAdminSubscriptionCharge from '../views/superadmin/SuperAdminSubscriptionCharge.vue'
import SuperAdminBuildingBanks from '../views/superadmin/SuperAdminBuildingBanks.vue'
import SuperAdminAppartmentlist from '../views/superadmin/SuperAdminAppartmentlist'
import SuperAdminAuditFeature from '../views/superadmin/SuperAdminAuditFeature'
import SuperAdminReportIssues from '../views/superadmin/SuperAdminReportIssues'
import SuperAdminActivitySummary from '../views/superadmin/SuperAdminActivitySummary'
import SuperAdminTrailPaidSummary from '../views/superadmin/SuperAdminTrailPaidSummary'

//File not NotFound
import FileNotFound from '../views/filenotfound/FileNotFound.vue'


const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
        noAuthNeeded: true
      }
  },
  {
    path: '/selectrole',
    name: 'SelectRole',
    component: SelectRole,
    meta: {
        noAuthNeeded: true,
        selrole: 'role'
      }
  },
  {
    path: '/deletepolicy',
    name: 'deletepolicy',
    component: DeletePolicy,
    meta: {
        noAuthNeeded: true,
      }
  },
  //user
  {
    path: '/userpayments',
    name: 'UserPayments',
    component: UserPayments,
    meta: {
        role: "User"
      }
  },
  {
    path: '/mywallet',
    name: 'Wallet',
    component: Wallet,
    params: true,
    meta: {
        role: "User"
      }
  },
  {
    path: '/commonfacilities',
    name: 'CommonFacilities',
    component: CommonFacilities,
    meta: {
        role: "User"
      }
  },
  {
    path: '/requestotp/:phone_no',
    name: 'RequestOtp',
    component: RequestOtp,
    params: true,
    meta: {
        noAuthNeeded: true
      }
  },
  //user module routes
  {
    path: '/addflat',
    name: 'AddFlat',
    component: AddFlat,
    meta: {
        role: "User",
        page: 'addflat'
      }
  },
   {
    path: '/addnewflat',
    name: 'AddFlatUser',
    component: AddFlatUser,
    meta: {
        role: "User"
      }
  },
  {
    path: '/selectflat',
    name: 'SelectFlat',
    component: SelectFlat,
    meta: {
        role: "User",
        page: 'selectflat'
      }
  },
  {
    path: '/userhome',
    name: 'UserHome',
    component: UserHome,
    meta: {
        role: "User"
      }
  },
  {
    path: '/usercomplaints',
    name: 'UserComplaints',
    component: UserComplaints,
    meta: {
        role: "User"
      }
  },
  {
    path: '/userservicerequests',
    name: 'UserServiceRequests',
    component: UserServiceRequests,
    meta: {
        role: "User"
      }
  },
  {
    path: '/usergroups',
    name: 'UserGroups',
    component: UserGroups,
    meta: {
        role: "User"
      }
  },
  {
    path: '/usergroupmembers/:flat_id/:group_id',
    name: 'UserGroupMembers',
    component: UserGroupMembers,
    params: true,
    meta: {
        role: "User"
      }
  },
  {
    path: '/usergroupmemberdetails/:user_id',
    name: 'UserGroupMemberDetails',
    component: UserGroupMemberDetails,
    params: true,
    meta: {
        role: "User"
      }
  },
  {
    path: '/userevent',
    name: 'UserEvent',
    component: UserEvent,
    meta: {
        role: "User"
      }
  },
  {
    path: '/phonedirectory',
    name: 'PhoneDirectory',
    component: PhoneDirectory,
    meta: {
        role: "User"
      }
  },
  {
    path: '/usernotification',
    name: 'UserNotification',
    component: UserNotification,
    meta: {
        role: "User"
      }
  },
  {
    path: '/userabout',
    name: 'UserAbout',
    component: UserAbout,
    meta: {
        role: "User"
      }
  },
  {
    path: '/usertermsandconditions',
    name: 'UserTermsAndConditions',
    component: UserTermsAndConditions,
    meta: {
        role: "User"
      }
  },
  {
    path: '/userhelpandfeedback',
    name: 'UserHelpAndFeedback',
    component: UserHelpAndFeedback,
    meta: {
        role: "User"
      }

  },
  {
    path: '/userprofile',
    name: 'UserProfile',
    component: UserProfile,
    meta: {
        role: "User"
      }

  },
  {
    path: '/confirm_payment',
    name: 'ConfirmPayment',
    component: ConfirmPayment,
    meta: {
        role: "User"
      }

  },
  {
    path: '/wallet_status',
    name: 'WalletStatus',
    component: WalletStatus,
    meta: {
        role: "User"
      }

  },
  {
    path: '/user_visitor_pass',
    name: 'UserVisitorPass',
    component: UserVisitorPass,
    meta: {
        role: "User"
      }

  },
  {
    path: '/userreportissue',
    name: 'UserReportIssue',
    component: UserReportIssue,
    meta: {
        role: "User"
      }

  },
  //Admin Module routes
  {
    path: '/adminhome',
    name: 'AdminHome',
    component: AdminHome,
    meta: {
        role: "Admin"
      }
  },
  {
    path: '/adminprofile',
    name: 'AdminProfile',
    component: AdminProfile,

    meta: {
        role: "Admin"
      }
  },
  {
    path: '/adminevent',
    name: 'AdminEvent',
    component: AdminEvent,

    meta: {
        role: "Admin"
      }
  },
  {
    path: '/adminabout',
    name: 'AdminAbout',
    component: AdminAbout,
    meta: {
        role: "Admin"
      }
  },
  {
    path: '/adminhelpandfeedback',
    name: 'AdminHelpAndFeedback',
    component: AdminHelpAndFeedback,
    meta: {
        role: "Admin"
      }

  },
  {
    path: '/admintermsandconditions',
    name: 'AdminTermsAndConditions',
    component: AdminTermsAndConditions,
    meta: {
        role: "Admin"
      }

  },
  {
    path: '/admincomplaints',
    name: 'AdminComplaints',
    component: AdminComplaints,
    meta: {
        role: "Admin"
      }

  },
  {
    path: '/adminservicerequests',
    name: 'AdminServiceRequests',
    component: AdminServiceRequests,
    meta: {
        role: "Admin"
      }

  },
  {
    path: '/adminservice',
    name: 'AdminService',
    component: AdminService,
    meta: {
        role: "Admin"
      }

  },
  {
    path: '/admincommonfacilities',
    name: 'AdminCommonFacilities',
    component: AdminCommonFacilities,
    meta: {
        role: "Admin"
      }

  },
  {
    path: '/adminnotifications',
    name: 'AdminNotifications',
    component: AdminNotifications,
    meta: {
        role: "Admin"
      }

  },
  {
    path: '/adminuserlist',
    name: 'AdminUserList',
    component: AdminUserList,
    meta: {
        role: "Admin"
      }
  },
  {
    path: '/admindirectory',
    name: 'AdminDirectory',
    component: AdminDirectory,
    meta: {
        role: "Admin"
      }
  },
  {
    path: '/adminmessage',
    name: 'AdminMessage',
    component: AdminMessage,
    meta: {
        role: "Admin"
      }
  },
  {
    path: '/adminbanks',
    name: 'AdminBanks',
    component: AdminBanks,
    meta: {
        role: "Admin"
      }
  },

  //report module route
  {
    path: '/adminreports',
    name: 'AdminReports',
    component: AdminReports,
    meta: {
        role: "Admin"
      }

  },
  {
    path: '/adminmypayment',
    name: 'AdminMyPayment',
    component: AdminMyPayment,
    meta: {
        role: "Admin"
      }

  },
  {
    path: '/adminpayment',
    name: 'AdminPayment',
    component: AdminPayment,
    meta: {
        role: "Admin"
      }

  },
  {
    path: '/adminsubscriptioncharge',
    name: 'AdminSubscriptionCharge',
    component: AdminSubscriptionCharge,
    meta: {
        role: "Admin"
      }

  },
  {
    path: '/adminconfirmpayment',
    name: 'AdminConfirmPayment',
    component: AdminConfirmPayment,
    meta: {
        role: "Admin"
      }

  },
  {
    path: '/adminreportissue',
    name: 'AdminReportIssue',
    component: AdminReportIssue,
    meta: {
        role: "Admin"
      }

  },
  {
    path: '/admin_visitor_pass',
    name: 'AdminVisitorPass',
    component: AdminVisitorPass,
    meta: {
        role: "Admin"
      }

  },
  {
    path: '/adminparking',
    name: 'AdminParking',
    component: AdminParking,
    meta: {
        role: "Admin"
      }

  },
  {
    path: '/superadminhome',
    name: 'SuperAdminHome',
    component: SuperAdminHome,
    meta: {
        role: "SuperAdmin"
      }

  },
  {
    path: '/superadminhelpAndfeedback',
    name: 'SuperAdminHelpAndFeedback',
    component: SuperAdminHelpAndFeedback,
    meta: {
        role: "SuperAdmin"
      }

  },
  {
    path: '/superadminabout',
    name: 'SuperAdminAbout',
    component: SuperAdminAbout,
    meta: {
        role: "SuperAdmin"
      }

  },
  {
    path: '/superadminabout',
    name: 'SuperAdminAbout',
    component: SuperAdminAbout,
    meta: {
        role: "SuperAdmin"
      }

  },
  {
    path: '/superadmintermsandconditions',
    name: 'SuperAdminTermsAndConditions',
    component: SuperAdminTermsAndConditions,
    meta: {
        role: "SuperAdmin"
      }

  },
  {
    path: '/superadminsubscriptioncharge',
    name: 'SuperAdminSubscriptionCharge',
    component: SuperAdminSubscriptionCharge,
    meta: {
        role: "SuperAdmin"
      }

  },

  {
    path: '/superadminbuildingbanks',
    name: 'SuperAdminBuildingBanks',
    component: SuperAdminBuildingBanks,
    meta: {
        role: "SuperAdmin"
      }
  },

  {
    path: '/superadmin_appartment_list',
    name: 'SuperAdminAppartmentlist',
    component: SuperAdminAppartmentlist,
    meta: {
        role: "SuperAdmin"
      }
  },

  {
    path: '/superadminauditfeature',
    name: 'SuperAdminAuditFeature',
    component: SuperAdminAuditFeature,
    meta: {
        role: "SuperAdmin"
      }
  },

   {
    path: '/superadminreportissues',
    name: 'SuperAdminReportIssues',
    component: SuperAdminReportIssues,
    meta: {
        role: "SuperAdmin"
      }
  },
  {
   path: '/superadminactivitysummary',
   name: 'SuperAdminActivitySummary',
   component: SuperAdminActivitySummary,
   meta: {
       role: "SuperAdmin"
     }
 },
 {
  path: '/superadmintrailpaidsummary',
  name: 'SuperAdminTrailPaidSummary',
  component: SuperAdminTrailPaidSummary,
  meta: {
      role: "SuperAdmin"
    }
},


  {
    path: '/:catchAll(.*)',
    name: 'FileNotFound',
    component: FileNotFound
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Nav Guard
router.beforeEach((to, from, next) => {
  // Check for requiresAuth guard
  if (localStorage.SignedIn && !to.meta.noAuthNeeded) {
    if(localStorage.user_role){
      if(localStorage.user_role == to.meta.role){
        next();
      }
      else {
        var pa = (localStorage.user_role).toLowerCase()
        next({
          path: '/'+pa+'home'
        });
      }
    }
    else{
      next({
        path: ''
      })
    }
  }
  else if(localStorage.SignedIn && to.meta.noAuthNeeded && to.meta.selrole == 'role'){
    next();
  }
  else if(localStorage.SignedIn && to.meta.noAuthNeeded && to.meta.role){
    var pa = (localStorage.user_role).toLowerCase()
    next({
      path: '/'+pa+'home'
    });
  }
  else if(localStorage.SignedIn && to.meta.noAuthNeeded && localStorage.building_id){
    var pa = (localStorage.user_role).toLowerCase()
    next({
      path: '/'+pa+'home'
    });
  }
  else if(localStorage.SignedIn && to.meta.noAuthNeeded && !localStorage.building_id){
    next({
      path: ''
    })
  }
  else if(to.meta.noAuthNeeded){
    next()
  }
  else{
    next({
      path: ''
    })
  }
});

export default router
