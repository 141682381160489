<template>
<aside class="left-sidebar">
<!-- Sidebar scroll-->
  <div class="scroll-sidebar">
  <!-- User Profile-->
  <div class="user-profile">
  <div class="user-pro-body">
  <div>
    <img :src="profilepic" alt="user-img" class="img-circle" style="width:60%;" data-toggle="modal" data-target="#edit-profile-pic" @click="viewImageUpload">
  </div>
  <div class="dropdown">
    <a href="javascript:void(0)" class="dropdown-toggle u-dropdown link hide-menu" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
      {{user_name}}
      <span class="caret"><br/>{{building_name}}/{{flat_name}}</span>
    </a>
  <div class="dropdown-menu animated flipInY">
    <!-- text-->
    <router-link :to="{ name: 'UserProfile' }">
      <a href="javascript:void(0)" class="dropdown-item">
        <i class="ti-user"></i> My Profile</a>
    </router-link>
      <div class="dropdown-item flat-parent">
        <i class="fa fas fa-toggle-on"></i>Checkout Flat</div>
          <div v-for="appartment in appartmentlist" :key="appartment.id">
            <div @click="checkoutFlat(appartment.id,appartment.number)">{{appartment.number}}</div>
          </div>
            <div class="dropdown-item" @click="logoutUser">
              <i class="fa fa-power-off"></i> Logout</div>
              <!-- text-->
            </div>
          </div>
        </div>
      </div>
      <!-- Sidebar navigation-->
      <nav class="sidebar-nav">
        <ul id="sidebarnav">
          <li>
            <router-link :to="{ name: 'UserComplaints' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa fas fa-list-alt"></i>
              <span class="hide-menu">Complaints</span>
              <span v-if="complaint_count >= 1" :class="[complaint_count >= 1 ? ['badge','badge-pill','badge-cyan','ml-auto'] : '']">{{complaint_count}}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'UserServiceRequests' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa fas fa-user-plus"></i>
              <span class="hide-menu">Service Request</span>
              <span v-if="service_count >= 1" :class="[service_count >= 1 ? ['badge','badge-pill','badge-cyan','ml-auto'] : '']">{{service_count}}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'UserPayments' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa far fa-credit-card"></i>
              <span class="hide-menu">Make Payment</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'UserVisitorPass' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa far fa-address-card"></i>
              <span class="hide-menu">Visitor Pass</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'UserEvent' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa far fa-calendar-alt"></i>
              <span class="hide-menu">Events</span>
              <span v-if="event_count >= 1" :class="[event_count >= 1 ? ['badge','badge-pill','badge-cyan','ml-auto'] : '']">{{event_count}}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'CommonFacilities' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa far fa-building"></i>
              <span class="hide-menu">Common Facilities</span>
              <span v-if="facility_count >= 1" :class="[facility_count >= 1 ? ['badge','badge-pill','badge-cyan','ml-auto'] : '']">{{facility_count}}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Wallet' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa far fa-credit-card"></i>
              <span class="hide-menu">My Wallet</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'UserNotification' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa far fa-bell"></i>
              <span class="hide-menu">Notifications
              <span v-if="notification_count >= 1" :class="[notification_count >= 1 ? ['badge','badge-pill','badge-cyan','ml-auto'] : '']">{{notification_count}}</span>
              </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'UserGroups' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa fas fa-users"></i>
              <span class="hide-menu">Our Groups</span>
              <span v-if="group_count >= 1" :class="[group_count >= 1 ? ['badge','badge-pill','badge-cyan','ml-auto'] : '']">{{group_count}}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'PhoneDirectory' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa fas fa-address-book"></i>
              <span class="hide-menu">Phone Directory</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'UserReportIssue' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa fas fa-bug "></i>
              <span class="hide-menu">Report Issue</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'UserHelpAndFeedback' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa fas fa-question-circle"></i>
              <span class="hide-menu">Help & Feedback</span>
            </router-link>
          </li>
        </ul>
      </nav>
      <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
  </aside>
  <div v-if="imagemodal == true">
    <ProfilePicture :image_url = 'profilepic'></ProfilePicture>
  </div>
</template>

<script>
// @ is an alias to /src
import ProfilePicture from '@/components/sidebars/ProfilePicture.vue'
import {mapState,mapActions} from 'vuex'
export default {
  name: 'UserSidebar',
  components: {
    ProfilePicture
  },
  data(){
    return{
      flat_name: localStorage.flat_name,
      building_name: localStorage.building_name,
      user_name: localStorage.user_name,
      imagemodal: false
    }

  },
  computed:{
    ...mapState('profile',['profilepic']),
    ...mapState('notification',['notification_count','complaint_count','service_count','event_count','facility_count','group_count']),
    ...mapState('flat',['appartmentlist'])
  },
  mounted(){
    this.getProfileImage();
    this.get_notification_count();
    this.get_appartment_details_checkout();

  },
  methods:{
    ...mapActions('profile',['getProfileImage']),

    logoutUser(){
      this.$store.dispatch('login/logoutUser')
    },
    viewImageUpload(){
      this.imagemodal = true;
    },
    get_notification_count(){
      this.$store.dispatch('notification/list_notifications')
    },
    checkoutFlat(appartment_id,appartment_name)
    {
      localStorage["flat_name"]= appartment_name
      localStorage["flat_id"] = appartment_id
      this.$router.go('')
    },
    get_appartment_details_checkout(){
      const appartment = new URLSearchParams()
			appartment.append('building_id', localStorage["building_id"])
			appartment.append('user_id',localStorage["user_id"])
      this.$store.dispatch('flat/get_appartment_details_checkout',appartment)
    }
  }
}
</script>

<style>
    @media screen and (min-width: 992px) {
        .flat{
    display:none;
    color:#01c0c8!important;
    width: 100%;
    padding:6px 25px;
    }
    .flat:hover{
    color:tomato!important;
}
}
@media screen and (max-width: 600px){
    .flat{
    display:inline-flex !important;
    color:#01c0c8!important;
    width: 100%;
    padding:6px 25px !important;
}
}
@media screen and (max-width: 768px) {
    .flat{
    display:inline-flex !important;
    color:#01c0c8!important;
    width: 100%;
    padding:6px 25px !important;
}
}
</style>
