<template>
  <div id="delete-parking" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
              <div class="modal-content">
                  <div class="modal-header">
                      <h4 class="modal-title" id="myModalLabel">Delete Parking</h4>
                      <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                  </div>
                  <div class="card-body" style="padding: 25px 50px !important;">
                      <form class="form" @submit.prevent="delete_parking">
                            <label for="example-text-input" class="col-form-label" style="font-weight: 500;margin-left: 10px;">Parkings</label>
                            <div class="col-12">
                                <select  class="form-control" v-model="parking" @change="getselectedvalue($event.target.selectedIndex)"  :class="{ error: v$.parking.$error } ">
                                  <option >Select Parking</option>
                                   <option v-for="item in parkings" :value="item" :key ="item.id">{{item.name}}</option>
                                </select>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Description</label>
                                    <div class="col-12">
                                        <textarea class="form-control" rows="5"  placeholder="Enter Description" v-model="description" :class="{ error: v$.description.$error }"></textarea>
                                    </div>
                                </div>
                            </div>
                          <div class="col-sm-12">
                              <div class="form-group">
                                  <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                    <button type="submit" class="btn btn-success mr-2">Submit</button>
                                      <button type="button" class="btn btn-danger mr-2" data-dismiss="modal"
                                          aria-hidden="true">Cancel</button>
                                  </div>
                              </div>
                          </div>

                      </form>
                  </div>
              </div>
              <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
      </div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required} from '@vuelidate/validators'
import {mapState} from 'vuex'
export default {
  name: 'DeleteParking',
  components: {

  },
  props:['parkings'],
   data() {
    return {
      description: '',
      parking: 'Select Parking',
      parking_id: ''
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations: {

    description: {
      required

    },
    parking: {
      required,
     isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === 'Select Parking') return false
        else return true


         } },
  },
  computed:{

  },

  methods:{


    closeModal(){
      this.buttonactive = false
    },
    getselectedvalue(selectedIndex)
    {

      var selectedIdea = this.parkings[selectedIndex-1];
      this.parking_id =  selectedIdea.id

    },
    delete_parking(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const parking = new URLSearchParams()
        parking.append('flat_id',localStorage["flat_id"])
        parking.append('parking_id', this.parking_id)
        parking.append('description', this.description)
        this.$store.dispatch('parking/delete_parking_request',parking)
      }
    }
  },

}

</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
