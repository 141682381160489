<template>
	<body class="skin-default fixed-layout">
	<!--     <div class="black">
	        <div class="black-row">
	            <div class="spinner-grow spin" role="status">
	            </div>
	            <div class="spinner-grow spin2" role="status">
	            </div>
	            <div class="spinner-grow spin" role="status">
	            </div>
	        </div>
	    </div>
	   -->
	<!-- ============================================================== -->
	<!-- Main wrapper - style you can find in pages.scss -->
	<!-- ============================================================== -->
	<div id="main-wrapper">
		    <AdminHeader/>
		  <AdminSidebar/>
	    <!-- ============================================================== -->
	    <!-- Topbar header - style you can find in pages.scss -->
	    <!-- ============================================================== -->
	    <!-- ============================================================== -->
	    <!-- End Topbar header -->
	    <!-- ============================================================== -->
	    <!-- ============================================================== -->
	    <!-- Left Sidebar - style you can find in sidebar.scss  -->
	    <!-- ============================================================== -->

	    <!-- ============================================================== -->
	    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
	    <!-- ============================================================== -->
	    <!-- ============================================================== -->
	    <!-- Page wrapper  -->
	    <!-- ============================================================== -->
	    <div class="page-wrapper">
	        <!-- ============================================================== -->
	        <!-- Container fluid  -->
	        <!-- ============================================================== -->
	        <div class="container-fluid">
	            <!-- ============================================================== -->
	            <!-- Bread crumb and right sidebar toggle -->
	            <!-- ============================================================== -->
	            <div class="row page-titles">
	                <div class="col-md-5 align-self-center">
	                    <h4 class="text-themecolor">Profile</h4>
	                </div>
	                <div class="alert alert-success signout_name banner-text" role="alert">
	                    <span><strong>Success!</strong> Name updated succesfully</span>
	                </div>
	                <div class="alert alert-success signout_building_name banner-text" role="alert">
	                    <span><strong>Success!</strong> Building name updated succesfully</span>
	                </div>
	                 <div class="alert alert-success signout_email banner-text" role="alert">
	                    <span><strong>Success!</strong> Email updated succesfully</span>
	                </div>
	                 <div class="alert alert-success signout_phone banner-text" role="alert">
	                    <span><strong>Success!</strong> Phone number updated succesfully</span>
	                </div>
	                 <div class="alert alert-success signout_privacy banner-text" role="alert">
	                    <span><strong>Success!</strong> Privacy updated succesfully</span>
	                </div>
	                 <div class="alert alert-success signout_address banner-text" role="alert">
	                    <span><strong>Success!</strong> Address updated succesfully</span>
	                </div>
									<div class="alert alert-warning wrong_otp banner-text" role="alert">
										 <span><strong>Warning!</strong> OTP you entered is wrong. Please try again</span>
								 </div>
	                <div class="col-md-7 align-self-center text-right">
	                    <div class="d-flex justify-content-end align-items-center">
	                        <ol class="breadcrumb">
	                            <li class="breadcrumb-item"><router-link :to="{ name: 'AdminHome' }">Home</router-link></li>
	                            <li class="breadcrumb-item active">Profile</li>
	                        </ol>
	                    </div>
	                </div>
	            </div>
	            <!-- ============================================================== -->
	            <!-- End Bread crumb and right sidebar toggle -->
	            <!-- ============================================================== -->
	            <!-- ============================================================== -->
	            <!-- Start Page Content -->
	            <!-- ============================================================== -->
	            <!-- Row -->

	            <div class="row">
	                <!-- Column -->
	                <div class="col-lg-4 col-xlg-3 col-md-5">
	                    <div class="card">
	                        <div class="card-body">
	                            <center class="m-t-30">
	                                <div class="image-container">
	                                    <div class="book">

	                                        <div class="imagehere">
	                                            <div class="containers" >

	                                                <div class="overlay">
	                                                    <a href="#" class="icon" title="Update Profile Photo">
	                                                        <img id="croppingImage" class="img-circle" :src="profile_picture" width="150" height="150" data-toggle="modal" data-target="#edit-profile-pic" @click="viewImageUpload">
	                                                    </a>
	                                                </div>
	                                            </div>
	                                        </div>
	                                    </div>

	                                </div>
	                                <h4 class="card-title m-t-10">{{building_name}}</h4>

	                            </center>
	                        </div>
	                        <div>
	                            <hr>
	                        </div>

	                        <div class="card-body">
                                <small class="text-muted">Caretaker Name:</small>
                                <h6>{{caretaker_name_admin}}</h6>
                                <small class="text-muted">Email</small>
                                <h6>{{caretaker_email_admin}}</h6>
                                <small class="text-muted p-t-30 db">Phone</small>
                                <h6>{{caretaker_phone_admin}}</h6>
                            </div>
	                    </div>
	                </div>
	                <!-- Column -->
	                <!-- Column -->
	                <div class="col-lg-8 col-xlg-9 col-md-7">
	                    <div class="card">
	                        <!-- Nav tabs -->
	                        <ul class="nav nav-tabs profile-tab" role="tablist">
	                        	 <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#settings"
	                                    role="tab">Admin Settings</a> </li>
	                            <li class="nav-item"> <a class="nav-link " data-toggle="tab" href="#home" role="tab">Your
	                                    Activities</a> </li>
	                        </ul>
	                        <!-- Tab panes -->
	                        <div class="tab-content">
	                            <div class="tab-pane" id="home" role="tabpanel">
	                                <div class="card-body">
	                                    <div class="card">
	                                        <div class="card-body">
	                                            <h4 class="card-title">Your Activities</h4>
	                                            <div class="table-responsive m-t-40" style="margin-top:0px;">
	                                                <table id="myTable" class="table table-bordered table-striped" ref="tables">
	                                                    <thead>
	                                                        <tr>
	                                                            <th>Activity</th>
	                                                            <th>Date</th>
	                                                        </tr>
	                                                    </thead>
	                                                    <tbody>
	                                                       <tr v-for="item in activities_admin " :key ="item.id">
	                                                       	<td>{{ item.activity_log }}</td>
	                                                       	<td>{{ format_date(item.created_at) }}</td>
	                                                       </tr>
	                                                    </tbody>
	                                                </table>
	                                            </div>
	                                        </div>
	                                    </div>
	                                </div>
	                            </div>
	                            <!--second tab-->
	                            <div class="tab-pane" id="profile" role="tabpanel">
	                                <div class="card-body">
	                                    <div class="row">
	                                        Your payment and pending history
	                                    </div>
	                                </div>
	                            </div>
	                            <div class="tab-pane active" id="settings" role="tabpanel">
	                                <div class="card-body">
	                                    <form class="form-horizontal form-material">
	                                        <div class="form-group">
	                                            <label class="col-md-12">Building Name</label>
	                                            <div class="col-md-12 input-container profile-edit" >
	                                                <input type="text"  v-model="building_name" :placeholder="[[ building_name ]]" class="form-control form-control-line" disabled>
	                                                <span style="margin-left:3px;color:green" data-toggle="modal"
	                                                    data-target="#edit-buildingname" @click="update_building_name">
	                                                    <i class="fa fas fa-edit" data-toggle="tooltip" data-placement="bottom"
	                                                        title="Change Full Name!"></i></span>
	                                            </div>
	                                        </div>
	                                        <div class="form-group">
	                                            <label class="col-md-12">Admin Name</label>
	                                            <div class="col-md-12 input-container profile-edit" >
	                                                <input type="text"  v-model="admin_name" :placeholder="[[ admin_name ]]" class="form-control form-control-line" disabled>
	                                                <span style="margin-left:3px;color:green" data-toggle="modal"
	                                                    data-target="#edit-fullname" @click="update_name">
	                                                    <i class="fa fas fa-edit" data-toggle="tooltip" data-placement="bottom"
	                                                        title="Change Full Name!"></i></span>
	                                            </div>
	                                        </div>
	                                        <div class="form-group">
	                                            <label for="example-email" class="col-md-12">Email</label>
	                                            <div class="col-md-12 input-container profile-edit">
	                                                <input type="email" v-model="useremail" :placeholder="[[ useremail_admin ]]" class="form-control form-control-line" disabled>
	                                                <span style="margin-left:3px;color:green" data-toggle="modal"
	                                                    data-target="#edit-email_admin" @click="update_email" >
	                                                    <i class="fa fas fa-edit" data-toggle="tooltip" data-placement="bottom"
	                                                        title="Change Email!"></i></span>
	                                            </div>
	                                        </div>
	                                        <div class="form-group">
	                                            <label class="col-md-12">Phone No</label>
	                                            <div class="col-md-12 input-container profile-edit">
	                                                <input type="text" v-model="phone" :placeholder="[[ phone_admin ]]" class="form-control form-control-line" disabled>
	                                                <span style="margin-left:3px;color:green" data-toggle="modal"
	                                                    data-target="#edit-ph_admin" @click="update_phone">
	                                                    <i class="fa fas fa-edit" data-toggle="tooltip" data-placement="bottom"
	                                                        title="Change Phone No!"></i></span>
	                                            </div>
	                                        </div>
	                                          <div class="form-group">
	                                            <label class="col-md-12">Address</label>
	                                            <div class="col-md-12 input-container profile-edit">
	                                                  <textarea rows="4" id ="address" class="form-control form-control-line" disabled>{{address}}</textarea>
	                                                <span style="margin-left:3px;color:green" data-toggle="modal"
	                                                    data-target="#edit-address" @click="update_address">
	                                                    <i class="fa fas fa-edit" data-toggle="tooltip" data-placement="bottom"
	                                                        title="Change Phone No!"></i></span>
	                                            </div>
                                            </div>
	                                    </form>
	                                </div>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	                <!-- Column -->
	            </div>

	            <!-- Row -->
	            <!-- ============================================================== -->
	            <!-- End PAge Content -->
	            <!-- ============================================================== -->
	            <!-- ============================================================== -->
	            <!-- Right sidebar -->
	            <!-- ============================================================== -->
	            <!-- .right-sidebar -->

	            <!-- ============================================================== -->
	            <!-- End Right sidebar -->
	            <!-- ============================================================== -->
	        </div>
	        <!-- ============================================================== -->
	        <!-- End Container fluid  -->
	        <!-- ============================================================== -->
	    </div>

	    <!-- Edti full name -->
	    <div v-if="updateusername == true">
              <UpdateName :name_='admin_name' ></UpdateName>
            </div>
         <div v-if="updateuseremail == true">
              <UpdateEmailAdmin :email_='useremail_admin' :phone_="phone_admin" ></UpdateEmailAdmin>
          </div>
	    <!--Edit email  -->
	    <!-- Edit phone number -->
	    <div v-if="updateuserphone == true">
              <UpdatePhoneAdmin :phone_='phone_admin' ></UpdatePhoneAdmin>
        </div>
        <div v-if="Updatebuildingname == true">
              <UpdateBuildingName :building_name_ ='building_name' ></UpdateBuildingName>
        </div>
        <div v-if="Updateaddress == true">
              <UpdateAddress :address_ ='address' ></UpdateAddress>
        </div>
				<div v-if="imagemodal == true">
			    <ProfilePicture :image_url = 'profile_picture'></ProfilePicture>
			  </div>
	    <!-- Change password -->

	    <!-- ============================================================== -->
	    <!-- End Page wrapper  -->
	    <!-- ============================================================== -->
	    <!-- ============================================================== -->
	    <!-- footer -->
	    <!-- ============================================================== -->

	    <footer class="footer">
	        © 2020 www.ontash.net
	    </footer>
	    <!-- ============================================================== -->
	    <!-- End footer -->
	    <!-- ============================================================== -->
	</div>
</body>
</template>
<script>

	// @ is an alias to /src
import AdminHeader from '@/components/headers/AdminHeader.vue'
import AdminSidebar from '@/components/sidebars/AdminSidebar.vue'
import UpdateBuildingName from '@/components/profile/UpdateBuildingName.vue'
import UpdateName from '@/components/profile/UpdateName.vue'
import UpdateEmailAdmin from '@/components/profile/UpdateEmailAdmin.vue'
import UpdatePhoneAdmin from '@/components/profile/UpdatePhoneAdmin.vue'
import UpdateAddress from '@/components/profile/UpdateAddress.vue'
import ProfilePicture from '@/components/sidebars/ProfilePicture.vue'
// import UpdateComplaint from '@/components/complaints/UpdateComplaint.vue'
import {mapState} from 'vuex'
import moment from 'moment'
export default {
  name: 'UserProfile',
  components: {AdminHeader, AdminSidebar, UpdateName,UpdatePhoneAdmin,
		UpdateBuildingName,UpdateEmailAdmin,UpdateAddress,ProfilePicture
	},

  computed:{
    ...mapState('profile',['profile_picture','useremail_admin','phone_admin','admin_name','building_name','profile_admin','caretaker_name_admin','caretaker_email_admin','caretaker_phone_admin','activities_admin','address']),
  },
  data(){
      return{
				useremail: '',
				phone: '',
        updateusername: false,
        updateuseremail: false,
        updateuserphone: false,
        Updatebuildingname: false,
        Updateaddress: false,
        email_: '',
        status_id: '',
        building_name_: '',
				imagemodal: false
     }
  },
  mounted(){
      this.showalerts();
			this.$store.dispatch('profile/show_admin_profile')
  },
  methods: {
  	update_name(){
        this.name_ = this.name
        this.updateusername = true
  	},
  	update_email(){

        this.updateuseremail = true
  	},
  	update_phone(){

        this.updateuserphone = true
  	},
  	update_building_name(){
  	    this.Updatebuildingname = true

  	},
  	update_address(){
  		this.Updateaddress = true
  	},
		viewImageUpload(){
			console.log("Hii")
      this.imagemodal = true;
    },
    format_date(value){
       if (value) {
         return moment(String(value)).format('DD-MM-YYYY')
        }
    },
    showalerts(){
      if (localStorage.status == "email_updated")
      {
        $('.signout_email').delay(500).slideDown(500);
        $('.signout_email').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "phone_updated")
      {
        $('.signout_phone').delay(500).slideDown(500);
        $('.signout_phone').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "name_updated")
      {
        $('.signout_name').delay(500).slideDown(500);
        $('.signout_name').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "privacy_updated")
      {
        $('.signout_privacy').delay(500).slideDown(500);
        $('.signout_privacy').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "building_name_updated")
      {
        $('.signout_building_name').delay(500).slideDown(500);
        $('.signout_building_name').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
       else if (localStorage.status == "address_updated")
      {
        $('.signout_address').delay(500).slideDown(500);
        $('.signout_address').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
			else if (localStorage.status == "wrong_otp")
			{
        $('.wrong_otp').delay(500).slideDown(500);
        $('.wrong_otp').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }

    },
  },
  watch: {
          activities_admin() {
            $(this.$refs.tables).DataTable().destroy();
            this.$nextTick(() => {
              $(this.$refs.tables).DataTable({
                 destroy: true,
                "ordering":false
              });
            });
          }
          }
}
</script>
