<template>
  <div id="delete-subscription-charge" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" style="top:23%;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" style="margin-left: 5%;opacity: 60%;" id="myModalLabel">Confirm Your Action</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
      </div>
      <div class="card-body" style="padding: 10px 50px;">
        <div class="row" style="justify-content: center;">
          <h4><strong>Are you sure?</strong> Do you want to delete this subscription charge?</h4>

        </div>
        <hr>
        <div class="col-sm-12">
          <div class="form-group">
            <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
              <button type="submit" class="btn btn-success mr-2 " data-dismiss="modal"
              aria-hidden="true" @click="deletecharge" >OK</button>
              <button type="button" class="btn btn-danger mr-2" data-dismiss="modal" aria-hidden="true">Cancel</button>
            </div>
          </div>
        </div>
      </div>
      <hr>
    </div>
  </div>
</div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'DeleteSubscriptionCharge',
  components: {

  },
  props: ['charge_id'],
  methods:{
    deletecharge(){
      this.$store.commit('spinner/showLoading', true)
      const charge = new URLSearchParams()
      charge.append('sub_id', this.charge_id)
      this.$store.dispatch('subscriptioncharge/deletecharge',charge)
    }
  }
}
</script>
