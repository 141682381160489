<template>
  <body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <SuperAdminHeader/>
      <SuperAdminSidebar/>
      <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
          <!-- ============================================================== -->
          <!-- Bread crumb and right sidebar toggle -->
          <!-- ============================================================== -->
          <div class="row page-titles">
            <div class="col-md-5 align-self-center">
              <h4 class="text-themecolor">Subscription Charge</h4>
            </div>
            <div class="alert alert-success signout_create banner-text" role="alert">
                <span><strong>Success!</strong> You have successfully created a new subscription charge</span>
            </div>
            <div class="alert alert-danger signout_delete banner-text" role="alert">
                <span><strong>Deleted!</strong> You have successfully deleted the subscription charge</span>
            </div>
            <div class="alert alert-info signout_reminder banner-text" role="alert">
                <span><strong>Success!</strong> You have successfully reminded about the subscription charge</span>
            </div>

            <div class="col-md-7 align-self-center text-right">
              <div class="d-flex justify-content-end align-items-center">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><router-link :to="{ name: 'SuperAdminHome' }">Home</router-link></li>
                  <li class="breadcrumb-item active">Subscription Charge</li>
                </ol>
                <button type="button" class="btn btn-info d-lg-block m-l-15" data-toggle="modal" data-target="#create-subscription-charge" @click="loadCreateForm()">
                  +Create Subscription Charge</button>
              </div>
            </div>
          </div>

          <div v-if="createform === true">
            <CreateSubscriptionCharge></CreateSubscriptionCharge>
          </div>
          <div v-if="deleteform === true">
            <DeleteSubscriptionCharge   :charge_id='charge_id'/>
          </div>
          <!-- ============================================================== -->
          <!-- End Bread crumb and right sidebar toggle -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Info box -->
          <!-- ============================================================== -->
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row m-t-40">
                  </div>
                  <div v-if="subscriptioncharges.length <= 0">
                    <div style ="padding-left:15px;padding-right:15px; width:100%">
                                      <div class="alert alert-dark">
                                          <span>No data to show</span>
                                      </div>
                    </div>
                  </div>
                  <div v-else>
                  <div class="table-responsive">
                    <table id="myTable" class="table table-striped"  ref="tables">
                      <thead>
                        <tr>
                          <th >id</th>
	                      <th>Building Name</th>
	                      <th>Charge</th>
	                      <th>Month</th>
	                      <th>Created Date</th>
	                      <th>Paid Date</th>
	                      <th>Status</th>
	                      <th>Action</th>
                        <th>Reminder</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in subscriptioncharges" :key="item.building_id">
                          <td>{{item.building_id}}</td>
                          <td> {{item.building_name}}</td>
                          <td> {{item.amount}}</td>
                          <td>{{item.month}}</td>
                          <td>{{format_date(item.created_at)}}</td>
                          <td> {{item.paid_date}}</td>
                          <td v-if="item.status == true">  <div class="label label-table label-success ">
                                                                Paid
                         </div></td>
                         <td v-if="item.status == false">  <div class="label label-table label-warning" >
                                                                Pending
                         </div></td>
                         <td v-if="item.status == true"></td>
                         <td @click="delete_charge(item.id)" v-if="item.status == false" data-toggle="modal" data-target="#delete-subscription-charge">  <div class="label label-table label-danger" >
                                                                Delete
                         </div></td>
                         <td v-if="item.status == true"> </td>
                        <td v-if="item.status == false" @click="sendReminder(item.id,item.building_id)"> <div class="label label-table label-info" >
                                                               Send
                        </div></td>
                        </tr>
                    </tbody>
                    </table>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ============================================================== -->
          <!-- End Info box -->
          <!-- ============================================================== -->

          <!-- ============================================================== -->
          <!-- Review -->
          <!-- ============================================================== -->

          <!-- ============================================================== -->
          <!-- End Review -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Comment - chats -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- End Comment - chats -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- End Page Content -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Right sidebar -->
          <!-- ============================================================== -->
          <!-- .right-sidebar -->

          <!-- ============================================================== -->
          <!-- End Right sidebar -->
          <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
      </div>
      <!-- ============================================================== -->
      <!-- End Page wrapper  -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- footer -->
      <!-- ============================================================== -->
      <footer class="footer">
        © 2020 www.ontash.net
      </footer>
      <!-- ============================================================== -->
      <!-- End footer -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Wrapper -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- All Jquery -->
    <!-- ============================================================== -->
  </body>
</template>

<script>
// @ is an alias to /src
import SuperAdminHeader from '@/components/headers/SuperAdminHeader.vue'
import SuperAdminSidebar from '@/components/sidebars/SuperAdminSidebar.vue'
import CreateSubscriptionCharge from '@/components/subscriptioncharge/CreateSubscriptionCharge.vue'
import DeleteSubscriptionCharge from '@/components/subscriptioncharge/DeleteSubscriptionCharge.vue'
import {mapState,mapActions,mapMutations} from 'vuex'
import moment from 'moment'
export default {
  name: 'SuperAdminSubscriptionCharge',
  components: {
    SuperAdminHeader,
    SuperAdminSidebar,
    CreateSubscriptionCharge,
    DeleteSubscriptionCharge
  },
  data(){
    return{
      createform: false,
      deleteform: false,
      charge_id: ''

    }

  },
  computed:{
    ...mapState('subscriptioncharge',['subscriptioncharges'])
  },
  mounted(){

    this.getbuilding_list();
    this.showalerts();

  },
  methods:{
    ...mapActions('subscriptioncharge',['getbuilding_list']),

    loadCreateForm(){
      this.createform = true
    },
    loadStatusFormComplaint(comp_id,flat_number){

      this.statusformcomplaint = true
      this.complaint_id = comp_id
      this.flat_number = flat_number
    },
    sendReminder(id,building_id){
      this.$store.commit('spinner/showLoading', true)
      const reminder = new URLSearchParams()
      reminder.append('subs_id', id)
      reminder.append('building_id', building_id)
      this.$store.dispatch('subscriptioncharge/sendReminder',reminder)
    },
    format_date(value){
         if (value) {
            var date = moment(value).format('DD-MM-YYYY')
            return date
          }
    },
    delete_charge(id){
       this.charge_id = id
       this.deleteform = true

    },
    showalerts(){
    	if (localStorage.status == "created")
        {
          $('.signout_create').delay(500).slideDown(500);
          $('.signout_create').delay(4000).slideUp(500);
          localStorage.removeItem('status')
        }
         else if (localStorage.status == "deleted")
        {
          $('.signout_delete').delay(500).slideDown(500);
          $('.signout_delete').delay(4000).slideUp(500);
          localStorage.removeItem('status')
        }
        else if (localStorage.status == "reminder")
       {
         $('.signout_reminder').delay(500).slideDown(500);
         $('.signout_reminder').delay(4000).slideUp(500);
         localStorage.removeItem('status')
       }

    }
  },
  watch: {
    subscriptioncharges() {
      $(this.$refs.tables).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.tables).DataTable({
           destroy: true,
          "ordering":false
        }).draw();
      });
    }
  }
}
</script>
<style>

</style>
