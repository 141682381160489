<template>


  <div id="Update_groups" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="top:10%;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">Update Groups</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" @click="closeModal()">×</button>
            </div>
            <div class="card-body">
                    <form class="form" @submit.prevent="updatename">
                            <div class="col-sm-12">
                                <div class="form-group row">
                                   <label class="col-md-12">Name of the Group</label>
                                    <input type="text" v-model="group_name" placeholder="Enter Name of the Group" :class="{ error: v$.group_name.$error }" class="form-control group_name1">
                                    <input type="hidden" class="form-control groupId" value="">
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                        <button type="submit" class="btn btn-info mr-2 ">Update</button>
                                        <button type="button" class="btn btn-danger mr-2" data-toggle="modal" data-target="#verticalcenter1" data-dismiss="modal" @click="activeDelete">Delete</button>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
           </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<div v-if="deleteactive == true">
  <DeleteGroup :grp_id="grp_id"></DeleteGroup>
</div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import DeleteGroup from '@/components/group/DeleteGroup.vue'
import {mapState} from 'vuex'
export default {
  name: 'UpdateGroup',
  components: {
    DeleteGroup
  },
   setup () {
    return { v$: useVuelidate() }
  },
   data(){
    return{
      deleteactive: false
    }

  },
  props:['grp_id'],
  created(){
    this.getgroup();
  },
   computed:{
    group_name: {
      get () {
        return this.$store.state.adminflatgroup.grp_name
      },
      set (value) {
        this.$store.commit('adminflatgroup/updateGroupname', value)
      }
    }
  },
  validations () {
    return {
      group_name: { required }, // Matches this.firstName
    }
  },
  methods: {
    getgroup(){
       const group_id = new URLSearchParams()
       group_id.append('id', this.grp_id)
       this.$store.dispatch('adminflatgroup/get_group',group_id)
     },
     activeDelete(){
      this.deleteactive = true

     },
     updatename(){
         this.v$.$touch()
      if(!this.v$.$invalid) {
       this.$store.commit('spinner/showLoading', true)
       const group = new URLSearchParams()
       group.append('id', this.grp_id)
       group.append('group_name', this.group_name)
       group.append('building_id',localStorage.building_id)
       this.$store.dispatch('adminflatgroup/update_group',group)
      }
     }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
