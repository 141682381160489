<template>
  <body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <SuperAdminHeader/>
      <SuperAdminSidebar/>
      <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
          <!-- ============================================================== -->
          <!-- Bread crumb and right sidebar toggle -->
          <!-- ============================================================== -->
          <div class="row page-titles">
            <div class="col-md-5 align-self-center">
              <h4 class="text-themecolor">Super Administrator Dashboard</h4>
            </div>
            <div class="alert alert-success signout_create banner-text" role="alert">
                <span><strong>Success!</strong> You have successfully added new building</span>
            </div>
            <div class="alert alert-success signout_update banner-text" role="alert">
                <span><strong>Success!</strong> You have successfully updated the building details</span>
            </div>
            <div class="alert alert-danger signout_delete banner-text" role="alert">
                <span><strong>Deactivated!</strong> You have successfully deactivated the building</span>
            </div>
            <div class="alert alert-warning already_exist banner-text" role="alert">
                <span><strong>Warning!</strong> User/Building you created already exists</span>
            </div>
            <div class="alert alert-success activated banner-text" role="alert">
                <span><strong>Activated!</strong> You have successfully activated the building</span>
            </div>
            <div class="col-md-7 align-self-center text-right">
              <div class="d-flex justify-content-end align-items-center">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">Home</li>
                </ol>
                <button type="button" class="btn btn-info d-lg-block m-l-15" data-toggle="modal" data-target="#add-new_building" @click="loadCreateForm()">
                  +New Building</button>
              </div>
            </div>
          </div>

          <div v-if="createform === true">
            <CreateBuilding></CreateBuilding>
          </div>
          <!-- ============================================================== -->
          <!-- End Bread crumb and right sidebar toggle -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Info box -->
          <!-- ============================================================== -->
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                          <!-- Column -->
                          <div class="col-md-6 col-lg-3 col-xlg-3" @click="loadTabOne">
                              <div class="card">
                                  <div class="box label-success text-center">
                                      <h1 class="font-light text-white">{{active_count}}</h1>
                                      <h6 class="text-white">Active Buildings</h6>
                                  </div>
                              </div>
                          </div>
                          <!-- Column -->
                          <div class="col-md-6 col-lg-3 col-xlg-3" @click="loadTabTwo">
                              <div class="card">
                                  <div class="box label-danger text-center">
                                      <h1 class="font-light text-white">{{inactive_count}}</h1>
                                      <h6 class="text-white">Inactive Buildings</h6>
                                  </div>
                              </div>
                          </div>
                    </div>
                  <div class="row m-t-40">
                  </div>
                  <div v-if="active_building_caretakers.length <= 0 && inactive_building_caretakers.length <= 0">
                    <div style ="padding-left:15px;padding-right:15px; width:100%">
                                      <div class="alert alert-dark">
                                          <span>No data to show</span>
                                      </div>
                    </div>
                  </div>
                  <div v-else>
                  <div class="table-responsive">
                    <table id="myTable" class="table table-striped"  ref='table1' v-if="tab_one == true">
                      <thead>
                        <tr>
                          <th>Building Id</th>
                          <th>Building Name</th>
                          <th>CareTaker Name</th>
                          <th>CareTaker Phone</th>
                          <th>Trail Period Expiry</th>
                          <th>Edit</th>
                          <th >Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="active_building_caretaker in active_building_caretakers" :key="active_building_caretaker.building_id">
                          <td @click="list_appartment(active_building_caretaker.building_id)"><a  class="link">{{active_building_caretaker.building_id}}</a></td>
                          <td @click="list_appartment(active_building_caretaker.building_id)"> {{active_building_caretaker.building_name}}</td>
                          <td> {{active_building_caretaker.username}}</td>
                          <td>{{active_building_caretaker.phone}}</td>
                          <td v-if="active_building_caretaker.trial_status == '1'">Expired</td>
                          <td v-else>Not Expired</td>
                          <td class="text-center">
                            <div class="label label-table label-info modify_complaint" data-toggle="modal" data-target="#update-building" @click="loadUpdateForm(active_building_caretaker.building_caretaker_id)"><a>Edit</a></div>
                          </td>
                          <td class="text-center">
                            <div class="label label-table label-danger delete_care" data-toggle="modal" data-target="#delete-building" @click="loadDeactiveForm(active_building_caretaker.building_caretaker_id)"><a>Deactive</a></div>
                          </td>
                        </tr>
                    </tbody>
                    </table>
                    <table id="myTable" class="table table-striped"  ref='table2' v-else-if="tab_two == true">
                      <thead>
                        <tr>
                          <th>Building Id</th>
                          <th>Building Name</th>
                          <th>CareTaker Name</th>
                          <th>CareTaker Phone</th>
                          <th>Trail Period Expiry</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="inactive_building_caretaker in inactive_building_caretakers" :key="inactive_building_caretaker.building_id">
                          <td @click="list_appartment(inactive_building_caretaker.building_id)"><a  class="link">{{inactive_building_caretaker.building_id}}</a></td>
                          <td @click="list_appartment(inactive_building_caretaker.building_id)"> {{inactive_building_caretaker.building_name}}</td>
                          <td> {{inactive_building_caretaker.username}}</td>
                          <td>{{inactive_building_caretaker.phone}}</td>
                          <td v-if="inactive_building_caretaker.trial_status == '1'">Expired</td>
                          <td v-else>Not Expired</td>
                          <td class="text-center">
                            <div class="label label-table label-success" data-toggle="modal" data-target="#active-building" @click="loadActiveForm(inactive_building_caretaker.building_caretaker_id)"><a>Active</a></div>
                          </td>
                        </tr>
                    </tbody>
                    </table>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="updateform === true">
            <UpdateBuilding :building_caretaker_id='building_caretaker_id' />
          </div>
          <div v-if="deactiveform === true">
            <DeactivateBuilding :building_caretaker_id='building_caretaker_id' />
          </div>
          <div v-if="activeform === true">
            <ActivateBuilding :building_caretaker_id='building_caretaker_id' />
          </div>

          <!-- ============================================================== -->
          <!-- End Info box -->
          <!-- ============================================================== -->

          <!-- ============================================================== -->
          <!-- Review -->
          <!-- ============================================================== -->

          <!-- ============================================================== -->
          <!-- End Review -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Comment - chats -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- End Comment - chats -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- End Page Content -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Right sidebar -->
          <!-- ============================================================== -->
          <!-- .right-sidebar -->

          <!-- ============================================================== -->
          <!-- End Right sidebar -->
          <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
      </div>
      <!-- ============================================================== -->
      <!-- End Page wrapper  -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- footer -->
      <!-- ============================================================== -->
      <footer class="footer">
        © 2020 www.ontash.net
      </footer>
      <!-- ============================================================== -->
      <!-- End footer -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Wrapper -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- All Jquery -->
    <!-- ============================================================== -->
  </body>
</template>

<script>
// @ is an alias to /src
import SuperAdminHeader from '@/components/headers/SuperAdminHeader.vue'
import SuperAdminSidebar from '@/components/sidebars/SuperAdminSidebar.vue'
import CreateBuilding from '@/components/building/CreateBuilding.vue'
import UpdateBuilding from '@/components/building/UpdateBuilding.vue'
import DeactivateBuilding from '@/components/building/DeactivateBuilding.vue'
import ActivateBuilding from '@/components/building/ActivateBuilding.vue'

import {mapState,mapActions,mapMutations} from 'vuex'
import moment from 'moment'
export default {
  name: 'SuperAdminHome',
  components: {
    SuperAdminHeader,
    SuperAdminSidebar,
    CreateBuilding,
    UpdateBuilding,
    DeactivateBuilding,
    ActivateBuilding
  },
  data(){
    return{
      createform: false,
      updateform: false,
      deactiveform: false,
      activeform: false,
      building_caretaker_id: '',
      tab_one: false,
      tab_two: false
    }

  },
  computed:{
    ...mapState('building',['active_building_caretakers','inactive_building_caretakers','active_count','inactive_count'])
  },
  mounted(){

    this.getBuildingCaretakers();
    this.showalerts();
    this.tab_one = true
  },
  methods:{
    ...mapActions('building',['getBuildingCaretakers']),

    loadCreateForm(){
      this.createform = true
    },
    list_appartment(building_id)
    {
      this.$router.push({ name: 'SuperAdminAppartmentlist', params: {building_id: building_id}})
    },
    loadUpdateForm(b_c_id){
      this.building_caretaker_id = b_c_id
      this.updateform = true

    },
    loadDeactiveForm(b_c_id){
      this.building_caretaker_id = b_c_id
      this.deactiveform = true

    },
    loadActiveForm(b_c_id){
      this.building_caretaker_id = b_c_id
      this.activeform = true

    },
    loadTabOne(){
      this.tab_one = true
      this.tab_two = false
      $(this.$refs.table2).DataTable().destroy();
      $(this.$refs.table1).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.table1).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    },
    loadTabTwo(){
      this.tab_two = true
      this.tab_one = false
      $(this.$refs.table1).DataTable().destroy();
      $(this.$refs.table2).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.table2).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    },
    showalerts(){
      if (localStorage.status == "created")
      {
        $('.signout_create').delay(500).slideDown(500);
        $('.signout_create').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "updated")
      {
        $('.signout_update').delay(500).slideDown(500);
        $('.signout_update').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "deleted")
      {
        $('.signout_delete').delay(500).slideDown(500);
        $('.signout_delete').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "already_exist")
      {
        $('.already_exist').delay(500).slideDown(500);
        $('.already_exist').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "activated")
      {
        $('.activated').delay(500).slideDown(500);
        $('.activated').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }

    }
  },
  watch: {
    active_building_caretakers() {
      $(this.$refs.table1).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.table1).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    },
    inactive_building_caretakers() {
      $(this.$refs.table2).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.table2).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    }
  }
}
</script>
<style scoped>
.banner-text{
  font-weight:400;
  position:absolute;
  width: 98%;
  margin-top: -5px;
  z-index: 10;
  text-align: center;
  display: none;
}
</style>
