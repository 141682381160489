<template>
     <div id="edit-contact" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title" id="myModalLabel">Edit Contact</h4>
                                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                                            </div>
                                            <div class="card-body">
                                                    <form class="form" @submit.prevent="update_contact">
                                                    <input type="hidden" id="edit_contact_id" >
                                                            <div class="col-sm-12">
                                                                <div class="form-group row">
                                                                    <label for="example-text-input" class="" style=" padding-left: 10px; padding-right: 10px;">Contact Name</label>
                                                                    <div class="col-12">
                                                                    <input type="text" v-model="contact_name" autocomplete="off"
                                                                            class="form-control" placeholder="Enter Contact Name" :class="{ error: v$.contact_name.$error }">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12">
                                                                <div class="form-group row">
                                                                    <label for="example-text-input" class="" style=" padding-left: 10px; padding-right: 10px;">Contact Number</label>
                                                                    <div class="col-12">
                                                                    <input type="tel" v-model="contact_number" autocomplete="off"
                                                                            class="form-control" placeholder="Enter Contact Number" onkeydown="javascript: return event.keyCode === 8 ||
                                                                    event.keyCode === 46 ? true : !isNaN(Number(event.key))" :class="{ error: v$.contact_number.$error }">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12">
                                                                <div class="form-group row">
                                                                    <label for="example-text-input" class="" style=" padding-left: 10px; padding-right: 10px;">Email</label>
                                                                    <div class="col-12">
                                                                    <input type="text" v-model="contact_email" autocomplete="off"
                                                                            class="form-control" placeholder="Enter Email" >
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12">
                                                                <div class="form-group row">
                                                                    <label for="example-text-input" class="" style=" padding-left: 10px; padding-right: 10px;">Job Title</label>
                                                                    <div class="col-12">
                                                                    <input type="text" v-model="contact_job" placeholder="Enter Job Title" autocomplete="off"
                                                                            class="form-control"  :class="{ error: v$.contact_job.$error }">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12">
                                                                <div class="form-group row">
                                                                    <label for="example-text-input" class="" style=" padding-left: 10px; padding-right: 10px;">Address</label>
                                                                <div class="col-12">
                                                                        <textarea class="form-control" rows="3" v-model="contact_address" autocomplete="off" placeholder="Enter Address" :class="{ error: v$.contact_address.$error }"></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12">
                                                                <div class="form-group">
                                                                    <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                                                        <button type="submit" class="btn btn-info mr-2" id="update_contact">Update</button>
                                                                        <button type="button" class="btn btn-danger mr-2">Cancel</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </form>
                                        </div>
                                        </div>
                                        <!-- /.modal-content -->
                                    </div>
                                    <!-- /.modal-dialog -->
                                </div>
                            <!-- Start Page Content -->
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {mapState} from 'vuex'
export default {
  name: 'CreateDirectory',
  setup () {
    return { v$: useVuelidate() }
  },

  props:['contact_id'],

  created(){
    this.getcontact();
  },
  computed:{
    contact_name: {
      get () {
        return this.$store.state.directory.contact_name
      },
      set (value) {
        this.$store.commit('directory/Updatename', value)
      }
    },
    contact_number: {
      get () {
        return this.$store.state.directory.contact_number
      },
      set (value) {
        this.$store.commit('directory/updatenumber', value)
      }
    },
    contact_email: {
      get () {
        return this.$store.state.directory.contact_email
      },
      set (value) {
        this.$store.commit('directory/updateemail', value)
      }
    },
    contact_job: {
      get () {
        return this.$store.state.directory.contact_job_title
      },
      set (value) {
        this.$store.commit('directory/updatejob', value)
      }
    },
    contact_address: {
      get () {
        return this.$store.state.directory.contact_address
      },
      set (value) {
        this.$store.commit('directory/updateaddress', value)
      }
    }
  },
  validations () {
    return {
      contact_name: { required },
      contact_number: { required },
      contact_job: { required },
      contact_address: { required }
    }
  },
  methods: {
    getcontact()
    {
       const contact_id = new URLSearchParams()
       contact_id.append('id', this.contact_id)
       this.$store.dispatch('directory/get_directory_on_id',contact_id)
    },
    update_contact(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const contact = new URLSearchParams()
        contact.append('contact_id', this.contact_id)
        contact.append('contact_name', this.contact_name)
        contact.append('contact_number', this.contact_number)
        contact.append('contact_email', this.contact_email)
        contact.append('contact_job', this.contact_job)
        contact.append('contact_address', this.contact_address)
        contact.append('building_id',localStorage.building_id)
        this.$store.dispatch('directory/edit_contact',contact)
      }
    }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
  .requires {
    color: red;
    }
</style>
