<template>
<!-- Add New Service Popup Model -->
<div id="add-event" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                    aria-hidden="true" style="top:1%;">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="myModalLabel">Add New Event</h4>
                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                             <form class="form" @submit.prevent="create_event">
                                <div class="card-body" style="padding: 10px 50px;">
                                  <div class="row" style="justify-content: center;">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>Event Name</label>
                                                <input v-model="event_name" :class="{ error: v$.event_name.$error }" class="form-control"  placeholder="Enter Event Name ">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>Organizer</label>
                                                <input v-model="organizer"  :class="{ error: v$.organizer.$error }" class="form-control"  placeholder="Enter Organizer" >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>Venue</label>
                                                <input v-model="venue" type="venue" :class="{ error: v$.venue.$error }" class="form-control" placeholder="Enter Venue" >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label> Description</label>
                                                <textarea  v-model="description" class="form-control"  :class="{ error: v$.description.$error }" rows="3" placeholder="Enter Description" ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class='col-md-12'>
                                           <div class="form-group">
                                              <label>Start Time</label>
                                              <div class="input-group ">
                                                   <input class="form-control edit-date" type="date" v-model="start_date" :class="{ error: v$.start_date.$error }"  ref="eventinputdate1">
                                                 <vue-timepicker format="h:mm a"  v-model="start_time" :class="{ error: v$.start_time.$error }" ></vue-timepicker>
                                              </div>
                                           </div>
                                        </div>
                                    </div>
                                     <div class="row">
                                        <div class='col-md-12'>
                                           <div class="form-group">
                                              <label>End Time</label>
                                              <div class="input-group">
                                                <input class="form-control edit-date" type="date" v-model="end_date" :class="{ error: v$.end_date.$error }"  ref="eventinputdate2">
                                                 <vue-timepicker format="h:mm a"   v-model="end_time" :class="{ error: v$.end_time.$error }"></vue-timepicker>
                                              </div>
                                           </div>
                                        </div>
                                    </div>
                                
                                <div v-if="condition == true" class="col-md-12" style="padding: 15px;">
                                    <span style="font-size:13px;color:red;">The end date must be higher than start date!</span>
                                </div>
                                <div v-if="condition1 == true" class="col-md-12" style="padding: 15px;">
                                    <span style="font-size:13px;color:red;">The end time must be higher than start time!</span>
                                </div>

                                <hr>
                                  <div class="col-sm-12">
                                    <div class="form-group">
                                    <button type="submit" class="btn btn-success mr-2" id="save">Submit</button>
                                    <button type="button" class="btn btn-danger mr-2" data-dismiss="modal">Cancel</button>

                                </div>
                                </div>
                              </div>
                            </form>
                        </div>
                        <!-- /.modal-content -->
                    </div>
                    <!-- /.modal-dialog -->
                </div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Datepicker from 'vue3-datepicker'
import VueTimepicker from 'vue3-timepicker'
import 'vue3-timepicker/dist/VueTimepicker.css'
import { ref } from 'vue'
import moment from 'moment'
export default {
  name: 'CreateEvent',
   setup () {
    return { v$: useVuelidate() }
  },
  components:{
    Datepicker,
    VueTimepicker
  },
  data(){
    return{
      event_name: '',
      organizer: '',
      venue: '',
      description: '',
      start_date: ref(new Date('00-00-0000')),
      start_time: '',
      end_date: ref(new Date('00-00-0000')),
      end_time: '',
      dateFormat : 'dd-MM-yyyy',
      condition: false,
      condition1: false
    }

  },
   validations () {
    return {
      event_name: { required },
      organizer: { required },
      venue: { required } ,
      description: { required },
      start_date: { required },
      start_time: { required },
      end_date: { required },
      end_time: { required },
    }
  },
  mounted(){
    this.disable_date();
  },
  methods:{
     format_date(value){
         if (value) {
           return moment(String(value)).format('DD-MM-YYYY')
          }
    },
    disable_date(){
      var date = new
      Date().toISOString().slice(0,10);
      $(this.$refs.eventinputdate1).attr('min',date);
      $(this.$refs.eventinputdate2).attr('min',date);
    },
    create_event(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        if (this.start_date > this.end_date)
         {
          this.condition = true
         }
         else if (this.start_date == this.end_date && this.start_time > this.end_time)
         {
          this.condition1 = true
         }
         else{
        var dbs = this.format_date(this.start_date) + ' ' + this.start_time
        var dbe = this.format_date(this.end_date) + ' ' + this.end_time
        const event = new URLSearchParams()
        event.append('eventname', this.event_name)
        event.append('org', this.organizer)
        event.append('ven', this.venue)
        event.append('desc', this.description)
        event.append('startat', dbs)
        event.append('endat', dbe)
        event.append('building_id',localStorage.building_id)
        this.$store.dispatch('event/create_event',event)
        }
      }
      }
  }

}
</script>

<style scoped>
.error {
    border: 1px solid red;
  }
</style>
