<template>
 <div id="view_complete_details" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true" style="top:20%">
     <div class="modal-dialog">
         <div class="modal-content">
             <div class="modal-header">
                 <h4 class="modal-title" id="myModalLabel">Payments</h4>
                 <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
             </div>
             <div class="card-body" style="padding-top: 0px;">
                 <div class="card" style="margin-bottom: 0px;max-height: 300px;">
                  <div class="table-responsive">
                      <table class="table table-striped">
                          <thead>
                              <tr>
                                  <th>Type</th> 
                                  <th >Reference_no</th>
                                  <th >From</th>                                        
                                  <th >Date</th>
                                  <th >Charge</th>
                                  <th >Status</th>
                              </tr>
                          </thead>
                          <tbody>
                               <tr v-for="item in ref " :key ="item.id">
                                  <td  >{{type}}</td>
                                  <td  >{{item.service_id}}</td>
                                  <td>{{split(item.appartment_group)}}</td>
                                  <td  >{{format_date(item.service_date)}}</td>
                                  <td  >{{item.service_charge}}</td> 
                                   <td ><div  v-if="status === true"> 
                                        <div class="label label-table label-success">Settled</div></div>
                                        <div v-else-if="status === false">
                                        <div class="label label-table label-danger">Incomplete</div>
                                       </div>

                                  </td>

                              </tr>
                          </tbody>
                      </table>
                  </div>
                     <div class="col-sm-12" style="margin-top: 20px;">
                             <div class="form-group">
                                 <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:10px;">
                                     <button type="submit" class="btn btn-danger mr-2" data-dismiss="modal">Cancel</button>
                                 </div>
                             </div>
                         </div>
                 </div>
             </div>
         </div>
         <!-- /.modal-content -->
     </div>
     <!-- /.modal-dialog -->
 </div>
                
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {mapState} from 'vuex'
import moment from 'moment'
export default {
  name: 'ShowPaymentDetails',
  data(){
    return{
      
    }

  },
  
  computed:{
    ...mapState('payment',['ref','status','type'])

  },
  props: ['payment_id'],
  created(){
    this.getpayment();
  },
  methods: {
    getpayment(){
       const payment_id = new URLSearchParams()
       payment_id.append('id', this.payment_id)
       this.$store.dispatch('payment/get_payment_detail',payment_id)
    },
    split(name){
      return name.split(':')[1].split(' ')[0]
      
    },
    format_date(value){
         if (value) {
           return moment(String(value)).format('DD-MM-YYYY')
          }
    },
    
  }
}
</script>






  






  