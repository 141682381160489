<template>
<div id="update-booking" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                    aria-hidden="true" style="top:10%;">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="myModalLabel">Update booking</h4>
                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div class="alert alert-warning already_exists banner-text" role="alert">
                                <span>Already have booking for the specified time range. Please choose another slot.</span>
                            </div>
                             <form class="form" @submit.prevent="UpdateBooking">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>Booked Facility</label>
                                                <div class="col-12">
                                                    <input v-model="facility_type" class="form-control edit-date" type="text" readonly="true" :class="{ error: v$.facility_type.$error }">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>Booked For</label>
                                                <div class="col-12">
                                                    <input v-model="booked_for" class="form-control edit-date" type="date" :class="{ error: v$.booked_for.$error }" ref="inputdatebooking">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>Start Time (Enter in 24-hour format)</label>
                                                <div class="col-12">
                                                   <vue-timepicker  :key="timepicker" v-model="start_time" format="hh:mm A" :class="{ error: v$.start_time.$error }"></vue-timepicker>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>End Time (Enter in 24-hour format)</label>
                                                <div class="col-12">
                                                    <vue-timepicker :key="timepicker" format="hh:mm A"    v-model="end_time" :class="{ error: v$.end_time.$error }"></vue-timepicker>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                            <button type="submit" class="btn btn-success mr-2">Update</button>
                                            <button type="button" class="btn btn-danger mr-2" data-dismiss="modal" aria-hidden="true">Cancel</button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                        <!-- /.modal-content -->
                    </div>
                    <!-- /.modal-dialog -->
                </div>

</template>
<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import DeleteComplaint from '@/components/complaints/DeleteComplaint.vue'
import VueTimepicker from 'vue3-timepicker'
import {mapState,mapActions} from 'vuex'
import moment from 'moment'
export default {
  name: 'UpdateBooking',
  components: {
    DeleteComplaint,
    VueTimepicker
  },
  setup () {
    return { v$: useVuelidate() }
  },
  props:['booking_id','starting_time','ending_time'],
  data(){
    return{
      complaint_status: this.status_id,
      buttonactive: false,
      deleteactive: false,
      start_time: this.starting_time,
      end_time: this.ending_time,
      timepicker: 0
    }
  },
  validations () {
    return {
      facility_type: { required },
      booked_for:{ required },
      start_time: { required },
      end_time: { required }
    }
  },
  computed:{

    facility_type: {

      get () {
        return this.$store.state.facility.facility_type
      },
      set (value) {
        this.$store.commit('facility/update_facility', value)
      }
    },
    booked_for: {
      get () {
        return this.$store.state.facility.booked_for
      },
      set (value) {
         this.$store.commit('facility/updatebookedfor', value)
      }
    },
    ...mapState('facility',['facility_list','fac_id']),
  },
  created(){
    this.Get_facility();
    this.disable_date();
  },
  methods:{
    Get_facility(){
      const booking_id = new URLSearchParams()
      booking_id.append('booking_id', this.booking_id)
      booking_id.append('flat_id', localStorage.flat_id)
      this.$store.dispatch('facility/getfacility',booking_id)
    },
    activeEditButton(){
      this.complaint_status = 0
      this.buttonactive = true
      this.disable_date();
    },
    disable_date(){
      var date = new
      Date().toISOString().slice(0,10);
      $(this.$refs.inputdatebooking).attr('min',date);
    },
    UpdateBooking(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const booking = new URLSearchParams()
        var st_time = moment(this.start_time, "h:mm A").format("HH:mm:ss")
        var en_time = moment(this.end_time, "h:mm A").format("HH:mm:ss")
        booking.append('start_time', st_time)
        booking.append('end_time', en_time)
        booking.append('booking_id', this.booking_id)
        booking.append('booked_for', this.booked_for)
        booking.append('flat_id', localStorage.flat_id)
        booking.append('common_facilities_id', this.fac_id)
        this.$store.dispatch('facility/updatefacility',booking)
      }
    },
    activeDelete(){
      this.complaint_status = 0
      this.deleteactive = true
    },
    closeModal(){
      this.buttonactive = false
    }
  },
  watch:{
    booking_id(){
      this.Get_facility();
      this.timepicker += 1
      this.start_time = this.starting_time
      this.end_time = this.ending_time
    }
  }

}

</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
