<template>
  <body class="skin-default fixed-layout">
    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <SuperAdminHeader/>
      <SuperAdminSidebar/>
      <div class="page-wrapper">
          <!-- ============================================================== -->
          <!-- Container fluid  -->
          <!-- ============================================================== -->
          <div class="container-fluid">
              <!-- ============================================================== -->
              <!-- Bread crumb and right sidebar toggle -->
              <!-- ============================================================== -->
              <div class="row page-titles">
                  <div class="col-md-5 align-self-center">
                      <h4 class="text-themecolor">Activity Summary</h4>
                  </div>

                  <div class="col-md-7 align-self-center text-right">
                      <div class="d-flex justify-content-end align-items-center">
                          <ol class="breadcrumb">
                              <li class="breadcrumb-item"><router-link :to="{ name: 'SuperAdminHome' }">Home</router-link></li>
                              <li class="breadcrumb-item active">Activity Summary</li>
                          </ol>
                      </div>
                  </div>
              </div>
              <!-- ============================================================== -->
              <!-- End Bread crumb and right sidebar toggle -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- Info box -->
              <!-- ============================================================== -->

              <div class="row">
                  <div class="col-12">
                      <div class="card">
                          <div class="card-body">
                            <div class="row" style="margin-top: 25px !important; margin-left: 10px;">
                                <div v-if="activity_summary.length > 0">
                                      <h4><b>Activity Summary Report For The Year {{activity_summary[i].year}}</b></h4>
                                  </div>

                              </div>
                              <hr>
                              <div v-if="activity_summary.length > 0">
                               <div class="card-body" >
                                 <div class="table-responsive">
                                   <table class="table table-striped">
                                     <thead>
                                       <tr>
                                         <th>Month</th>
                                         <th>Buildings on Trial</th>
                                         <th>Apartments on Trial</th>
                                         <th>Users on Trial</th>
                                         <th>Active Users on Trial</th>
                                         <th>Admins on Trial</th>
                                         <th>Active Admins on Trial</th>
                                         <th>Buildings Subscribed</th>
                                         <th>Apartments Subscribed</th>
                                         <th>Users Subscribed</th>
                                         <th>Active Users Subscribed</th>
                                         <th>Admins Subscribed</th>
                                         <th>Active Admins Subscribed</th>
                                         <th>Revenue Billed</th>
                                         <th>Revenue Collected</th>
                                         <th>Admin Payments Made</th>
                                         <th>Complaints Requested</th>
                                         <th >Complaints Completed</th>
                                         <th >Service Requested</th>
                                         <th >Service Completed</th>
                                         <th >Parking Requested</th>
                                         <th >Parking Alloted</th>
                                       </tr>
                                     </thead>
                                     <tbody>
                                       <tr v-for=" val in activity_summary[i].data" :key="val.month">
                                         <td>{{val.month}}</td>
                                         <td>{{val.trail_buildings}}</td>
                                         <td>{{val.total_trail_appartments}}</td>
                                         <td>{{val.total_trail_users}}</td>
                                         <td>{{val.total_active_trail_users}}</td>
                                         <td>{{val.total_trail_admins}}</td>
                                         <td>{{val.total_trail_active_admins}}</td>
                                         <td>{{val.subscribed_buildings}}</td>
                                         <td>{{val.total_subscribed_appartments}}</td>
                                         <td>{{val.total_subscribed_users}}</td>
                                         <td>{{val.total_active_subscribed_users}}</td>
                                         <td>{{val.total_subscribed_admins}}</td>
                                         <td>{{val.total_subscribed_active_admins}}</td>
                                         <td>{{val.revenue_billed}}</td>
                                         <td>{{val.revenue_collected}}</td>
                                         <td>{{val.admin_revenue_paid}}</td>
                                         <td>{{val.complaints_requested}}</td>
                                         <td>{{val.complaints_completed}}</td>
                                         <td>{{val.service_requested}}</td>
                                         <td>{{val.service_completed}}</td>
                                         <td>{{val.parking_requested}}</td>
                                         <td>{{val.parking_filled}}</td>
                                       </tr>
                                   </tbody>
                                   </table>
                                 </div>
                                   <div class="row">

                                             <div class="col-md-3">
                                               <button type="button" class="btn btn-success d-lg-block " @click="generatePdfActivityReport()">
                                                 <i class="fa fa-download" aria-hidden="true"></i> Download Report</button>
                                               </div>
                                               <div class="col-md-2">
                                               <button type="button" class="btn btn-info d-lg-block " @click="nextPage()">
                                                 Next Page</button>
                                               </div>
                                               <div class="col-md-2">
                                               <button type="button" class="btn btn-info d-lg-block " @click="previousPage()">
                                                 Previous Page</button>
                                               </div>
                                           </div>

                               </div>
                             </div>
                             <div >
                             </div>

                          </div>
                      </div>
                  </div>
              </div>

              <!-- ============================================================== -->
              <!-- End Info box -->
              <!-- ============================================================== -->

              <!-- ============================================================== -->
              <!-- Review -->
              <!-- ============================================================== -->

              <!-- ============================================================== -->
              <!-- End Review -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- Comment - chats -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- End Comment - chats -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- End Page Content -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- Right sidebar -->
              <!-- ============================================================== -->
              <!-- .right-sidebar -->

              <!-- ============================================================== -->
              <!-- End Right sidebar -->
              <!-- ============================================================== -->
          </div>
          <!-- ============================================================== -->
          <!-- End Container fluid  -->
          <!-- ============================================================== -->
      </div>
      <!-- ============================================================== -->
      <!-- End Page wrapper  -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- footer -->
      <!-- ============================================================== -->
      <footer class="footer">
          © 2020 www.ontash.net
      </footer>
      <!-- ============================================================== -->
      <!-- End footer -->
      <!-- ============================================================== -->
  </div>
</body>
</template>
<script>
// @ is an alias to /src
import SuperAdminHeader from '@/components/headers/SuperAdminHeader.vue'
import SuperAdminSidebar from '@/components/sidebars/SuperAdminSidebar.vue'
import {mapState,mapActions,mapMutations} from 'vuex'
export default {
  name: 'SuperAdminActivitySummary',
  components: {
    SuperAdminHeader,
    SuperAdminSidebar,

  },

  computed:{
    ...mapState('building',['activity_summary']),
  },
  data(){
    return{
      i: 0
    }

  },
  mounted(){
    this.getActivityReportDetails();
  },

  methods:{
    getActivityReportDetails(){
        const gen_report = new URLSearchParams()
        this.$store.dispatch('building/getActivityReportDetails',gen_report)
    },
    generatePdfActivityReport(){
        const pdf_report = new URLSearchParams()
        this.$store.dispatch('building/generatePdfActivityReport',pdf_report)
    },
    nextPage(){
      if (this.i < this.activity_summary.length-1 ){
        this.i++
      }
    },
    previousPage(){
      if (this.i > 0 ){
        this.i--
      }
    }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
