<template>
<div id="change-status" class="modal" tabindex="-1" role="dialog" aria-labelledby="vcenter"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="vcenter">Change Status</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body" style="padding-left: 30px;">
                            <div class="row">
                                <div class="col-6">
                                    <label>Appartment</label>
                                </div>
                                <div class="col-6">
                                    <label>{{ flat_number }}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label>Topic</label>
                                </div>
                                <div class="col-6">
                                    <label>{{ topic }}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label>Description</label>
                                </div>
                                <div class="col-6">
                                    <label>{{ description }}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label>Status</label>
                                </div>
                                <div class="col-6">
                                  <select class="form-control custom-select" v-model="status">
                                    <option class="status_new">New</option>
                                    <option>Pending</option>
                                    <option>Complete</option>
                                    <option>Closed</option>
                                  </select>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success mr-2" data-dismiss="modal" @click="updateAdminStatus">Update Status</button>
                <button type="button" class="btn btn-danger mr-2" data-dismiss="modal">Cancel</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
</template>
<script>
import {mapState} from 'vuex'
export default {
  name: 'AdminUpdateStatus',
  components: {

  },
  props:['complaint_id','flat_number'],
  data(){
    return{
      flat_number: this.flat_number,
      buttonactive: false,
      deleteactive: false
    }
  },
  computed:{
    topic: {
      get () {
        return this.$store.state.complaint.topic
      },
      set (value) {
        this.$store.commit('complaint/updateTopic', value)
      }
    },
    description: {
      get () {
        return this.$store.state.complaint.description
      },
      set (value) {
        this.$store.commit('complaint/updateDescription', value)
      }
    },
    status: {
      get () {
        return this.$store.state.complaint.status
      },
      set (value) {
        this.$store.commit('complaint/updateStatus', value)
      }
    }
  },
  created(){
    this.getAdminComplaintForStatus();
  },

  methods:{
    getAdminComplaintForStatus(){
      const complaint_id = new URLSearchParams()
      complaint_id.append('complaint_id', this.complaint_id)
      complaint_id.append('building_id', localStorage.building_id)
      this.$store.dispatch('complaint/getAdminComplaintForStatus',complaint_id)
    },
    updateAdminStatus(){
        this.$store.commit('spinner/showLoading', true)
        const complaint = new URLSearchParams()
        complaint.append('complaint_id', this.complaint_id)
        complaint.append('status', this.status)
        complaint.append('building_id', localStorage.building_id)
        this.$store.dispatch('complaint/updateAdminStatus',complaint)
    }
  },
  watch:{
    complaint_id(){
      this.getAdminComplaintForStatus();
    }
  }
}
</script>

<style scoped>
.error {
    border: 1px solid red;
  }
</style>
