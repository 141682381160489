<template>
  <div id="delete-contact" class="modal" tabindex="-1" role="dialog" aria-labelledby="vcenter"
                                aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h4 class="modal-title" id="vcenter">Delete</h4>
                                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                                        </div>
                                        <div class="card-body" style="padding: 10px 50px;">
                                        <div class="row" style="justify-content: center;">
                                            <h4 style="margin-left: 40px"><strong>Are you sure?</strong> Do you want to delete this contact details?</h4>
                                        </div>
                                         <hr>
                                      <div class="col-sm-12">
                                        <div class="form-group">
                                            <input type="hidden" id="delete_contact_id" >
                                            <button type="button" id="delete_contact" @click = "delete_contact"  class="btn btn-success mr-2 " data-dismiss="modal" aria-hidden="true">OK</button>
                                            <button type="button" class="btn btn-danger waves-effect"  data-dismiss="modal" style="border-color: white;">Cancel</button>
                                            

                                        </div>
                                    </div>
                                </div>
                                    </div>
                                    <!-- /.modal-content -->
                                </div>
                                <!-- /.modal-dialog -->
                            </div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'CreateDirectory',
  props:['contact_id'],
  methods: {
    delete_contact(){
        this.$store.commit('spinner/showLoading', true)
        const contact = new URLSearchParams()
        contact.append('cont_id', this.contact_id)
        this.$store.dispatch('directory/delete_contact',contact)
    }
  }
}
</script>
