<template>
  <body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <UserHeader/>
      <UserSidebar/>
      <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
          <!-- ============================================================== -->
          <!-- Bread crumb and right sidebar toggle -->
          <!-- ============================================================== -->
          <div class="row page-titles">
            <div class="col-md-5 align-self-center">
              <h4 class="text-themecolor">{{building_name}}/{{flat_name}}</h4>
            </div>
            <div class="col-md-7 align-self-center text-right">
              <div class="d-flex justify-content-end align-items-center">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a >Home</a></li>
                </ol>
              </div>
            </div>
          </div>
          <!-- ============================================================== -->
          <!-- End Bread crumb and right sidebar toggle -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Info box -->
          <!-- ============================================================== -->
          <div class="card-group">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <router-link :to="{ name: 'UserNotification'}">
                    <div class="d-flex no-block align-items-center">
                      <div>
                        <h3><i class="fa fa-bell"></i></h3>
                        <p class="text-muted">Notifications</p>
                      </div>
                      <div class="ml-auto">
                        <h2 class="counter text-primary">{{notifications_count}}</h2>
                      </div>
                    </div>
                    </router-link>
                  </div>
                  <div class="col-12">
                    <div class="progress">
                      <div class="progress-bar bg-primary" role="progressbar" style="width: 85%; height: 6px;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Column -->
            <!-- Column -->
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <router-link :to="{ name: 'UserServiceRequests'}">
                    <div class="d-flex no-block align-items-center">
                      <div>
                        <h3><i class="fa fas fa-user-plus"></i></h3>
                        <p class="text-muted">Services</p>
                      </div>
                      <div class="ml-auto">
                        <h2 class="counter text-cyan">{{servicerequests_count}}</h2>
                      </div>
                    </div>
                  </router-link>
                  </div>
                  <div class="col-12">
                    <div class="progress">
                      <div class="progress-bar bg-cyan" role="progressbar" style="width: 85%; height: 6px;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Column -->
            <!-- Column -->
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <router-link :to="{ name: 'UserComplaints'}">
                    <div class="d-flex no-block align-items-center">
                      <div>
                        <h3><i class="fa fas fa-list-alt"></i></h3>
                        <p class="text-muted">Complaints</p>
                      </div>
                      <div class="ml-auto">
                        <h2 class="counter text-purple">{{complaints_count}}</h2>
                      </div>
                    </div>
                  </router-link>
                  </div>
                  <div class="col-12">
                    <div class="progress">
                      <div class="progress-bar bg-purple" role="progressbar" style="width: 85%; height: 6px;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Column -->
            <!-- Column -->
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <router-link :to="{ name: 'UserPayments'}">
                    <div class="d-flex no-block align-items-center">
                      <div>
                        <h3><i class="fa far fa-credit-card"></i></h3>
                        <p class="text-muted">Balance</p>
                      </div>
                      <div class="ml-auto">
                        <h2 class="counter text-success">{{wallet_bal}}</h2>
                      </div>
                    </div>
                  </router-link>
                  </div>
                  <div class="col-12">
                    <div class="progress">
                      <div class="progress-bar bg-success" role="progressbar" style="width: 85%; height: 6px;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ============================================================== -->
          <!-- End Info box -->
          <!-- ============================================================== -->

          <!-- ============================================================== -->
          <!-- Review -->
          <!-- ============================================================== -->
          <div class="row">
            <!-- Column -->
            <div class="col-lg-12 col-md-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">MY DASHBOARD</h5>
                </div>
                <div v-if="complaints.length == 0 && servicerequests.length == 0">
                  <div style ="padding-left:15px;padding-right:15px; width:100%" >
                      <div class="alert alert-dark">
                          <span>No data to show</span>
                      </div>
                  </div>
                </div>
                <div v-else>
                <div class="table-responsive">
                  <table id="myTable" class="table table-striped"  ref="tables">
                    <thead>
                      <tr>
                        <th>Invoice</th>
                        <th>Service Type</th>
                        <th>Order date</th>
                        <th>Description</th>
                        <th class="text-center">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="complaint in complaints" :key="complaint.id">
                        <td><a  class="link"> CO#{{complaint.id}}</a></td>
                        <td>Complaint</td>
                        <td><span class="text-muted"> {{format_date(complaint.created_at)}}</span></td>
                        <td>{{complaint.description}}</td>
                        <td class="text-center" v-if="complaint.complaint_status_id == '1' ">
                          <div class="label label-table label-info">New</div>
                        </td>
                        <td class="text-center" v-else-if="complaint.complaint_status_id == '2' ">
                          <div class="label label-table label-success">Pending</div>
                        </td>
                        <td class="text-center" v-else-if="complaint.complaint_status_id == '3' ">
                          <div class="label label-table label-danger">Complete</div>
                        </td>
                        <td class="text-center" v-else="complaint.complaint_status_id == '4' ">
                          <div class="label label-table label-warning">Closed</div>
                        </td>
                      </tr>
                      <tr v-for="servicerequest in servicerequests" :key="servicerequest.id">
                        <td><a  class="link"> SR#{{servicerequest.id}}</a></td>
                        <td>Service</td>
                        <td><span class="text-muted">{{format_date(servicerequest.created_at)}}</span></td>
                        <td>{{servicerequest.description}}</td>
                        <td class="text-center" v-if="servicerequest.service_status_id == '1' ">
                          <div class="label label-table label-info">New</div>
                        </td>
                        <td class="text-center" v-else-if="servicerequest.service_status_id == '2' ">
                          <div class="label label-table label-success">Pending</div>
                        </td>
                        <td class="text-center" v-else-if="servicerequest.service_status_id == '3' ">
                          <div class="label label-table label-danger">Complete</div>
                        </td>
                        <td class="text-center" v-else="servicerequest.service_status_id == '4' ">
                          <div class="label label-table label-warning">Closed</div>
                        </td>
                      </tr>
                  </tbody>
                  </table>
              </div>
            </div>
              </div>
            </div>
            <!-- Column -->
          </div>
          <!-- ============================================================== -->
          <!-- End Review -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Comment - chats -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- End Comment - chats -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- End Page Content -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Right sidebar -->
          <!-- ============================================================== -->
          <!-- .right-sidebar -->

          <!-- ============================================================== -->
          <!-- End Right sidebar -->
          <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
      </div>
      <!-- ============================================================== -->
      <!-- End Page wrapper  -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- footer -->
      <!-- ============================================================== -->
      <footer class="footer">
        © 2020 www.ontash.net
      </footer>
      <!-- ============================================================== -->
      <!-- End footer -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Wrapper -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- All Jquery -->
    <!-- ============================================================== -->
  </body>
</template>

<script>
// @ is an alias to /src
import UserHeader from '@/components/headers/UserHeader.vue'
import UserSidebar from '@/components/sidebars/UserSidebar.vue'
import {mapState,mapActions} from 'vuex'
import moment from 'moment'
export default {
  name: 'UserHome',
  components: {
    UserHeader,
    UserSidebar
  },
  data(){
    return{
      flat_name: localStorage.flat_name,
      building_name:localStorage.building_name
    }

  },
  computed:{
    ...mapState('home',['wallet_bal','notifications_count','complaints_count','servicerequests_count',
          'complaints','servicerequests'])
  },
  mounted(){
    this.getUserHome();

  },
  methods:{
    ...mapActions('home',['getUserHome']),
    format_date(value){
       if (value) {
         return moment(String(value)).format('DD-MM-YYYY')
        }
    },
  },
  watch: {
    complaints() {
      $(this.$refs.tables).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.tables).DataTable({
           destroy: true,
          "ordering":false
        }).draw();
      });
    }
  }
}
</script>
<style>

</style>
