<template>
<!-- Add New Service Popup Model -->
<div id="show-bank" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                    aria-hidden="true" style="top:5%;">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="myModalLabel">Bank Details</h4>
                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                                  <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label class="required">Building Name</label>
                                                <input type="text" class="form-control" v-model="building_name" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label class="required">Benificiary ID</label>
                                                <input type="text" class="form-control" v-model="bene_id" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label class="required">Beneficiary Name</label>
                                                <input type="text" class="form-control" v-model="bene_name" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label class="required">Email</label>
                                                <input type="email" class="form-control" v-model="bene_email" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label class="required">Mobile</label>
                                                <input type="tel" class="form-control" v-model="bene_phone" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label class="required">Bank Account No</label>
                                                <input type="text" class="form-control" v-model="bank_account" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label class="required">IFSC</label>
                                                    <input type="text" class="form-control" v-model="ifsc" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>VPA</label>
                                                    <input type="text" class="form-control" v-model="vpa" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>Card Number</label>
                                                    <input type="text" class="form-control" v-model="card_no" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label class="required">Address1</label>
                                                    <input type="text" class="form-control" v-model="address1" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>Address2</label>
                                                    <input type="text" class="form-control" v-model="address2" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>City</label>
                                                    <input type="text" class="form-control" v-model="city" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>State</label>
                                                    <input type="text" class="form-control" v-model="state" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>Pincode</label>
                                                    <input type="text" class="form-control" v-model="pincode" readonly>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                        </div>
                        <!-- /.modal-content -->
                    </div>
                    <!-- /.modal-dialog -->
                </div>
</template>

<script>
// @ is an alias to /src
import {mapState,mapActions} from 'vuex'
export default {
  name: 'ShowBuildingBank',
  components: {

  },
  props:['bank_id'],
  computed:{
    ...mapState('bank',['building_name','bene_id','bene_name','bene_email',
        'bene_phone','bank_account','ifsc','vpa','card_no','address1',
        'address2','city','state','pincode'])
  },
  mounted(){
    this.getBuildingBank();
  },
  methods:{
    getBuildingBank(){
      const bank_id = new URLSearchParams()
      bank_id.append('bank_id', this.bank_id)
      this.$store.dispatch('bank/getBuildingBank',bank_id)
    }
  },
  watch:{
    bank_id(){
      this.getBuildingBank();
    }
  }
}
</script>
<style scoped>

</style>
