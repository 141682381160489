<template>
	<body class="skin-default fixed-layout">
		<!-- ============================================================== -->
	    <!-- Main wrapper - style you can find in pages.scss -->
	    <!-- ============================================================== -->
	    <div id="main-wrapper">
	    	<!-- Page wrapper  -->
	        <!-- ============================================================== -->
	        <AdminHeader/>
          <AdminSidebar/>
	        <div class="page-wrapper">
	            <!-- ============================================================== -->
	            <!-- Container fluid  -->
	            <!-- ============================================================== -->
	            <div class="container-fluid">
	                <!-- ============================================================== -->
	                <!-- Bread crumb and right sidebar toggle -->
	                <!-- ============================================================== -->

	                <div class="row page-titles">

										<div class="alert alert-info parking_exist banner-text" role="alert">
												<span><strong>Alert!</strong> Parking name already exist</span>
										</div>
                        <div class="alert alert-success signout_create banner-text" role="alert">
                            <span><strong>Success!</strong> You have successfully created new parking</span>
                        </div>
                         <div class="alert alert-danger signout_delete banner-text" role="alert">
                            <span><strong>Deleted!</strong> You have successfully deleted the parking</span>
                        </div>
                        <div class="alert alert-success signout_update banner-text" role="alert">
                            <span><strong>Success!</strong> You have successfully updated the parking</span>
                        </div>
                        <div class="alert alert-danger signout_reject banner-text" role="alert">
                            <span><strong>Rejected!</strong> You have successfully rejected the parking request</span>
                        </div>
                        <div class="alert alert-success signout_approve banner-text" role="alert">
                            <span><strong>Success!</strong> You have approved the parking request</span>
                        </div>
                        <div class="alert alert-success signout_approvedelete banner-text" role="alert">
                            <span><strong>Success!</strong> You have approved the delete request</span>
                        </div>

	                    <div class="col-md-5 align-self-center">
	                        <h4 class="text-themecolor">Parking</h4>
	                    </div>
	                    <div class="col-md-7 align-self-center text-right">
	                        <div class="d-flex justify-content-end align-items-center">
	                            <ol class="breadcrumb">
	                                <li class="breadcrumb-item"><router-link :to="{ name: 'AdminHome' }">Home</router-link></li>
	                                <li class="breadcrumb-item active">Parking</li>
	                            </ol>
	                            <button type="button" class="btn btn-info d-none d-lg-block m-l-15" data-toggle="modal"
	                                data-target="#book-parking" @click="loadCreateForm">
	                                + Create Parking</button>
	                        </div>
	                    </div>
	                </div>
	                <!-- ============================================================== -->
                <!-- End Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Info box -->
                <!-- ============================================================== -->
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row m-t-40">
                                  <div class="col-md-6 col-lg-3 col-xlg-3" @click="loadTabTwo">
                                        <div class="card">
                                            <div class="box label-danger text-center">
                                                <h1 class="font-light text-white">{{pending_count}}</h1>
                                                <h6 class="text-white">Pending Requets</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Column -->
                                </div>
																<div v-if="parkings.length <= 0">
		                              <div style ="padding-left:15px;padding-right:15px; width:100%">
		                                                <div class="alert alert-dark">
		                                                    <span>No data to show</span>
		                                                </div>
		                              </div>
		                            </div>
		                            <div v-else>
                                <div class="table-responsive" id="table_areas">
                                    <table id="myTable" class="table  table-striped" ref="table1" v-if="tab_one == true">
                                        <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th>Name</th>
                                                <th>Category</th>
                                                <th>Appartment</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in parkings " :key ="item.id">
                                             <td>{{ item.id }}</td>
                                             <td>{{ item.name }}</td>
                                             <td>{{ item.category }}</td>
                                             <td>{{ item.appartment }}</td>
                                             <td> <div @click="loadUpdateForm(item.id)" class="label label-table label-info" data-toggle="modal"
                                                        data-target="#edit-parking">Edit</div>
                                                    <div @click="deletebooking(item.id)" class="label label-table label-danger" data-toggle="modal" data-target="#verticalcenter1">Delete</div>
                                             </td>
                                             </tr>
                                        </tbody>
                                    </table>
                                     <table id="myTable" class="table  table-striped" ref='table2' v-else-if="tab_two == true">
                                              <thead>
                                                  <tr>
                                                    <th>Id</th>
                                                    <th>Flat Number</th>
                                                    <th>Type</th>
                                                    <th>Status</th>
                                                    <th>Description</th>
                                                    <th >Action</th>
                                                  </tr>
                                              </thead>
                                              <tbody>

                                                <tr v-for="item in parking_req" :key="item.id">
                                                  <td><a href="javascript:void(0)" class="link">{{item.id}}</a></td>
                                                  <td>{{item.appartment_name}}</td>
                                                  <td>{{item.type}}</td>
                                                  <td>{{item.status}}</td>
                                                  <td>{{item.description}}</td>
                                                  <td v-if="item.status == 'Pending' && item.type == 'Add Parking'">
                                                      <div class="label label-table label-success action-column" data-toggle="modal"
                                                          data-target="#approve-parking" @click="approve_parking(item.id,item.appartment_name,item.appartment_id)">Approve</div>
                                                      <div class="label label-table label-danger action-column" data-toggle="modal"
                                                          data-target="#reject-parking" @click="reject_parking(item.id,item.appartment_id)">Reject</div>
                                                  </td>
                                                  <td  v-else-if="item.status == 'Pending' && item.type == 'Remove Parking'">
                                                      <div class="label label-table label-success action-column" data-toggle="modal"
                                                          data-target="#approve-delete-parking" @click="approve_delete_parking(item.id,item.appartment_id,item.parking_id,item.parking_name)">Approve</div>
                                                  </td>
                                                  <td v-else></td>
                                                </tr>
                                              </tbody>
                                          </table>
																	</div>
                                </div>
                                <div v-if="createform === true">
                                  <CreateParking></CreateParking>
                                </div>
                                <div v-if="approveform === true">
                                  <ApproveParking :request_id='request_id' :appartment_name='appartment_name' :flat_id='flat_id' ></ApproveParking>
                                </div>
                                <div v-if="approve_delete_form === true">
                                  <ApproveDeleteRequest :request_id='request_id' :parking_name='parking_name' :parking_id='parking_id' :flat_id='flat_id'  ></ApproveDeleteRequest>
                                </div>

                                <div v-if="rejectform === true">
                                  <RejectParking :request_id='request_id' :flat_id='flat_id'></RejectParking>
                                </div>

                                <div v-if="updateform == true">
                                  <UpdateParking :parking_id='parking_id' ></UpdateParking>
                                </div>
                                <div v-if="deleteform === true">
                                  <DeleteParking :parking_id='parking_id'></DeleteParking>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- ============================================================== -->
                <!-- End Info box -->
                <!-- ============================================================== -->
            </div>
        </div>
        <footer class="footer">
            © 2020 www.ontash.net
        </footer>
    </div>
	</body>
</template>

<script>
// @ is an alias to /src
import AdminHeader from '@/components/headers/AdminHeader.vue'
import AdminSidebar from '@/components/sidebars/AdminSidebar.vue'
import CreateParking from '@/components/parking/CreateParking.vue'
import UpdateParking from '@/components/parking/UpdateParking.vue'
import DeleteParking from '@/components/parking/DeleteParking.vue'
import ApproveParking  from '@/components/parking/ApproveParking'
import RejectParking  from '@/components/parking/RejectParking'
import ApproveDeleteRequest  from '@/components/parking/ApproveDeleteRequest'
import {mapState,mapActions} from 'vuex'
import moment from 'moment'
export default {
	name: 'AdminParking',
	components: {AdminHeader, AdminSidebar,CreateParking,UpdateParking,DeleteParking,RejectParking,ApproveParking,ApproveDeleteRequest},
	computed:{
		...mapState('parking',['parkings', 'parking_req','pending_count']),

	},
	data(){
		return{

			createform: false,
			updateform: false,
			deleteform: false,
			tab_one: false,
			tab_two: false,
			approveform: false,
			rejectform: false,
			approve_delete_form: false
		}

	},
	created: function(){
		const taskid = new URLSearchParams()
		taskid.append('page', 'AdminParking')
		taskid.append('building_id',localStorage.building_id)
		this.$store.dispatch('notification/change_notification_status_admin_taskid',taskid)
		const parking = new URLSearchParams()
		parking.append('building_id',localStorage.building_id)
		this.$store.dispatch('parking/show_parking',parking)
		this.$store.dispatch('parking/show_parking_request',parking)


	},
	mounted(){
		this.showalerts();
		this.tab_one = true
	},

	methods: {
		loadCreateForm(){

			this.createform = true

		},
		deletebooking(id){
			this.parking_id = id
			this.deleteform = true
		},
		loadUpdateForm(id){
			this.parking_id = id
			this.updateform = true

		},
		loadTabTwo(){
			this.tab_two = true
			this.tab_one = false
			$(this.$refs.table1).DataTable().destroy();
			$(this.$refs.table2).DataTable().destroy();
			this.$nextTick(() => {
				$(this.$refs.table2).DataTable({
					destroy: true,
					"ordering":false
				});
			});
		},
		approve_parking(id,name,appartment_id){
			this.request_id = id
			this.appartment_name = name
			this.flat_id = appartment_id
			this.approveform = true
		},
		approve_delete_parking(id, appartment_id,parking_id,parking_name){
			this.request_id = id
			this.flat_id = appartment_id
			this.parking_id = parking_id
			this.parking_name = parking_name
			this.approve_delete_form = true
		},
		reject_parking(id, appartment_id){
			this.request_id = id
			this.flat_id = appartment_id
			this.rejectform = true

		},
		showalerts(){
			if (localStorage.status == "created")
			{
				$('.signout_create').delay(500).slideDown(500);
				$('.signout_create').delay(4000).slideUp(500);
				localStorage.removeItem('status')
			}
			else if (localStorage.status == "updated")
			{
				$('.signout_update').delay(500).slideDown(500);
				$('.signout_update').delay(4000).slideUp(500);
				localStorage.removeItem('status')
			}
			else if (localStorage.status == "deleted")
			{
				$('.signout_delete').delay(500).slideDown(500);
				$('.signout_delete').delay(4000).slideUp(500);
				localStorage.removeItem('status')
			}
			else if (localStorage.status == "rejected")
			{
				$('.signout_reject').delay(500).slideDown(500);
				$('.signout_reject').delay(4000).slideUp(500);
				localStorage.removeItem('status')
			}
			else if (localStorage.status == "approved")
			{
				$('.signout_approve').delay(500).slideDown(500);
				$('.signout_approve').delay(4000).slideUp(500);
				localStorage.removeItem('status')
			}
			else if (localStorage.status == "approved_deleted")
			{
				$('.signout_approvedelete').delay(500).slideDown(500);
				$('.signout_approvedelete').delay(4000).slideUp(500);
				localStorage.removeItem('status')
			}
			else if (localStorage.status == "parking_exist")
			{
				$('.parking_exist').delay(500).slideDown(500);
				$('.parking_exist').delay(4000).slideUp(500);
				localStorage.removeItem('status')
			}


		}
	},

	watch: {
		parkings() {
			$(this.$refs.table1).DataTable().destroy();
			this.$nextTick(() => {
				$(this.$refs.table1).DataTable({
					destroy: true,
					"ordering":false
				});
			});
		},
		booking_requests() {
			$(this.$refs.table2).DataTable().destroy();
			this.$nextTick(() => {
				$(this.$refs.table2).DataTable({
					destroy: true,
					"ordering":false
				});
			});
		}
	}
}

</script>
