<template>
<!-- Add New Service Popup Model -->
<div id="add-member" class="modal fade in" tabindex="-1" role="dialog"
                                      aria-labelledby="myModalLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                          <div class="modal-content">
                                              <div class="modal-header">
                                                  <h4 class="modal-title" id="myModalLabel">Add Members to this group</h4>
                                                  <button type="button" class="close" data-dismiss="modal"
                                                      aria-hidden="true">×</button>
                                              </div>
                                              <div class="card-body" style="padding-top: 0px;">
                                              <div class="card" style="margin-bottom: 0px;">
                                          <ul class="nav nav-tabs customtab" role="tablist">
                                              <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#userrequest" role="tab" aria-selected="true"><span class="hidden-sm-up"></span> <span class="hidden-xs-down">Add a Member</span></a> </li>
                                              <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#grouprequest" role="tab" aria-selected="false"><span class="hidden-sm-up"><span class="hidden-xs-down">Remove Member</span></span></a> </li>
                                          </ul>
                                          <!-- Tab panes -->

                                          <input id="selected" name="group_id" type="hidden" value="<%=@button_val.to_i%>"/>
                                          <div class="tab-content" style="padding: 10px 14px;">
                                              <div class="tab-pane active" id="userrequest" role="tabpanel">
                                                      <div class="table-responsive m-t20">
                                                           <table id="myTable1" class="table table-bordered table-striped" ref="tables1">
                                                          <thead>
                                                              <tr>
                                                                  <th>Flat</th>
                                                                  <th></th>
                                                              </tr>
                                                          </thead>
                                                          <tbody>
                                                            <tr v-for="item in appart_details " :key ="item.id">
                                                              <td>{{item.number}}</td>
                                                              <td><input  @click="addflat(item.id)" type="checkbox"></td>
                                                            </tr>
                                                          </tbody>
                                                      </table>
                                                          </div>
                                                          <hr>
                                              <div class="col-sm-12" style="display: contents !important;">
                                                  <div class="form-group">
                                                    <div class="alert alert-danger pass1" style="display: none;"><span>Please Check atleast One</span></div>
                                                      <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0;float:right;margin-right: 0px !important;">
                                                          <button type="submit" @click="add_group_member" data-dismiss="modal" class="btn btn-success mr-2">Add</button>
                                                          <button type="submit" data-dismiss="modal" class="btn btn-danger mr-2">Cancel</button>

                                                       <!--   <%end%> -->
                                                      </div>
                                                  </div>

                                              </div>
                                              </div>
                                              <div class="tab-pane" id="grouprequest" role="tabpanel">
                                                 <!--   <%= form_tag(remove_from_group_path, method: 'post', :id => 'medication-select') do %> -->
                                                   <input id="selected" name="group_id" type="hidden" value="<%=@button_val.to_i%>"/>
                                                      <div class="col-sm-12">
                                                              <div class="form-group row">


                                                                  <label for="example-text-input" class="col-12 col-form-label" style="font-weight: 500;"></label>
                                                                   <div class="table-responsive m-t20">
                                                               <table id="myTable2" class="table table-bordered table-striped" ref="tables2">
                                                          <thead>
                                                              <tr>
                                                                  <th>Flat</th>
                                                                  <th></th>
                                                              </tr>
                                                          </thead>
                                                          <tbody>
                                                            <tr v-for="item in grp_list " :key ="item.id">
                                                              <td>{{item.number}}</td>
                                                              <td><input @click="remflat(item.id)" type="checkbox"></td>
                                                            </tr>

                                                          </tbody>

                                                      </table>

                                                          </div>


                                                              </div>
                                                              <hr>
                                                              <div class="form-group">
                                                                <div class="alert alert-danger pass2" style="display: none;"><span>Please Check atleast One</span></div>
                                                      <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0;float:right;margin-right: 0px !important;">
                                                          <button type="submit" @click="remove_group_member" data-dismiss="modal" class="btn btn-success mr-2">Remove</button>
                                                          <!--  <%= submit_tag "Remove",class: "btn btn-success mr-2" %> -->
                                                          <button type="submit" data-dismiss="modal" class="btn btn-danger mr-2">Cancel</button>
                                                      </div>
                                                  </div>
                                                          </div>
                                                         <!-- <%end%> -->
                                              </div>
                                          </div>
                                      </div>

                                  </div>

                               </div>
                               <!-- /.modal-content -->
                            </div>
                            <!-- /.modal-dialog -->
                         </div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {mapState} from 'vuex'
export default {
  name: 'AddMember',
  computed:{
      ...mapState('adminflatgroup',['appart_details', 'grp_list'])


  },
   data(){
      return{
       flats: [],
       rem_flat: []

      }

    },

  mounted(){
    this.getappart();
  },
  methods: {
     getappart(){
      const flat = new URLSearchParams()
      flat.append('building_id',  localStorage["build_id"])
      flat.append('group_id',  localStorage["group_id"])
      this.$store.dispatch('adminflatgroup/get_non_appartment_details',flat)
      this.$store.dispatch('adminflatgroup/list_app_details',flat)
     },
     addflat(flat_id){
       this.flats.push(flat_id)
     },
     remflat(flat_id){
       this.rem_flat.push(flat_id)
     },
     add_group_member()
     {
      this.$store.commit('spinner/showLoading', true)
      const flat = new URLSearchParams()
      flat.append('group_id',  localStorage["group_id"])
      this.flats.forEach((item) => {
         flat.append('appartment_ids[]', item);
      });
      this.$store.dispatch('adminflatgroup/add_new_member',flat)

     },
     remove_group_member()
     {
       this.$store.commit('spinner/showLoading', true)
       const flat = new URLSearchParams()
       flat.append('group_id',  localStorage["group_id"])
       this.rem_flat.forEach((item) => {
         flat.append('appartment_ids[]', item);
       });
       this.$store.dispatch('adminflatgroup/remove_flat_member',flat)
     }
  },
  watch: {
    appart_details() {
      $(this.$refs.tables1).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.tables1).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    },
     grp_list() {
      $(this.$refs.tables2).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.tables2).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    }
    }
}
</script>
