import axios from 'axios'
import baseurl from './axioscredentials'
axios.defaults.withCredentials = true
import router from '@/router'
const group = {
  namespaced: true,
  state: {
    usergroups: [],
    groupdetails: [],
    groupmemeberdetails: [],
    userdetails: [],
    userprofile:[],
    userappartments:[],
    userprofilepicture:''
  },
  getters: {

  },
  mutations: {
    SET_USERGROUPS(state,data){
      state.usergroups = data.user_groups
    },
    SET_USERGROUP_MEMBERS(state,data){
      state.groupdetails = data.group_details
      state.groupmemeberdetails = data.group_members
    },
    SET_USER_DETAILS(state,data){
      state.userdetails = data.user_details
      state.userprofile = data.profile
      state.userappartments = data.appartments
      state.userprofilepicture = data.profile_picture
    }

  },
  actions: {
    getUserGroups({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/user_groups',{flat_id: localStorage.flat_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_USERGROUPS',res.data)
        }).catch(error => console.log(error))
    },
    getUserGroupMembers({ commit, state},groupmembers){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/user_group_members',groupmembers,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_USERGROUP_MEMBERS',res.data)
        }).catch(error => console.log(error))
    },
    getUserDetails({ commit, state},userid){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/view_profile_group_member',userid,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_USER_DETAILS',res.data)
        }).catch(error => console.log(error))
    }
  }
}
export default group
