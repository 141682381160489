<template>
<div id="edit-area" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    aria-hidden="true" style="top:10%;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">Edit Facility</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <form @submit.prevent="UpdateAdminFacility">
                <div class="card-body" style="padding: 10px 50px;">
                  <div class="row" style="justify-content: center;">
                        <div class="col-md-12 ">
                            <div class="form-group">
                                <label>Name</label>
                                <input v-model="facility_name" type="text" class="form-control" placeholder="Enter Name" :class="{ error: v$.facility_name.$error }">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 ">
                            <div class="form-group">
                                <label>Description</label>
                                <textarea v-model="description" class="form-control" rows="3" placeholder="Enter Description" :class="{ error: v$.description.$error }"></textarea>
                            </div>
                        </div>
                    </div>
                    <input type="radio" name="availability" value="true" v-model="status" ><label>Available  </label>&nbsp;
                    <input type="radio" name="availability" value="false" v-model="status" ><label>Unavailable  </label>
               
                <hr>
            <div class="col-sm-12">
              <div class="form-group">

                    <button type="submit" class="btn btn-info waves-effect">Update</button>
                    <button type="button" class="btn btn-danger waves-effect" data-dismiss="modal"  style="margin-left: 10px">Cancel</button>
                </div>
                 </div>

              </div>
            </form>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>

</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {mapState} from 'vuex'

export default {
  name: 'UpdateFacility',
  components: {

  },
  setup () {
    return { v$: useVuelidate() }
  },
  props:['facility_id'],
  data(){
    return{
      buttonactive: false,
      deleteactive: false
    }
  },
  computed:{
    facility_name: {
      get () {
        return this.$store.state.facility.facility_name
      },
      set (value) {
        this.$store.commit('facility/updateFacilityName', value)
      }
    },
    description: {
      get () {
        return this.$store.state.facility.description
      },
      set (value) {
        this.$store.commit('facility/updateDescription', value)
      }
    },
    status: {
      get () {
        return this.$store.state.facility.status
      },
      set (value) {
        this.$store.commit('facility/updateStatus', value)
      }
    }
  },
  created(){
    this.getAdminFacility();
  },
  validations () {
    return {
      facility_name: { required }, // Matches this.firstName
      description: { required } // Matches this.lastName
    }
  },
  methods:{
    getAdminFacility(){
      const facility_id = new URLSearchParams()
      facility_id.append('facility_id', this.facility_id)
      facility_id.append('building_id', localStorage.building_id)
      this.$store.dispatch('facility/getAdminFacility',facility_id)
    },
    UpdateAdminFacility(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const facility = new URLSearchParams()
        facility.append('facility_id', this.facility_id)
        facility.append('facility_name', this.facility_name)
        facility.append('description', this.description)
        facility.append('building_id', localStorage.building_id)
        facility.append('status', this.status)
        this.$store.dispatch('facility/UpdateAdminFacility',facility)
      }
    }
  },
  watch:{
    facility_id(){
      this.getAdminFacility();
    }
  }

}

</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
