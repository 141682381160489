<template>
<body class="skin-default fixed-layout">
    <div id="main-wrapper">
        <UserHeader/>
        <UserSidebar/>
    <div class="page-wrapper">

            <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="container-fluid">
                <!-- ============================================================== -->
                <!-- Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Payments</h4>
                    </div>
                    <div class="alert alert-warning signout_delete banner-text" role="alert">
                        <span><strong>Warning!</strong> Please add a valid email address before adding money to wallet</span>
                    </div>
                    <div class="alert alert-warning flat_approval banner-text" role="alert">
                        <span><strong>Warning!</strong> Your account is not activated</span>
                    </div>

                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link :to="{ name: 'UserHome' }">Home</router-link></li>
                                <li class="breadcrumb-item active">Payments</li>
                            </ol>
                            <button type="button" class="btn btn-info d-lg-block m-l-15" @click = "add_to_wallet" data-toggle="modal" data-target="#wallet-payment">
                                +Add to Wallet</button>
                        </div>
                    </div>
                </div>
                <!-- Start Page Content -->
                <!-- ============================================================== -->
                <div v-if="add_wallet === true">
                  <Addwallet></Addwallet>
                </div>
                <div v-if="make_payment === true">
                  <CreateUserPayment :payment_id ='payment_id' :walletblnz = 'walletblnz'/>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body" style="padding: 25px 50px;">
                                <div class="row">
                                        <div class="col-md-6 col-lg-3 col-xlg-3">
                                            <div class="card">
                                                <div class="box label-success text-center" @click="sortBypending(0)">
                                                    <h1 class="font-light text-white">{{pending_count}}</h1>
                                                    <h6 class="text-white">Pending</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="payment_list.length <= 0">
                                      <div style ="padding-left:15px;padding-right:15px; width:100%">
                                                        <div class="alert alert-dark">
                                                            <span>No data to show</span>
                                                        </div>
                                      </div>
                                    </div>
                                    <div v-else>
                                <div class="table-responsive">
                                    <table id="myTable" class="table table-striped"  ref="tables">
                                            <thead>
                                                <tr>
                                                    <th>Payment Id</th>
                                                    <th>Date</th>
                                                    <th>Topic</th>
                                                    <th>Amount</th>
                                                    <th >Status</th>
                                                    <th >Payments</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in payment_list " :key ="item.id">
                                                    <td>{{item.id}}</td>
                                                    <td>{{format_date(item.created_at)}}</td>
                                                    <td>{{item.topic}}</td>
                                                    <td>{{item.amount}}</td>
                                                    <td v-if="item.status == false">Pending</td>
                                                    <td v-else-if="item.status == true">Paid</td>
                                                    <td v-if="item.status == false"> <div class="label label-table label-danger " style="cursor: pointer;" data-toggle="modal" data-target="#make-payment-individual" @click="create_payment(item.id)">Make payment</div></td>
                                                    <td v-else-if="item.status == true"> <div class="label label-table label-success" style="cursor: pointer;" >Payment Done</div></td>

                                                </tr>

                                             </tbody>
                                            </table>
                                          </div>
                            </div>
                        </div>
                    </div>
                </div>

                    <!-- /.modal-dialog -->

                <!-- ============================================================== -->
                <!-- End PAge Content -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
        </div>
    </div>
    <footer class="footer">
    © 2020 www.ontash.net
</footer>
</div>
</body>

</template>

<script>
    // @ is an alias to /src
    import UserSidebar from '@/components/sidebars/UserSidebar.vue'
    import UserHeader from '@/components/headers/UserHeader.vue'
    import Addwallet  from '@/components/wallet/Addwallet.vue'
    import CreateUserPayment from '@/components/payment/CreateUserPayment.vue'
    import moment from 'moment'
    import {mapState,mapActions,mapMutations} from 'vuex'
    export default{
        name: 'UserPayments',
        components: {
            UserHeader,
            UserSidebar,
            Addwallet,
            CreateUserPayment
        },
        data() {
            return {
                add_wallet: false,
                make_payment: false,
                payment_id: ''
            }
        },
        computed:{
            ...mapState('wallet',['wallet_bal','payments','payment_list','pending_count']),
            ...mapState('flat',['appartmentstatus'])

        },
        mounted(){
            this.$store.dispatch('wallet/user_payment')
            this.showalerts();
            this.getAppartmentUserStatus();
        },
        methods: {
          ...mapMutations('wallet',['sortBypending']),
          ...mapActions('flat',['getAppartmentUserStatus']),
          add_to_wallet(){
             if (this.appartmentstatus == "active"){
               this.add_wallet = true
             }
             else{
               $('.flat_approval').delay(500).slideDown(500);
               $('.flat_approval').delay(4000).slideUp(500);
             }
          },
          create_payment(id){
             this.payment_id = id
             this.walletblnz = this.wallet_bal
             this.make_payment = true
          },
          format_date(value){
             if (value) {
               return moment(String(value)).format('DD-MM-YYYY')
              }
          },
           showalerts(){
              if (localStorage.status == "update_email")
              {
                $('.signout_delete').delay(500).slideDown(500);
                $('.signout_delete').delay(4000).slideUp(500);
                localStorage.removeItem('status')
              }
          }
        },
        watch: {
            payment_list() {
              $(this.$refs.tables).DataTable().destroy();
              this.$nextTick(() => {
                $(this.$refs.tables).DataTable({
                   destroy: true,
                  "ordering":false
                });
              });
            }
        }
    }
</script>
