<template>
  <body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <AdminHeader/>
      <AdminSidebar/>
      <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
          <!-- ============================================================== -->
          <!-- Bread crumb and right sidebar toggle -->
          <!-- ============================================================== -->
          <div class="row page-titles">
            <div class="col-md-5 align-self-center">
              <h4 class="text-themecolor">{{building_name}} Administrator</h4>
            </div>
            <div class="col-md-7 align-self-center text-right">
              <div class="d-flex justify-content-end align-items-center">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">Home</li>
                </ol>
              </div>
            </div>
          </div>
          <!-- ============================================================== -->
          <!-- End Bread crumb and right sidebar toggle -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Info box -->
          <!-- ============================================================== -->
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row m-t-40">
                    <!-- Column -->
                    <div class="col-md-6 col-lg-3 col-xlg-3" @click="sortByStatusAdmin(1)">
                      <div class="card">
                        <div class="box label-info text-center">
                          <h1 class="font-light text-white">{{new_count}}</h1>
                          <h6 class="text-white">New</h6>
                        </div>
                      </div>
                    </div>
                    <!-- Column -->
                    <div class="col-md-6 col-lg-3 col-xlg-3" @click="sortByStatusAdmin(2)">
                      <div class="card">
                        <div class="box label-success text-center">
                          <h1 class="font-light text-white">{{pending_count}}</h1>
                          <h6 class="text-white">Pending</h6>
                        </div>
                      </div>
                    </div>
                    <!-- Column -->
                    <div class="col-md-6 col-lg-3 col-xlg-3" @click="sortByStatusAdmin(3)">
                      <div class="card">
                        <div class="box label-danger text-center">
                          <h1 class="font-light text-white">{{complete_count}}</h1>
                          <h6 class="text-white">Complete</h6>
                        </div>
                      </div>
                    </div>
                    <!-- Column -->
                    <div class="col-md-6 col-lg-3 col-xlg-3" @click="sortByStatusAdmin(4)">
                      <div class="card">
                        <div class="box label-warning text-center">
                          <h1 class="font-light text-white">{{closed_count}}</h1>
                          <h6 class="text-white">Closed</h6>
                        </div>
                      </div>
                    </div>
                    <!-- Column -->
                  </div>
                  <div v-if="admincomplaints.length <= 0 && adminservicerequests.length <= 0">
                    <div style ="padding-left:15px;padding-right:15px; width:100%">
                                      <div class="alert alert-dark">
                                          <span>No data to show</span>
                                      </div>
                    </div>
                  </div>
                  <div v-else>
                  <div class="table-responsive">
                    <table id="myTable" class="table table-striped"  ref="tables">
                      <thead>
                        <tr>
                          <th>Invoice</th>
                          <th>Flat/Group</th>
                          <th>Request Type</th>
                          <th>Request date</th>
                          <th>Description</th>
                          <th class="text-center">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="complaint in admincomplaints" :key="complaint.id">
                          <td><a  class="link"> CO#{{complaint.id}}</a></td>
                          <td> {{complaint.appartment}}</td>
                          <td>Complaint</td>
                          <td>{{format_date(complaint.created_at)}}</td>
                          <td>{{complaint.description}}</td>
                          <td class="text-center" v-if="complaint.status_id == '1' " @click="loadStatusFormComplaint(complaint.id,complaint.appartment)" data-toggle="modal" data-target="#change-status">
                            <div class="label label-table label-info">New</div>
                          </td>
                          <td class="text-center" v-else-if="complaint.status_id == '2' " @click="loadStatusFormComplaint(complaint.id,complaint.appartment)" data-toggle="modal" data-target="#change-status">
                            <div class="label label-table label-success">Pending</div>
                          </td>
                          <td class="text-center" v-else-if="complaint.status_id == '3' " @click="loadStatusFormComplaint(complaint.id,complaint.appartment)" data-toggle="modal" data-target="#change-status">
                            <div class="label label-table label-danger">Complete</div>
                          </td>
                          <td class="text-center" v-else="complaint.status_id == '4' " @click="loadStatusFormComplaint(complaint.id,complaint.appartment)" data-toggle="modal" data-target="#change-status">
                            <div class="label label-table label-warning">Closed</div>
                          </td>
                        </tr>
                        <tr v-for="servicerequest in adminservicerequests" :key="servicerequest.service_id">
                          <td><a  class="link"> SR#{{servicerequest.service_id}}</a></td>
                            <td>{{servicerequest.flat_or_group}}</td>
                          <td>Service</td>
                          <td>{{format_date(servicerequest.service_date)}}</td>
                          <td>{{servicerequest.description}}</td>
                          <td class="text-center" v-if="servicerequest.status_id == '1' " @click="loadStatusFormService(servicerequest.service_id,servicerequest.flat_or_group)" data-toggle="modal" data-target="#change-status-servrequest">
                            <div class="label label-table label-info">New</div>
                          </td>
                          <td class="text-center" v-else-if="servicerequest.status_id == '2' " @click="loadStatusFormService(servicerequest.service_id,servicerequest.flat_or_group)" data-toggle="modal" data-target="#change-status-servrequest">
                            <div class="label label-table label-success">Pending</div>
                          </td>
                          <td class="text-center" v-else-if="servicerequest.status_id == '3' " @click="loadStatusFormService(servicerequest.service_id,servicerequest.flat_or_group)" data-toggle="modal" data-target="#change-status-servrequest">
                            <div class="label label-table label-danger">Complete</div>
                          </td>
                          <td class="text-center" v-else="servicerequest.status_id == '4' " @click="loadStatusFormService(servicerequest.service_id,servicerequest.flat_or_group)" data-toggle="modal" data-target="#change-status-servrequest">
                            <div class="label label-table label-warning">Closed</div>
                          </td>
                        </tr>
                    </tbody>
                    </table>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="statusformservice === true">
            <AdminUpdateServiceStatus :servicerequest_id='servicerequest_id' :flat_group_number='flat_group_number'/>
          </div>
          <div v-if="statusformcomplaint === true">
            <AdminUpdateStatus :complaint_id='complaint_id' :flat_number='flat_number'/>
          </div>
          <!-- ============================================================== -->
          <!-- End Info box -->
          <!-- ============================================================== -->

          <!-- ============================================================== -->
          <!-- Review -->
          <!-- ============================================================== -->

          <!-- ============================================================== -->
          <!-- End Review -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Comment - chats -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- End Comment - chats -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- End Page Content -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Right sidebar -->
          <!-- ============================================================== -->
          <!-- .right-sidebar -->

          <!-- ============================================================== -->
          <!-- End Right sidebar -->
          <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
      </div>
      <!-- ============================================================== -->
      <!-- End Page wrapper  -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- footer -->
      <!-- ============================================================== -->
      <footer class="footer">
        © 2020 www.ontash.net
      </footer>
      <!-- ============================================================== -->
      <!-- End footer -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Wrapper -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- All Jquery -->
    <!-- ============================================================== -->
  </body>
</template>

<script>
// @ is an alias to /src
import AdminHeader from '@/components/headers/AdminHeader.vue'
import AdminSidebar from '@/components/sidebars/AdminSidebar.vue'
import AdminUpdateServiceStatus from '@/components/service_requests/AdminUpdateServiceStatus.vue'
import AdminUpdateStatus from '@/components/complaints/AdminUpdateStatus.vue'
import {mapState,mapActions,mapMutations} from 'vuex'
import moment from 'moment'
export default {
  name: 'AdminHome',
  components: {
    AdminHeader,
    AdminSidebar,
    AdminUpdateServiceStatus,
    AdminUpdateStatus
  },
  data(){
    return{
      building_name:localStorage.building_name,
      statusformservice: false,
      servicerequest_id: '',
      flat_group_number: '',
      statusformcomplaint: false,
      complaint_id: '',
      flat_number: ''

    }

  },
  computed:{
    ...mapState('home',['new_count','pending_count','complete_count','closed_count',
          'admincomplaints','adminservicerequests'])
  },
  mounted(){
    
    this.getAdminHome();

  },
  methods:{
    ...mapActions('home',['getAdminHome']),
    ...mapMutations('home',['sortByStatusAdmin']),
    format_date(value){
       if (value) {
         return moment(String(value)).format('DD-MM-YYYY')
        }
    },
    loadStatusFormService(serv_id,flat_group_number){
      this.statusformservice = true
      this.servicerequest_id = serv_id
      this.flat_group_number = flat_group_number
    },
    loadStatusFormComplaint(comp_id,flat_number){

      this.statusformcomplaint = true
      this.complaint_id = comp_id
      this.flat_number = flat_number
    },
  },
  watch: {
    admincomplaints() {
      $(this.$refs.tables).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.tables).DataTable({
           destroy: true,
          "ordering":false
        }).draw();
      });
    }
  }
}
</script>
<style>

</style>
