<template>
<!-- Add New Service Popup Model -->
<div id="add-service-request" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="top:10%;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">Complaints</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="card-body">
                    <form class="form" @submit.prevent="createComplaintAdmin">
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label for="example-text-input" class="col-4 col-form-label" style="font-weight: 500;">Appartment</label>
                                    <div class="col-12">
                                        <select class="form-control custom-select" v-model="flat_id" :class="{ error: v$.flat_id.$error }">
                                            <option  >Select Appartment</option>
                                            <option v-for="appartment in appartments" :key="appartment.id" :value="appartment.id">{{appartment.number}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label for="example-text-input" class="col-4 col-form-label" style="font-weight: 500;">Complaint Topic</label>
                                    <div class="col-12">
                                      <input type="text" class="form-control" v-model="topic" placeholder="Enter Complaint Topic " :class="{ error: v$.topic.$error }">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Description</label>
                                    <div class="col-12">
                                        <textarea class="form-control" rows="5"  placeholder="Enter Description" v-model="description" :class="{ error: v$.description.$error }"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                        <button type="submit" class="btn btn-success mr-2">Submit</button>
                                        <button type="button" class="btn btn-danger mr-2" data-dismiss="modal" aria-hidden="true">Cancel</button>
                                    </div>
                                </div>
                            </div>

                        </form>
           </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {mapState,mapActions} from 'vuex'
export default {
  name: 'CreateComplaint',
  setup () {
    return { v$: useVuelidate() }
  },
  components: {

  },
  data(){
    return{
      flat_id: 'Select Appartment',
      topic: '',
      description: ''
    }

  },
  computed:{
    ...mapState('complaint',['appartments']),
  },
  mounted(){
    this.getAppartments();
  },
  validations () {
    return {
      flat_id: { required,
       isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === 'Select Appartment') return false
        else return true


         }


      },
      topic: { required }, // Matches this.firstName
      description: { required } // Matches this.lastName
    }
  },
  methods:{
    ...mapActions('complaint',['getAppartments']),
    createComplaintAdmin(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const complaint = new URLSearchParams()
        complaint.append('flat_id', this.flat_id)
        complaint.append('topic', this.topic)
        complaint.append('description', this.description)
        complaint.append('building_id', localStorage.building_id)
        this.$store.dispatch('complaint/createComplaintAdmin',complaint)
      }
    }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
