<template>
 <div id="delete-event" class="modal" tabindex="-1" role="dialog" aria-labelledby="vcenter" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" style="margin-left: 5%;opacity: 60%;" id="myModalLabel">Confirm
                                    your
                                    changes</h4>
                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div class="card-body" style="padding: 10px 50px;">
                                <div class="row" style="justify-content: left;">
                                    <h4 style="margin-left: 40px"><strong>Are you sure?</strong> Do you want to delete this event?</h4>

                                </div>
                                <hr>
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                            <button type="submit" @click="delete_event" class="btn btn-success mr-2 confirm-submit"
                                                data-dismiss="modal" aria-hidden="true">OK</button>
                                            <button type="button"  class="btn btn-danger mr-2" data-dismiss="modal"
                                                aria-hidden="true">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /.modal-content -->
                    </div>
                    <!-- /.modal-dialog -->
                </div>

</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  name: 'DeleteEvent',
  props:['e_id'],
  methods: {
    delete_event(){
        this.$store.commit('spinner/showLoading', true)
        const event = new URLSearchParams()
        event.append('eventid', this.e_id)
        this.$store.dispatch('event/delete_event',event)
    }
  }
}
</script>
