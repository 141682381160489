const spinner = {
  namespaced: true,
  state: {
    loading: false
  },
  getters: {

  },
  mutations: {
    showLoading(state,payload){
      state.loading = payload
    }

  },
  actions: {

  }
}
export default spinner
