<template>
<!-- Add New Service Popup Model -->
<div id="add-new-request" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title" id="myModalLabel">Add New Contact</h4>
                                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                                            </div>
                                            <div class="card-body">
                                                    <form class="form" @submit.prevent="create_contact">
                                                            <div class="col-sm-12">
                                                                <div class="form-group row">
                                                                    <label for="example-text-input" class="" style=" padding-left: 10px; padding-right: 10px;">Contact Name</label>
                                                                    <div class="col-12">
                                                                    <input type="text" v-model="contact_name" autocomplete="off"
                                                                            class="form-control" placeholder="Enter Contact Name"
                                                                            :class="{ error: v$.contact_name.$error }" >
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12">
                                                                <div class="form-group row">
                                                                    <label for="example-text-input" class="" style=" padding-left: 10px; padding-right: 10px;">Contact Number</label>
                                                                    <div class="col-12">
                                                                    <input type="tel" v-model="contact_number" autocomplete="off"
                                                                            class="form-control" placeholder="Enter Contact Number "  :class="{ error: v$.contact_number.$error }" >
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12">
                                                                <div class="form-group row">
                                                                    <label for="example-text-input" class="" style=" padding-left: 10px; padding-right: 10px;">Email</label>
                                                                    <div class="col-12">
                                                                    <input type="email" v-model="contact_email" autocomplete="off"
                                                                            class="form-control" placeholder="Enter Email"  >
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12">
                                                                <div class="form-group row">
                                                                    <label for="example-text-input" class="" style=" padding-left: 10px; padding-right: 10px;">Job Title</label>
                                                                    <div class="col-12">
                                                                    <input type="text" v-model="contact_job" autocomplete="off"
                                                                            class="form-control" placeholder="Enter Job Title "  :class="{ error: v$.contact_job.$error }">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12">
                                                                <div class="form-group row">
                                                                    <label for="example-text-input" class="" style=" padding-left: 10px; padding-right: 10px;">Address</label>
                                                                <div class="col-12">
                                                                        <textarea class="form-control" rows="3" v-model="contact_address" autocomplete="off"  :class="{ error: v$.contact_address.$error }" placeholder="Enter Address" ></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12">
                                                                <div class="form-group">
                                                                     <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                                                        <button type="submit" class="btn btn-success mr-2" >Submit</button>
                                                                        <button type="button" class="btn btn-danger mr-2" data-dismiss="modal">Cancel</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </form>
                                        </div>
                                        </div>
                                        <!-- /.modal-content -->
                                    </div>
                                    <!-- /.modal-dialog -->
                                </div>
                            <!-- Start Page Content -->
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required,numeric,minLength,maxLength} from '@vuelidate/validators'
export default {
  name: 'CreateDirectory',
  setup () {
    return { v$: useVuelidate() }
  },
  data(){
    return{
      contact_name: '',
      contact_number: '',
      contact_email: '',
      contact_job: '',
      contact_address: '',
    }
  },
  validations () {
    return {
      contact_name: { required },
      contact_number:{ required, numeric, minLength: minLength(10), maxLength: maxLength(10)},
      contact_job: { required },
      contact_address: { required },
    }
  },
  methods:{
    create_contact(){
        this.v$.$touch()
        if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const directory1 = new URLSearchParams()
        directory1.append('contact_name', this.contact_name)
        directory1.append('contact_number', this.contact_number)
        directory1.append('contact_email', this.contact_email)
        directory1.append('contact_job', this.contact_job)
        directory1.append('contact_address', this.contact_address)
        directory1.append('building_id',localStorage.building_id)
        this.$store.dispatch('directory/create_directory',directory1)
        }
    }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
