<template>
<!-- Add New Service Popup Model -->
<div id="add-new_building" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="top:10%;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">Add Building</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="card-body">
                    <form class="form" @submit.prevent="createBuilding">
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Building Name</label>
                                    <div class="col-12">
                                      <input type="text" class="form-control" v-model="buildingname" placeholder="Enter Building Name" :class="{ error: v$.buildingname.$error }">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Building Address</label>
                                    <div class="col-12">
                                        <input type="text" class="form-control" v-model="address" placeholder="Enter Building Address" :class="{ error: v$.address.$error }">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">CareTaker Name</label>
                                    <div class="col-12">
                                      <input type="text" class="form-control" v-model="username" placeholder="Enter CareTaker Name" :class="{ error: v$.username.$error }">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Phone</label>
                                    <div class="col-12">
                                      <div class="row" style="margin-left: 0px;">
                                        <div class="col-sm-3" style="padding: 0px; width: 10%">
                                        <select class="form-control" v-model="country_code" @change="get_code($event.target.selectedIndex)" >
                                          <option v-for="data in code" selectedIndex=0>({{data.code}}){{data.dial_code}}</option>
                                        </select>
                                      </div>
                                      <div class="col-sm-8" style="width: 100%">
                                        <input type="text" class="form-control" v-model="phone" placeholder="Enter Phone" :class="{ error: v$.phone.$error }">
                                    </div>
                                  </div>
                              </div>
                              </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Email</label>
                                    <div class="col-12">
                                        <input type="text" class="form-control" v-model="email" placeholder="Enter Email" :class="{ error: v$.email.$error }">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Trail Days</label>
                                    <div class="col-12">
                                      <input type="text" class="form-control" v-model="traildays" placeholder="Enter Trail Days" :class="{ error: v$.traildays.$error }">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label for="example-text-input" class="col-4 col-form-label" style="font-weight: 500;">Monthly subscription charge</label>
                                    <div class="col-12">
                                      <input type="text" class="form-control" v-model="subscriptioncharge" placeholder="Enter Monthly subscription charge" :class="{ error: v$.subscriptioncharge.$error }">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                        <button type="submit" class="btn btn-success mr-2">Submit</button>
                                        <button type="button" class="btn btn-danger mr-2" data-dismiss="modal" aria-hidden="true">Cancel</button>
                                    </div>
                                </div>
                            </div>

                        </form>
           </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required,numeric,minLength,maxLength } from '@vuelidate/validators'
import {mapState,mapActions} from 'vuex'
import json from '@/assets/json/CountryCode.json'
export default {
  name: 'CreateBuilding',
  setup () {
    return { v$: useVuelidate() }
  },
  components: {

  },
  data(){
    return{
      buildingname: '', // Matches this.firstName
      address: '',
      username: '',
      phone: '',
      email: '',
      traildays: '0',
      subscriptioncharge: '',
      myJson: json,
      code: {},
      country_code: '(IN)+91',
      ctry_code : '+91'
    }

  },

  created(){
    var booked_list = {}
    booked_list= JSON.parse(JSON.stringify(this.myJson['Country']), function(key, value) {
      return value;
    });
    this.code = booked_list
  },
  validations () {
    return {
      buildingname: { required }, // Matches this.firstName
      address: { required },
      username: { required },
      phone: { required, numeric, minLength: minLength(10), maxLength: maxLength(10) },
      email: { required },
      traildays: { required },
      subscriptioncharge: { required, numeric }
       // Matches this.lastName
    }
  },
  methods:{
    createBuilding(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        var number = this.ctry_code.slice(1) + this.phone
        const building = new URLSearchParams()
        building.append('building_name', this.buildingname)
        building.append('address_building', this.address)
        building.append('caretaker_name', this.username)
        building.append('phone', number)
        building.append('email', this.email)
        building.append('traildays', this.traildays)
        building.append('monthly_subscription_charge', this.subscriptioncharge)
        this.$store.dispatch('building/createBuilding',building)
      }
    },
    get_code(selectedIndex)
    {
      this.ctry_code = this.code[selectedIndex].dial_code

    }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
