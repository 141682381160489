<template>
  <!-- Add New Service Popup Model -->
  <div id="add-service-request" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div class="modal-dialog">
              <div class="modal-content">
                  <div class="modal-header">
                      <h4 class="modal-title" id="myModalLabel">Request for a Service</h4>
                      <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                  </div>
                  <div class="card-body" style="padding-top: 0px;">
                      <div class="card" style="margin-bottom: 0px;">
                          <ul class="nav nav-tabs customtab" role="tablist">
                              <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#userrequest" role="tab"><span class="hidden-sm-up"></span> <span class="hidden-xs-down">User Request</span></a> </li>
                              <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#grouprequest" role="tab"><span class="hidden-sm-up"></span><span class="hidden-xs-down">Group Request</span></a> </li>
                          </ul>
                          <!-- Tab panes -->
                          <div class="tab-content">
                              <div class="tab-pane active" id="userrequest" role="tabpanel">
                                  <div class="p-20">
                                    <form class="form" @submit.prevent="createAdminServiceRequestUser">
                                            <div class="col-sm-12">
                                                <div class="form-group row">
                                                    <label for="example-text-input" class="col-4 col-form-label" style="font-weight: 500;">Appartment</label>
                                                    <div class="col-12">
                                                        <select class="form-control custom-select" v-model="user.flat_id"  :class="{ error: v$.user.flat_id.$error }">
                                                            <option selected value="Select Appartment">Select Appartment</option>
                                                            <option v-for="flat in adminappartments" :key="flat.id" :value="flat.id">{{flat.number}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="form-group row">
                                                    <label for="example-text-input" class="col-4 col-form-label" style="font-weight: 500;">Service</label>
                                                    <div class="col-12">
                                                        <select class="form-control custom-select" v-model="user.service_name" @change="getAmount(user.service_name)" :class="{ error: v$.user.service_name.$error }">
                                                            <option>Select Service</option>
                                                            <option v-for="service in adminservices" :key="service.id">{{service.name}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="form-group row">
                                                    <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Service
                                                        Charge</label>
                                                    <input type="text" style="margin-left: 10px;width:96%;" disabled id="disabledTextInput"
                                                        class="form-control" v-model="user.service_charge" placeholder="Enter Service Charge" :class="{ error: v$.user.service_charge.$error }">

                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="form-group row">
                                                    <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Service
                                                        Date</label>
                                                    <div class="col-12">
                                                        <input class="form-control " @change.native="someEvent()" type="date" v-model="user.service_date" :class="{ error: v$.user.service_date.$error }" ref="serviceinputdate1">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="form-group row">
                                                    <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Service
                                                        Time</label>
                                                    <div class="col-12" >
                                                      <select class="form-control custom-select" v-model="user.service_time" :class="{ error: v$.user.service_time.$error }">
                                                          <option>Select Time</option>
                                                          <option v-if="time_phase_one == true" >Morning(8:00AM-11:00AM)</option>
                                                          <option v-if="time_phase_two == true" >Noon(11:00AM-2:00PM)</option>
                                                          <option v-if="time_phase_three == true" >Evening(2:00PM-5:00PM)</option>
                                                      </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="form-group row">
                                                    <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;" >Description</label>
                                                    <div class="col-12">
                                                        <textarea class="form-control" rows="5" placeholder="Enter Description" v-model="user.description" :class="{ error: v$.user.description.$error }"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                                        <button type="submit" class="btn btn-success mr-2">Submit</button>
                                                        <button type="button" class="btn btn-danger mr-2" data-dismiss="modal" aria-hidden="true">Cancel</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </form>
                                  </div>
                              </div>
                              <div class="tab-pane  p-20" id="grouprequest" role="tabpanel">
                                <form class="form" @submit.prevent="createAdminServiceRequestGroup">
                                        <div class="col-sm-12">
                                            <div class="form-group row">
                                                <label for="example-text-input" class="col-4 col-form-label" style="font-weight: 500;">Group</label>
                                                <div class="col-12">
                                                    <select class="form-control custom-select" v-model="group.group_id" @change="getAmountGroup(group.g_service_name,group.group_id)"  :class="{ error: v$.group.group_id.$error }">
                                                        <option selected value="Select Group">Select Group</option>
                                                        <option v-for="group in admingroups" :key="group.id" :value="group.id">{{group.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group row">
                                                <label for="example-text-input" class="col-4 col-form-label" style="font-weight: 500;">Service</label>
                                                <div class="col-12">
                                                    <select class="form-control custom-select" v-model="group.g_service_name" @change="getAmountGroup(group.g_service_name,group.group_id)" :class="{ error: v$.group.g_service_name.$error }">
                                                        <option>Select Service</option>
                                                        <option v-for="service in adminservices" :key="service.id">{{service.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group row">
                                                <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Service
                                                    Charge</label>
                                                <input type="text" style="margin-left: 10px;width:96%;" disabled id=""
                                                    class="form-control" v-model="group.g_service_charge" placeholder="Enter Service Charge" :class="{ error: v$.group.g_service_charge.$error }">

                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group row">
                                                <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Service
                                                    Date</label>
                                                <div class="col-12">
                                                    <input class="form-control "  @change.native="someEvent1()" type="date" v-model="group.g_service_date" :class="{ error: v$.group.g_service_date.$error }" ref="serviceinputdate2">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group row">
                                                <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Service
                                                    Time</label>
                                                <div class="col-12">
                                                  <select class="form-control custom-select" v-model="group.g_service_time" :class="{ error: v$.group.g_service_time.$error }">
                                                      <option>Select Time</option>
                                                      <option v-if="time_phase_one == true" >Morning(8:00AM-11:00AM)</option>
                                                      <option v-if="time_phase_two == true" >Noon(11:00AM-2:00PM)</option>
                                                      <option v-if="time_phase_three == true" >Evening(2:00PM-5:00PM)</option>
                                                  </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group row">
                                                <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;" >Description</label>
                                                <div class="col-12">
                                                    <textarea class="form-control" rows="5" placeholder="Enter Description" v-model="group.g_description" :class="{ error: v$.group.g_description.$error }"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                                    <button type="submit" class="btn btn-success mr-2">Submit</button>
                                                    <button type="button" class="btn btn-danger mr-2" data-dismiss="modal" aria-hidden="true">Cancel</button>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
      </div>
  <!-- ============================================================== -->
  <!-- End Bread crumb and right sidebar toggle -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Start Page Content -->
  <!-- ============================================================== -->
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {mapState,mapActions} from 'vuex'
export default {
  name: 'AdminCreateServiceRequest',
  components: {

  },
  setup () {
    return { v$: useVuelidate() }
  },
  data(){
    return{
      user:{
        flat_id: 'Select Appartment',
        service_name: 'Select Service',
        service_charge: '',
        service_date: '',
        service_time: 'Select Time',
        description: '',

      },
      time_phase_two: true,
      time_phase_one: true,
      time_phase_three: true,
      group:{
        group_id: 'Select Group',
        g_service_name: 'Select Service',
        g_service_charge: '',
        g_service_date: '',
        g_service_time: 'Select Time',
        g_description: ''
      }

    }

  },
  computed:{
    ...mapState('servicerequest',['adminservices']),
    ...mapState('servicerequest',['adminappartments']),
    ...mapState('servicerequest',['admingroups'])
  },
  mounted(){
    this.getAdminServices();
    this.getAdminAppartments();
    this.getAdminGroups();
    this.disable_date();
  },
  validations () {
    return {
      user:{
        flat_id: { required,
        isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === 'Select Appartment') return false
        else return true


         }  },
        service_name: { required ,
         isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === 'Select Service') return false
        else return true


         }  },
        service_charge: { required },
        service_date: { required },
        service_time: { required ,
         isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === 'Select Time') return false
        else return true


         }  },
        description: { required }
      },

      group:{
        group_id: { required ,
        isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === 'Select Group') return false
        else return true


         }},
        g_service_name: { required ,
           isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === 'Select Service') return false
        else return true


         }
        },
        g_service_charge: { required },
        g_service_date: { required },
        g_service_time: { required,
         isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === 'Select Time') return false
        else return true


         }  },
        g_description: { required }
      }

    }
  },
  methods:{
    ...mapActions('servicerequest',['getAdminServices']),
    ...mapActions('servicerequest',['getAdminAppartments']),
    ...mapActions('servicerequest',['getAdminGroups']),
    createAdminServiceRequestUser(){
      this.v$.user.$touch()
      if(!this.v$.user.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const servicerequest = new URLSearchParams()
        servicerequest.append('service_name', this.user.service_name)
        servicerequest.append('service_charge', this.user.service_charge)
        servicerequest.append('service_date', this.user.service_date)
        servicerequest.append('service_time', this.user.service_time)
        servicerequest.append('service_description', this.user.description)
        servicerequest.append('flat_id', this.user.flat_id)
        servicerequest.append('building_id', localStorage.building_id)
        this.$store.dispatch('servicerequest/createAdminServiceRequestUser',servicerequest)
      }
    },
    createAdminServiceRequestGroup(){
      this.v$.group.$touch()
      if(!this.v$.group.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const servicerequest = new URLSearchParams()
        servicerequest.append('service_name', this.group.g_service_name)
        servicerequest.append('service_charge', this.group.g_service_charge)
        servicerequest.append('service_date', this.group.g_service_date)
        servicerequest.append('service_time', this.group.g_service_time)
        servicerequest.append('service_description', this.group.g_description)
        servicerequest.append('group_id', this.group.group_id)
        servicerequest.append('building_id', localStorage.building_id)
        this.$store.dispatch('servicerequest/createAdminServiceRequestGroup',servicerequest)
      }
    },
    someEvent(){
      var currentDateWithFormat = new Date().toJSON().slice(0,10).replace(/-/g,'-');
      if(currentDateWithFormat == this.user.service_date){
         const today = new Date();
         const time = today.getHours()
         if(time > 8){
          this.time_phase_one = false
          this.time_phase_two = true
          this.time_phase_three = true

         }
         if (time > 11){
          this.time_phase_one = false
          this.time_phase_two = false
          this.time_phase_three = true

         }
          if(time > 14){
          this.time_phase_one = false
          this.time_phase_two = false
          this.time_phase_three = false

         }
      }
      else{
        this.time_phase_one = true
        this.time_phase_two = true
        this.time_phase_three = true
      }
    },
    someEvent1(){
      var currentDateWithFormat = new Date().toJSON().slice(0,10).replace(/-/g,'-');
      if(currentDateWithFormat == this.group.g_service_date){
         const today = new Date();
         const time = today.getHours()
         if(time > 8){
          this.time_phase_one = false
          this.time_phase_two = true
          this.time_phase_three = true

         }
         if (time > 11){
          this.time_phase_one = false
          this.time_phase_two = false
          this.time_phase_three = true

         }
          if(time > 14){
          this.time_phase_one = false
          this.time_phase_two = false
          this.time_phase_three = false

         }
      }
      else{
        this.time_phase_one = true
        this.time_phase_two = true
        this.time_phase_three = true
      }
    },
    getAmount(service_nme){
      if (service_nme != "Select Service"){
        var serv = this.adminservices.filter(s => s.name == service_nme)
        this.user.service_charge = serv[0].charge
      }
      else{
        this.user.service_charge = ''
      }
    },
    getAmountGroup(service_nme,group_id){
      if (service_nme != "Select Service" && group_id != "Select Group"){
        var serv = this.adminservices.filter(s => s.name == service_nme)
        var grp = this.admingroups.filter(s => s.id == group_id)
        this.group.g_service_charge = parseFloat(serv[0].charge) * parseFloat(grp[0].group_member_count)

      }
      else{
        this.group.g_service_charge = ''
      }
    },
    disable_date(){
      var date = new
      Date().toISOString().slice(0,10);
      $(this.$refs.serviceinputdate1).attr('min',date);
      $(this.$refs.serviceinputdate2).attr('min',date);
    }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
