<template>
<div id="edit-service" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    aria-hidden="true" style="top:3%;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">Edit Service</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12 ">
                        <div class="form-group">
                            <label>Service Id</label>
                            <input type="text" class="form-control" v-model="service_id" readonly>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 ">
                        <div class="form-group">
                            <label>Service Name</label>
                            <input type="text" class="form-control" placeholder="Enter Service Name" v-model="service_name" :class="{ error: v$.service_name.$error }" >
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 ">
                        <div class="form-group">
                            <label>Description</label>
                            <textarea class="form-control" v-model="description"  placeholder="Enter Description" :class="{ error: v$.description.$error }" rows="3"></textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Service Charge</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">&#x20b9;</span>

                                <input type="number" class="form-control" v-model="service_charge" placeholder="Enter Service Charge" autocomplete="off" :class="{ error: v$.service_charge.$error }">

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-info mr-2" @click="updateService">Update</button>
              <button type="button" class="btn btn-danger mr-2" data-dismiss="modal" aria-hidden="true">Cancel</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {mapState} from 'vuex'
export default {
  name: 'UpdateService',
  components: {

  },
  setup () {
    return { v$: useVuelidate() }
  },
  props:['service_id'],
  data(){
    return{

    }
  },
  computed:{
    service_name: {
      get () {
        return this.$store.state.service.service_name
      },
      set (value) {
        this.$store.commit('service/updateServiceName', value)
      }
    },
    description: {
      get () {
        return this.$store.state.service.description
      },
      set (value) {
        this.$store.commit('service/updateDescription', value)
      }
    },
    service_charge: {
      get () {
        return this.$store.state.service.service_charge
      },
      set (value) {
        this.$store.commit('service/updateServiceCharge', value)
      }
    }
  },
  created(){
    this.getService();
  },
  validations () {
    return {
      service_name: { required },
      description: { required },
      service_charge: { required }
    }
  },
  methods:{
    getService(){
      const service_id = new URLSearchParams()
      service_id.append('service_id', this.service_id)
      service_id.append('building_id', localStorage.building_id)
      this.$store.dispatch('service/getService',service_id)
    },

    updateService(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const service = new URLSearchParams()
        service.append('service_id', this.service_id)
        service.append('service_name', this.service_name)
        service.append('description', this.description)
        service.append('service_charge', this.service_charge)
        service.append('building_id', localStorage.building_id)
        this.$store.dispatch('service/updateService',service)
      }
    }
  },
  watch:{
    service_id(){
      this.getService();
    }
  }

}

</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
