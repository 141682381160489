<template>
  <html>
  <body class="skin-default card-no-border">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <section id="wrapper">
      <div class="login-register" style="background-image:url(assets/images/background/login-register.jpg);">
        <div class="login-box card">
          <div class="card-body">
            <form  @submit.prevent="submitOtp">
            <!--  <div class="alert alert-info signout" style="text-align: center;" role="alert">
                <span style="font-weight:400;">Successfully Signed Out!</span>
              </div> -->
              <img id="croppingImage" class="img-circle" src="@/assets/images/login_logo.png" style="width: 100%">
              <h3 class="text-center m-b-20">Enter OTP</h3>
              <div class="form-group ">
                <div class="col-xs-12">
                  <input class="form-control" type="hidden" required="" placeholder="" v-model="phone_no">
                  <input class="form-control" type="number" placeholder="Enter OTP" v-model="otp" :class="{ error: v$.otp.$error }" ref="inp"> </div>
                </div>
                <div class="col-md-12" style="padding: 15px;" v-if="error_tab">
                    <span style="font-size:13px;color:red;">{{errormessage}}</span>
                </div>
                <div class="form-group row" style="text-align: center;font-size: 21px;">
                  <div class="col-md-12" >
                    <vue-countdown class="timerClass" v-if="counting " :time="180000" @end="onCountdownEnd" v-slot="{ minutes, seconds }"> {{ minutes }} : {{ seconds }} </vue-countdown>
                    <a v-if="otplink " @click="resendOtp()" style="color: #fb9678">Resend OTP</a>
                  </div>
                </div>
                <div class="form-group text-center">
                  <div class="col-xs-12 p-b-20" >
                    <button class="btn btn-block btn-lg btn-info btn-rounded" type="submit">Next >></button>
                  </div>
                </div>

              </form>
              </div>
            </div>
          </div>
        </section>
      </body>

      </html>
    </template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required,numeric,minLength,maxLength } from '@vuelidate/validators'
import {mapState} from 'vuex'
import VueCountdown from '@chenfengyuan/vue-countdown';
export default {
  name: 'RequestOtp',
  components: {
    VueCountdown
  },
  setup () {
    return { v$: useVuelidate() }
  },
  computed:{
    ...mapState('login',['errormessage']),
    error_status: {
      get () {
        return this.$store.state.login.error_status
      },
      set (value) {
        this.$store.commit('login/updateErrorStatus', value)
      }
    },
    counting: {
      get () {
        return this.$store.state.login.counting
      },
      set (value) {
        this.$store.commit('login/updateCounting', value)
      }
    }
  },
  data() {
    return{
      otp: '',
      phone_no: this.$route.params.phone_no,
      otplink:true,
      error_tab: false
    }
  },
  validations () {
    return {
      otp: { numeric, minLength: minLength(6), maxLength: maxLength(6),required}

    }
  },
  mounted(){
    this.startCountdown();
  },
  methods:{
    submitOtp(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        const mobile_otp = new URLSearchParams()
        mobile_otp.append('phone_no', this.phone_no)
        mobile_otp.append('otp', this.otp)
        this.$store.dispatch('login/submitOtp',mobile_otp)
      }
    },
    startCountdown() {
      if (this.counting == true){
        this.otplink = false;
      }
    },
    onCountdownEnd() {
      this.counting = false;
      this.error_status = false;
      this.otplink = true;
    },
    resendOtp(){
      const mobile = new URLSearchParams()
      mobile.append('phone_no', this.phone_no)
      this.$store.dispatch('login/resendOtp',mobile)
      this.error_tab = false;
      this.otplink = false;
      this.counting = true;
    }
  },
  watch: {
    error_status(){
      if (this.error_status == true){
        this.onCountdownEnd();
        this.error_tab = true;
        this.otp = ""
        this.v$.$reset()
      }
    }
  },

}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
.timerClass{
  color: #fb9678;
  font-weight: bold;
}

</style>
