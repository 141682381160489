<template>
	<div id="verticalcenter" class="modal" tabindex="-1" role="dialog" aria-labelledby="vcenter"
	                         aria-hidden="true">
	                         <div class="modal-dialog modal-dialog-centered">
	                             <div class="modal-content">
	                                 <div class="modal-header">
	                                     <h4 class="modal-title" id="vcenter">Delete</h4>
	                                     <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
	                                 </div>
	                                 <div class="card-body" style="padding: 10px 50px;">
                                        <div class="row" style="justify-content: center;">
	                                     <h4 style="margin-left: 40px"><strong>Are you sure?</strong> Do you want to delete this flat details?</h4>
	                                 </div>
	                                 <hr>
	                                 <div class="col-sm-12">
                                        <div class="form-group">

	                                     
	                                     <button type="submit" class="btn btn-success mr-2 " data-dismiss="modal" aria-hidden="true" @click="deleteflat" >OK</button>
	                                     <button type="button" class="btn btn-danger waves-effect"  data-dismiss="modal" style="border-color: white;">Cancel</button>
	                                   </div>
	                                   </div>

	                                 </div>
	                             </div>
	                             <!-- /.modal-content -->
	                         </div>
	                         <!-- /.modal-dialog -->
	                     </div>

</template>

<script>
// @ is an alias to /src

export default {
  name: 'DeleteFlat',
  components: {

  },
  props: ['flat_id'],
  methods:{
    deleteflat(){
			this.$store.commit('spinner/showLoading', true)
      const apartment_id = new URLSearchParams()
      apartment_id.append('id', this.flat_id)
      this.$store.dispatch('adminflatgroup/delete_appartment',apartment_id)
    }
  }
}
</script>
