<template>
<!-- Add New Service Popup Model -->
<div id="approve-parking" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="top:10%;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">Approve Parking</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="card-body">
                   <form class="form" @submit.prevent="approveparking">
                                               <div class="col-sm-12">
                                                    <div class="form-group">
                                                        <label class="col-md-12">Appartment Name</label>
                                                        <div class="col-md-12 input-container profile-edit">
                                                            <input type="text" :placeholder="[[ appartment_name ]]"  class="form-control form-control-line" disabled>
                                                        </div>
                                                    </div>
                                                     <div class="col-sm-12">
                                                         <div class="form-group row">
                                                           <div class="col-12">
                                                             <label>Parking</label>
                                                              <input type="text" class="form-control custom-select" autocomplete="off"  v-model="ref_text" v-on:keydown="find" placeholder="Select Parking" :class="{ error: v$.ref_text.$error }">
                                                              <select  class="form-control custom-select" v-model="flat_no" @change="getselectedvalue($event.target.selectedIndex)" >
                                                               <option >Select Reference</option>
                                                               <option v-for="item in parking_list" :value="item" :key ="item.id">{{item['name']}}</option>
                                                               </select>
                                                             </div>
                                                         </div>
                                                     </div>
                                                     <div class="col-sm-12">
                                                         <div class="form-group">
                                                             <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                                                 <button type="submit" class="btn btn-success mr-2">Submit</button>
                                                                 <button type="button" class="btn btn-danger mr-2" data-dismiss="modal" aria-hidden="true">Cancel</button>
                                                             </div>
                                                         </div>
                                                     </div>

                                               </div>

                                           </form>
           </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import VueTimepicker from 'vue3-timepicker'
import {mapState} from 'vuex'
import { required } from '@vuelidate/validators'
export default {
  name: 'ApproveParking',
  components: {
    VueTimepicker

  },
  computed:{
  ...mapState('adminflatgroup',['parking_list'])
  },
  props: ['request_id' , 'appartment_name' ,'flat_id'],
  
  setup () {
    return { v$: useVuelidate() }
  },
    validations () {
      return {
     
        ref_text: { required },
      }
    },
  created(){
    this.$store.dispatch('adminflatgroup/get_non_assigned_parking')
  },
  data(){
     return{
       ref_text: '',
       flat_no: 'Select Reference',
       parkings: [],
       parking_id: []

     }

   },
  methods:{
     getselectedvalue(selectedIndex)
    {

      var selectedIdea = this.parking_list[selectedIndex-1];
      this.parkings.push(selectedIdea.name)
      this.parking_id.push(selectedIdea.id)
      this.ref_text = this.parkings
      this.flat_no = 'Select Reference'
      this.parking_list.splice(selectedIndex-1, 1)

    },
     
    approveparking(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const parking = new URLSearchParams()
        parking.append('flat_id', this.flat_id)
        parking.append('request_id', this.request_id)
        this.parking_id.forEach((item) => {
           parking.append('parking_ids[]', item);
        });
        this.$store.dispatch('parking/approve_parking',parking)

     }

    }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
