<template>
  <body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <SuperAdminHeader/>
      <SuperAdminSidebar/>
      <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
          <!-- ============================================================== -->
          <!-- Bread crumb and right sidebar toggle -->
          <!-- ============================================================== -->
          <div class="row page-titles">
            <div class="col-md-5 align-self-center">
              <h4 class="text-themecolor">Report Issues</h4>
            </div>

          </div>

          <!-- ============================================================== -->
          <!-- End Bread crumb and right sidebar toggle -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Info box -->
          <!-- ============================================================== -->
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row m-t-40">
                  </div>
                  <div v-if="report_list.length <= 0">
                    <div style ="padding-left:15px;padding-right:15px; width:100%">
                                      <div class="alert alert-dark">
                                          <span>No data to show</span>
                                      </div>
                    </div>
                  </div>
                  <div v-else>
                  <div class="table-responsive">
                    <table id="myTable" class="table table-striped"  ref="tables">
                      <thead>
                        <tr>
                          <th >id</th>
                          <th>Building Name</th>
                          <th>Username</th>
                          <th>Topic </th>
                          <th>Description</th>
                          <th>Created At</th>
                          <th>Image</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in report_list" :key="item.building_id">
                          <td>{{item.id}}</td>
                          <td> {{item.building_name}}</td>
                          <td> {{item.user_name}}</td>
                          <td>{{item.topic}}</td>
                          <td>{{item.description}}</td>
                          <td>{{format_date(item.created_at)}}</td>
                          <td v-if="item.image.length > 0" style="color: blue" @click="showImage(item.image)" data-toggle="modal" data-target="#edit-profile-pic"><u>View Image</u></td>
                          <td v-else ><u>No Attachment</u></td>
                        </tr>
                    </tbody>
                    </table>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="imagemodal == true">
            <ShowImage :image_url = 'issueimage'></ShowImage>
          </div>
          <!-- ============================================================== -->
          <!-- End Info box -->
          <!-- ============================================================== -->

          <!-- ============================================================== -->
          <!-- Review -->
          <!-- ============================================================== -->

          <!-- ============================================================== -->
          <!-- End Review -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Comment - chats -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- End Comment - chats -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- End Page Content -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Right sidebar -->
          <!-- ============================================================== -->
          <!-- .right-sidebar -->

          <!-- ============================================================== -->
          <!-- End Right sidebar -->
          <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
      </div>
      <!-- ============================================================== -->
      <!-- End Page wrapper  -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- footer -->
      <!-- ============================================================== -->
      <footer class="footer">
        © 2020 www.ontash.net
      </footer>
      <!-- ============================================================== -->
      <!-- End footer -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Wrapper -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- All Jquery -->
    <!-- ============================================================== -->
  </body>
</template>

<script>
// @ is an alias to /src
import SuperAdminHeader from '@/components/headers/SuperAdminHeader.vue'
import SuperAdminSidebar from '@/components/sidebars/SuperAdminSidebar.vue'
import ShowImage from '@/components/reportissue/ShowImage.vue'
import {mapState,mapActions,mapMutations} from 'vuex'
import moment from 'moment'
export default {
  name: 'SuperAdminReportIssues',
  components: {
    SuperAdminHeader,
    SuperAdminSidebar,
    ShowImage
  },
  data(){
    return{
      imagemodal: false,
      issueimage: ''
    }

  },
  computed:{
    ...mapState('reportissue',['report_list'])
  },
  mounted(){

    this.get_report_list();


  },
  methods:{
    get_report_list(){
        this.$store.dispatch('reportissue/get_report_list')
    },

    format_date(value){
         if (value) {
            var date = moment(value).format('DD-MM-YYYY')
            return date
          }
    },
    showImage(value){
         this.imagemodal = true
         this.issueimage = value
    }
  },
  watch: {
    report_list() {
      $(this.$refs.tables).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.tables).DataTable({
           destroy: true,
          "ordering":false
        }).draw();
      });
    }
  }
}
</script>
<style>

</style>
