<template>
	<body class="skin-default fixed-layout">
	<!--     <div class="black">
	        <div class="black-row">
	            <div class="spinner-grow spin" role="status">
	            </div>
	            <div class="spinner-grow spin2" role="status">
	            </div>
	            <div class="spinner-grow spin" role="status">
	            </div>
	        </div>
	    </div>
	   -->
	<!-- ============================================================== -->
	<!-- Main wrapper - style you can find in pages.scss -->
	<!-- ============================================================== -->
	<div id="main-wrapper">
		  <UserHeader/>
	        <UserSidebar/>
	    <!-- ============================================================== -->
	    <!-- Topbar header - style you can find in pages.scss -->
	    <!-- ============================================================== -->
	    <!-- ============================================================== -->
	    <!-- End Topbar header -->
	    <!-- ============================================================== -->
	    <!-- ============================================================== -->
	    <!-- Left Sidebar - style you can find in sidebar.scss  -->
	    <!-- ============================================================== -->

	    <!-- ============================================================== -->
	    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
	    <!-- ============================================================== -->
	    <!-- ============================================================== -->
	    <!-- Page wrapper  -->
	    <!-- ============================================================== -->
	    <div class="page-wrapper">
	        <!-- ============================================================== -->
	        <!-- Container fluid  -->
	        <!-- ============================================================== -->
	        <div class="container-fluid">
	            <!-- ============================================================== -->
	            <!-- Bread crumb and right sidebar toggle -->
	            <!-- ============================================================== -->
	            <div class="row page-titles">
	                <div class="col-md-5 align-self-center">
	                    <h4 class="text-themecolor">Profile</h4>
	                </div>
	                <div class="alert alert-success signout_name banner-text" role="alert">
	                    <span><strong>Success!</strong> Name updated succesfully</span>
	                </div>
	                 <div class="alert alert-success signout_email banner-text" role="alert">
	                    <span><strong>Success!</strong> Email updated succesfully</span>
	                </div>
	                 <div class="alert alert-success signout_phone banner-text" role="alert">
	                    <span><strong>Success!</strong>Phone number updated succesfully</span>
	                </div>
	                 <div class="alert alert-success signout_privacy banner-text" role="alert">
	                    <span><strong>Success!</strong>Privacy updated succesfully</span>
	                </div>
	                 <div class="alert alert-success signout_parking banner-text" role="alert">
	                    <span><strong>Success!</strong>New Parking requested succesfully</span>
	                </div><div class="alert alert-success signout_parking banner-text" role="alert">
	                    <span><strong>Success!</strong>New Parking requested succesfully</span>
	                </div>
	                <div class="alert alert-success signout_delreq banner-text" role="alert">
	                    <span><strong>Success!</strong>Dlete Parking request send succesfully</span>
	                </div>
									<div class="alert alert-warning wrong_otp banner-text" role="alert">
										 <span><strong>Warning!</strong> OTP you entered is wrong. Please try again</span>
								 </div>

	                <div class="col-md-7 align-self-center text-right">
	                    <div class="d-flex justify-content-end align-items-center">
	                        <ol class="breadcrumb">
	                            <li class="breadcrumb-item"><router-link :to="{ name: 'UserHome' }">Home</router-link></li>
	                            <li class="breadcrumb-item active">Profile</li>
	                        </ol>
	                    </div>
	                </div>
	            </div>
	            <!-- ============================================================== -->
	            <!-- End Bread crumb and right sidebar toggle -->
	            <!-- ============================================================== -->
	            <!-- ============================================================== -->
	            <!-- Start Page Content -->
	            <!-- ============================================================== -->
	            <!-- Row -->


	            <div class="row">
	                <!-- Column -->
	                <div class="col-lg-4 col-xlg-3 col-md-5">
	                    <div class="card">
	                        <div class="card-body">
	                            <center class="m-t-30">
	                                <div class="image-container">
	                                    <div class="book">

	                                        <div class="imagehere">
	                                            <div class="containers">

	                                                <div class="overlay">

	                                                        <img id="croppingImage" class="img-circle" :src="profile_picture" width="150" height="150" data-toggle="modal" data-target="#edit-profile-pic" @click="viewImageUpload">

	                                                </div>
	                                            </div>
	                                        </div>
	                                    </div>

	                                </div>
	                                <h4 class="card-title m-t-10">{{name}}</h4>

	                            </center>
	                        </div>
	                        <div>
	                            <hr>
	                        </div>

	                        <div class="card-body">
                                <small class="text-muted">Caretaker Name:</small>
                                <h6>{{caretaker_name}}</h6>
                                <small class="text-muted">Email</small>
                                <h6>{{caretaker_email}}</h6>
                                <small class="text-muted p-t-30 db">Phone</small>
                                <h6>{{caretaker_phone}}</h6>
                            </div>
	                    </div>
	                </div>
	                <!-- Column -->
	                <!-- Column -->
	                <div class="col-lg-8 col-xlg-9 col-md-7">
	                    <div class="card">
	                        <!-- Nav tabs -->
	                        <ul class="nav nav-tabs profile-tab" role="tablist">
	                        	 <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#settings"
	                                    role="tab">My Settings</a> </li>
	                            <li class="nav-item"> <a class="nav-link " data-toggle="tab" href="#home" role="tab">Your
	                                    Activities</a> </li>
	                        </ul>
	                        <!-- Tab panes -->
	                        <div class="tab-content">
	                            <div class="tab-pane" id="home" role="tabpanel">
	                                <div class="card-body">
	                                    <div class="card">
	                                        <div class="card-body">
	                                            <h4 class="card-title">Your Activities</h4>
	                                            <h6 class="card-subtitle"></h6>
	                                            <div class="table-responsive m-t-40" style="margin-top:0px;">
	                                                <table id="myTable" class="table table-bordered table-striped" ref="tables">
	                                                    <thead>
	                                                        <tr>
	                                                            <th>Activity</th>
	                                                            <th>Date</th>
	                                                        </tr>
	                                                    </thead>
	                                                    <tbody>
	                                                       <tr v-for="item in activities " :key ="item.id">
	                                                       	<td>{{ item.activity_log }}</td>
	                                                       	<td>{{ format_date(item.created_at) }}</td>
	                                                       </tr>
	                                                    </tbody>
	                                                </table>
	                                            </div>
	                                        </div>
	                                    </div>
	                                </div>
	                            </div>
	                            <!--second tab-->
	                            <div class="tab-pane" id="profile" role="tabpanel">
	                                <div class="card-body">
	                                    <div class="row">
	                                        Your payment and pending history
	                                    </div>
	                                </div>
	                            </div>
	                            <div class="tab-pane active" id="settings" role="tabpanel">
	                                <div class="card-body">
	                                    <form class="form-horizontal form-material">
	                                        <div class="form-group">
	                                            <label class="col-md-12">Full Name</label>
	                                            <div class="col-md-12 input-container profile-edit" >
	                                                <input type="text"  v-model="name" :placeholder="[[ name ]]" class="form-control form-control-line" disabled>
	                                                <span style="margin-left:3px;color:green" data-toggle="modal"
	                                                    data-target="#edit-fullname" @click="update_name">
	                                                    <i class="fa fas fa-edit" data-toggle="tooltip" data-placement="bottom"
	                                                        title="Change Full Name!"></i></span>
	                                            </div>
	                                        </div>
	                                        <div class="form-group">
	                                            <label for="example-email" class="col-md-12">Email</label>
	                                            <div class="col-md-12 input-container profile-edit">
	                                                <input type="email" v-model="useremail" :placeholder="[[ useremail ]]" class="form-control form-control-line" disabled>
	                                                <span style="margin-left:3px;color:green" data-toggle="modal"
	                                                    data-target="#edit-email" @click="update_email" >
	                                                    <i class="fa fas fa-edit" data-toggle="tooltip" data-placement="bottom"
	                                                        title="Change Email!"></i></span>
	                                            </div>
	                                        </div>
	                                        <div class="form-group">
	                                            <label class="col-md-12">Phone No</label>
	                                            <div class="col-md-12 input-container profile-edit">
	                                                <input type="text" v-model="phone" :placeholder="[[ phone ]]" class="form-control form-control-line" disabled>
	                                                <span style="margin-left:3px;color:green" data-toggle="modal"
	                                                    data-target="#edit-ph" @click="update_phone">
	                                                    <i class="fa fas fa-edit" data-toggle="tooltip" data-placement="bottom"
	                                                        title="Change Phone No!"></i></span>
	                                            </div>
	                                        </div>
	                                        <div class="form-group">
	                                            <label class="col-md-12">My Parkings</label>
	                                            <div class="col-md-12 input-container profile-edit">
	                                                <input type="text" v-model="parking" :placeholder="[[ parking_name ]]"  class="form-control form-control-line" disabled>
	                                                <span style="margin-left:3px;color:blue" data-toggle="modal"
	                                                    data-target="#add-parking" @click="add_parking">
	                                                    <i class="fa fa-plus" data-toggle="tooltip" data-placement="bottom"
	                                                        title="Change Phone No!"></i></span>

	                                                 <span style="margin-left:10px;color:red" data-toggle="modal"
	                                                    data-target="#delete-parking" @click="delete_parking">
	                                                    <i class="fa fa-minus" data-toggle="tooltip" data-placement="bottom"
	                                                        title="Change Phone No!"></i></span>

	                                            </div>
	                                        </div>
	                                        <div class="form-group" style="display:flex;">
	                                            <label class="col-sm-2">Profile Status</label>
	                                            <div class="col-sm-8" style="display:flex;">
	                                                <div class="custom-control custom-radio">
	                                                    <input type="radio" id="customRadio1" value="public" v-model="privacy_status" name="profile-status" data-toggle="modal"
	                                                    data-target="#confirm-privacy"
	                                                        class="custom-control-input" :checked="privacy == true">
	                                                    <label class="custom-control-label" for="customRadio1" style="margin-right:10px;color:green;">Public</label>
	                                                </div>
	                                                <div class="custom-control custom-radio">
	                                                    <input type="radio" id="customRadio2" value="private" v-model="privacy_status" name="profile-status"
	                                                        data-toggle="modal" data-target="#confirm-privacy" class="custom-control-input" :checked="privacy == false">
	                                                    <label class="custom-control-label" for="customRadio2" style="color:tomato;">Private</label>
	                                                </div>
	                                            </div>
	                                        </div>
	                                    </form>
	                                </div>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	                <!-- Column -->
	            </div>

	            <!-- Row -->
	            <!-- ============================================================== -->
	            <!-- End PAge Content -->
	            <!-- ============================================================== -->
	            <!-- ============================================================== -->
	            <!-- Right sidebar -->
	            <!-- ============================================================== -->
	            <!-- .right-sidebar -->

	            <!-- ============================================================== -->
	            <!-- End Right sidebar -->
	            <!-- ============================================================== -->
	        </div>
	        <!-- ============================================================== -->
	        <!-- End Container fluid  -->
	        <!-- ============================================================== -->
	    </div>

	    <!-- Edti full name -->
	    <div v-if="updateusername === true">
              <UpdateName :name_='name' ></UpdateName>
            </div>
         <div v-if="updateuseremail === true">
              <UpdateEmail :email_='useremail' :phone_="phone" ></UpdateEmail>
          </div>
          <div v-if="addparking === true">
              <AddParking ></AddParking>
          </div>
          <div v-if="deleteparking === true">
              <DeleteParking :parkings='parkings' ></DeleteParking>
          </div>

	    <!--Edit email  -->
	    <!-- Edit phone number -->
	    <div v-if="updateuserphone === true">
              <UpdatePhone :phone_='phone' ></UpdatePhone>
            </div>
			<div v-if="imagemodal == true">
		    <ProfilePicture :image_url = 'profile_picture'></ProfilePicture>
		  </div>
	    <!-- Change password -->
	    <div id="edit-password" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
	        aria-hidden="true">
	        <div class="modal-dialog">
	            <div class="modal-content">
	                <div class="modal-header">
	                    <h4 class="modal-title" id="myModalLabel">Change Password</h4>
	                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
	                </div>
	                <div class="card-body" style="padding: 25px 50px !important;">
	                    <form class="form">
	                        <div class="col-sm-12">
	                            <div class="form-group row">
	                                <div class="col-md-12">
	                                    <input type="text" placeholder="Current Password" class="form-control form-control-line"
	                                        required>
	                                </div>
	                            </div>
	                        </div>
	                        <div class="col-sm-12">
	                            <div class="form-group row">
	                                <div class="col-md-12">
	                                    <input type="text" placeholder="New Password" class="form-control form-control-line"
	                                        required>
	                                </div>
	                            </div>
	                        </div>
	                        <div class="col-sm-12">
	                            <div class="form-group row">
	                                <div class="col-md-12">
	                                    <input type="text" id="confirm-password" placeholder="Confirm Password" class="form-control form-control-line"
	                                        required>
	                                </div>
	                            </div>
	                        </div>
	                        <div class="col-sm-12 otp-password" style="display:none;">
	                            <div class="form-group row">
	                                <div class="col-md-12">
	                                    <input type="text" placeholder="OTP" class="form-control form-control-line"
	                                        required>
	                                </div>
	                                <div class="col-md-12" style="padding: 15px;">
	                                    <span style="font-size:13px;color:red;">An OTP has been sent to your new Phone
	                                        number. Please enter here!</span>
	                                </div>
	                            </div>
	                        </div>

	                        <div class="col-sm-12">
	                            <div class="form-group">
	                                <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
	                                    <button type="submit" class="btn btn-success mr-2">Update</button>
	                                    <button type="button" class="btn btn-danger mr-2" data-dismiss="modal"
	                                        aria-hidden="true">Cancel</button>
	                                </div>
	                            </div>
	                        </div>

	                    </form>
	                </div>
	            </div>
	            <!-- /.modal-content -->
	        </div>
	        <!-- /.modal-dialog -->
	    </div>
	    <div id="edit-address" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
	        aria-hidden="true">
	        <div class="modal-dialog">
	            <div class="modal-content">
	                <div class="modal-header">
	                    <h4 class="modal-title" id="myModalLabel">Change Address</h4>
	                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
	                </div>
	                <div class="card-body" style="padding: 25px 50px !important;">
	                    <form class="form">
	                        <label class="col-md-12">Address</label>
	                        <div class="col-md-12">
	                            <textarea rows="5" class="form-control form-control-line" required></textarea>
	                        </div>
	                        <div class="col-sm-12">
	                            <div class="form-group">
	                                <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
	                                    <button type="submit" class="btn btn-success mr-2">Update</button>
	                                    <button type="button" class="btn btn-danger mr-2" data-dismiss="modal"
	                                        aria-hidden="true">Cancel</button>
	                                </div>
	                            </div>
	                        </div>

	                    </form>
	                </div>
	            </div>
	            <!-- /.modal-content -->
	        </div>
	        <!-- /.modal-dialog -->
	    </div>

	    <!-- Confirmation -->
	    <div id="confirm-privacy" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
	        aria-hidden="true" style="top:23%;">
	        <div class="modal-dialog">
	            <div class="modal-content">
	                <div class="modal-header">
	                    <h4 class="modal-title" style="margin-left: 5%;opacity: 60%;" id="myModalLabel">Confirm your
	                        changes</h4>
	                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
	                </div>
	                <div class="card-body" style="padding: 10px 50px;">
	                    <div class="row" style="padding: 20px; justify-content: center;">
	                        <h4>Are you sure want to change privacy settings to {{privacy_status}}?</h4>

	                    </div>
	                    <hr>
	                    <div class="col-sm-12">
	                        <div class="form-group">
	                            <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
	                                <button type="submit" class="btn btn-success mr-2 confirm-submit" data-dismiss="modal"
	                                    aria-hidden="true" @click="changePrivacyStatus">Yes</button>
	                                <button type="button" class="btn btn-danger mr-2" data-dismiss="modal" aria-hidden="true">Cancel</button>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	                <hr>
	            </div>
	            <!-- /.modal-content -->
	        </div>
	        <!-- /.modal-dialog -->
	    </div>
	    <!-- ============================================================== -->
	    <!-- End Page wrapper  -->
	    <!-- ============================================================== -->
	    <!-- ============================================================== -->
	    <!-- footer -->
	    <!-- ============================================================== -->

	    <footer class="footer">
	        © 2020 www.ontash.net
	    </footer>
	    <!-- ============================================================== -->
	    <!-- End footer -->
	    <!-- ============================================================== -->
	</div>
</body>
</template>
<script>

	// @ is an alias to /src
import UserSidebar from '@/components/sidebars/UserSidebar.vue'
import UserHeader from '@/components/headers/UserHeader.vue'
import UpdateName from '@/components/profile/UpdateName.vue'
import UpdateEmail from '@/components/profile/UpdateEmail.vue'
import UpdatePhone from '@/components/profile/UpdatePhone.vue'
import AddParking from '@/components/profile/AddParking.vue'
import DeleteParking from '@/components/profile/DeleteParking.vue'
import ProfilePicture from '@/components/sidebars/ProfilePicture.vue'
import {mapState} from 'vuex'
import moment from 'moment'
export default {
  name: 'UserProfile',
  components: {UserSidebar, UserHeader, UpdateName,UpdateEmail,UpdatePhone,ProfilePicture,AddParking,DeleteParking},
  computed:{

      ...mapState('profile',['useremail']),
      ...mapState('profile',['phone']),
      ...mapState('profile',['name']),
      ...mapState('profile',['profile']),
      ...mapState('profile',['caretaker_name']),
      ...mapState('profile',['caretaker_email']),
      ...mapState('profile',['caretaker_phone']),
      ...mapState('profile',['activities']),
      ...mapState('profile',['privacy']),
	  ...mapState('profile',['profile_picture']),
	  ...mapState('profile',['parking_name']),
	  ...mapState('profile',['parkings'])
	},
  data(){
    return{
      updateusername: false,
      updateuseremail: false,
      updateuserphone: false,
      addparking: false,
      deleteparking: false,
      name_: '',
      email_: '',
      parking: '',
      status_id: '',
      privacy_status: '',
	    imagemodal: false
    }
  },
  mounted(){
    this.showalerts();
		this.$store.dispatch('profile/show_profile')
  },
  methods: {
  	update_name(){
        this.updateusername = true
        this.name_ = this.name
  	},
  	update_email(){

        this.updateuseremail = true
  	},
  	add_parking(){
      this.addparking = true
  	},
  	delete_parking(){
      this.deleteparking = true
  	},
  	update_phone(){

        this.updateuserphone = true
  	},
		viewImageUpload(){
			console.log("Hii")
      this.imagemodal = true;
    },
		changePrivacyStatus(){
			const pri_status = new URLSearchParams()
			pri_status.append('privacy', this.privacy_status)
			pri_status.append('user_id',localStorage["user_id"])
			this.$store.dispatch('profile/changePrivacyStatus',pri_status)
		},
    format_date(value){
       if (value) {
         return moment(String(value)).format('DD-MM-YYYY')
        }
    },
    showalerts(){
      if (localStorage.status == "email_updated")
      {
        $('.signout_email').delay(500).slideDown(500);
        $('.signout_email').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "phone_updated")
      {
        $('.signout_phone').delay(500).slideDown(500);
        $('.signout_phone').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "name_updated")
      {
        $('.signout_name').delay(500).slideDown(500);
        $('.signout_name').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "privacy_updated")
      {
        $('.signout_privacy').delay(500).slideDown(500);
        $('.signout_privacy').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
			else if (localStorage.status == "wrong_otp")
			{
        $('.wrong_otp').delay(500).slideDown(500);
        $('.wrong_otp').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "requested")
      {
        $('.signout_parking').delay(500).slideDown(500);
        $('.signout_parking').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "requested_delete")
      {
        $('.signout_delreq').delay(500).slideDown(500);
        $('.signout_delreq').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }

    },
  },
  watch: {
          activities() {
            $(this.$refs.tables).DataTable().destroy();
            this.$nextTick(() => {
              $(this.$refs.tables).DataTable({
                 destroy: true,
                "ordering":false
              });
            });
          }
          }
}
</script>
