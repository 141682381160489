<template>
<div id="change-status" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
   aria-hidden="true" style="top:23%;">
   <div class="modal-dialog">
       <div class="modal-content">
           <div class="modal-header">
               <h4 class="modal-title" style="margin-left: 5%;opacity: 60%;" id="myModalLabel">Confirm
                   your
                   changes</h4>
               <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
           </div>
           <div class="card-body" style="padding: 10px 50px;">
               <div class="row" style="justify-content: left;">
                   <h4 style="margin-left: 40px">Are you sure want to change status to {{status}}?</h4>
                   <div v-if="status == 'rejected' " style="width:100%;padding:10px">
                      <textarea class="form-control" placeholder="Enter Reason" rows="5" v-model="reason" :class="{ error: v$.reason.$error }">Reason</textarea>
                   </div>
               </div>
               <hr>
               <div class="col-sm-12">
                   <div class="form-group">
                       <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                           <button type="submit" class="btn btn-success mr-2"
                                aria-hidden="true" @click="updateFacilityBookingStatus">OK</button>
                           <button type="button" class="btn btn-danger mr-2" data-dismiss="modal"
                               aria-hidden="true">Cancel</button>
                       </div>
                   </div>
               </div>
           </div>
           <hr>
       </div>

       <!-- /.modal-content -->
   </div>
   <!-- /.modal-dialog -->
</div>

</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'UpdateStatusFacility',
  components: {

  },
  setup () {
    return { v$: useVuelidate() }
  },
  props: ['booking_id','status'],
  data(){
     return{
       reason: '',
     }
   },
   created: function(){
    this.setReason();
  },

  validations () {
    return {
      reason: { required }
    }
  },
  methods:{
    updateFacilityBookingStatus(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const booking = new URLSearchParams()
        booking.append('booking_id', this.booking_id)
        booking.append('status', this.status)
        booking.append('reason', this.reason)
        booking.append('building_id', localStorage.building_id)
        this.$store.dispatch('facility/updateFacilityBookingStatus',booking)
      }
    },
    setReason(){
      if (this.status == 'accepted'){
        this.reason = "blank text"
      }
      else{
        this.reason == ''
      }
    }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
