import axios from 'axios'
import baseurl from './axioscredentials'
axios.defaults.withCredentials = true
import router from '@/router'
const building = {
  namespaced: true,
  state: {
    active_building_caretakers: [],
    inactive_building_caretakers: [],
    active_count: '',
    inactive_count: '',
    buildingname: '',
    address: '',
    username: '',
    phone: '',
    email: '',
    traildays: '',
    subscriptioncharge: '',
    datastatus: false,
    appartment_details: [],
    subscription_reports: [],
    activity_summary: []

  },
  getters: {

  },
  mutations: {
    SET_BUILDING_CARETAKERS(state,data){
      state.active_building_caretakers = data.active_building_caretakers
      state.inactive_building_caretakers = data.inactive_building_caretakers
      state.active_count = data.active_count
      state.inactive_count = data.inactive_count
    },
    SET_BUILDING_CARETAKER(state,data){
      state.buildingname = data.building_caretaker[0].building_name,
      state.address =  data.building_caretaker[0].address,
      state.username =  data.building_caretaker[0].username,
      state.phone =  (data.building_caretaker[0].phone).slice(2),
      state.email = data.building_caretaker[0].email,
      state.traildays = data.building_caretaker[0].trial_months,
      state.subscriptioncharge = data.building_caretaker[0].subscription_charge

    },

    SET_ACTIVITY_SUMMARY(state,data){
      state.activity_summary = data
    },
    SET_TRAIL_PAID_SUMMARY(state,data){
      if(data == null){
        state.activity_summary = []
      }
      else{
        state.activity_summary = data
      }
    },
    SET_CHECKBOX(state,data){
      if(data.building_caretaker[0].trail_status == '1'){
        state.checkbox = true
      }
      else{
        state.checkbox = false
      }

    },
    SET_appartment_details(state,data){
      state.appartment_details = data.appartment_details,
      state.building_name = data.building_name
    },
    updateBuildingName(state,payload){
      state.buildingname = payload
    },
    updateAddress(state,payload){
      state.address = payload
    },
    updateUsername(state,payload){
      state.username = payload
    },
    updatePhone(state,payload){
      state.phone = payload
    },
    updateEmail(state,payload){
      state.email = payload
    },
    updateTrialdays(state,payload){
      state.traildays = payload
    },
    updateSubscriptioncharge(state,payload){
      state.subscriptioncharge = payload
    },
    updateCheckbox(state,payload){
      state.checkbox = payload
    },
    updateSubscriptionReport(state,payload){
      state.subscription_reports = payload
    }
  },
  actions: {
    getBuildingCaretakers({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_building_caretakers',{},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_BUILDING_CARETAKERS',res.data)
        }).catch(error => console.log(error))
    },
    getbuildingCaretaker({ commit, state},building_id){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_building_caretaker',building_id,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_BUILDING_CARETAKER',res.data)
          commit('SET_CHECKBOX',res.data)
        }).catch(error => console.log(error))
    },
    get_appartment_details({ commit, state},building_id){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_appartment_lists',building_id,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_appartment_details',res.data)
        }).catch(error => console.log(error))
    },
    createBuilding({ commit, state},building){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/create_building_caretaker',building,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          if(res.data.message == "User Already Exists"){
            router.go({ name: 'SuperAdminHome'})
            localStorage.status = "already_exist"
          }
          else{
            router.go({ name: 'SuperAdminHome'})
            localStorage.status = "created"
          }

        }).catch(error => console.log(error))
    },
    getActivityReportDetails({ commit, state},gen_report){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_activity_summary',gen_report,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_ACTIVITY_SUMMARY',res.data)
        }).catch(error => console.log(error))
    },
    generatePdfActivityReport({ commit, state},pdf_report){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/generate_activity_summary_pdf',pdf_report,{headers:{'Authorization':localStorage.token},responseType: 'blob'}).then(
        res => {
          var fileURL = window.URL.createObjectURL(new Blob([res.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'ActivitySummary.pdf');
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch(error => console.log(error))
    },
    getTrailPaidSubscriptionDetails({ commit, state},get_report){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_activity_trail_paid_subscription',get_report,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_TRAIL_PAID_SUMMARY',res.data)
        }).catch(error => console.log(error))
    },
    generatePdfTrailPaidSubscriptionReport({ commit, state},pdf_report){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/generate_activity_trail_paid_subscription_pdf',pdf_report,{headers:{'Authorization':localStorage.token},responseType: 'blob'}).then(
        res => {
          var fileURL = window.URL.createObjectURL(new Blob([res.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'TrailPaidSummary.pdf');
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch(error => console.log(error))
    },
    updateBuilding({ commit, state},building){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/update_building_caretaker',building,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: 'SuperAdminHome'})
          localStorage.status = "updated"
        }).catch(error => console.log(error))
    },

    deactivateBuilding({ commit, state},building){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/deactivate_building_caretaker',building,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: 'SuperAdminHome'})
          localStorage.status = "deleted"
        }).catch(error => console.log(error))
    },
    activateBuilding({ commit, state},building){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/active_building_caretaker',building,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: 'SuperAdminHome'})
          localStorage.status = "activated"
        }).catch(error => console.log(error))
    }
  }
}
export default building
