<template>
<div id="confirm-deleted" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    aria-hidden="true" style="top:23%;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" style="margin-left: 5%;opacity: 60%;" id="myModalLabel">Confirm Your Action</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="card-body" style="padding: 10px 50px;">
                <div class="row" style="justify-content: center;">
                    <h4 style="margin-left: 40px"><strong>Are you sure?</strong> Do you want to delete this service request details?</h4>

                </div>
                <hr>
                <div class="col-sm-12">
                    <div class="form-group">
                        <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                          <button type="submit" class="btn btn-success mr-2 " data-dismiss="modal"
                          aria-hidden="true" @click="deleteServiceRequest" >OK</button>
                          <button type="button" class="btn btn-danger mr-2" data-dismiss="modal" aria-hidden="true">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>

</template>

<script>
// @ is an alias to /src

export default {
  name: 'DeleteServiceRequest',
  components: {

  },
  props: ['servicerequest_id'],
  methods:{
    deleteServiceRequest(){
      this.$store.commit('spinner/showLoading', true)
      const servicerequest = new URLSearchParams()
      servicerequest.append('service_request_id', this.servicerequest_id)
      servicerequest.append('flat_id', localStorage.flat_id)
      this.$store.dispatch('servicerequest/deleteServiceRequest',servicerequest)
    }
  }
}
</script>
