import axios from 'axios'
import baseurl from './axioscredentials'
axios.defaults.withCredentials = true
import router from '@/router'
const messages = {
  namespaced: true,
  state: {
    message:''

  },
  getters: {

  },
  mutations: {
    set_messages(state,data){
      state.message = data.messages
      state.groups = data.groups
      state.users_count = data.users_count
    }

  },
  actions: {
    get_messages({ commit, state}){
      axios.post(baseurl+'/api/v1/get_message_broadcasts',{building_id: localStorage.building_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('set_messages',res.data)
        }).catch(error => console.log(error))
    },


    create_new_message({ commit, state},new_message){
      axios.post(baseurl+'/api/v1/create_message_broadcasts',new_message,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: 'AdminMessage'})
          localStorage.status = "created"
        }).catch(error => console.log(error))
    },
  }
}
export default messages
