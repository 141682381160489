<template>
  <body class="skin-default fixed-layout">
    <div id="main-wrapper">
     <UserHeader/>
      <UserSidebar/>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Upcoming Events</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><router-link :to="{ name: 'UserHome' }">Home</router-link></li>
                                <li class="breadcrumb-item active">Events</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row notification-list">
                    <div class="col-12">
                      <div v-if="events.length > 0">
                        <div v-for="item in events" >
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-6 lefttab">
                                        <div class="row centered eventtitle">
                                            {{item.event_name}}
                                        </div>
                                        <div class="row centered">
                                            <span class="eventlabel">{{format_date(item.event_started_at)}}</span>
                                        </div>
                                        <div class="row centered">
                                            <span>Organizer:</span><span class="eventlabel">{{item.event_organizer}}</span>
                                        </div>
                                        <div class="row centered">
                                            <span>Location:</span><span class="eventlabel">{{item.event_venue}}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 eventdesc">
                                        {{item.event_description}}

                                    </div>

                                </div>
                            </div>
                        </div>
                        </div>
                      </div>
                      <div v-else>
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-6 lefttab">
                                        <div class="row centered eventtitle">
                                          No Events To Show
                                        </div>
                                        <div class="row centered">
                                            <span class="eventlabel"></span>
                                        </div>
                                        <div class="row centered">
                                            <span></span><span class="eventlabel"></span>
                                        </div>
                                        <div class="row centered">
                                            <span></span><span class="eventlabel"></span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 eventdesc">

                                    </div>

                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="footer">
      © 2020 www.ontash.net
    </footer>
</body>
</template>
<script>
// @ is an alias to /src
import UserHeader from '@/components/headers/UserHeader.vue'
import UserSidebar from '@/components/sidebars/UserSidebar.vue'
import {mapState} from 'vuex'
import moment from 'moment'
export default {
  name: 'UserHome',
  components: {
    UserHeader,
    UserSidebar
  },
  computed:{
    ...mapState('event',['events'])


  },
  created: function(){
      const notify = new URLSearchParams()
      notify.append('page', 'Events')
      notify.append('flat_id',localStorage.flat_id)
      this.$store.dispatch('notification/change_notification_status_user_task_id',notify)
      this.$store.dispatch('event/list_events')
  },
  methods: {

        format_date(value){
           if (value) {
             return moment(String(value)).format('DD-MM-YYYY h:mm a')
            }
        },
  }

}
</script>
 <style>

</style>
