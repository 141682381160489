<template>
  <body class="skin-default fixed-layout">
    <div id="main-wrapper">
     <UserHeader/>
      <UserSidebar/>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Phone Directory</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link :to="{ name: 'UserHome' }">Home</router-link></li>
                                <li class="breadcrumb-item active">Phone Directory</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row">
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="card-body">
                                              <div v-if="directory.length <= 0">
              		                              <div style ="padding-left:15px;padding-right:15px; width:100%">
              		                                                <div class="alert alert-dark">
              		                                                    <span>No data to show</span>
              		                                                </div>
              		                              </div>
              		                            </div>
              		                            <div v-else>
                                                <div class="table-responsive">
                                                  <table id="myTable" class="table table-striped" ref="tables">
                                                    <thead>
                                                      <tr>
                                                        <th>Contact Name</th>
                                                        <th>Contact Number</th>
                                                        <th>Email</th>
                                                        <th>Job Title</th>
                                                        <th>Address</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr v-for="item in directory" :key ="item.id">
                                                       <td>{{ item.name }}</td>
                                                       <td>{{ item.phone }}</td>
                                                       <td>{{ item.email }}</td>
                                                       <td>{{ item.job_title }}</td>
                                                       <td>{{ item.address }}</td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

            </div>
        </div>
    </div>
    <footer class="footer">
      © 2020 www.ontash.net
    </footer>
</body>
</template>
<script>
// @ is an alias to /src
import UserHeader from '@/components/headers/UserHeader.vue'
import UserSidebar from '@/components/sidebars/UserSidebar.vue'
import {mapState} from 'vuex'
export default {
  name: 'UserHome',
  components: {
    UserHeader,
    UserSidebar
  },
  computed:{
    ...mapState('directory',['directory'])


  },
  created: function(){
      this.$store.dispatch('directory/list_directory')

  },
  watch: {
    directory() {
      $(this.$refs.tables).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.tables).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    }
    }

}
</script>
  <style>
    .banner-text{
    font-weight:400;
    position:absolute;
    width: 98%;
    margin-top: -5px;
    z-index: 10;
    text-align: center;
    display: none;
}

</style>
