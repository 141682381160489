<template>
  <body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
    	 <AdminHeader/>
          <AdminSidebar/>
        <!-- ============================================================== -->
        <!-- Topbar header - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper">
            <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="container-fluid">
                <!-- ============================================================== -->
                <!-- Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">My Payments</h4>
                    </div>
                    <div class="alert alert-success signout_create banner-text" role="alert">
                        <span><strong>Success!</strong> You have successfully made a payment</span>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link :to="{ name: 'AdminHome' }">Home</router-link></li>
                                <li class="breadcrumb-item active">Admin Payments
                                </li>
                            </ol>
                            <button type="button" class="btn btn-info d-none d-lg-block m-l-15" @click="make_payment" data-toggle="modal"
                                data-target="#make_payment">
                                + Make Payment</button>
                        </div>
                    </div>
                </div>


                <div v-if="makepayment === true">
                  <CreateAdminPayment :service='service' :complaint='complaint'/>
                </div>
                  <div v-if="viewpayment === true">
                  <UpdatePaymentDetails :payment_id ='payment_id'/>
                </div>
                 <div v-if="showpayment === true">
                  <ShowPaymentDetails :payment_id ='payment_id'/>
                </div>



                <!-- ============================================================== -->
                <!-- End Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Info box -->
                <!-- ============================================================== -->
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">

                                <div class="row m-t-40">
                                    <div class="col-md-6 col-lg-3 col-xlg-3">
                                        <div class="card">
                                            <div class="box label-danger text-center">
                                                <h1 class="font-light text-white">&#x20b9;{{total_debited}}</h1>
                                                <h6 class="text-white">Total Debited Amount</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- /.modal-dialog -->
                            </div>
                                <div class="table-responsive">
                                     <table id="myTable" class="table  table-striped" ref="tables">
                                        <thead>
                                            <tr>
                                                <th>Payment ID</th>
                                                <th>Date</th>
                                                <th>Amount</th>
                                                <th >Paid To</th>
                                                <th >Contact No</th>
                                                <th>Status</th>
                                                <th>View</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                             <tr v-for="item in payment_records " :key ="item.id">
                                                <td><span class="viewmore" data-toggle="modal"
                                                data-target="#view_details"  @click = "update_details(item.id)">{{item.id}}</span></td>
                                                <td><span class="text-muted">{{format_date(item.payment_date)}}</span></td>
                                                <td ><span>{{item.paid_amount}}</span></td>
                                                <td>{{item.payee_name}}</td>
                                                <td>{{item.payee_contact_no}}</td>
                                                <td><div  v-if="item.status === true">
                                                       <div class="label label-table label-success">Settled</div></div>
                                                    <div v-else-if="item.status === false">
                                                       <div class="label label-table label-danger">Incomplete</div>
                                                    </div>

                                                </td>
                                                <td class="viewmoretd"><span class="viewmore" @click = "show_details(item.id)" data-toggle="modal"
                                                        data-target="#view_complete_details">View Details</span></td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ============================================================== -->
                <!-- End Info box -->
                <!-- ============================================================== -->

                <!-- ============================================================== -->
                <!-- Review -->
                <!-- ============================================================== -->

                <!-- ============================================================== -->
                <!-- End Review -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Comment - chats -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- End Comment - chats -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- End Page Content -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Right sidebar -->
                <!-- ============================================================== -->
                <!-- .right-sidebar -->

                <!-- ============================================================== -->
                <!-- End Right sidebar -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer">
            © 2020 www.ontash.net
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->

</body>
</template>

<script>
// @ is an alias to /src
import AdminHeader from '@/components/headers/AdminHeader.vue'
import AdminSidebar from '@/components/sidebars/AdminSidebar.vue'
import CreateAdminPayment from '@/components/payment/CreateAdminPayment.vue'
import UpdatePaymentDetails from '@/components/payment/UpdatePaymentDetails.vue'
import ShowPaymentDetails from '@/components/payment/ShowPaymentDetails.vue'
import {mapState} from 'vuex'
import moment from 'moment'
export default {
  name: 'AdminMyPayment',
  components: {
    AdminHeader,
    AdminSidebar,
    CreateAdminPayment,
    UpdatePaymentDetails,
    ShowPaymentDetails
  },
   data(){
       return{
        makepayment: false,
        viewpayment: false,
        showpayment: false,
        service: '',
        complaint: '',
        payment_id: ''

       }

   },
   computed:{
     ...mapState('payment',['total_debited','payment_records','complaints','serv_req'])

   },
   created(){

        this.$store.dispatch('payment/mypayment')
      },
  mounted(){
       this.showalerts();
      },
   methods: {
   	make_payment()
   	{
         this.service = this.serv_req
        this.complaint = this.complaints
       this.makepayment = true
   	},
   	update_details(id){
        this.payment_id = id
   		this.viewpayment = true
   	},
   	show_details(id) {
        this.payment_id = id
   		this.showpayment = true

   	},
    format_date(value){
       if (value) {
         return moment(String(value)).format('DD-MM-YYYY')
        }
    },
    showalerts(){
      if (localStorage.status == "Done")
      {
        $('.signout_create').delay(500).slideDown(500);
        $('.signout_create').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
    }

   },
   watch: {
     payment_records() {
      $(this.$refs.tables).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.tables).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    }
  }

}
</script>
