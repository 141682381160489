<template>
  <div id="edit-service-request" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myModalLabel">Modify Your Service Request </h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true" @click="closeModal()">×</button>
        </div>
        <div class="card-body">
          <form class="form" @submit.prevent="updateServiceRequest">
            <div class="col-sm-12">
              <div class="form-group row">
                <label for="example-text-input" class="col-4 col-form-label" style="font-weight: 500;">Service</label>
                <div class="col-12">
                  <select class="form-control custom-select" :disabled="buttonactive == false" v-model="service_name" @change="getAmount(service_name)" :class="{ error: v$.service_name.$error }">
                    <option v-for="service in services" :key="service.id">{{service.name}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group row">
                <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Service
                  Charge</label>
                  <input type="text" style="margin-left: 10px;width:96%;" disabled
                  class="form-control" placeholder="Enter Service Charge" v-model="service_charge" :class="{ error: v$.service_charge.$error }">

                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group row">
                  <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Service
                    Date</label>
                    <div class="col-12">
                      <input class="form-control edit-date" type="date" v-model="service_date" :disabled="buttonactive == false" :class="{ error: v$.service_date.$error }" ref="serviceinputdate">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group row">
                    <label for="example-text-input" class="col-4 col-form-label" style="font-weight: 500;">Service Time</label>
                    <div class="col-12">
                      <select class="form-control custom-select" v-model="service_time" :disabled="buttonactive == false" :class="{ error: v$.service_time.$error }">
                        <option >Morning(8:00AM-11:00AM)</option>
                        <option >Noon(11:00AM-2:00PM)</option>
                        <option >Evening(2:00PM-5:00PM)</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group row">
                    <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Description</label>
                    <div class="col-12">
                      <textarea class="form-control edit-description" placeholder="Enter Description" v-model="description" rows="5" :disabled="buttonactive == false" :class="{ error: v$.description.$error }"></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                      <button type="button" class="btn btn-info mr-2 edit-complaint" v-if="servicerequest_status == 1" @click="activeEditButton">Click Here To Edit</button>
                      <button type="button" class="btn btn-danger mr-2" v-if="servicerequest_status == 1" data-dismiss="modal" aria-hidden="true" @click="closeModal()">Cancel</button>
                      <button type="submit" class="btn btn-success mr-2 " v-if="buttonactive == true">Update</button>
                      <button type="button" class="btn btn-danger mr-2" data-toggle="modal" data-target="#confirm-deleted" data-dismiss="modal" v-if="buttonactive == true" @click="activeDelete">Delete</button>
                    </div>
                  </div>
                </div>

              </form>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>

      <div v-if="deleteactive == true">
        <DeleteServiceRequest :servicerequest_id="servicerequest_id"></DeleteServiceRequest>
      </div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {mapState,mapActions} from 'vuex'
import DeleteServiceRequest from '@/components/service_requests/DeleteServiceRequest.vue'
export default {
  name: 'UpdateServiceRequest',
  components: {
    DeleteServiceRequest
  },
  setup () {
    return { v$: useVuelidate() }
  },
  props:['servicerequest_id','status_id'],
  data(){
    return{
      servicerequest_status: this.status_id,
      buttonactive: false,
      deleteactive: false
    }
  },
  computed:{
    service_name: {
      get () {
        return this.$store.state.servicerequest.service_name
      },
      set (value) {
        this.$store.commit('servicerequest/UpdateServiceName', value)
      }
    },
    service_charge: {
      get () {
        return this.$store.state.servicerequest.service_charge
      },
      set (value) {
        this.$store.commit('servicerequest/UpdateServiceCharge', value)
      }
    },
    service_date: {
      get () {
        return this.$store.state.servicerequest.service_date
      },
      set (value) {
        this.$store.commit('servicerequest/UpdateServiceDate', value)
      }
    },
    service_time: {
      get () {
        return this.$store.state.servicerequest.service_time
      },
      set (value) {
        this.$store.commit('servicerequest/UpdateServiceTime', value)
      }
    },
    description: {
      get () {
        return this.$store.state.servicerequest.description
      },
      set (value) {
        this.$store.commit('servicerequest/updateDescription', value)
      }
    },
    ...mapState('servicerequest',['services']),

  },
  validations () {
    return {
      service_name: { required },
      service_charge: { required },
      service_date: { required },
      service_time: { required },
      description: { required }
    }
  },
  created(){
    this.getServiceRequest();
    this.getServices();
    this.disable_date();

  },
  methods:{
    ...mapActions('servicerequest',['getServices']),
    getServiceRequest(){
      const servicerequest_id = new URLSearchParams()
      servicerequest_id.append('servicerequest_id', this.servicerequest_id)
      servicerequest_id.append('flat_id', localStorage.flat_id)
      this.$store.dispatch('servicerequest/getServiceRequest',servicerequest_id)
    },
    getAmount(service_nme){
        var serv = this.services.filter(s => s.name == service_nme)
        this.service_charge = serv[0].charge
    },
    activeEditButton(){
      this.servicerequest_status = 0
      this.buttonactive = true
      this.disable_date();
    },
    updateServiceRequest(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const servicerequest = new URLSearchParams()
        servicerequest.append('service_request_id', this.servicerequest_id)
        servicerequest.append('service_name', this.service_name)
        servicerequest.append('service_charge', this.service_charge)
        servicerequest.append('service_date', this.service_date)
        servicerequest.append('service_time', this.service_time)
        servicerequest.append('service_description', this.description)
        servicerequest.append('flat_id', localStorage.flat_id)
        this.$store.dispatch('servicerequest/UpdateServiceRequest',servicerequest)
      }
    },
    activeDelete(){
      this.servicerequest_status = 0
      this.deleteactive = true
    },
    closeModal(){
      this.buttonactive = false
      this.servicerequest_status = this.status_id
    },
    disable_date(){
      var date = new
      Date().toISOString().slice(0,10);
      $(this.$refs.serviceinputdate).attr('min',date);
    }
  },
  watch:{
    servicerequest_id(){
      this.getServiceRequest();
    },
    status_id(){
      this.servicerequest_status = this.status_id
    }
  }

}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
