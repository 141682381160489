<template>
  <html>
  <body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <UserHeader/>
      <UserSidebar/>
      <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
          <!-- ============================================================== -->
          <!-- Bread crumb and right sidebar toggle -->
          <!-- ============================================================== -->
          <div class="row page-titles">
            <div class="col-md-5 align-self-center">
              <h4 class="text-themecolor">Service Requests</h4>
            </div>
            <div class="alert alert-success signout_create banner-text" role="alert">
                <span><strong>Success!</strong> You have successfully created a new service request</span>
            </div>
            <div class="alert alert-success signout_update banner-text" role="alert">
                <span><strong>Success!</strong> You have successfully updated the service request details</span>
            </div>
            <div class="alert alert-danger signout_delete banner-text" role="alert">
                <span><strong>Deleted!</strong> You have successfully deleted the service request</span>
            </div>
            <div class="alert alert-warning flat_approval banner-text" role="alert">
                <span><strong>Warning!</strong> Your account is not activated</span>
            </div>
            <div class="col-md-7 align-self-center text-right">
              <div class="d-flex justify-content-end align-items-center">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><router-link :to="{ name: 'UserHome' }">Home</router-link></li>
                  <li class="breadcrumb-item active">Service Request</li>
                </ol>
                <button type="button" class="btn btn-info d-lg-block m-l-15" data-toggle="modal" data-target="#add-service-request" @click="loadCreateForm">
                  + Create
                </button>

              </div>
            </div>
          </div>
          <div v-if="createform === true">
            <CreateServiceRequest></CreateServiceRequest>
          </div>

            <!-- ============================================================== -->
            <!-- End Bread crumb and right sidebar toggle -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- Start Page Content -->
            <!-- ============================================================== -->
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <!-- Column -->
                      <div class="col-md-6 col-lg-3 col-xlg-3" @click="sortByStatus(1)">
                        <div class="card">
                          <div class="box label-info text-center">
                            <h1 class="font-light text-white">{{new_service_count}}</h1>
                            <h6 class="text-white">New</h6>
                          </div>
                        </div>
                      </div>
                      <!-- Column -->
                      <div class="col-md-6 col-lg-3 col-xlg-3" @click="sortByStatus(2)">
                        <div class="card">
                          <div class="box label-success text-center" >
                            <h1 class="font-light text-white">{{pending_service_count}}</h1>
                            <h6 class="text-white">Pending</h6>
                          </div>
                        </div>
                      </div>
                      <!-- Column -->
                      <div class="col-md-6 col-lg-3 col-xlg-3" @click="sortByStatus(3)">
                        <div class="card">
                          <div class="box label-danger text-center">
                            <h1 class="font-light text-white">{{complete_service_count}}</h1>
                            <h6 class="text-white">Complete</h6>
                          </div>
                        </div>
                      </div>
                      <!-- Column -->
                      <div class="col-md-6 col-lg-3 col-xlg-3" @click="sortByStatus(4)">
                        <div class="card">
                          <div class="box label-warning text-center">
                            <h1 class="font-light text-white">{{closed_service_count}}</h1>
                            <h6 class="text-white">Closed</h6>
                          </div>
                        </div>
                      </div>
                      <!-- Column -->
                    </div>
                    <div v-if="servicerequests.length <= 0">
                      <div style ="padding-left:15px;padding-right:15px; width:100%">
                                        <div class="alert alert-dark">
                                            <span>No data to show</span>
                                        </div>
                      </div>
                    </div>
                    <div v-else>
                    <div class="table-responsive">
                      <table id = "myTable" class="table table-striped" ref="tables">
                        <thead>
                          <tr>
                            <th>Invoice</th>
                            <th>Service</th>
                            <th>Service Date</th>
                            <th>Service Time</th>
                            <th>Description</th>
                            <th class="text-center">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="servicerequest in servicerequests" :key="servicerequest.id">
                            <td @click="loadUpdateForm(servicerequest.service_id,servicerequest.status_id)" data-toggle="modal" data-target="#edit-service-request">
                              <a  class="link"> SR#{{servicerequest.service_id}}</a>
                            </td>
                            <td>{{servicerequest.service_name}}</td>
                            <td><span class="text-muted">{{format_date(servicerequest.service_date)}}</span></td>
                            <td>{{servicerequest.service_time}}</td>
                            <td>{{servicerequest.description}}</td>
                            <td class="text-center" v-if="servicerequest.status_id == '1' ">
                              <div class="label label-table label-info">New</div>
                            </td>
                            <td class="text-center" v-else-if="servicerequest.status_id == '2' ">
                              <div class="label label-table label-success">Pending</div>
                            </td>
                            <td class="text-center" v-else-if="servicerequest.status_id == '3' ">
                              <div class="label label-table label-danger">Complete</div>
                            </td>
                            <td class="text-center" v-else="servicerequest.status_id == '4' ">
                              <div class="label label-table label-warning">Closed</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="updateform === true">
              <UpdateServiceRequest :servicerequest_id='servicerequest_id' :status_id='status_id'/>
            </div>
            <!-- ============================================================== -->
            <!-- End PAge Content -->
            <!-- ============================================================== -->
          </div>
          <!-- ============================================================== -->
          <!-- End Container fluid  -->
          <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer">
          © 2020 www.ontash.net {{status}}
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
      </div>
      <!-- ============================================================== -->
      <!-- End Wrapper -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
    </body>

    </html>
  </template>

<script>
// @ is an alias to /src
import UserHeader from '@/components/headers/UserHeader.vue'
import UserSidebar from '@/components/sidebars/UserSidebar.vue'
import CreateServiceRequest from '@/components/service_requests/CreateServiceRequest.vue'
import UpdateServiceRequest from '@/components/service_requests/UpdateServiceRequest.vue'
import moment from 'moment'
import {mapState,mapActions,mapMutations} from 'vuex'
export default {
  name: 'UserServiceRequests',
  components: {
    UserHeader,
    UserSidebar,
    CreateServiceRequest,
    UpdateServiceRequest
  },
  computed:{
    ...mapState('servicerequest',['new_service_count','pending_service_count',
                'complete_service_count','closed_service_count','servicerequests','status']),
    ...mapState('flat',['appartmentstatus'])
  },
  data(){
    return{
      createform: false,
      updateform: false,
      servicerequest_id: '',
      status_id: ''
    }

  },
  mounted(){
    this.getServiceRequests();
    this.showalerts();
    this.getAppartmentUserStatus();
  },
  methods:{
    ...mapActions('servicerequest',['getServiceRequests']),
    ...mapActions('flat',['getAppartmentUserStatus']),
    ...mapMutations('servicerequest',['sortByStatus']),
    loadCreateForm(){
      if (this.appartmentstatus == "active"){
        this.createform = true
      }
      else{
        $('.flat_approval').delay(500).slideDown(500);
        $('.flat_approval').delay(4000).slideUp(500);
      }
    },
    format_date(value){
       if (value) {
         return moment(String(value)).format('DD-MM-YYYY')
        }
    },
    loadUpdateForm(servicerequest_id,status_id){
      const notify = new URLSearchParams()
      notify.append('task_id', servicerequest_id)
      notify.append('page', 'ServiceRequest')
      notify.append('flat_id',localStorage.flat_id)
      this.$store.dispatch('notification/change_notification_status_user_task_id',notify)
      this.updateform = true
      this.servicerequest_id = servicerequest_id
      this.status_id = status_id
    },
    showalerts(){
      if (localStorage.status == "created")
      {
        $('.signout_create').delay(500).slideDown(500);
        $('.signout_create').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "updated")
      {
        $('.signout_update').delay(500).slideDown(500);
        $('.signout_update').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "deleted")
      {
        $('.signout_delete').delay(500).slideDown(500);
        $('.signout_delete').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
    }
  },
  watch: {
    servicerequests() {
      $(this.$refs.tables).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.tables).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    }
  }
}
</script>
<style scoped>
.banner-text{
  font-weight:400;
  position:absolute;
  width: 98%;
  margin-top: -5px;
  z-index: 10;
  text-align: center;
  display: none;
}
</style>
