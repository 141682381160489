<template>
<body class="skin-default fixed-layout">

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper">
  <AdminHeader/>
  <AdminSidebar/>
  <div class="page-wrapper">
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <div class="row page-titles">
        <div class="col-md-5 align-self-center">
          <h4 class="text-themecolor">Reports</h4>
        </div>
        <div class="col-md-7 align-self-center text-right">
          <div class="d-flex justify-content-end align-items-center">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link :to="{ name: 'AdminHome' }">Home</router-link></li>
              <li class="breadcrumb-item active">Reports</li>
            </ol>
            <!-- <button type="button" class="btn btn-info d-none d-lg-block m-l-15"><i class="fa fa-plus-circle"></i>
            Create New</button> -->
          </div>
        </div>
      </div>
      <!-- ============================================================== -->
      <!-- End Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- Info box -->
      <!-- ============================================================== -->
      <div class="row" >
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row" style="margin-top: 25px !important;">
                <div class="col-sm-3">

                  <div class="col-md-12 ">
                    <div class="form-group">
                      <div class="col-12">
                        <h6>Category</h6>
                        <select class="form-control custom-select" v-model="report_id" @change="resetTable()">
                          <option value="1" selected="selected">All Transaction</option>
                          <option value="2">Admin Payments</option>
                          <option value="3">User Paid Payments</option>
                          <option value="4">User Pending Payments</option>
                        </select>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="col-md-12 ">
                    <div class="form-group">
                      <div class="col-12">
                        <h6>From Date</h6>
                        <input class="form-control " type="date" v-model="start_date" :class="{ error: v$.start_date.$error }" ref="reportinputdate1">
                      </div>

                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="col-md-12 ">
                    <div class="form-group">
                      <div class="col-12">
                        <h6>To Date</h6>
                        <input class="form-control " type="date" v-model="end_date" :class="{ error: v$.end_date.$error }" ref="reportinputdate2">
                      </div>

                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="col-md-12 ">
                    <div class="form-group">
                      <div class="col-12">
                        <button type="button" class="btn btn-info d-lg-block m-l-15" style="margin-left: 0px;margin-top:25px;" @click="getReportDetails">
                          Generate Report</button>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <hr>

                <div class="row" v-if="reportdetails.length">
                  <div class="table-responsive" id="table_areas">

                    <table id="myTable" class="table  table-striped" ref="tables1" v-if="tab_one == true">
                        <thead>
                          <tr>
                            <th>Transaction Id</th>
                            <th>Transaction Type</th>
                            <th>Client Name</th>
                            <th>Description</th>
                            <th>Date</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr v-for="report in reportdetails" :key="report.payment_ref">
                                <td>{{report.payment_ref}}</td>
                                <td>{{report.payment_trans}}</td>
                                <td>{{report.clientname}}</td>
                                <td>{{report.payment_note}}</td>
                                <td>{{format_date(report.payment_date)}}</td>
                                <td>{{report.payment_amount}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table id="myTable" class="table  table-striped" ref="tables2" v-else-if="tab_two == true">
                      <thead>
                        <tr>
                            <th>Transaction Id</th>
                            <th>Client Name</th>
                            <th>Description</th>
                            <th>Date</th>
                            <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="report in reportdetails" :key="report.id">
                            <td>Payment{{report.id}}</td>
                            <td>{{report.payee_name}}</td>
                            <td>{{report.description}}</td>
                            <td>{{format_date(report.created_at)}}</td>
                            <td>{{report.paid_amount}}</td>
                        </tr>
                      </tbody>
                    </table>
                    <table id="myTable" class="table  table-striped" ref="tables3" v-else-if="tab_three == true">
                      <thead>
                        <tr>
                            <th>Transaction Id</th>
                            <th>Client Name</th>
                            <th>Appartment Name</th>
                            <th>Description</th>
                            <th>Date</th>
                            <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="report in reportdetails" :key="report.id">
                            <td>Payment{{report.id}}</td>
                            <td>{{report.name}}</td>
                            <th>{{report.appartment_number}}</th>
                            <td>{{report.topic}}</td>
                            <td>{{format_date(report.created_at)}}</td>
                            <td>{{report.amount}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-md-12 ">
                      <div class="form-group">
                        <div class="col-12">
                          <button type="button" class="btn btn-info d-lg-block m-l-15" @click="generatePdfReport">
                            <i class="fa fa-download" aria-hidden="true"></i> Download Report</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else style =" width:100%" >
                      <div class="alert alert-dark ">
                          <span>No data to show</span>
                      </div>
                  </div>
                </div>
              </div>
            </div>


            <!-- /.modal-dialog -->
          </div>
          <!-- ============================================================== -->
          <!-- End Info box -->
          <!-- ============================================================== -->

          <!-- ============================================================== -->
          <!-- Review -->
          <!-- ============================================================== -->

          <!-- ============================================================== -->
          <!-- End Review -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Comment - chats -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- End Comment - chats -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- End Page Content -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Right sidebar -->
          <!-- ============================================================== -->
          <!-- .right-sidebar -->

          <!-- ============================================================== -->
          <!-- End Right sidebar -->
          <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
      </div>
      <!-- ============================================================== -->
      <!-- End Page wrapper  -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- footer -->
      <!-- ============================================================== -->
      <footer class="footer">
        © 2020 www.ontash.net
      </footer>
      <!-- ============================================================== -->
      <!-- End footer -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Wrapper -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- All Jquery -->
    <!-- ============================================================== -->

  </body>
</template>

<script>
// @ is an alias to /src
import AdminHeader from '@/components/headers/AdminHeader.vue'
import AdminSidebar from '@/components/sidebars/AdminSidebar.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {mapState} from 'vuex'
import moment from 'moment'
export default {
  name: 'AdminReports',
  components: {
    AdminHeader,
    AdminSidebar
  },
  setup () {
    return { v$: useVuelidate() }
  },
  computed:{
    reportdetails: {
      get () {
        return this.$store.state.report.reportdetails
      },
      set (value) {
        this.$store.commit('report/updateportdetails', value)
      }
    }
  },
  data(){
    return{
      report_id: '1',
      start_date: '',
      end_date: '',
      tab_one: 'false',
      tab_two: 'false',
      tab_three: 'false'

    }
  },
  mounted(){
    this.disable_date();
  },
  validations () {
    return {
      report_id: { required },
      start_date: { required },
      end_date: { required }
    }
  },
  methods:{
    getReportDetails(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        if(this.report_id == '1'){
          this.tab_one = true,
          this.tab_two = false,
          this.tab_three = false
          $(this.$refs.tables1).DataTable().destroy();
          $(this.$refs.tables2).DataTable().destroy();
          $(this.$refs.tables3).DataTable().destroy();
          this.$nextTick(() => {
            $(this.$refs.tables1).DataTable({
               destroy: true,
              "ordering":false
            });
          });
        }
        else if(this.report_id == '2')
        {
          this.tab_one = false,
          this.tab_two = true,
          this.tab_three = false
          $(this.$refs.tables1).DataTable().destroy();
          $(this.$refs.tables2).DataTable().destroy();
          $(this.$refs.tables3).DataTable().destroy();
          this.$nextTick(() => {
            $(this.$refs.tables2).DataTable({
               destroy: true,
              "ordering":false
            });
          });
        }
        else{
          this.tab_one = false,
          this.tab_two = false,
          this.tab_three = true
          $(this.$refs.tables1).DataTable().destroy();
          $(this.$refs.tables2).DataTable().destroy();
          $(this.$refs.tables3).DataTable().destroy();
          this.$nextTick(() => {
            $(this.$refs.tables3).DataTable({
               destroy: true,
              "ordering":false
            });
          });
        }
        const gen_report = new URLSearchParams()
        gen_report.append('report_id', this.report_id)
        gen_report.append('start_date', this.start_date)
        gen_report.append('end_date', this.end_date)
        gen_report.append('building_id', localStorage.building_id)
        this.$store.dispatch('report/getReportDetails',gen_report)
      }
    },
    resetTable(){
      this.reportdetails = [];
    },
     format_date(value){
       if (value) {
         return moment(String(value)).format('DD-MM-YYYY')
        }
    },
    disable_date(){
      var date = new
      Date().toISOString().slice(0,10);
      $(this.$refs.reportinputdate1).attr('max',date);
      $(this.$refs.reportinputdate2).attr('max',date);
    },
    generatePdfReport(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        const gen_report = new URLSearchParams()
        gen_report.append('report_id', this.report_id)
        gen_report.append('start_date', this.start_date)
        gen_report.append('end_date', this.end_date)
        gen_report.append('building_id', localStorage.building_id)
        this.$store.dispatch('report/generatePdfReport',gen_report)
      }
    }
  },
  watch: {
    reportdetails() {
      $(this.$refs.tables1).DataTable().destroy();
      $(this.$refs.tables2).DataTable().destroy();
      $(this.$refs.tables3).DataTable().destroy();
      if(this.tab_one == true){
        this.$nextTick(() => {
          $(this.$refs.tables1).DataTable({
             destroy: true,
            "ordering":false
          });
        });
      }
      else if(this.tab_two == true)
      {
        this.$nextTick(() => {
          $(this.$refs.tables2).DataTable({
             destroy: true,
            "ordering":false
          });
        });
      }
      else if (this.tab_three == true){
        this.$nextTick(() => {
          $(this.$refs.tables3).DataTable({
             destroy: true,
            "ordering":false
          });
        });
      }

    }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
