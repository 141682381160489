<template>
  <body class="skin-default card-no-border">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->

    <section id="wrapper">

        <div class="login-register" style="background-image:url(assets/images/background/login-register.jpg);">
            <div class="login-box card" style="top:-30px;">
                <div class="card-body">
                    <form id="loginform" @submit.prevent="addflat">
                        <h3 class="text-center m-b-20" style="font-weight: 400;">Add New Flat</h3>
                        <div class="row" style="justify-content: center;">
                        </div>
                        <div class="form-group row" style="padding: 15px 30px 10px 30px;margin-bottom:0px !important;">
                            <label for="example-text-input" class="col-form-label" style="font-weight: 500;margin-left: 10px;">Building </label>
                            <div class="col-12">
                                <select id="item_status" class="form-control" v-model="building_no"  @change="get_flat_no($event.target.selectedIndex)" :class="{ error: v$.building_no.$error }">
                                  <option >Select Building</option>
                                  <option v-for="item in building_id" :value="item">{{item['building_name']}}</option>
                              </select>

                            </div>
                        </div>
                        <div class="form-group row" style="padding: 10px 30px 10px 30px;">
                            <label for="example-text-input" class="col-form-label" style="font-weight: 500;margin-left: 10px;">Flat</label>
                            <div class="col-12">
                                 <select id="item_status" class="form-control" v-model="flat_no" :class="{ error: v$.flat_no.$error }">
                                  <option >Select Flat</option>
                                  <option v-for="item in flats" :value="item" :key ="item.id">{{item['appartment_number']}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row" style="padding: 10px 30px 10px 30px;">
                            <label for="example-text-input" class="col-4 col-form-label" style="font-weight: 500;">Enter Name</label>
                            <div class="col-12">
                                 <input type="text" class="form-control" v-model="name" :class="{ error: v$.name.$error }" placeholder="Enter Name">
                            </div>
                        </div>
                        <div class="row" style="justify-content: center;">
                            <div class="form-group text-center" style="width: 65%;margin-bottom:0px !important;">
                                <div class="col-xs-12 p-b-20">
                                   <button class="btn btn-block btn-lg btn-info btn-rounded" type="submit" >Add >></button>
                                </div>
                                <div class="col-xs-12 p-b-20">
                                   <a href="#" @click="logoutUser">Sign Out</a>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

         </div>
    </section>
</body>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required} from '@vuelidate/validators'
import {mapState} from 'vuex'
export default {
  name: 'AddFlat',
  components: {

  },
   computed:{
    ...mapState('flat',['building_id','flat_id'])


  },
  data() {
    return{
      flats: [],
      building_no: 'Select Building',
      flat_no: 'Select Flat',
      name: ''
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  mounted(){

    this.showalerts();
  },
  validations () {
    return {
      building_no: { required,
         isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === 'Select Building') return false
        else return true


         } },
       name: {required},
      flat_no: {required ,
        isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === 'Select Flat') return false
        else return true


         }}
    }
  },
  created: function(){

      this.$store.dispatch('flat/get_user_details')

    },
  methods:{
    addflat(){
      var flat = 0
      this.v$.$touch()
      if(!this.v$.$invalid) {
      this.flat_id.forEach((item) => {
        if(this.flat_no['appartment_number'] == item.appartment_number && this.building_no['building_id'] == item.building_id){
          flat = item.appartment_id

        }
      });
      const add_details = new URLSearchParams()
      add_details.append('flat_id', flat)
      add_details.append('user_id', localStorage.user_id)
      add_details.append('building_id', this.building_no['building_id'])
      add_details.append('username', this.name)
      this.$store.dispatch('flat/addflat_req',add_details)
    }
    },
     showalerts(){
      if (localStorage.status == "Flat_allocated")
      {
        $('.signout_create').delay(500).slideDown(500);
        $('.signout_create').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
    },
    logoutUser(){
      this.$store.dispatch('login/logoutUser')
    },
    get_flat_no(selectedIndex)
    {
      this.flats = []
      var selectedIdea = this.building_id[selectedIndex-1];
      var resArr = [];
      this.flat_id.forEach((item) => {
       if (selectedIdea.building_id == item.building_id){
         this.flats.push({id: item.id,appartment_number: item.appartment_number})
       }
      // do something
      });
    }

}
 }
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
