<template>
	<div id="confirm-delete" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
	                   aria-hidden="true" style="top:23%;">
	                   <div class="modal-dialog">
	                       <div class="modal-content">
	                           <div class="modal-header">
	                               <h4 class="modal-title" style="margin-left: 5%;opacity: 60%;" id="myModalLabel">Confirm Delete</h4>
	                               <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
	                           </div>
	                           <div class="card-body" style="padding: 10px 50px;">
	                             <div class="row" style="justify-content: center;">
	                               <h4 style="margin-left: 40px"><strong>Are you sure?</strong> Do you want to delete this booked pass?</h4>

	                             </div>
	                             <hr>
	                               <div class="col-sm-12">
	                                   <div class="form-group">
	                                       <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
	                                           <button type="submit" class="btn btn-success mr-2 confirm-submit confirm-delete-facility-user"
	                                               data-dismiss="modal" @click="deletepass" aria-hidden="true">OK</button>
	                                           <button type="button" class="btn btn-danger mr-2" data-dismiss="modal"
	                                               aria-hidden="true">Cancel</button>
	                                       </div>
	                                   </div>
	                               </div>
	                           </div>
	                           <hr>
	                       </div>

	                       <!-- /.modal-content -->
	                   </div>
	                   <!-- /.modal-dialog -->
	               </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'DeletePass',
  components: {

  },
  props: ['pass_id'],

  methods:{
    deletepass(){
			this.$store.commit('spinner/showLoading', true)
      const visitor = new URLSearchParams()
      visitor.append('visitor_id', this.pass_id)
      visitor.append('building_id', localStorage.building_id)
       this.$store.dispatch('visitor/deleteadminvisitor',visitor)
    }
  }
}
</script>
