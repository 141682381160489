<template>
<body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <AdminHeader/>
      <AdminSidebar/>
        <!-- ============================================================== -->
        <!-- Topbar header - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- End Topbar header -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper">
            <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="container-fluid">
                <!-- ============================================================== -->
                <!-- Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Admin Events</h4>
                    </div>
                    <div class="alert alert-success signout_create banner-text" role="alert">
                        <span><strong>Success!</strong> You have successfully created a new event</span>
                    </div>
                    <div class="alert alert-success signout_update banner-text" role="alert">
                        <span><strong>Success!</strong> You have successfully updated the event details</span>
                    </div>
                    <div class="alert alert-danger signout_delete banner-text" role="alert">
                        <span><strong>Deleted!</strong> You have successfully deleted the event</span>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link :to="{ name: 'AdminHome' }">Home</router-link></li>
                                <li class="breadcrumb-item active">Add Events</li>
                            </ol>
                            <button type="button" @click="add_event" class="btn btn-info d-none d-lg-block m-l-15" data-toggle="modal"
                                data-target="#add-event">+ Add New Event</button>
                        </div>
                    </div>
                </div>

                <!-- Add New Service Popup Model -->



                <!-- Add Contact Popup Model -->

                <!--End of Add New Service-->
                <!-- ============================================================== -->
                <!-- End Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Start Page Content -->
                <!-- ============================================================== -->
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="card-body">
                                    <div class="table-responsive">
                                       <table id="myTable" class="table  table-striped" ref="tables">
                                            <thead>
                                                <tr>
                                                    <th>Event ID</th>
                                                    <th>Event Name</th>
                                                    <th>Organizer</th>
                                                    <th>Venue</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                 <tr v-for="item in admin_events " :key ="item.id">
                                                    <td>{{ item.id }}</td>
                                                    <td @click="update_event(item.id)"  data-toggle="modal" data-target="#show-events">{{ item.event_name }}</td>
                                                    <td>{{ item.organizer }}</td>
                                                    <td>{{ item.venue }}</td>
                                                    <td>{{ format_date(item.start_at) }}</td>
                                                    <td>{{format_date(item.end_at )}}</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="addevent === true">
                  <CreateEvent></CreateEvent>
                </div>
                 <div v-if="updateevent === true">
                   <ShowEvent :event_id='event_id'/>
                </div>
               <!--   <div v-if="deleteevent === true">
                   <DeleteEvent :event_id='event_id'/>
                </div> -->
                <div id="edit-service" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                    aria-hidden="true" style="top:10%;">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="myModalLabel">Edit Service</h4>
                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12 ">
                                        <div class="form-group">
                                            <label>Service Name</label>
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 ">
                                        <div class="form-group">
                                            <label>Description</label>
                                            <textarea class="form-control" rows="3"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Service Charge</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">INR &#x20b9;</span>
                                                </div>
                                                <input type="number" class="form-control" aria-label="Amount (to the nearest dollar)">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-info waves-effect" data-dismiss="modal">Update</button>
                                <button type="button" class="btn btn-default waves-effect" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                        <!-- /.modal-content -->
                    </div>
                    <!-- /.modal-dialog -->
                </div>
                <div id="alreadybooked" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" style="top:40%;">
                    <div class="modal-dialog">
                        <div class="modal-content" style="border:none;">
                            <div class="modal-header" style="padding-top:35px;">
                                <h4 class="modal-title"  style="margin-left: 5%;opacity: 60%;" id="myModalLabel"></h4>
                                <button type="button" id="#bookclose" class="close">×</button>
                            </div>
                             <div class="card-body" style="padding: 10px 50px;">
                                <div class="row" style="justify-content: center;">
                                    <span class="total_payment"><img src="assets/images/danger.png" style="width:100px;" class="private-account"></span>
                                </div>

                                    <div class="col-sm-12 private-text text-center" >
                                        <span style="font-size:20px;font-weight:400;">Already Booked!!</span>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div id="verticalcenter" class="modal" tabindex="-1" role="dialog" aria-labelledby="vcenter"
                    aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="vcenter">Delete</h4>
                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div class="modal-body">
                                <h4>Are You Sure!!</h4>
                                <p>Do you want to delete cleaning?</p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-info waves-effect" data-dismiss="modal" style="border-color: white;">Cancel</button>
                                <button type="button" class="btn btn-info label-success" data-dismiss="modal" style="background-color: #e46a76;border-color: white;">Delete</button>

                            </div>
                        </div>
                        <!-- /.modal-content -->
                    </div>
                    <!-- /.modal-dialog -->
                </div>
                <!-- ============================================================== -->
                <!-- End PAge Content -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer">
            © 2020 www.ontash.net
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Wrapper -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- All Jquery -->
    <!-- ============================================================== -->
  </body>
</template>

<script>
// @ is an alias to /src
import AdminHeader from '@/components/headers/AdminHeader.vue'
import AdminSidebar from '@/components/sidebars/AdminSidebar.vue'
import CreateEvent from '@/components/events/CreateEvent.vue'
import ShowEvent from '@/components/events/ShowEvent.vue'
import {mapState} from 'vuex'
import moment from 'moment'
export default {
  name: 'AdminEvent',
  components: {
    AdminHeader,
    AdminSidebar,
    CreateEvent,
    ShowEvent

  },
  data(){
    return{
     addevent: false,
     updateevent: false,
     event_id: ''

    }

  },
  computed:{
    ...mapState('event',['admin_events']),

  },
  mounted(){
    this.showalerts();
  },
    created: function(){
      this.$store.dispatch('event/list_event_for_admin')


  },

 methods:{
     add_event(){
        this.addevent = true
    },
    format_date(value){
         if (value) {
            var date = moment(value);
            var timeComponent = date.format('h:mm a');
            var dateComponent = date.format('DD-MM-YYYY')
            var date_val = dateComponent+' '+timeComponent
            return date_val
          }
    },
    update_event(id){
        this.updateevent = true
        this.event_id = id
    },
    showalerts(){
        if (localStorage.status == "created")
        {
          $('.signout_create').delay(500).slideDown(500);
          $('.signout_create').delay(4000).slideUp(500);
          localStorage.removeItem('status')
        }
        else if (localStorage.status == "updated")
        {
          $('.signout_update').delay(500).slideDown(500);
          $('.signout_update').delay(4000).slideUp(500);
          localStorage.removeItem('status')
        }
        else if (localStorage.status == "deleted")
        {
          $('.signout_delete').delay(500).slideDown(500);
          $('.signout_delete').delay(4000).slideUp(500);
          localStorage.removeItem('status')
        }
      }

  },

 watch: {
     admin_events() {
      $(this.$refs.tables).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.tables).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    }
 }

}
</script>
<style>

</style>
