<template>
  <html>
  <body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="row page-titles">

        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Info box -->
        <!-- ============================================================== -->
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row info-icon">
                            <img src="../../assets/images/login_logo.png" alt="homepage" class="dark-logo imagesize"/>
                        </div>
                        <div class="row info-icon">
                                <h6>An app for landlord/owners/managers of building associations,
                                    cooperative apartments, and housing complexes.</h6>
                        </div>
                        <hr>

                        <div class="row contents">
                            <span>
                                <h4>
                                        USER DELETE POLICY
                                </h4>
                            </span>
                        </div>
                        <div class="row contents">
                          <span>
                            <strong>1. User Initiated Account Deletion: &nbsp;&nbsp;</strong>
                          </span>
                        </div>
                          <div class="row contents">
                          <span>
                            Users have the option to delete their accounts at any time. To initiate the account deletion process, users should follow these steps:
                          </span>
                        </div>
                          <div class="row contents">
                            <ul>
                                <li>
                                    <span>
                                        Log in to their account.
                                    </span>
                                </li>
                                <li>
                                        <span>
                                                Navigate to the account settings or profile section. </span>
                                    </li>

                                    <li>
                                            <span>
                                                    Select the option to delete or deactivate the account.
                                             </span>
                                        </li>
                                        <li>
                                                <span>
                                                        Follow the on-screen instructions to complete the account deletion process.
                                                 </span>
                                            </li>
                                </ul>
                        </div>
                        <div class="row contents">
                          <span>
                            <strong>2. Data Retention: &nbsp;&nbsp;</strong>
                          </span>
                        </div>
                        <div class="row contents">
                          <span>
                            Upon account deletion, Ontash will retain certain data for a specified period to comply with legal obligations or for internal record-keeping purposes. The retained data may include transaction records, account activity, or other relevant information.
                          </span>
                        </div>
                        <div class="row contents">
                          <span>
                            <strong>3. Contact Information: &nbsp;&nbsp;</strong>
                          </span>
                        </div>
                        <div class="row contents">
                          <span>
                            For questions or assistance related to account deletion, users can contact our support team at <strong>+91 9747349415</strong> or <strong>willows@ontash.net</strong>
                          </span>
                        </div>
                        <div class="row contents">
                          <span>
                            <strong>4. Changes to the Policy: &nbsp;&nbsp;</strong>
                          </span>
                        </div>
                        <div class="row contents">
                          <span>
                            Willows reserves the right to update or modify this account deletion policy. Users will be notified of any changes via email and in app notification.
                          </span>
                        </div>
                        <hr>
                        <div class="row">

                            <a href="" style="color: #32c0c8;margin-left: 20px;">Delete Policy</a>

                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Info box -->
        <!-- ============================================================== -->

        <!-- ============================================================== -->
        <!-- Review -->
        <!-- ============================================================== -->

        <!-- ============================================================== -->
        <!-- End Review -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Comment - chats -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- End Comment - chats -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- End Page Content -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Right sidebar -->
        <!-- ============================================================== -->
        <!-- .right-sidebar -->

        <!-- ============================================================== -->
        <!-- End Right sidebar -->
        <!-- ============================================================== -->

    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->

<!-- ============================================================== -->
<!-- End Page wrapper  -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- footer -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- End footer -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
</body>
</html>
</template>

<script>
// @ is an alias to /src
import UserHeader from '@/components/headers/UserHeader.vue'
import UserSidebar from '@/components/sidebars/UserSidebar.vue'
export default {
  name: 'DeletePolicy',
  components: {
    UserHeader,
    UserSidebar
  }
}
</script>

<style scoped>
  .info-icon{
    justify-content: center;
  }
  .img-icon{
    height: 70px;
    margin-bottom: 5px;
  }
  .contents{
    padding: 15px 15px 0px 15px;
  }

  .imagesize{
    width: 350px;
  }

  .content ul{
    margin-right: 25px;
  }
  h4{
    font-weight: 500 !important;
  }
  strong{
    font-weight: 500 !important;
  }
  h6{
    font-weight: 400;
  }
</style>
