<template>
	<body class="skin-default fixed-layout">
		<div id="main-wrapper">
			<UserHeader/>
			<UserSidebar/>
	 	<div class="page-wrapper">

	            <!-- ============================================================== -->
	            <!-- Container fluid  -->
	            <!-- ============================================================== -->
	            <div class="container-fluid">
	            <!-- ============================================================== -->
                <!-- Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">My Wallet</h4>
                    </div>
                    <div class="alert alert-danger signout_delete banner-text" role="alert">
                        <span><strong>Warning!</strong> Please add a valid email address before adding money to wallet</span>
                    </div>
										<div class="alert alert-warning flat_approval banner-text" role="alert">
                        <span><strong>Warning!</strong> Your account is not activated</span>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link :to="{ name: 'UserHome' }">Home</router-link></li>
                                <li class="breadcrumb-item active">My Wallet</li>
                            </ol>
                            <button type="button" data-toggle="modal" @click="create_wallet" data-target="#wallet-payment" class="btn btn-info d-none d-lg-block m-l-15">
                                + Add Cash to Wallet</button>
                            <!-- <button type="button" class="btn btn-info d-none d-lg-block m-l-15"><i class="fa fa-plus-circle"></i>
                                Create New</button> -->
                        </div>
                    </div>
                </div>
                <div v-if="add_wallet === true">
                  <Addwallet></Addwallet>
                </div>
                <!-- ============================================================== -->
                <!-- End Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Info box -->
                <!-- ============================================================== -->
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row m-t-40">
                                    <!-- Column -->
                                    <div class="col-md-6 col-lg-3 col-xlg-3">
                                        <div class="card">
                                            <div class="box label-info text-center" @click="sortBystatus(0)">
                                                <h1 class="font-light text-white"><span class="currency">&#x20b9; </span>{{ current_bal }}</h1>
                                                <h6 class="text-white">Current Balance</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Column -->
                                    <div class="col-md-6 col-lg-3 col-xlg-3">
                                        <div class="card">
                                            <div class="box label-success text-center" @click="sortBystatus(1)">
                                                <h1 class="font-light text-white"><span class="currency">&#x20b9; </span>{{total_credited}}</h1>
                                                <h6 class="text-white">Total Credited</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Column -->
                                    <div class="col-md-6 col-lg-3 col-xlg-3">
                                        <div class="card">
                                            <div class="box label-danger text-center" @click="sortBystatus(2)">
                                                <h1 class="font-light text-white"><span class="currency">&#x20b9; </span>{{total_debited}}</h1>
                                                <h6 class="text-white">Total Debited</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Column -->

                                    <!-- Column -->
                                </div>
																<div v-if="trans.length <= 0">
						                      <div style ="padding-left:15px;padding-right:15px; width:100%">
						                                        <div class="alert alert-dark">
						                                            <span>No data to show</span>
						                                        </div>
						                      </div>
						                    </div>
						                    <div v-else>
                                <div class="table-responsive">
                                     <table id="myTable" class="table table-striped" ref="tables">
                                        <thead>
                                            <tr>
                                                 <th>Reference Number</th>
                                                 <th>Date</th>
                                                 <th>Description</th>
                                                 <th class="text-center">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                              <tr v-for="item in trans " :key ="item.id">
                                                <td>{{item.id}}</td>
                                                <td>{{format_date(item.created_at)}}</td>
                                                <td>{{item.topic}}</td>
                                                <td>{{item.amount}}</td>
                                              </tr>
                                        </tbody>
                                    </table>
                                </div>
																</div>
                                <div id="change-status" class="modal" tabindex="-1" role="dialog" aria-labelledby="vcenter"
                                    aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title" id="vcenter">Change Status</h4>
                                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="card">
                                                        <div class="card-body" style="padding-left: 30px;">
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <label>Invoice</label>
                                                                </div>
                                                                <div class="col-6">
                                                                    <label>Order #53434</label>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <label>User</label>
                                                                </div>
                                                                <div class="col-6">
                                                                    <label>Bibin George</label>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <label>Complaint Date</label>
                                                                </div>
                                                                <div class="col-6">
                                                                    <label>Oct 15, 2014</label>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <label>Description</label>
                                                                </div>
                                                                <div class="col-6">
                                                                    <label>Cleaning</label>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <label>Status</label>
                                                                </div>
                                                                <div class="col-6">
                                                                    <select class="form-control custom-select">
                                                                        <option class="status_new">New</option>
                                                                        <option>Pending</option>
                                                                        <option>Complete</option>
                                                                        <option>Closed</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-info waves-effect" data-dismiss="modal"
                                                    style="border-color: white;">Cancel</button>
                                                <button type="button" class="btn btn-info label-success" data-dismiss="modal"
                                                    style="background-color: #e46a76;border-color: white;">Update
                                                    Status</button>

                                            </div>
                                        </div>
                                        <!-- /.modal-content -->
                                    </div>
                                    <!-- /.modal-dialog -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ============================================================== -->
                <!-- End Info box -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
        </div>
        <footer class="footer">
    © 2020 www.ontash.net
</footer>
    </div>
</body>
</template>

<script>
	// @ is an alias to /src
	import UserSidebar from '@/components/sidebars/UserSidebar.vue'
	import UserHeader from '@/components/headers/UserHeader.vue'
  import Addwallet  from '@/components/wallet/Addwallet.vue'
	import {mapState,mapActions,mapMutations} from 'vuex'
  import moment from 'moment'
	export default{
		name: 'Wallet',
		components: {
			UserHeader,
			UserSidebar,
      Addwallet
		},
    data() {
        return {
            add_wallet: false
        }
    },
	  computed:{
	    ...mapState('wallet',['current_bal','total_credited','total_debited','payment_account_status','transactions','trans']),
			...mapState('flat',['appartmentstatus'])
	  },
    mounted(){
       this.showalerts();
			 this.getAppartmentUserStatus();
    },
	  methods: {
        ...mapMutations('wallet',['sortBystatus']),
				...mapActions('flat',['getAppartmentUserStatus']),
        create_wallet(){
					if (this.appartmentstatus == "active"){
						this.add_wallet = true
					}
					else{
						$('.flat_approval').delay(500).slideDown(500);
						$('.flat_approval').delay(4000).slideUp(500);
					}
        },
        format_date(value){
           if (value) {
             return moment(String(value)).format('DD-MM-YYYY')
            }
        },
        showalerts(){
            if (localStorage.status == "update_email")
            {
              $('.signout_delete').delay(500).slideDown(500);
              $('.signout_delete').delay(4000).slideUp(500);
              localStorage.removeItem('status')
            }
        }
    },
    created(){
      this.$store.dispatch('wallet/mywallet')
    },
    watch: {
      trans() {
        $(this.$refs.tables).DataTable().destroy();
        this.$nextTick(() => {
          $(this.$refs.tables).DataTable({
             destroy: true,
            "ordering":false
          });
        });
      }
    }
  }
</script>
