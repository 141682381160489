<template>
  <header class="topbar" ref="myTopnav">
    <nav class="navbar top-navbar navbar-expand-md navbar-dark" >
      <!-- ============================================================== -->
      <!-- Logo -->
      <!-- ============================================================== -->
    <div class="navbar-header">
      <router-link :to="{ name: 'SuperAdminHome' }" class="navbar-brand">
        <!-- Logo icon -->
        <b>
          <img src="../../assets/images/logo_only.png" alt="homepage" class="dark-logo" />
        </b>
        <!--End Logo icon -->
        <!-- Logo text -->
        <span>
          <img src="../../assets/images/logo-text.png" alt="homepage" class="dark-logo" />
        </span>
      </router-link>
    </div>
    <!-- ============================================================== -->
    <!-- End Logo -->
    <!-- ============================================================== -->
    <div class="navbar-collapse" ref="test2">
      <!-- ============================================================== -->
      <!-- toggle and nav items -->
      <!-- ============================================================== -->
      <ul class="navbar-nav mr-auto" >
        <!-- This is  -->
        <li class="nav-item"> <a class="nav-link nav-toggler d-block d-md-none waves-effect waves-dark" ref="navtogg" @click="navbartoggleclick"><i class="ti-menu"></i></a> </li>
        <li class="nav-item"> <a class="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark" ref="sdbrtogg" @click="sidebartoggleclick"><i class="icon-menu"></i></a> </li>
        <!-- ============================================================== -->
        <!-- Search -->
        <!-- ============================================================== -->
      </ul>
      <!-- ============================================================== -->
      <!-- User profile and search -->
      <!-- ============================================================== -->
      <ul class="navbar-nav my-lg-0">
        <!-- ============================================================== -->
        <!-- Comment -->
        <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- End Comment -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->

              <li class="nav-item right-side-toggle">
                  <router-link :to="{ name: 'SuperAdminAbout' }" class="nav-link  waves-effect waves-light">
                  <i class="fa fa-info-circle"></i>
                </router-link>
              </li>
            </ul>
         </div>
      </nav>
   </header>
</template>

<script>
// @ is an alias to /src
import {mapState} from 'vuex'
import moment from 'moment'
export default {
  name: 'SuperAdminHeader',
  components: {

  },
  data(){
    return{
      show: true
    }
  },
   computed:{

  },
    mounted(){
      $('.scroll-sidebar, .right-side-panel, .message-center, .right-sidebar').perfectScrollbar();
      if ($("body").hasClass("mini-sidebar")) {
          $('.navbar-brand span').hide();
      }
  },
   methods: {

      sidebartoggleclick(){
        if ($("body").hasClass("mini-sidebar")) {
            this.set();
            $("body").removeClass("mini-sidebar");
            $('.navbar-brand span').show();
        }
        else {
            this.set();
            $("body").addClass("mini-sidebar");
            $('.navbar-brand span').hide();
        }
      },
      navbartoggleclick() {
        if ($("body").hasClass("show-sidebar")) {
          this.set();
          $("body").removeClass("show-sidebar");
          $(".nav-toggler i").removeClass("ti-close");
          $(".nav-toggler i").addClass("ti-menu");
          $('.navbar-brand span').hide();
        }
        else {
          this.set();
          $("body").addClass("show-sidebar");
          $(".nav-toggler i").removeClass("ti-menu");
          $(".nav-toggler i").addClass("ti-close");
          $('.navbar-brand span').hide();
        }
      },
      set() {
          var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
          var topOffset = 55;
          if (width < 1170) {
              $("body").addClass("mini-sidebar");
              $('.navbar-brand span').hide();
              $(".sidebartoggler i").addClass("ti-menu");
          }
          else {
              $("body").removeClass("mini-sidebar");
              $('.navbar-brand span').show();
          }
           var height = ((window.innerHeight > 0) ? window.innerHeight : this.screen.height) - 1;
          height = height - topOffset;
          if (height < 1) height = 1;
          if (height > topOffset) {
              $(".page-wrapper").css("min-height", (height) + "px");
          }
      }
   },

   watch(){

   }

}

</script>
