import axios from 'axios'
import baseurl from './axioscredentials'
axios.defaults.withCredentials = true
import router from '@/router'
const subscriptioncharge = {
  namespaced: true,
  state: {
    subscription_charges: [],
    subscriptioncharges: []
  },
  getters: {

  },
  mutations: {
    SET_SUBSCRIPTION_CHARGE(state,data){
      state.subscription_charges = data.subscription_charges
    },
    SET_BUILDING(state,data){
      state.building = data.buildings
      state.subscriptioncharges = data.subscriptioncharges
    },
  },
  actions: {
    getSubscriptionCharges({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/admin_subscription_charges',{building_id: localStorage.building_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_SUBSCRIPTION_CHARGE',res.data)
        }).catch(error => console.log(error))
    },
    getbuilding_list({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_building_list',{building_id: localStorage.building_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_BUILDING',res.data)
        }).catch(error => console.log(error))
    },
    createsubscriptioncharge({ commit, state},charge){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/create_subscrption_charge',charge,{headers:{'Authorization':localStorage.token}}).then(
        res => {
           localStorage.status = "created"
            router.go({ name: 'SuperAdminSubscriptionCharge'})
        }).catch(error => console.log(error))
    },
    sendReminder({ commit, state},reminder){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/create_subscrption_remider',reminder,{headers:{'Authorization':localStorage.token}}).then(
        res => {
           localStorage.status = "reminder"
            router.go({ name: 'SuperAdminSubscriptionCharge'})
        }).catch(error => console.log(error))
    },
    deletecharge({ commit, state},charge){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/delete_subscription_charge',charge,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          localStorage.status = "deleted"
            router.go({ name: 'SuperAdminSubscriptionCharge'})
        }).catch(error => console.log(error))
    },
    confirmPayment({ commit, state},subscription){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/store_subscription_payment',subscription,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          if (res.data.error){
            router.go({ name: 'AdminSubscriptionCharge'})
          }
          else{
            router.push({ name: 'AdminConfirmPayment', params: {payment_link: res.data.paymentlink}})
          }

        }).catch(error => console.log(error))
    }

  }
}
export default subscriptioncharge
