import axios from 'axios'
import baseurl from './axioscredentials'
axios.defaults.withCredentials = true
import router from '@/router'
const profile = {
  namespaced: true,
  state: {

    profilepic: '',
    error_status: false,
    errormessage: '',
    counting: false

  },
  getters: {

  },
  mutations: {
    Get_profile(state,data){
     state.profile = data.profile
     state.name = data.user_details['name']
     state.phone = "+"+data.user_details['phone']
     state.useremail = data.user_details['email']
     state.caretaker_name = data.caretaker_details['name']
     state.caretaker_email = data.caretaker_details['email']
     state.caretaker_phone = "+"+data.caretaker_details['phone']
     state.activities = data.activity
     state.privacy = data.profile['privacy']
     state.profile_picture = data.profile_picture
     var parkinglist = {}
     parkinglist = JSON.parse(JSON.stringify(data['parkings']), function(key, value) {
       return value;
     });
     var resArr = [];
     parkinglist.forEach(function(item){
      resArr.push(item['name'])
     });
     state.parking_name = resArr
     state.parkings = data.parkings
   },
   Get_profile_admin(state,data){
     state.profile_admin = data.profile
     state.building_name = data.building.name
     state.admin_name = data.username
     state.phone_admin = "+"+data.building['phone_no']
     state.useremail_admin = data.building['email']
     state.address = data.building.adress_building
     state.caretaker_name_admin = data.caretaker_details['name']
     state.caretaker_email_admin = data.caretaker_details['email']
     state.caretaker_phone_admin = "+"+data.caretaker_details['phone']
     state.activities_admin = data.activity
     state.profile_picture = data.profile_picture
   },
   SET_PROFILEPHOTO(state,data){
     state.profilepic = data.image_url
   },
    SET_COUNTING(state,data){
      state.counting = true
      console.log(state.counting)
      console.log(data.otp)
    },
    SET_ERROR_MESSAGE(state,data){

      state.errormessage = data
      state.error_status = true
      state.counting = false
    },
    updateErrorStatus(state,payload){
      state.error_status = payload
    },
    updateCounting(state,payload){
      state.counting = payload
    }

  },
  actions: {
  	show_profile({commit,state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      var flat_id = localStorage["flat_id"]
      var tokens = ''
      axios.post(baseurl+'/api/v1/view_profile',{flat_id},
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
        console.log(res.data)
      commit('Get_profile',res.data)
      }).catch(error => console.log(error))
    },
    updatename({commit,state},username){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/update_profile_name',username,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
      localStorage["user_name"]=res.data.user.name
      localStorage.status = "name_updated"
      router.go({})
      }).catch(error => console.log(error))

    },
     requestOtp({ commit, state},mobile){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/generate_otp', mobile).then(
        res => {
          commit('SET_COUNTING',res.data)
          //console.log(res.data.otp)
        }).catch(error => console.log(error))
    },
    requestOtpEmail({ commit, state},mobile){
     this.dispatch('token/VerifyTokenExpiry', null, {root:true})
     axios.post(baseurl+'/generate_otp_email', mobile).then(
       res => {
         commit('SET_COUNTING',res.data)
         //console.log(res.data.otp)
       }).catch(error => console.log(error))
   },
    update_phone({ commit, state},mobile){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/update_profile_phone_no', mobile, {headers:{'Authorization':localStorage.token}}).then(
      res => {
        var sets = JSON.stringify(res.data)
          if (sets.includes("errormessage")){
            console.log(res.data)
            commit('SET_ERROR_MESSAGE',res.data.errormessage)
          }
        else{
          axios.post(baseurl+'/logout',{},
                  {headers:{'Authorization':localStorage.token}}).then(
            res => {
            }).catch(error => console.log(error))
            localStorage.clear();
            router.push({ name: 'Login'})
      }
      }).catch(error => console.log(error))
    },
    update_phone_admin({ commit, state},mobile){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/update_profile_phone_no_admin', mobile, {headers:{'Authorization':localStorage.token}}).then(
      res => {
        console.log(res.data)
        var sets = JSON.stringify(res.data)
          if (sets.includes("errormessage")){
            
            commit('SET_ERROR_MESSAGE',res.data.errormessage)
          }
        else{
          axios.post(baseurl+'/logout',{},
                  {headers:{'Authorization':localStorage.token}}).then(
            res => {
            }).catch(error => console.log(error))
            localStorage.clear();
            router.push({ name: 'Login'})
        }
      }).catch(error => console.log(error))
    },
    update_email({ commit, state},mobile){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/update_profile_email', mobile, {headers:{'Authorization':localStorage.token}}).then(
      res => {
        var sets = JSON.stringify(res.data)
          if (sets.includes("errormessage")){
            
            commit('SET_ERROR_MESSAGE',res.data.errormessage)
          }
        else{
          localStorage.status = "email_updated"
          router.go({ name: 'UserProfile'})
        }

      }).catch(error => console.log(error))
    },
    resendOtp({ commit, state},mobile){
      axios.post(baseurl+'/generate_otp', mobile).then(
        res => {
          console.log(res.data.otp)
        }).catch(error => console.log(error))
    },
    resendOtpemail({ commit, state},mobile){
      axios.post(baseurl+'/generate_otp_email', mobile).then(
        res => {
          console.log(res.data.otp)
        }).catch(error => console.log(error))
    },
    update_email_admin({ commit, state},mobile){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/update_profile_email_admin', mobile, {headers:{'Authorization':localStorage.token}}).then(
      res => {
        var sets = JSON.stringify(res.data)
          if (sets.includes("errormessage")){
            
            commit('SET_ERROR_MESSAGE',res.data.errormessage)
          }
        else{
          localStorage.status = "email_updated"
          router.go({ name: 'AdminProfile'})
        }
      }).catch(error => console.log(error))
    },
    setAsProfile({ commit, state},profilepic){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/change_profilepicture',profilepic,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ })
        }).catch(error => console.log(error))
    },
    getProfileImage({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_profilepicture',{user_id: localStorage.user_id},{headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_PROFILEPHOTO',res.data)
        }).catch(error => console.log(error))
    },
    show_admin_profile({commit,state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      var building_id = localStorage["building_id"]
      var tokens = ''
      axios.post(baseurl+'/api/v1/view_admin_profile',{building_id},
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
       commit('Get_profile_admin',res.data)
      }).catch(error => console.log(error))
    },
    update_building_name({commit,state},building){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/update_building_name',building,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
      localStorage["building_name"]=res.data.building.name
      localStorage.status = "building_name_updated"
      router.go({})
      }).catch(error => console.log(error))
    },
    update_address({commit,state},address){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/update_building_address',address,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
      localStorage.status = "address_updated"
      router.go({ name: 'AdminProfile'})
      }).catch(error => console.log(error))
    },
    changePrivacyStatus({commit,state},pri_status){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/update_profile_privacy',pri_status,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {

      router.go({ name: 'UserProfile'})
      localStorage.status = "privacy_updated"
      }).catch(error => console.log(error))
    }
  }
}
export default profile
