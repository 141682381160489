+<template>
<div id="add-area" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    aria-hidden="true" style="top:10%;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">Add New Facility</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <form>
                <div class="card-body" style="padding: 10px 50px;">
                  <div class="row" style="justify-content: center;">
                        <div class="col-md-12 ">
                            <div class="form-group">
                                <label>Name</label>
                                <input v-model="facility_name" type="text" class="form-control" placeholder="Enter Name" :class="{ error: v$.facility_name.$error }">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 ">
                            <div class="form-group">
                                <label>Description</label>
                                <textarea v-model="description" class="form-control" placeholder="Enter Description" rows="3" :class="{ error: v$.description.$error }"></textarea>
                            </div>
                        </div>
                    </div>
               

               <hr>
                 <div class="col-sm-12">
                   <div class="form-group">

                    <button  type="button" class="btn btn-success waves-effect" @click="createAdminFacility">Submit</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal" style="margin-left: 10px">Cancel</button>
                </div>
              </div>
              </div>
            </form>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>

</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'CreateFacility',
  setup () {
    return { v$: useVuelidate() }
  },
  components: {

  },
  data(){
    return{
      facility_name: '',
      description: ''
    }

  },
  validations () {
    return {
      facility_name: { required }, // Matches this.firstName
      description: { required } // Matches this.lastName
    }
  },
  methods:{
    createAdminFacility(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const facility = new URLSearchParams()
        facility.append('facility_name', this.facility_name)
        facility.append('description', this.description)
        facility.append('building_id', localStorage.building_id)
        this.$store.dispatch('facility/createAdminFacility',facility)
      }
    }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
