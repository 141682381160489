<template>
  <html>
  <body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <SuperAdminHeader/>
      <SuperAdminSidebar/>
  <div class="page-wrapper">
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="row page-titles">
            <div class="col-md-5 align-self-center">
                <h4 class="text-themecolor">About Willows</h4>
            </div>
            <div class="col-md-7 align-self-center text-right">
                <div class="d-flex justify-content-end align-items-center">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><router-link :to="{ name: 'SuperAdminHome' }">Home</router-link></li>
                        <li class="breadcrumb-item active">About</li>
                    </ol>
                    <!-- <button type="button" class="btn btn-info d-none d-lg-block m-l-15"><i class="fa fa-plus-circle"></i>
                        Create New</button> -->
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Info box -->
        <!-- ============================================================== -->
       <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row m-t-40">
                  </div>
                  <div v-if="appartment_details.length <= 0">
                    <div style ="padding-left:15px;padding-right:15px; width:100%">
                                      <div class="alert alert-dark">
                                          <span>No data to show</span>
                                      </div>
                    </div>
                  </div>
                  <div v-else>
                  <div class="table-responsive">
                    <table id="myTable" class="table table-striped"  ref="tables">
                      <thead>
                        <tr>
                          <th>Appartment Name</th>
                          <th>Owner	</th>
                          <th>Status</th>
                          <th>Last Login</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="buildingcaretaker in appartment_details" :key="buildingcaretaker.id">
                          <td>{{buildingcaretaker.number}}</td>
                          <td> {{buildingcaretaker.owner}}</td>
                          <td> {{buildingcaretaker.status}}</td>
                          <td></td>
                        </tr>
                    </tbody>
                    </table>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
</div>
<footer class="footer">
    © 2020 www.ontash.net
</footer>
</div>
</body>
</html>
</template>

<script>
// @ is an alias to /src
import SuperAdminHeader from '@/components/headers/SuperAdminHeader.vue'
import SuperAdminSidebar from '@/components/sidebars/SuperAdminSidebar.vue'
import {mapState,mapActions,mapMutations} from 'vuex'
export default {
  name: 'SuperAdminAppartmentlist',
  components: {
    SuperAdminHeader,
    SuperAdminSidebar
  },
   data() {
    return{
      building_id: this.$route.params.building_id,
    }
  },
  computed:{
    ...mapState('building',['appartment_details' , 'building_name'])
  },
  created(){
  	const building_id = new URLSearchParams()
  	building_id.append('building_id', this.building_id)
    this.$store.dispatch('building/get_appartment_details',building_id)
  },
  watch: {
    appartment_details() {
      $(this.$refs.tables).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.tables).DataTable({
           destroy: true,
          "ordering":false
        }).draw();
      });
    }
  }
}

</script>

<style scoped>
.info-icon{
  justify-content: center;
}
.img-icon{
  height: 70px;
  margin-bottom: 5px;
}
.contents{
  padding: 15px 15px 0px 15px;
}

.imagesize{
  width: 350px;
}

.content ul{
  margin-right: 25px;
}
h4{
  font-weight: 500 !important;
}
strong{
  font-weight: 500 !important;
}
h6{
  font-weight: 400;
}
</style>
