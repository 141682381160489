<template>
<!-- Add New Service Popup Model -->
  <div id="view_details" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="myModalLabel">Payments</h4>
                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>

                            <div class="card-body" style="padding-top: 0px;">
                                <div class="card" style="margin-bottom: 0px;">
                                      <div>
                                                    <span class="viewmorespan">Payment ID</span>
                                                </div>
                                                <div>
                                                        <input type="text" v-model="payment_ids" class="form-control"
                                                        placeholder="" readonly="readonly">
                                                </div>
                                         <div class="refdiv">
                                        <span class="viewmorespan">Complaint Reference No:</span>
                                        <div class="col-sm-12">
                                            <div>
                                                <input type="text" class="form-control ref_text"
                                                            autocomplete="off"  v-model="ref_text" placeholder="search reference" >
                                                <select class="form-control"  v-model="flat_no" @change="getselectedvalue($event.target.selectedIndex)" >

                                                <option v-for="item in complaints" :value="item" :key ="item.id">{{item['appartment_group']}} ]</option>
                                            </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 ref_nest1">

                                        </div>
                                         </div>

                                         <div class="refdiv">
                                <span class="viewmorespan">Service Reference No:</span>
                                <div class="col-sm-12">
                                    <div>
                                        <input type="text" class="form-control ref_text"
                                                            autocomplete="off"  v-model="ref_text1"  placeholder="search reference"  >
                                        <select  class="form-control" v-model="flat_no_" @change="getselectedvalue1($event.target.selectedIndex)" >

                                        <option v-for="item in serv_req" :value="item" :key ="item.id">{{item['appartment_group']}} ]</option>
                                    </select>
                                    </div>
                                </div>



                                                    <div class="col-sm-12 ref_nest2">

                                                    </div>

                                         </div>
                                    <div class="table-responsive" style="padding: 25px;">
                                                <table class="table">

                                        <tbody>


                                                    <tr>
                                                    <td class="tdleft">
                                                            <span class="viewmorespan"> Date</span>
                                                </td>
                                                <td class="tdright">
                                                        <input type="date" v-model = "date" class="form-control"
                                                        placeholder="" ref="inputdatepayment">
                                                </td>
                                            </tr>
                                            <tr>
                                                    <td class="tdleft">
                                                            <span class="viewmorespan">Amount</span>
                                                </td>
                                                <td class="tdright">
                                                        <input type="text" v-model = "amount" class="form-control"
                                                        placeholder="Enter Amount" >
                                                </td>
                                            </tr>
                                            <tr>
                                                    <td class="tdleft">
                                                            <span class="viewmorespan">Estimate_amount</span>
                                                </td>
                                                <td class="tdright">
                                                        <input type="text" v-model = "estimate_amount" class="form-control"
                                                        placeholder="Enter Estimate_amount" >
                                                </td>
                                            </tr>
                                            <tr>
                                                    <td class="tdleft">
                                                            <span class="viewmorespan">Status</span>
                                                </td>
                                                <td class="tdright">
                                                        <div class="form-group row" style="margin-left: 0px;margin-bottom:0px !important;">
                                                                <div class="custom-control custom-checkbox" style="margin-top: 7px !important;">
                                                                    <input type="checkbox" v-model = "status" class="custom-control-input" id="checkbox1"
                                                                        value="check">
                                                                    <label class="custom-control-label" for="checkbox1">Settled</label>
                                                                </div>
                                                            </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                    <td class="tdleft">
                                                            <span class="viewmorespan"> Recepient Name</span>
                                                </td>
                                                <td class="tdright">
                                                        <input type="text" class="form-control"
                                                        placeholder="Enter Recepient Name" v-model="payee_name">
                                                </td>
                                            </tr>
                                            <tr>
                                                    <td class="tdleft">
                                                            <span class="viewmorespan">Recepient Phone Number</span>
                                                </td>
                                                <td class="tdright">
                                                        <input type="number" class="form-control"
                                                        placeholder="Enter Recepient Phone Number " v-model="payee_phone">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                    <div class="col-sm-12">
                                            <div class="form-group">
                                                <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:10px;">
                                                    <button type="submit" @click="update_payment" class="btn btn-info mr-2">Update</button>
                                                    <button type="submit" class="btn btn-danger mr-2" data-dismiss="modal">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <!-- /.modal-content -->
                    </div>
                    <!-- /.modal-dialog -->
                </div>

</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {mapState} from 'vuex'
export default {
  name: 'UpdatePaymentDetails',
  data(){
    return{
      ref_text: '',
      ref_text1: '',
      flat_no_: 'select_reference',
      flat_no: 'select reference',
      services: [],
      serv_id: [],
      comp_id: [],
      complaint: [],
    }

  },
  computed:{
    ...mapState('payment',['serv_req','complaints']),
    payment_ids:{
        get () {
        return this.$store.state.payment.payment_id
      },
    },
    date: {
      get () {
        return this.$store.state.payment.date
      },
      set (value) {
        this.$store.commit('payment/updatedate', value)
      }
    },
    amount: {
      get () {
        return this.$store.state.payment.amount
      },
      set (value) {
        this.$store.commit('payment/updateamount', value)
      }
    },
    status: {
      get () {
        return this.$store.state.payment.status
      },
      set (value) {
        this.$store.commit('payment/updatestatus', value)
      }
    },
    payee_name: {
      get () {
        return this.$store.state.payment.payee_name
      },
      set (value) {
        this.$store.commit('payment/updatename', value)
      }
    },
    payee_phone: {
      get () {
        return this.$store.state.payment.payee_phone
      },
      set (value) {
        this.$store.commit('payment/updatephone', value)
      }
    },
    estimate_amount: {
      get () {
        return this.$store.state.payment.estimate_amount
      },
      set (value) {
        this.$store.commit('payment/updateestimate', value)
      }
    },

  },
  props: ['payment_id'],

  mounted(){
    this.disable_date();
    this.getpayment();
  },
  methods: {
    getpayment(){
       const payment_id = new URLSearchParams()
       payment_id.append('id', this.payment_id)
       this.$store.dispatch('payment/get_payment_detail',payment_id)
    },
    disable_date(){
      var date = new
      Date().toISOString().slice(0,10);
      $(this.$refs.inputdatepayment).attr('max',date);
    },
     getselectedvalue1(selectedIndex)
    {

      var selectedIdea = this.serv_req[selectedIndex];
      this.services.push(selectedIdea.appartment_group)
      this.serv_id.push(selectedIdea.service_id)
      this.estimate_amount = this.estimate_amount+selectedIdea.service_charge
      this.ref_text1 = this.services
      this.serv_req.splice(selectedIndex, 1)
     },

     getselectedvalue(selectedIndex){
      var selectedIdea = this.complaints[selectedIndex];
      this.complaint.push(selectedIdea.appartment_group)
      this.comp_id.push(selectedIdea.id)
      this.ref_text = this.complaint
      this.complaints.splice(selectedIndex, 1)

     },
     update_payment(){
       this.$store.commit('spinner/showLoading', true)
         const pay = new URLSearchParams()
        pay.append('id',this.payment_ids)
        pay.append('paid_date', this.date)
        pay.append('paid_amount', this.amount)
        pay.append('payee', this.payee_name)
        pay.append('payee_no', this.payee_phone)
        if(this.status == true){
            this.cb = "checked"

        }
        pay.append('cb', this.cb)
        pay.append('estimate_amt', this.estimate_amount)
        this.serv_id.forEach((item) => {
           pay.append('serv_requests[]', item);
        });
        this.comp_id.forEach((item) => {
           pay.append('complaints[]', item);
        });
        this.$store.dispatch('payment/update_payment',pay)
     }

  }
}

</script>
