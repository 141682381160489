<template>
<!-- Add New Service Popup Model -->
  <div id="myModal" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                    aria-hidden="true">
               <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title" id="myModalLabel">Create New Group</h4>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-hidden="true">×</button>
                                            </div>
                                            <div class="modal-body">
                                                <form class="form" @submit.prevent="create_contact">
                                                     <div class="form-group">
                                                                <label class="col-md-12">Group Name</label>
                                                                <div class="col-md-12">
                                                                    <input type="text" v-model="group_name"  :class="{ error: v$.group_name.$error }" class="form-control" placeholder="Enter Group Name" autocomplete="off">
                                                                </div>
                                                     </div>
                                                    <div class="form-group">
                                                                <label class="col-md-12">Add Members</label>


                                                            <div class="col-md-12">
                                                                    <input type="text" id="add_members" v-model="flat_id"  class="form-control search" placeholder="Search" autocomplete="off">
                                                                </div>
                                                                <div v-for="item in appart_list">
                                                            <div class="col-md-12 addmem" >

                                                                    <div class="col-md-12 lined">

                                                                            <div class="col-sm-12">
                                                                             <span>{{item.number}} [</span><span>{{item.user_name}} ]</span>
                                                                             <span><input  @click="addflat(item.id,$event)" type="checkbox"></span>
                                                                            </div>


                                                                    </div>
                                                                  </div>

                                                            </div>
                                                         </div>
                                                      <div class="form-group">
                                                                <label class="col-md-12">Total Amount</label>
                                                                <div class="col-md-12">

                                                                        <div class="input-group mb-3">
                                                                                <div class="input-group-prepend">
                                                                                  <span class="input-group-text">&#x20b9;</span>
                                                                                  <input type="textarea" v-on:keyup="find" v-model="total_amt" class="form-control"
                                                                                      placeholder="Enter Total Amount"  :class="{ error: v$.total_amt.$error }" autocomplete="off">

                                                                                </div>
                                                                            </div>
                                                                </div>
                                                      </div>
                                                       <div class="form-group">
                                                                <label class="col-md-12">Amount Per Person</label>
                                                                <div class="col-md-12">
                                                                  <div class="input-group mb-3">
                                                                          <div class="input-group-prepend">
                                                                            <span class="input-group-text">&#x20b9;</span>
                                                                            <input type="textarea" v-model="amt_per_person" placeholder="Amount Per Person"  :class="{ error: v$.amt_per_person.$error }" class="form-control" autocomplete="off">
                                                                          </div>
                                                                      </div>

                                                                </div>
                                                      </div>
                                                      <div class="col-sm-12">
                                                        <div class="form-group">
                                                          <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                                            <button type="submit" class="btn btn-success mr-2">Submit</button>
                                                            <button type="button" class="btn btn-danger mr-2" data-dismiss="modal">Cancel</button>
                                                          </div>
                                                         </div>
                                                      </div>

                                                </form>
                                            </div>

                                    </div>
                    <!-- /.modal-dialog -->
                </div>
    </div>


</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'CreateGroupPayment',
  props:['appart_list'],
  setup () {
    return { v$: useVuelidate() }
  },
  data(){
    return{
      select_flat_name: '----select_appartment-----',
      flat_id: [],
      amt_per_person: '',
      total_amt: '',
      group_name: '',
      add_members: '',


    }

  },
  validations () {
    return {

      group_name: { required },
      total_amt: { required } ,
       amt_per_person: { required },


    }
  },
  methods:{
    getselectedvalue(selectedIndex)
    {

      var selectedIdea = this.active_list[selectedIndex];
      this.flat_id =  selectedIdea.id


    },
    addflat(flat_ids,event){
      if (event.target.checked) {
       this.flat_id.push(flat_ids)
       this.find()
      }
      else{
        var index = this.flat_id.indexOf(flat_ids)
        this.flat_id.splice(index, 1)
        this.find()
      }

     },
     find(){
      var amt = this.total_amt/this.flat_id.length
      if (amt > 0) {
        this.amt_per_person = amt.toFixed(2)
       }
       else {
        this.amt_per_person = 0
       }
     },

    create_contact(){
         this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const pay = new URLSearchParams()
        pay.append('group_name', this.group_name)
        pay.append('amt_per_person', this.amt_per_person)
        pay.append('amount', this.total_amt)
        this.flat_id.forEach((item) => {
           pay.append('flat_ids[]', item);
        });
        pay.append('building_id', localStorage["building_id"])
       this.$store.dispatch('payment/group_payment',pay)
     }
      }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
