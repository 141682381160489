<template>
  <!-- Add New Service Popup Model -->
    <div id="add-service-request" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" id="myModalLabel">Request for a Service</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div class="card-body">
                            <form class="form" @submit.prevent="createServiceRequest">
                                    <div class="col-sm-12">
                                        <div class="form-group row">
                                            <label for="example-text-input" class="col-4 col-form-label" style="font-weight: 500;">Service</label>
                                            <div class="col-12">
                                                <select class="form-control custom-select" v-model="service_name" @change="getAmount(service_name)" :class="{ error: v$.service_name.$error }">
                                                    <option>Select Service</option>
                                                    <option v-for="service in services" :key="service.id">{{service.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group row">
                                            <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Service
                                                Charge</label>
                                            <input type="text" style="margin-left: 10px;width:96%;" disabled id="disabledTextInput"
                                                class="form-control" v-model="service_charge" placeholder="Enter Service Charge" :class="{ error: v$.service_charge.$error }">

                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group row">
                                            <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Service
                                                Date</label>
                                            <div class="col-12">
                                                <input class="form-control " type="date" @change.native="someEvent()" v-model="service_date" :class="{ error: v$.service_date.$error }" ref="serviceinputdate">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group row">
                                            <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Service
                                                Time</label>
                                            <div class="col-12">
                                              <select class="form-control custom-select" v-model="service_time" :class="{ error: v$.service_time.$error }">
                                                  <option>Select Time</option>
                                                   <option v-if="time_phase_one == true" >Morning(8:00AM-11:00AM)</option>
                                                   <option v-if="time_phase_two == true" >Noon(11:00AM-2:00PM)</option>
                                                   <option v-if="time_phase_three == true" >Evening(2:00PM-5:00PM)</option>
                                              </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group row">
                                            <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;" >Description</label>
                                            <div class="col-12">
                                                <textarea class="form-control" rows="5" placeholder="Enter Description" v-model="description" :class="{ error: v$.description.$error }"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                                <button type="submit" class="btn btn-success mr-2">Submit</button>
                                                <button type="button" class="btn btn-danger mr-2" data-dismiss="modal" aria-hidden="true">Cancel</button>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                   </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!-- ============================================================== -->
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {mapState,mapActions} from 'vuex'
export default {
  name: 'CreateServiceRequest',
  components: {

  },
  setup () {
    return { v$: useVuelidate() }
  },
  data(){
    return{
      service_name: 'Select Service',
      service_charge: '',
      service_date: '',
      service_time: 'Select Time',
      description: '',
      time_phase_two: true,
      time_phase_one: true,
      time_phase_three: true,
    }

  },
  computed:{
    ...mapState('servicerequest',['services']),
  },
  mounted(){
    this.getServices();
    this.disable_date();
  },
  validations () {
    return {
      service_name: { required,isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === 'Select Service') return false
        else return true


         }  },
      service_charge: { required },
      service_date: { required },
      service_time: { required,
         isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === 'Select Time') return false
        else return true


         } },
      description: { required }
    }
  },
  methods:{
    ...mapActions('servicerequest',['getServices']),
    createServiceRequest(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const servicerequest = new URLSearchParams()
        servicerequest.append('service_name', this.service_name)
        servicerequest.append('service_charge', this.service_charge)
        servicerequest.append('service_date', this.service_date)
        servicerequest.append('service_time', this.service_time)
        servicerequest.append('service_description', this.description)
        servicerequest.append('flat_id', localStorage.flat_id)
        this.$store.dispatch('servicerequest/createServiceRequest',servicerequest)
      }
    },
    someEvent(){
      var currentDateWithFormat = new Date().toJSON().slice(0,10).replace(/-/g,'-');
      if(currentDateWithFormat == this.service_date){
         const today = new Date();
         const time = today.getHours()
         if(time > 8){
          this.time_phase_one = false
          this.time_phase_two = true
          this.time_phase_three = true

         }
         if (time > 11){
          this.time_phase_one = false
          this.time_phase_two = false
          this.time_phase_three = true

         }
          if(time > 14){
          this.time_phase_one = false
          this.time_phase_two = false
          this.time_phase_three = false

         }
      }
      else{
        this.time_phase_one = true
        this.time_phase_two = true
        this.time_phase_three = true
      }
    },
    getAmount(service_nme){
      if (service_nme != "Select Service"){
        var serv = this.services.filter(s => s.name == service_nme)
        this.service_charge = serv[0].charge
      }
      else{
        this.service_charge = ''
      }
    },
    disable_date(){
      var date = new
      Date().toISOString().slice(0,10);
      $(this.$refs.serviceinputdate).attr('min',date);
    }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
