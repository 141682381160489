<template>
 <div id="edit-fullname" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
              <div class="modal-content">
                  <div class="modal-header">
                      <h4 class="modal-title" id="myModalLabel">Change Name</h4>
                      <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                  </div>
                  <div class="card-body" style="padding: 25px 50px !important;">
                      <form class="form" @submit.prevent="UpdateName">
                          <div class="col-sm-12">
                              <div class="form-group row">
                                  <div class="col-md-12">
                                      <input type="text"  v-model="name" :placeholder="[[ name_ ]]" class="form-control form-control-line" readonly="true">

                                  </div>
                              </div>
                          </div>
                          <div class="col-sm-12">
                              <div class="form-group row">
                                  <div class="col-md-12">
                                      <label for="example-text-input" class="col-4 col-form-label" style="">New Username</label>
                                      <input type="text"  v-model="new_name" placeholder="Enter New Username"  class="form-control form-control-line" :class="{ error: v$.new_name.$error }" >
                                  </div>
                              </div>
                          </div>
                          <div class="col-sm-12">
                              <div class="form-group">
                                  <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                      <button type="submit" class="btn btn-info mr-2">Update</button>
                                      <button type="button" class="btn btn-danger mr-2" data-dismiss="modal"
                                          aria-hidden="true">Cancel</button>
                                  </div>
                              </div>
                          </div>

                      </form>
                  </div>
              </div>
              <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
      </div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {mapState} from 'vuex'
export default {
  name: 'UpdateName',
  components: {

  },
  props:['name_'],
  data(){
    return{
      new_name: ''
    }
  },
  computed:{

  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations: {

    new_name: {
      required

    }
  },
  methods:{

    UpdateName(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const username = new URLSearchParams()
        username.append('user_id', localStorage.user_id)
        username.append('fullname', this.new_name)
        this.$store.dispatch('profile/updatename',username)
      }
    },
    activeDelete(){
      this.complaint_status = 0
      this.deleteactive = true
    },
    closeModal(){
      this.buttonactive = false
    }
  },

}

</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
