<template>
<div id="edit-ph" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div class="modal-dialog">
              <div class="modal-content">
                  <div class="modal-header">
                      <h4 class="modal-title" id="myModalLabel">Change Phone Number</h4>
                      <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                  </div>
                  <div class="card-body" style="padding: 25px 50px !important;">
                      <form class="form">
                          <div class="col-sm-12">
                              <div class="form-group row">
                                  <div class="col-md-12">
                                      <input type="number" onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))"
                                         v-model="phone" :placeholder="[[ phone_ ]]" :readonly="true" class="form-control form-control-line"
                                          required>
                                  </div>
                              </div>
                          </div>
                          <div class="col-sm-12">
                              <div class="form-group row">
                                  <div class="col-md-12">
                                      <label for="example-text-input" class="col-4 col-form-label" style="">New PhoneNumber</label>
                                      <div class="row" style="margin-left: 0px; justify-content: center;">
                                      <div class="col-sm-3" style="padding: 0px;">
                                        <select class="form-control" v-model="country_code" @change="get_code($event.target.selectedIndex)" >
                                        <option v-for="data in code" selectedIndex=0>({{data.code}}){{data.dial_code}}</option>
                                       </select>
                                      </div>
                                      <div class="col-sm-8">
                                      <input type="number" v-model="phonenum" onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))"
                                          placeholder="" class="form-control form-control-line"
                                          required :class="{ error: v$.phonenum.$error }">
                                    </div>
                                  </div>
                                  </div>
                              </div>
                          </div>

                          <div class="col-sm-12 otp-ph" v-if="displayotp == true">
                              <div class="form-group row">
                                  <div class="col-md-12">
                                      <input type="text" v-model="otp" placeholder="OTP" class="form-control form-control-line"
                                          required>
                                  </div>
                                  <div class="col-md-12" style="padding: 15px;">
                                      <span style="font-size:13px;color:red;">An OTP has been sent to your new phone
                                          number. Please enter here!</span>
                                  </div>
                              </div>
                          </div>
                          <div class="col-md-12" style="padding: 15px;" v-if="error_tab">
                              <span style="font-size:13px;color:red;">{{errormessage}}</span>
                          </div>
                          <div class="form-group row" style="text-align: center;font-size: 21px;">
                            <div class="col-md-12" >
                              <vue-countdown class="timerClass" v-if="counting " :time="180000" @end="onCountdownEnd" v-slot="{ minutes, seconds }"> {{ minutes }} : {{ seconds }} </vue-countdown>
                              <a v-if="otplink " @click="resendOtp()" style="color: #fb9678">Resend OTP</a>
                            </div>
                          </div>

                          <div class="col-sm-12">
                              <div class="form-group">
                                  <div class="custom-control custom-checkbox mr-sm-2"  style="padding-left:0">
                                      <button type="button" class="btn btn-info mr-2"  @click="updatephone_num" v-if="displayotp == true">Update</button>
                                      <button type="button" class="btn btn-success mr-2" v-if="displayotp == false" @click="sendotp" >Sent OTP</button>
                                      <button type="button" class="btn btn-danger mr-2" data-dismiss="modal"
                                          aria-hidden="true">Cancel</button>
                                  </div>
                              </div>
                          </div>

                      </form>
                  </div>
              </div>
              <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
      </div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required, sameAs, maxLength , minLength} from '@vuelidate/validators'
import {mapState} from 'vuex'
import json from '@/assets/json/CountryCode.json'
import VueCountdown from '@chenfengyuan/vue-countdown';
export default {
  name: 'UpdatePhone',
  components: {
    VueCountdown
  },
   props:['phone_'],
   data() {
    return {
      phonenum: '',
      displayotp: false,
      myJson: json,
      code: {},
      country_code: '(IN)+91',
      ctry_code : '+91',
      otplink:false,
      error_tab: false
    }
  },
   setup () {
    return { v$: useVuelidate() }
  },
  validations: {
    phonenum: {
      required,
      maxLength: maxLength(12),
      minLength: minLength(8)
    }
  },
  computed:{
    ...mapState('profile',['errormessage']),
    error_status: {
      get () {
        return this.$store.state.profile.error_status
      },
      set (value) {
        this.$store.commit('profile/updateErrorStatus', value)
      }
    },
    counting: {
      get () {
        return this.$store.state.profile.counting
      },
      set (value) {
        this.$store.commit('profile/updateCounting', value)
      }
    }

  },
   created(){
    var booked_list = {}
    console.log(this.myJson)
    booked_list= JSON.parse(JSON.stringify(this.myJson['Country']), function(key, value) {
      return value;
    });
    this.code = booked_list
  },
  mounted(){
    this.startCountdown();
  },

  methods:{

    UpdateName(){

        this.$store.dispatch('profile/updatename',this.name)
    },
     startCountdown() {
      console.log(this.counting )
      if (this.counting == true){
        this.otplink = false;
      }
    },
    onCountdownEnd() {
      console.log("hello")
      this.counting = false;
      this.error_status = false;
      this.otplink = true;
    },
    sendotp(){
      this.v$.$touch()
       if(!this.v$.$invalid){
        this.displayotp = true
       const mobile = new URLSearchParams()
       var number = this.ctry_code.slice(1) + this.phonenum
       mobile.append('phone_no', number)
       this.$store.dispatch('profile/requestOtp',mobile)

     }
    },
     get_code(selectedIndex)
    {
      this.ctry_code = this.code[selectedIndex].dial_code

    },
    updatephone_num(){
      // this.$store.commit('spinner/showLoading', true)
      const mobile = new URLSearchParams()
       var number = this.ctry_code.slice(1) + this.phonenum
      mobile.append('Phone', number)
      mobile.append('otp', this.otp)
      this.$store.dispatch('profile/update_phone',mobile)
      
    },
    closeAccountSelectModal() {
        $('#edit-ph').modal('hide');
    },
     resendOtp(){
      const mobile = new URLSearchParams()
       var number = this.ctry_code.slice(1) + this.phonenum
      mobile.append('phone_no', number)
      this.$store.dispatch('profile/resendOtp',mobile)
      this.error_tab = false;
      this.otplink = false;
      this.counting = true;
      this.displayotp = true;
    }
  },
   watch: {
    error_status(){
      if (this.error_status == true){
        console.log(this.errormessage)
        this.onCountdownEnd();
        this.error_tab = true;
        this.displayotp = false;
        this.otp = ""
        this.v$.$reset()
      }
      
    }
  },

}

</script>
<style scoped>
.error {
    border: 1px solid red;
  }
  .timerClass{
  color: #fb9678;
  font-weight: bold;
}
</style>
