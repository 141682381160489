<template>
	<body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <UserHeader/>
      <UserSidebar/>
        <!-- ============================================================== -->
        <!-- Topbar header - style you can find in pages.scss -->
        <!-- ============================================================== -->

        <!-- ============================================================== -->
        <!-- End Topbar header -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper">
            <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="container-fluid">
                <!-- ============================================================== -->
                <!-- Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Notifications</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link :to="{ name: 'UserHome' }">Home</router-link></li>
                                <li class="breadcrumb-item active">Notifications</li>
                            </ol>
                            <!-- <button type="button" class="btn btn-info d-none d-lg-block m-l-15"><i class="fa fa-plus-circle"></i>
                                Create New</button> -->
                        </div>
                    </div>
                </div>
                <!-- ============================================================== -->
                <!-- End Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Info box -->
                <!-- ============================================================== -->
                <div class="row notification-list">
                    <div class="col-12">
											<div v-if="notification.length <=0">
												<div class="card">
														<div class="card-body" >
																<h5 class="card-title"><span class="date_notification"></span></h5>
																<div class="media">
																		<div class="media-body">
																				No Notifications to show
																		</div>
																</div>
														</div>
												</div>
											</div>
											<div v-else>
                       <div v-for="item in notification"  :key ="item.id" >
                        <div v-if="item.read === true" class="card" style="background-color: #edf1f5;border-color: #edf1f5">
                            <div class="card-body" @click="find(item.id,item.link)">
                                <h5 class="card-title">{{item.subject}} <span class="date_notification"> {{format_date(item.date)}}</span></h5>
                                <div class="media">
                                    <div class="media-body">
                                        {{item.description}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="item.read === false" class="card">
                            <div class="card-body" @click="find(item.id,item.link)">
                                <h5 class="card-title">{{item.subject}} <span class="date_notification"> {{format_date(item.date)}}</span></h5>
                                <div class="media">
                                    <div class="media-body">
                                        {{item.description}}
                                    </div>
                                </div>
                            </div>
                        </div>
												<hr>
												</div>
                        </div>
                    </div>
                </div>
                <div class="row loading" style="display:none;margin :5px 0px 10px auto;">
                    <button class="btn btn-primary" type="button" style="background-color: #edf1f5;border-color: #edf1f5"
                        disabled>
                        <span class="spinner-grow spinner-grow-sm" style="background-color: #32c0c8;" role="status" aria-hidden="true"></span>
                        <span class="sr-only" style="position: inherit!important;">Loading...</span>
                    </button>
                </div>

                <!-- /.modal-dialog -->
            </div>
            <!-- ============================================================== -->
            <!-- End Info box -->
            <!-- ============================================================== -->

            <!-- ============================================================== -->
            <!-- Review -->
            <!-- ============================================================== -->

            <!-- ============================================================== -->
            <!-- End Review -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- Comment - chats -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- End Comment - chats -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- End Page Content -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- Right sidebar -->
            <!-- ============================================================== -->
            <!-- .right-sidebar -->

            <!-- ============================================================== -->
            <!-- End Right sidebar -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->
    <footer class="footer">
        © 2020 www.ontash.net
    </footer>
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->

</body>
</template>
<script>
// @ is an alias to /src
import UserHeader from '@/components/headers/UserHeader.vue'
import UserSidebar from '@/components/sidebars/UserSidebar.vue'
import {mapState} from 'vuex'
import moment from 'moment'
export default {
  name: 'UserHome',
  components: {
    UserHeader,
    UserSidebar
  },
  computed:{
    ...mapState('notification',['notification'])
  },
  created: function(){
      this.$store.dispatch('notification/list_notifications')
  },
  methods: {
      format_date(value){
         if (value) {
           return moment(String(value)).format('DD-MM-YYYY')
          }
      },
      find(id,link){
        this.$store.dispatch('notification/change_notification_status',id)
        this.$router.push(link);
      }
   },
}
</script>
