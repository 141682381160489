<template>
<!-- Add New Service Popup Model -->
<div id="add-contact" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="top:10%;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">Add New Flat</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="card-body">
                    <form class="form" @submit.prevent="addflat">
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <div class="col-12">
                                       <label>Flat Number</label>
                                      <input type="text" class="form-control" v-model="number" placeholder="Enter Flat Number" :class="{ error: v$.number.$error }">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <div class="col-12">
                                      <label>Area</label>
                                      <input type="number" class="form-control" v-model="area" placeholder="Enter Area" :class="{ error: v$.area.$error }">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <div class="col-12">
                                      <label>BHK</label>
                                        <select class="form-control custom-select" v-model="bhk" :class="{ error: v$.bhk.$error }">
                                            <option  >Select BHK</option>
                                            <option value="2BHK">2BHK</option>
                                            <option value="3BHK">3BHK</option>
                                            <option value="4BHK">4BHK</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <div class="col-12">
                                      <label>Interior</label>
                                        <select class="form-control custom-select" v-model="interior" :class="{ error: v$.interior.$error }">
                                            <option>Select Interior Type</option>
                                            <option value="Not-Furnished">Not-Furnished</option>
                                            <option value="Semi-Furnished">Semi-Furnished</option>
                                            <option value="Furnished">Furnished</option>

                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group row">
                                  <div class="col-12">
                                    <label>Parking</label>
                                     <input type="text" class="form-control custom-select" autocomplete="off"  v-model="ref_text" v-on:keydown="find" placeholder="Select Parking" :class="{ error: v$.ref_text.$error }">
                                     <select  class="form-control custom-select" v-model="flat_no" @change="getselectedvalue($event.target.selectedIndex)" >
                                      <option >Select Reference</option>
                                      <option v-for="item in parking_list" :value="item" :key ="item.id">{{item['name']}}</option>
                                      </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                        <button type="submit" class="btn btn-success mr-2">Submit</button>
                                        <button type="button" class="btn btn-danger mr-2" data-dismiss="modal" aria-hidden="true">Cancel</button>
                                    </div>
                                </div>
                            </div>

                        </form>
           </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {mapState,mapActions} from 'vuex'
export default {
  name: 'AddFlat',
  setup () {
    return { v$: useVuelidate() }
  },
   computed:{
    ...mapState('adminflatgroup',['parking_list'])


  },
  data(){
    return{
      bhk: 'Select BHK',
      number: '',
      area: '',
      interior: 'Select Interior Type',
      ref_text: '',
      flat_no: 'Select Reference',
      parkings: [],
      parking_id: []
    }

  },
  created(){
    this.$store.dispatch('adminflatgroup/get_non_assigned_parking')
  },
  validations () {
    return {
      bhk: { required,
       isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === 'Select BHK') return false
        else return true


         }


      },
       interior: { required,
       isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === 'Select Interior Type') return false
        else return true


         }


      },
      ref_text: {required},
      number: { required }, // Matches this.firstName
      area: { required } // Matches this.lastName
    }
  },
  methods:{

    addflat(){
     this.v$.$touch()
      if(!this.v$.$invalid) {
      this.$store.commit('spinner/showLoading', true)
      const add_flat = new URLSearchParams()
      add_flat.append('number', this.number)
      add_flat.append('area', this.area)
      add_flat.append('bhk', this.bhk)
      add_flat.append('interior', this.interior)
      add_flat.append('building_id',localStorage.building_id)
      this.parking_id.forEach((item) => {
         add_flat.append('parking_ids[]', item);
      });
      this.$store.dispatch('adminflatgroup/add_new_flat',add_flat)
      }
    },
    getselectedvalue(selectedIndex)
    {

      var selectedIdea = this.parking_list[selectedIndex-1];
      this.parkings.push(selectedIdea.name)
      this.parking_id.push(selectedIdea.id)
      this.ref_text = this.parkings
      this.flat_no = 'Select Reference'
      this.parking_list.splice(selectedIndex-1, 1)

    },
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
  .vali_text-field1{
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    font-size: .875rem;
    line-height: 1.5;
    color: #4F5467;
    vertical-align: middle;
    border: 1px solid #e9ecef;
    border-radius: .25rem;
}
.vali_text-field{
  width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    font-size: .875rem;
    line-height: 1.5;
    color: #4F5467;
    vertical-align: middle;
    border: 1px solid #e9ecef;
    border-radius: .25rem;
}
</style>
