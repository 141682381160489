<template>
  <html>
  <body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <SuperAdminHeader/>
      <SuperAdminSidebar/>
  <div class="page-wrapper">
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="row page-titles">
            <div class="col-md-5 align-self-center">
                <h4 class="text-themecolor">About Willows</h4>
            </div>
            <div class="col-md-7 align-self-center text-right">
                <div class="d-flex justify-content-end align-items-center">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><router-link :to="{ name: 'SuperAdminHome' }">Home</router-link></li>
                        <li class="breadcrumb-item active">About</li>
                    </ol>
                    <!-- <button type="button" class="btn btn-info d-none d-lg-block m-l-15"><i class="fa fa-plus-circle"></i>
                        Create New</button> -->
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Info box -->
        <!-- ============================================================== -->
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row info-icon">
                            <img src="../../assets/images/login_logo.png" alt="homepage" class="dark-logo imagesize"/>
                        </div>
                        <div class="row info-icon">
                                <h6>An app for landlord/owners/managers of building associations,
                                    cooperative apartments, and housing complexes.</h6>
                        </div>
                        <hr>
                        <div class="row contents">
                            <span>
                                    <h4>
                                            OVERVIEW
                                    </h4>
                            </span>
                        </div>
                        <div class="row contents">
                            <span>
                                    An app for people who live in flats (also building associations, cooperative apartments,
                                            housing complex with shared services) to coordinate activities such as requesting help on
                                            maintenance issues, paying association dues, getting alerts etc.

                            </span>
                        </div>
                        <div class="row contents">
                            <span>
                                <h4>
                                        FEATURES
                                </h4>
                            </span>
                        </div>
                        <div class="row contents">
                            <ul>
                                <li>
                                    <span>
                                        Open, efficient communication is critically important for tenant satisfaction as well as operations
                                        efficiency. Accessible from a secure native app as well as desktops, Willow offers managers a rules-based
                                        access hierarchy, so they get the information they need, and tenants get the information they need.
                                    </span>
                                </li>
                                <li>
                                        <span>
                                                <strong>Tenants</strong> get a clear channel for registering and tracking complaints as well as service requests.
                                                They can also make payments by credit card or paypal. </span>
                                    </li>

                                    <li>
                                            <span>
                                                    <strong>Managers</strong> can blast messaging to all tenants or communicate with individuals via text (SMS) or email,
                                                     while rent and other payments are displayed in a convenient report. Willow is hosted in the cloud,
                                                     as Software as a Service (SaaS) in an encrypted and secure fashion. </span>
                                        </li>
                                </ul>
                        </div>
                        <hr>
                        <div class="row">
                            <router-link :to="{ name: 'SuperAdminTermsAndConditions' }">
                              <a href="" style="color: #32c0c8;margin-left: 20px;">Terms And Conditions</a>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Info box -->
        <!-- ============================================================== -->

        <!-- ============================================================== -->
        <!-- Review -->
        <!-- ============================================================== -->

        <!-- ============================================================== -->
        <!-- End Review -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Comment - chats -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- End Comment - chats -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- End Page Content -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Right sidebar -->
        <!-- ============================================================== -->
        <!-- .right-sidebar -->

        <!-- ============================================================== -->
        <!-- End Right sidebar -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Page wrapper  -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- footer -->
<!-- ============================================================== -->
<footer class="footer">
    © 2020 www.ontash.net
</footer>
<!-- ============================================================== -->
<!-- End footer -->
<!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
</body>
</html>
</template>

<script>
// @ is an alias to /src
import SuperAdminHeader from '@/components/headers/SuperAdminHeader.vue'
import SuperAdminSidebar from '@/components/sidebars/SuperAdminSidebar.vue'
export default {
  name: 'SuperAdminAbout',
  components: {
    SuperAdminHeader,
    SuperAdminSidebar
  }
}
</script>

<style scoped>
.info-icon{
  justify-content: center;
}
.img-icon{
  height: 70px;
  margin-bottom: 5px;
}
.contents{
  padding: 15px 15px 0px 15px;
}

.imagesize{
  width: 350px;
}

.content ul{
  margin-right: 25px;
}
h4{
  font-weight: 500 !important;
}
strong{
  font-weight: 500 !important;
}
h6{
  font-weight: 400;
}
</style>
