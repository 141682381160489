<template>
<body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
    <AdminHeader/>
      <AdminSidebar/>
        <!-- ============================================================== -->
        <!-- Topbar header - style you can find in pages.scss -->
        <!-- ============================================================== -->

        <!-- ============================================================== -->
        <!-- End Topbar header -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper">
            <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="container-fluid">
                <!-- ============================================================== -->
                <!-- Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Members</h4>
                    </div>
                    <div class="alert alert-success signout_create banner-text" role="alert">
                        <span><strong>Success!</strong> You have successfully added a new flat</span>
                    </div>
                    <div class="alert alert-success signout_update banner-text" role="alert">
                        <span><strong>Success!</strong> You have successfully updated the flat details</span>
                    </div>
                    <div class="alert alert-danger signout_delete banner-text" role="alert">
                        <span><strong>Deleted!</strong> You have successfully deleted the flat details</span>
                    </div>
                    <div class="alert alert-success signout_create_group banner-text" role="alert">
                        <span><strong>Success!</strong> You have successfully added a new group</span>
                    </div>
                     <div class="alert alert-success signout_update_group banner-text" role="alert">
                        <span><strong>Success!</strong> You have successfully updated group details</span>
                    </div>
                    <div class="alert alert-danger  signout_delete_group banner-text" role="alert">
                        <span><strong>Deleted!</strong> You have successfully deleted group </span>
                    </div>
                     <div class="alert alert-success signout_add_member banner-text" role="alert">
                        <span><strong>Success!</strong> You have successfully added new members to the group</span>
                    </div>
                    <div class="alert alert-danger signout_delete_member banner-text" role="alert">
                        <span><strong>Removed!</strong> You have successfully removed members from the group</span>
                    </div>
                    <div class="alert alert-warning signout_group_exist banner-text" role="alert">
                        <span><strong>Warning!</strong>Group name already exists</span>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link :to="{ name: 'AdminHome' }">Home</router-link></li>
                                <li class="breadcrumb-item active">Admin</li>
                            </ol>
                            <button type="button" class="btn btn-info d-none d-lg-block m-l-15" data-toggle="modal"
                                data-target="#add-contact" @click="addmember">+ Add New Flat</button>
                        </div>
                    </div>
                </div>
                <!-- ============================================================== -->
                <!-- End Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Start Page Content -->
                <!-- ============================================================== -->
                <div class="row">
                    <div class="col-12">
                        <div class="card">

                            <!-- .left-right-aside-column-->
                            <div class="contact-page-aside">
                                <!-- .left-aside-column-->
                                <div class="left-aside bg-light-part mobscroll" style="overflow: auto;">
                                    <ul class="list-style-none">
                                          <li class="box-label"><a href="javascript:void(0)" data-toggle="modal"
                                              data-target="#myModal" @click="addgroup" style="margin-bottom:10px;" class="btn btn-info text-white">+ Create New
                                              Group</a></li>
                                          <li class="box-label" @click="list_all_appartment(1); secondFunction();"><a href="javascript:void(0)">All Appartments
                                                  <span>{{appartment_count}}</span></a></li>
                                          <li class="divider"  ></li>
                                          <div v-for="item in group_lists " :key ="item.id" class="row" style="margin-top: 15px;">
                                          <div class="col-sm-10"><a href="javascript:void(0)" style="color: #212529 !important"  @click="showmember(item.name,item.building_id,item.id)" >{{item.name}}</a></div>
                                          <div class="col-sm-2"><i class="fa fa-edit" @click="updategroup(item.id)"  aria-hidden="true" data-toggle="modal" data-target="#Update_groups" style="float: right;"><span style="font-weight: 400; font-size 14px;">{{item.group_member_count}}</span></i></div>
                                        </div>
                                    </ul>
                                </div>
                                <!-- /.left-aside-column-->
                                <div class="right-aside ">
                                    <div class="right-page-header">
                                        <div class="d-flex">
                                            <div class="align-self-center">
                                                <h4 class="card-title m-t-10">{{headval}} </h4>
                                            </div>
                                            <div><button type="button" class="btn btn-info d-none d-lg-block m-l-15" data-toggle="modal"
                                data-target="#add-member"  v-if="buttonactive == true"  @click="addnewmember">+  Add/Remove Member</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="addmem === true">
                                      <AddFlat></AddFlat>
                                    </div>
                                    <div v-if="addgrp === true">
                                      <CreateGroup></CreateGroup>
                                    </div>
                                     <div v-if="updategrp === true">
                                      <UpdateGroup :grp_id='grp_id'/>
                                    </div>
                                     <div v-if="updateflat === true">
                                      <UpdateFlat :flat_id='flat_id'/>
                                    </div>
                                     <div v-if="addnewmem === true">
                                      <AddMember :key='count'/>
                                    </div>


                                    <!-- Add Contact Popup Model -->

                                    <div class="table-responsive m-t-40">
                                        <table id="myTable" class="table table-striped" ref="tables"  v-if="tab_one == true">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Flat Name</th>
                                                    <th>Flat Request</th>
                                                    <th>Name</th>
                                                    <th>Phone</th>
                                                    <th>Joining Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                      <tr v-for="item in app_list " :key ="item.id">
                                                       <td>{{ item.id }}</td>
                                                       <td @click="update_flat(item.id)" data-toggle="modal" data-target="#update-flat">{{ item.number }}</td>
                                                       <td v-if="item.user_name !==''" ><div  v-if="item.status ==='active'">  <select @change="change_status($event,item.id)"  class="btn btn-success btn-sm dropdown-toggle" ref="select">
                                                             <option class="btn btn-success btn-sm dropdown-toggle" value="1">Active</option>
                                                             <option  class="btn btn-warning btn-sm dropdown-toggle" value="2">Pending</option>
                                                             <option  class="btn btn-danger btn-sm dropdown-toggle" value="3">Deactive</option>
                                                            </select>
                                                        </div>
                                                        <div v-else-if="item.status ==='pending'">  <select @change="change_status($event,item.id)"  ref="select" class="btn btn-warning btn-sm dropdown-toggle" >
                                                             <option   class="btn btn-warning btn-sm dropdown-toggle" value="2">Pending</option>
                                                             <option class="btn btn-success btn-sm dropdown-toggle" value="1">Active</option>
                                                             <option  class="btn btn-danger btn-sm dropdown-toggle" value="3">Deactive</option>
                                                            </select>
                                                        </div>
                                                        <div v-else-if="item.status ==='deactive'">  <select @change="change_status($event,item.id)"  ref="select" class="btn btn-danger btn-sm dropdown-toggle" >
                                                               <option  class="btn btn-danger btn-sm dropdown-toggle" value="3">Deactive</option>
                                                              <option  class="btn btn-success btn-sm dropdown-toggle" value="1">Active</option>
                                                              <option   class="btn btn-warning btn-sm dropdown-toggle" value="2">Pending</option>

                                                            </select>
                                                        </div>


                                                      </td>
                                                      <td v-else-if="item.user_name ===''"></td>
                                                       <td>{{ item.user_name }}</td>
                                                       <td>{{ item.phone }}</td>
                                                       <td>{{ format_date(item.created_at) }}</td>
                                                      </tr>
                                            </tbody>
                                        </table>

                                         <table id="myTable" class="table table-striped" ref="tables2"  v-else-if="tab_three == true">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Flat Name</th>
                                                    <th>Name</th>
                                                    <th>Phone</th>
                                                    <th>Joining Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                      <tr v-for="item in grp_list " :key ="item.id">
                                                       <td>{{ item.id }}</td>
                                                       <td @click="update_flat(item.id)" data-toggle="modal" data-target="#update-flat">{{ item.number }}</td>
                                                       <td>{{ item.user_name }}</td>
                                                       <td>{{ item.phone }}</td>
                                                       <td>{{ format_date(item.created_at) }}</td>
                                                      </tr>
                                            </tbody>
                                        </table>



                                    </div>
                                    <!-- .left-aside-column-->
                                </div>
                                <!-- /.left-right-aside-column-->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ============================================================== -->
                <!-- End PAge Content -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Right sidebar -->
                <!-- ============================================================== -->
                <!-- .right-sidebar -->
                <div class="right-sidebar">
                    <div class="slimscrollright">
                        <div class="rpanel-title"> Service Panel <span><i class="ti-close right-side-toggle"></i></span>
                        </div>
                        <div class="r-panel-body">
                            <ul id="themecolors" class="m-t-20">
                                <li><b>With Light sidebar</b></li>
                                <li><a href="javascript:void(0)" data-skin="skin-default" class="default-theme working">1</a></li>
                                <li><a href="javascript:void(0)" data-skin="skin-green" class="green-theme">2</a></li>
                                <li><a href="javascript:void(0)" data-skin="skin-red" class="red-theme">3</a></li>
                                <li><a href="javascript:void(0)" data-skin="skin-blue" class="blue-theme">4</a></li>
                                <li><a href="javascript:void(0)" data-skin="skin-purple" class="purple-theme">5</a></li>
                                <li><a href="javascript:void(0)" data-skin="skin-megna" class="megna-theme">6</a></li>
                                <li class="d-block m-t-30"><b>With Dark sidebar</b></li>
                                <li><a href="javascript:void(0)" data-skin="skin-default-dark" class="default-dark-theme ">7</a></li>
                                <li><a href="javascript:void(0)" data-skin="skin-green-dark" class="green-dark-theme">8</a></li>
                                <li><a href="javascript:void(0)" data-skin="skin-red-dark" class="red-dark-theme">9</a>
                                </li>
                                <li><a href="javascript:void(0)" data-skin="skin-blue-dark" class="blue-dark-theme">10</a></li>
                                <li><a href="javascript:void(0)" data-skin="skin-purple-dark" class="purple-dark-theme">11</a></li>
                                <li><a href="javascript:void(0)" data-skin="skin-megna-dark" class="megna-dark-theme ">12</a></li>
                            </ul>
                            <ul class="m-t-20 chatonline">
                                <li><b>Chat option</b></li>
                                <li>
                                    <a href="javascript:void(0)"><img src="assets/images/users/1.jpg" alt="user-img"
                                            class="img-circle"> <span>Varun Dhavan <small class="text-success">online</small></span></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><img src="assets/images/users/2.jpg" alt="user-img"
                                            class="img-circle"> <span>Genelia Deshmukh <small class="text-warning">Away</small></span></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><img src="assets/images/users/3.jpg" alt="user-img"
                                            class="img-circle"> <span>Ritesh Deshmukh <small class="text-danger">Busy</small></span></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><img src="assets/images/users/4.jpg" alt="user-img"
                                            class="img-circle"> <span>Arijit Sinh <small class="text-muted">Offline</small></span></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><img src="assets/images/users/5.jpg" alt="user-img"
                                            class="img-circle"> <span>Govinda Star <small class="text-success">online</small></span></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><img src="assets/images/users/6.jpg" alt="user-img"
                                            class="img-circle"> <span>John Abraham<small class="text-success">online</small></span></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><img src="assets/images/users/7.jpg" alt="user-img"
                                            class="img-circle"> <span>Hritik Roshan<small class="text-success">online</small></span></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><img src="assets/images/users/8.jpg" alt="user-img"
                                            class="img-circle"> <span>Pwandeep rajan <small class="text-success">online</small></span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!-- ============================================================== -->
                <!-- End Right sidebar -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
         <footer class="footer">
          © 2020 www.ontash.net
        </footer>

        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Wrapper -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- All Jquery -->
    <!-- ============================================================== -->
  </body>
</template>

<script>
// @ is an alias to /src
import AdminHeader from '@/components/headers/AdminHeader.vue'
import AdminSidebar from '@/components/sidebars/AdminSidebar.vue'
import AddFlat from '@/components/flat/AddFlat.vue'
import CreateGroup from '@/components/group/CreateGroup.vue'
import UpdateGroup from '@/components/group/UpdateGroup.vue'
import UpdateFlat from '@/components/flat/UpdateFlat.vue'
import AddMember from '@/components/member/AddMember.vue'
import moment from 'moment'
import {mapState,mapActions,mapMutations} from 'vuex'
export default {
  name: 'AdminUserList',
  components: {
    AdminHeader,
    AdminSidebar,
    AddFlat,
    CreateGroup,
    UpdateGroup,
    UpdateFlat,
    AddMember
  },
  data(){
      return{
       addmem: false,
       addgrp: false,
       updategrp: false,
       updateflat: false,
       grp_id: '',
       flat_id: '',
       buttonactive: false,
       headval: 'All Appartments',
       addnewmem:false,
       building_id: '',
       group_id: '',
       tab_two: false,
       tab_one: false,
       count: 0,
       tab_three: false

      }

    },
  computed:{
    ...mapState('adminflatgroup',['appartment_lists','appartment_count','app_list','dup_app_list','grp_list']),
    ...mapState('adminflatgroup',['group_lists'])


  },
  mounted(){
    this.showalerts();
    this.tab_one = true
  },

  methods:{
     ...mapMutations('adminflatgroup',['list_all_appartment']),
    addmember(){
        this.addmem = true
    },
    update_flat(flat_id){
        this.flat_id = flat_id
        this.updateflat = true

    },
    addgroup(){
        this.addgrp = true
    },
    updategroup(grpid){
        this.updategrp = true
        this.grp_id = grpid
    },
    showalerts(){
      if (localStorage.status == "created")
      {
        $('.signout_create').delay(500).slideDown(500);
        $('.signout_create').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "updated")
      {
        $('.signout_update').delay(500).slideDown(500);
        $('.signout_update').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "deleted")
      {
        $('.signout_delete').delay(500).slideDown(500);
        $('.signout_delete').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "group_created"){

        $('.signout_create_group').delay(500).slideDown(500);
        $('.signout_create_group').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "group_updated"){

        $('.signout_update_group').delay(500).slideDown(500);
        $('.signout_update_group').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
     else if (localStorage.status == "group_deleted"){

        $('.signout_delete_group').delay(500).slideDown(500);
        $('.signout_delete_group').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "member_deleted"){

         $('.signout_delete_member').delay(500).slideDown(500);
         $('.signout_delete_member').delay(4000).slideUp(500);
         localStorage.removeItem('status')
       }
       else if (localStorage.status == "member_added"){

          $('.signout_add_member').delay(500).slideDown(500);
          $('.signout_add_member').delay(4000).slideUp(500);
          localStorage.removeItem('status')
        }
        else if (localStorage.status == "group_exist"){

          $('.signout_group_exist').delay(500).slideDown(500);
          $('.signout_group_exist').delay(4000).slideUp(500);
          localStorage.removeItem('status')
        }

    },
    secondFunction(){
      this.tab_one = true
      this.tab_three = false
      this.headval = 'All Appartments'
      this.buttonactive = false
       $(this.$refs.tables).DataTable().destroy();
        $(this.$refs.tables2).DataTable().destroy();
        this.$nextTick(() => {
          $(this.$refs.tables).DataTable({
             destroy: true,
            "ordering":false
          });
        });

    },
    change_status(event,id)
    {
      const taskid = new URLSearchParams()
      taskid.append('task_id', id)
      taskid.append('page', "FlatOrGroups")
      taskid.append('building_id', localStorage.building_id)
      this.$store.dispatch('notification/change_notification_status_admin_taskid',taskid)
      const flat_id = new URLSearchParams()
      flat_id.append('id', id)
      if(event.target.value == 1)
      {
        this.$store.dispatch('adminflatgroup/activate_flat',flat_id)
      }
      else if(event.target.value == 2)
      {
        this.$store.dispatch('adminflatgroup/pending_flat',flat_id)
      }
      else if(event.target.value == 3)
      {
        this.$store.dispatch('adminflatgroup/inactivate_flat',flat_id)
      }
    },
    showmember(name,building_id,grp_id)
    {   this.tab_two = false
        this.tab_one = false
        this.tab_three = true
        this.buttonactive = true
        this.headval = name
        this.building_id = building_id
        this.group_id = grp_id
        localStorage["build_id"]= building_id
        localStorage["group_id"]= grp_id
        const grp_details = new URLSearchParams()
        grp_details.append('building_id', building_id)
        grp_details.append('group_id', grp_id)
        this.$store.dispatch('adminflatgroup/list_app_details',grp_details)
        $(this.$refs.tables).DataTable().destroy();
        $(this.$refs.tables2).DataTable().destroy();
        this.$nextTick(() => {
          $(this.$refs.tables2).DataTable({
             destroy: true,
            "ordering":false
          });
        });

    },
    addnewmember(){
     this.count += 1
     this.addnewmem = true
    },
    format_date(value){
         if (value) {
           return moment(String(value)).format('DD-MM-YYYY')
          }
    },

  },
  created: function(){
      this.$store.dispatch('adminflatgroup/list_appartment')
      this.$store.dispatch('adminflatgroup/list_group')


  },
  watch: {
     app_list() {
      $(this.$refs.tables).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.tables).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    },

     grp_list() {
      $(this.$refs.tables2).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.tables2).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    }


    }
}
</script>
<style>

</style>
