<template>
  <body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <AdminHeader/>
      <AdminSidebar/>
      <div class="page-wrapper">
          <!-- ============================================================== -->
          <!-- Container fluid  -->
          <!-- ============================================================== -->
          <div class="container-fluid">
              <!-- ============================================================== -->
              <!-- Bread crumb and right sidebar toggle -->
              <!-- ============================================================== -->
              <div class="row page-titles">
                  <div class="col-md-5 align-self-center">
                      <h4 class="text-themecolor">Services</h4>
                  </div>
                  <div class="alert alert-success signout_create banner-text" role="alert">
                      <span><strong>Success!</strong> You have successfully created a new service </span>
                  </div>
                  <div class="alert alert-success signout_update banner-text" role="alert">
                      <span><strong>Success!</strong> You have successfully updated the service details </span>
                  </div>
                  <div class="alert alert-danger signout_delete banner-text" role="alert">
                      <span><strong>Deleted!</strong> You have successfully deleted the service</span>
                  </div>
                  <div class="col-md-7 align-self-center text-right">
                      <div class="d-flex justify-content-end align-items-center">
                          <ol class="breadcrumb">
                              <li class="breadcrumb-item"><router-link :to="{ name: 'AdminHome' }">Home</router-link></li>
                              <li class="breadcrumb-item active">Services</li>
                          </ol>
                          <button type="button" class="btn btn-info d-none d-lg-block m-l-15" data-toggle="modal" data-target="#add-service" @click="loadCreateForm">
                            + Create</button>
                      </div>
                  </div>
              </div>
              <div v-if="createform === true">
                <CreateService></CreateService>
              </div>
              <!-- ============================================================== -->
              <!-- End Bread crumb and right sidebar toggle -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- Info box -->
              <!-- ============================================================== -->

              <div class="row">
                  <div class="col-12">
                      <div class="card">
                          <div class="card-body">
                            <div v-if="services.length <= 0">
                              <div style ="padding-left:15px;padding-right:15px; width:100%">
                                                <div class="alert alert-dark">
                                                    <span>No data to show</span>
                                                </div>
                              </div>
                            </div>
                            <div v-else>
                              <div class="table-responsive">
                                  <table id="myTable" class="table table-striped" ref="tables">
                                      <thead>
                                          <tr>
                                              <th>Service ID</th>
                                              <th>Service Name</th>
                                              <th>Service Charge</th>
                                              <th>Description</th>
                                              <th class="text-center">Edit</th>
                                              <th class="text-center">Delete</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr v-for="service in services" :key="service.id">
                                              <td> {{service.id}}</td>
                                              <th>{{service.name}}</th>
                                              <td>{{service.charge}}</td>
                                              <td>{{service.description}}</td>
                                              <td class="text-center">
                                                  <div class="label label-table label-info" data-toggle="modal"
                                                      data-target="#edit-service" @click="loadUpdateForm(service.id)">Edit</div>
                                              </td>
                                              <td class="text-center">
                                                  <div class="label label-table label-danger" data-toggle="modal"
                                                      data-target="#confirm-deleted" @click="loadDeleteForm(service.id)">Delete</div>
                                              </td>
                                          </tr>

                                      </tbody>
                                  </table>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div v-if="updateform === true">
                <UpdateService :service_id='service_id'/>
              </div>
              <div v-if="deleteform === true">
                <DeleteService :service_id='service_id'/>
              </div>

              <!-- ============================================================== -->
              <!-- End Info box -->
              <!-- ============================================================== -->

              <!-- ============================================================== -->
              <!-- Review -->
              <!-- ============================================================== -->

              <!-- ============================================================== -->
              <!-- End Review -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- Comment - chats -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- End Comment - chats -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- End Page Content -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- Right sidebar -->
              <!-- ============================================================== -->
              <!-- .right-sidebar -->

              <!-- ============================================================== -->
              <!-- End Right sidebar -->
              <!-- ============================================================== -->
          </div>
          <!-- ============================================================== -->
          <!-- End Container fluid  -->
          <!-- ============================================================== -->
      </div>
      <!-- ============================================================== -->
      <!-- End Page wrapper  -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- footer -->
      <!-- ============================================================== -->
      <footer class="footer">
          © 2020 www.ontash.net
      </footer>
      <!-- ============================================================== -->
      <!-- End footer -->
      <!-- ============================================================== -->
  </div>
</body>
</template>
<script>
// @ is an alias to /src
import AdminHeader from '@/components/headers/AdminHeader.vue'
import AdminSidebar from '@/components/sidebars/AdminSidebar.vue'
import CreateService from '@/components/services/CreateService.vue'
import UpdateService from '@/components/services/UpdateService.vue'
import DeleteService from '@/components/services/DeleteService.vue'
import {mapState,mapActions,mapMutations} from 'vuex'
export default {
  name: 'Adminservice',
  components: {
    AdminHeader,
    AdminSidebar,
    CreateService,
    UpdateService,
    DeleteService
  },
  computed:{
    ...mapState('service',['services']),
  },
  data(){
    return{
      createform: false,
      updateform: false,
      deleteform: false,
      service_id: ''
    }

  },
  mounted(){
    this.getServices();
    this.showalerts();
  },
  methods:{
    ...mapActions('service',['getServices']),
    loadCreateForm(){
      this.createform = true
    },
    loadUpdateForm(service_id){
      this.updateform = true
      this.service_id = service_id
    },
    loadDeleteForm(service_id){
      this.deleteform = true
      this.service_id = service_id
    },
    showalerts(){
      if (localStorage.status == "created")
      {
        $('.signout_create').delay(500).slideDown(500);
        $('.signout_create').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "updated")
      {
        $('.signout_update').delay(500).slideDown(500);
        $('.signout_update').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "deleted")
      {
        $('.signout_delete').delay(500).slideDown(500);
        $('.signout_delete').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
    }
  },
  watch: {
    services() {
      $(this.$refs.tables).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.tables).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    }
  }
}
</script>
<style scoped>
.banner-text{
  font-weight:400;
  position:absolute;
  width: 98%;
  margin-top: -5px;
  z-index: 10;
  text-align: center;
  display: none;
}
</style>
