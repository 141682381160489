import { createStore } from 'vuex'
import login from './module/login'
import wallet from './module/wallet'
import flat from './module/flat'
import home from './module/home'
import complaint from './module/complaint'
import servicerequest from './module/servicerequest'
import event from './module/event'
import directory from './module/directory'
import notification from './module/notification'
import facility from './module/facility'
import profile from './module/profile'
import group from './module/group'
import service from './module/service'
import adminflatgroup from './module/adminflatgroup'
import bank from './module/bank'
import payment from './module/payment'
import subscriptioncharge from './module/subscriptioncharge'
import report from './module/report'
import token from './module/token'
import messages from './module/messages'
import spinner from './module/spinner'
import building from './module/building'
import visitor from './module/visitor'
import reportissue from './module/reportissue'
import parking from './module/parking'

export default createStore({

  modules: {
    login,
    wallet,
    flat,
    home,
    complaint,
    servicerequest,
    event,
    directory,
    notification,
    facility,
    profile,
    group,
    service,
    adminflatgroup,
    bank,
    payment,
    subscriptioncharge,
    report,
    token,
    messages,
    spinner,
    building,
    visitor,
    reportissue,
    parking
  }
})
