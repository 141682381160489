<template>
<!-- Add New Service Popup Model -->
<div id="add-service-request" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" >
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">Create Pass</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="card-body">
                    <form class="form" @submit.prevent="create_pass">
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label for="example-text-input" class="col-4 col-form-label" style="font-weight: 500;">Visitor Name</label>
                                    <div class="col-12">
                                      <input type="text" class="form-control" v-model="visitor_name" placeholder="Enter Visitor Name" :class="{ error: v$.visitor_name.$error }">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                              <div class="form-group row">
                                  <div class="col-md-12">
                                      <label for="example-text-input" class="col-4 col-form-label" style="">Phone Number</label>
                                      <div class="row" style="margin-left: 0px; justify-content: center;">
                                      <div class="col-sm-4" style="padding: 0px;">
                                        <select class="form-control" v-model="country_code" @change="get_code($event.target.selectedIndex)" >
                                        <option v-for="data in code" selectedIndex=0>({{data.code}}){{data.dial_code}}</option>
                                       </select>
                                      </div>
                                      <div class="col-sm-8">
                                      <input type="number" v-model="phonenum" onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))"
                                          placeholder="Enter Phone Number" class="form-control form-control-line"
                                           :class="{ error: v$.phonenum.$error }">
                                    </div>
                                  </div>
                                  </div>
                              </div>
                          </div>
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label for="example-text-input" class="col-4 col-form-label" style="font-weight: 500;">Visitor Count</label>
                                    <div class="col-12">
                                      <input type="text" class="form-control" v-model="visitor_count" placeholder="Enter Visitor Count" :class="{ error: v$.visitor_count.$error }">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label for="example-text-input" class="col-4 col-form-label" style="font-weight: 500;">Purpose</label>
                                    <div class="col-12">
                                      <input type="text" class="form-control" v-model="purpose" placeholder="Enter Purpose" :class="{ error: v$.purpose.$error }">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group row">
                                     <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Date</label>
                                      <div class="col-12">
                                          <input class="form-control " type="date" v-model="visit_date" :class="{ error: v$.visit_date.$error }" ref="serviceinputdate">
                                      </div>
                                 </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 ">
                                    <div class="form-group">
                                         <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Time</label>
                                        <div class="col-12">
                                          <vue-timepicker format="h:mm a"    v-model="visit_time" :class="{ error: v$.visit_time.$error }"></vue-timepicker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0; margin-bottom: 50px;">
                                        <button type="submit" class="btn btn-success mr-2">Submit</button>
                                        <button type="button" class="btn btn-danger mr-2" data-dismiss="modal" aria-hidden="true">Cancel</button>
                                    </div>
                                </div>
                            </div>

                        </form>
           </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required, sameAs, maxLength , minLength} from '@vuelidate/validators'
import json from '@/assets/json/CountryCode.json'
import VueTimepicker from 'vue3-timepicker'
export default {
  name: 'CreateVistorPass',
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    VueTimepicker
  },
  data(){
    return{
      topic: '',
      description: '',
      visitor_name: '',
      phonenum: '',
      myJson: json,
      code: {},
      country_code: '(IN)+91',
      ctry_code : '+91',
      visitor_count: '',
      purpose: '',
      visit_date: '',
      visit_time: ''

    }

  },
   created(){

    var booked_list = {}
    console.log(this.myJson)
    booked_list= JSON.parse(JSON.stringify(this.myJson['Country']), function(key, value) {
      return value;
    });
    this.code = booked_list
  },
  mounted(){
    this.disable_date();
  },
  validations () {
    return {
      visitor_name: {required},
      phonenum: {
      required,
      maxLength: maxLength(12),
      minLength: minLength(8)
      },
      purpose: {required},
      visitor_count:  {required},
      visit_date: {required},
      visit_time: {required}
    }
  },
  methods:{
     get_code(selectedIndex)
    {
      this.ctry_code = this.code[selectedIndex].dial_code

    },
     disable_date(){
      var date = new
      Date().toISOString().slice(0,10);
      $(this.$refs.serviceinputdate).attr('min',date);
    },

    create_pass(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const pass = new URLSearchParams()
        pass.append('visitor_name', this.visitor_name)
        pass.append('phone', this.phonenum)
        pass.append('visitor_count', this.visitor_count)
        pass.append('purpose', this.purpose)
        pass.append('visit_date', this.visit_date)
        pass.append('visit_time', this.visit_time)
        pass.append('flat_id', localStorage.flat_id)
        this.$store.dispatch('visitor/createvisitor',pass)
      }
    }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
