<template>
  <body class="skin-default fixed-layout">
    <div id="main-wrapper">
     <AdminHeader/>
      <AdminSidebar/>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Admin Visitor Pass</h4>
                    </div>
                    <div class="alert alert-success signout_create banner-text" role="alert">
                      <span><strong>Success!</strong> You have successfully added a new visitor</span>
                  </div>
                    <div class="alert alert-danger signout_delete banner-text" role="alert">
                        <span><strong>Deleted!</strong> You have successfully deleted visitor details</span>
                    </div>

                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link :to="{ name: 'UserHome' }">Home</router-link></li>
                                <li class="breadcrumb-item active">Admin Visitor Pass</li>
                            </ol>
                            <button type="button" class="btn btn-info d-lg-block m-l-15" data-toggle="modal" data-target="#add-visitor-request" @click="loadCreateForm">
                  + Create Pass</button>
                        </div>
                    </div>
                </div>
                <div v-if="createform === true">
                  <CreateAdminVisitor></CreateAdminVisitor>
                </div>
                <div v-if="deletefrom == true">
                  <DeleteAdminPass :pass_id="pass_id"></DeleteAdminPass>
                </div>
                <div v-if="viewform == true">
                  <ViewAdminPass :visitor_id = "visitor_id" :qr_token="qr_token"></ViewAdminPass>
                </div>
                <div class="row">
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="card-body">
                                              <div v-if="visitors_admin.length <= 0">
                                                <div style ="padding-left:15px;padding-right:15px; width:100%">
                                                                  <div class="alert alert-dark">
                                                                      <span>No data to show</span>
                                                                  </div>
                                                </div>
                                              </div>
                                              <div v-else>
                                                <div class="table-responsive">
                                                   <table id="myTable" class="table table-striped" ref="tables">
                                                    <thead>
                                                      <tr>
                                                        <th>Visitor Name</th>
                                                        <th>Visitor Number</th>
                                                        <th>Visit Date</th>
                                                        <th>Visit Time</th>
                                                        <th>View Pass</th>
                                                        <th> Action </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr v-for="item in visitors_admin" :key ="item.id">
                                                       <td>{{ item.visitor_name }}</td>
                                                       <td>{{ item.phone }}</td>
                                                       <td>{{ format_date(item.visit_date) }}</td>
                                                       <td>{{ find_time(item.visit_time) }}</td>
                                                        <td><div class="label label-table label-info" @click="loadViewForm(item.id,item.qr_token)" data-toggle="modal" data-target="#view-pass">View Pass</div></td>
                                                       <td><div @click="deletebooking(item.id)" class="label label-table label-danger" data-toggle="modal" data-target="#confirm-delete">Delete</div></td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

            </div>
        </div>
    </div>
    <footer class="footer">
      © 2020 www.ontash.net
    </footer>
</body>
</template>
<script>
// @ is an alias to /src
import AdminHeader from '@/components/headers/AdminHeader.vue'
import AdminSidebar from '@/components/sidebars/AdminSidebar.vue'
import CreateAdminVisitor from '@/components/visitors/CreateAdminVisitor.vue'
import DeleteAdminPass from '@/components/visitors/DeleteAdminPass.vue'
import ViewAdminPass from '@/components/visitors/ViewAdminPass'
import {mapState} from 'vuex'
import moment from 'moment'
export default {
  name: 'AdminVisitorPass',
  components: {
    AdminSidebar,
    AdminHeader,
    CreateAdminVisitor,
    DeleteAdminPass,
    ViewAdminPass
  },
  computed:{
    ...mapState('visitor',['visitors_admin'])


  },
  data(){
    return{
      createform: false,
      deletefrom: false,
      pass_id: '',
      viewform: false,
      visitor_id: ''
    }
  },
  created: function(){
      this.$store.dispatch('visitor/get_visitors_list_admin')

  },
  mounted(){
    this.showalerts();
  },
  methods: {
     loadCreateForm(){

          this.createform = true

     },
      loadViewForm(visit_id,qrcode){
          this.qr_token = qrcode
          this.visitor_id = visit_id
          this.viewform = true
     },
     find_time(value){
        if (value) {
           value = value.split("T")[1]
           const parts = value.split(":");

           if (parts[0] > 12)
           {
           return +parts[0]-12 + " : " +parts[1]+' PM' ;
           }
           else if (parts[0] == 12){
           return +parts[0]+ " : " +parts[1]+' PM' ;
           }
           else
           {
             if (parts[0] == 0)
             {
               return +"12"+ " : " +parts[1]+' AM' ;
             }
             else{
               return +parts[0]+ " : " +parts[1]+' AM' ;
             }

           }
         }

     },
     showalerts(){
       if (localStorage.status == "created")
       {
         $('.signout_create').delay(500).slideDown(500);
         $('.signout_create').delay(4000).slideUp(500);
         localStorage.removeItem('status')
       }
       else if (localStorage.status == "deleted")
       {
         $('.signout_delete').delay(500).slideDown(500);
         $('.signout_delete').delay(4000).slideUp(500);
         localStorage.removeItem('status')
       }


     },
     deletebooking(id){
       this.pass_id = id
       this.deletefrom = true
     },
      format_date(value){
       if (value) {
         return moment(String(value)).format('DD-MM-YYYY')
        }
    },
  },
  watch: {
    visitors_admin() {
      $(this.$refs.tables).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.tables).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    }
    }

}
</script>
  <style>
    .banner-text{
    font-weight:400;
    position:absolute;
    width: 98%;
    margin-top: -5px;
    z-index: 10;
    text-align: center;
    display: none;
}

</style>
