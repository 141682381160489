import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import './assets/css/style.css'
import './assets/css/login.css'
import './assets/css/event.css'
import 'jquery'
import 'popper.js'
import 'bootstrap'
import '@/assets/js/perfect-scrollbar.jquery.min.js'
import '@/assets/js/datatablesjquery.min.js'
import '@/assets/js/datatables.js'
import firebaseMessaging from './firebase'

const app = createApp(App)
app.config.globalProperties.$messaging = firebaseMessaging
app.use(store).use(router).mount('#app')
