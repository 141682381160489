<template>
  <body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <AdminHeader/>
      <AdminSidebar/>
      <div class="page-wrapper">
          <!-- ============================================================== -->
          <!-- Container fluid  -->
          <!-- ============================================================== -->
          <div class="container-fluid">
              <!-- ============================================================== -->
              <!-- Bread crumb and right sidebar toggle -->
              <!-- ============================================================== -->
              <div class="row page-titles">
                  <div class="col-md-5 align-self-center">
                      <h4 class="text-themecolor">Common Facilities</h4>
                  </div>
                  <div class="alert alert-success signout_create banner-text" role="alert">
                      <span><strong>Success!</strong> You have successfully added a new facility</span>
                  </div>
                  <div class="alert alert-success signout_update banner-text" role="alert">
                      <span><strong>Success!</strong> You have successfully updated the facility details</span>
                  </div>
                  <div class="alert alert-danger signout_delete banner-text" role="alert">
                      <span><strong>Deleted!</strong> Common Facility Deleted Successfully</span>
                  </div>
                  <div class="alert alert-success status_updated banner-text" role="alert">
                      <span><strong>Success!</strong> You have successfully updated booking status </span>
                  </div>
                  <div class="alert alert-warning signout_delete banner-text" role="alert">
                      <span><strong>Warning!</strong> Common Facility Name Already Exist</span>
                  </div>
                  <div class="col-md-7 align-self-center text-right">
                      <div class="d-flex justify-content-end align-items-center">
                          <ol class="breadcrumb">
                              <li class="breadcrumb-item"><router-link :to="{ name: 'AdminHome' }">Home</router-link></li>
                              <li class="breadcrumb-item active">Common Facilities</li>
                          </ol>
                          <button type="button" class="btn btn-info d-lg-block m-l-15" data-toggle="modal" data-target="#add-area" @click="loadCreateForm">
                              + Create Facility</button>
                      </div>
                  </div>
              </div>
              <div v-if="createform === true">
                <CreateFacility></CreateFacility>
              </div>
              <!-- ============================================================== -->
              <!-- End Bread crumb and right sidebar toggle -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- Start Page Content -->
              <!-- ============================================================== -->
              <div class="row">
                  <div class="col-12">
                      <div class="card">
                          <div class="card-body">
                              <div class="row">
                                      <!-- Column -->
                                      <div class="col-md-6 col-lg-3 col-xlg-3" @click="loadTabOne">
                                          <div class="card">
                                              <div class="box label-info text-center">
                                                  <h1 class="font-light text-white">{{facilities_count}}</h1>
                                                  <h6 class="text-white">Common Areas</h6>
                                              </div>
                                          </div>
                                      </div>
                                      <!-- Column -->
                                      <div class="col-md-6 col-lg-3 col-xlg-3" @click="loadTabTwo">
                                          <div class="card">
                                              <div class="box label-success text-center">
                                                  <h1 class="font-light text-white">{{booking_requests_count}}</h1>
                                                  <h6 class="text-white">Booking Request</h6>
                                              </div>
                                          </div>
                                      </div>
                                      <!-- Column -->
                                      <div class="col-md-6 col-lg-3 col-xlg-3" @click="loadTabThree">
                                          <div class="card">
                                              <div class="box label-danger text-center">
                                                  <h1 class="font-light text-white">{{booked_facilities_count}}</h1>
                                                  <h6 class="text-white">Booked Areas</h6>
                                              </div>
                                          </div>
                                      </div>
                                      <!-- Column -->
                                  </div>
                                  <div v-if="facilities.length <= 0">
                                    <div style ="padding-left:15px;padding-right:15px; width:100%">
                                                      <div class="alert alert-dark">
                                                          <span>No data to show</span>
                                                      </div>
                                    </div>
                                  </div>
                                  <div v-else>
                              <div class="table-responsive">
                                  <table id="myTable" class="table  table-striped" ref='table1' v-if="tab_one == true">
                                          <thead>
                                              <tr>
                                                  <th>ID</th>
                                                  <th>Common Area</th>
                                                  <th>Description</th>
                                                  <th>Availability</th>
                                                  <th class="text-center">Status</th>
                                              </tr>
                                          </thead>
                                          <tbody>

                                              <tr v-for="facility in facilities" :key="facility.id">
                                                  <td>{{facility.id}}</td>
                                                  <td>{{facility.name}}</td>
                                                  <td>{{facility.description}}</td>
                                                  <td v-if="facility.available == true">
                                                      Available
                                                  </td>
                                                  <td v-else>
                                                      Not Available
                                                  </td>
                                                  <td class="text-center">
                                                    <div class="label label-table label-info edit-facility" data-toggle="modal"
                                                         data-target="#edit-area" @click="loadUpdateForm(facility.id)">Edit</div>
                                                  </td>

                                              </tr>
                                          </tbody>
                                      </table>
                                      <table id="myTable" class="table  table-striped" ref='table2' v-else-if="tab_two == true">
                                              <thead>
                                                  <tr>
                                                    <th>Id</th>
                                                    <th>FAcility Choosen</th>
                                                    <th>Request From</th>
                                                    <th>Booking Requested On</th>
                                                    <th>Booked for</th>
                                                    <th>Start Time</th>
                                                    <th>End Time</th>
                                                    <th class="text-center">Action</th>
                                                  </tr>
                                              </thead>
                                              <tbody>

                                                <tr v-for="booking_request in booking_requests" :key="booking_request.id">
                                                  <td><a href="javascript:void(0)" class="link">{{booking_request.id}}</a></td>
                                                  <td>{{booking_request.common_facilities_name}}</td>
                                                  <td>{{booking_request.user_name}}</td>
                                                  <td>{{format_date(booking_request.booking_requested_on)}}</td>
                                                  <td>{{format_date(booking_request.booked_for)}}</td>
                                                  <td>{{find_time(booking_request.start_time)}}</td>
                                                  <td>{{find_time(booking_request.end_time)}}</td>
                                                  <td class="text-center" >
                                                      <div class="label label-table label-success action-column" data-toggle="modal"
                                                          data-target="#change-status" @click="loadUpdateStatusForm(booking_request.id,accepted)">Accept</div>
                                                      <div class="label label-table label-danger action-column" data-toggle="modal"
                                                          data-target="#change-status" @click="loadUpdateStatusForm(booking_request.id,rejected)">Reject</div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                          </table>
                                          <table id="myTable" class="table  table-striped" ref='table3' v-else-if="tab_three == true">
                                                  <thead>
                                                    <tr>
                                                       <th>Id</th>
                                                       <th>User Name</th>
                                                       <th>Facility</th>
                                                       <th>Booked On</th>
                                                       <th>Start Time</th>
                                                       <th>End Time</th>
                                                   </tr>
                                                  </thead>
                                                  <tbody>

                                                    <tr v-for="booked_facility in booked_facilities" :key="booked_facility.id">
                                                      <td><a href="javascript:void(0)" class="link">{{booked_facility.id}}</a></td>
                                                      <td>{{booked_facility.user_name}}</td>
                                                      <td>{{booked_facility.common_facilities_name}}</td>
                                                      <td>{{format_date(booked_facility.booking_requested_on)}}</td>
                                                      <td>{{find_time(booked_facility.start_time)}}</td>
                                                      <td>{{find_time(booked_facility.end_time)}}</td>
                                                    </tr>
                                                  </tbody>
                                              </table>
                                  </div>
                                  </div>
                          </div>
                      </div>
                  </div>
                  <div v-if="updateform === true">
                    <UpdateFacility :facility_id='facility_id'/>
                  </div>
                  <div v-if="statusupdateform === true">
                    <UpdateStatusFacility :booking_id ='booking_id' :status ='status'/>
                  </div>
              </div>
              <!-- ============================================================== -->
              <!-- End PAge Content -->
              <!-- ============================================================== -->
          </div>
          <!-- ============================================================== -->
          <!-- End Container fluid  -->
          <!-- ============================================================== -->
      </div>
      <!-- ============================================================== -->
      <!-- End Page wrapper  -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- footer -->
      <!-- ============================================================== -->
      <footer class="footer">
          © 2020 www.ontash.net
      </footer>
      <!-- ============================================================== -->
      <!-- End footer -->
      <!-- ============================================================== -->
  </div>
  <!-- ============================================================== -->
  <!-- End Wrapper -->

</body>
</template>

<script>
// @ is an alias to /src
import AdminHeader from '@/components/headers/AdminHeader.vue'
import AdminSidebar from '@/components/sidebars/AdminSidebar.vue'
import CreateFacility from '@/components/facility/CreateFacility.vue'
import UpdateFacility from '@/components/facility/UpdateFacility.vue'
import UpdateStatusFacility from '@/components/facility/UpdateStatusFacility.vue'
import {mapState,mapActions,mapMutations} from 'vuex'
import moment from 'moment'
export default {
  name: 'AdminCommonFacilities',
  components: {
    AdminHeader,
    AdminSidebar,
    CreateFacility,
    UpdateFacility,
    UpdateStatusFacility
  },
  computed:{
    ...mapState('facility',['facilities','booking_requests',
                'booked_facilities','facilities_count',
              'booking_requests_count','booked_facilities_count'])
  },
  data(){
    return{
      createform: false,
      updateform: false,
      deleteform: false,
      statusupdateform: false,
      tab_one: false,
      tab_two: false,
      tab_three: false,
      facility_id: '',
      booking_id: '',
      status: '',
      accepted: 'accepted',
      rejected: 'rejected'
    }

  },
  mounted(){
    this.getAdminFacilities();
    this.showalerts();
    this.tab_one = true
  },
  methods:{
    ...mapActions('facility',['getAdminFacilities']),
    loadCreateForm(){
      this.createform = true
    },
    loadUpdateForm(facility_id){
      this.updateform = true
      this.facility_id = facility_id
    },

    loadUpdateStatusForm(booking_id,status){
      const taskid = new URLSearchParams()
      taskid.append('task_id', booking_id)
      taskid.append('page', "CommonFacility")
      taskid.append('building_id', localStorage.building_id)
      this.$store.dispatch('notification/change_notification_status_admin_taskid',taskid)
      this.statusupdateform = true
      this.booking_id = booking_id
      this.status = status
    },
    format_date(value){
       if (value) {
         return moment(String(value)).format('DD-MM-YYYY')
        }
    },
    find_time(value){
       if (value) {
          value = value.split("T")[1]
          const parts = value.split(":");

          if (parts[0] > 12)
          {
          return +parts[0]-12 + ": " +parts[1]+'pm' ;
          }
          else if (parts[0] == 12){
          return +parts[0]+ ": " +parts[1]+'pm' ;
          }
          else
          {
            if (parts[0] == 0)
            {
              return +"12"+ ": " +parts[1]+'am' ;
            }
            else{
              return +parts[0]+ ": " +parts[1]+'am' ;
            }

          }
        }

    },
    loadTabOne(){
      this.tab_one = true
      this.tab_two = false
      this.tab_three = false
      $(this.$refs.table2).DataTable().destroy();
      $(this.$refs.table3).DataTable().destroy();
      $(this.$refs.table1).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.table1).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    },
    loadTabTwo(){
      this.tab_two = true
      this.tab_one = false
      this.tab_three = false
      $(this.$refs.table1).DataTable().destroy();
      $(this.$refs.table3).DataTable().destroy();
      $(this.$refs.table2).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.table2).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    },
    loadTabThree(){
      this.tab_three = true
      this.tab_two = false
      this.tab_one = false
      $(this.$refs.table1).DataTable().destroy();
      $(this.$refs.table2).DataTable().destroy();
      $(this.$refs.table3).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.table3).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    },
    showalerts(){
      if (localStorage.status == "created")
      {
        $('.signout_create').delay(500).slideDown(500);
        $('.signout_create').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "updated")
      {
        $('.signout_update').delay(500).slideDown(500);
        $('.signout_update').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "deleted")
      {
        $('.signout_delete').delay(500).slideDown(500);
        $('.signout_delete').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
       else if (localStorage.status == "exist")
      {
        $('.signout_delete').delay(500).slideDown(500);
        $('.signout_delete').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }

    }
  },
  watch: {
    facilities() {
      $(this.$refs.table1).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.table1).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    },
    booking_requests() {
      $(this.$refs.table2).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.table2).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    }
  }
}
</script>
<style scoped>
.banner-text{
  font-weight:400;
  position:absolute;
  width: 98%;
  margin-top: -5px;
  z-index: 10;
  text-align: center;
  display: none;
}
</style>
