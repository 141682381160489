import axios from 'axios'
import baseurl from './axioscredentials'
axios.defaults.withCredentials = true
import router from '@/router'
import moment from 'moment'
const reportissue = {
  namespaced: true,
  state: {
   report_list: []
  },
  getters: {

  },
  mutations: {
    SET_REPORT_LIST(state,data){
      state.report_list = data.report_issue
    },

  },
  actions: {
    createAdminReportIssue({ commit,state},reportissue){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/create_admin_report_issues',reportissue,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({})
          localStorage.status = "createdissue"
        }).catch(error => console.log(error))
    },

    createUserReportIssue({ commit,state},reportissue){
     this.dispatch('token/VerifyTokenExpiry', null, {root:true})
     axios.post(baseurl+'/api/v1/create_user_report_issues',reportissue,
             {headers:{'Authorization':localStorage.token}}).then(
       res => {
         router.go({})
         localStorage.status = "createdissue"
       }).catch(error => console.log(error))
    },

    get_report_list({ commit,state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/list_report_issues',{},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
         commit('SET_REPORT_LIST',res.data)

        }).catch(error => console.log(error))
    }
  }
}
export default reportissue
