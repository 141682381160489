<template>
  <html>
  <body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <SuperAdminHeader/>
      <SuperAdminSidebar/>
 <div class="page-wrapper">
            <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="container-fluid">
                <!-- ============================================================== -->
                <!-- Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Help and Feedback</h4>
                    </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link :to="{ name: 'SuperAdminHome' }">Home</router-link></li>
                                <li class="breadcrumb-item active">Help and Feedbabck</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <!-- Add New Service Popup Model -->

                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body" style="padding: 25px 50px !important;">
                                <div class="row" style="display: contents;">
                                    <div class="row cen">
                                       <!--  <%= image_tag "default-avatar.png",:class => "img-circle",:style => "width:200px" %> -->

                                    </div>
                                    <div class="row cen">
                                        <h4 class="card-title m-t-10">Willows</h4>
                                    </div>
                                    <div class="row cen">
                                        <h5>Ontash India Calicut <br>
                                            KSITIL Special Economic Zone<br>
                                            Unit-15, 1st Floor<br>
                                            Sahya Building, Govt. Cyberpark<br>
                                            Nellikode (PO), Calicut - 673016<br>
                                            Kerala, India</h5>
                                    </div>
                                    <div class="row cen">
                                        <h5>Email: info@willows.in</h5>
                                    </div>
                                    <div class="row cen">
                                        <h5>Phone: +91 9747349415</h5>
                                    </div>
                                </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
        </div>
        <footer class="footer">
            © 2020 www.ontash.net
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
</body>
</html>

</template>

<script>
// @ is an alias to /src
import SuperAdminHeader from '@/components/headers/SuperAdminHeader.vue'
import SuperAdminSidebar from '@/components/sidebars/SuperAdminSidebar.vue'

export default {
  name: 'SuperAdminHelpAndFeedback',
  components: {
    SuperAdminHeader,
    SuperAdminSidebar
  }
}
</script>
