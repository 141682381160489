<template>
    <body class="skin-default fixed-layout">
        <div id="main-wrapper">
            <AdminHeader/>
          <AdminSidebar/>
            <div class="page-wrapper">
                <div class="container-fluid">
                    <div class="row page-titles">
                        <div class="col-md-5 align-self-center">
                            <h4 class="text-themecolor">Residents Payments</h4>
                        </div>
                        <div class="alert alert-success user_create banner-text" role="alert">
                            <span><strong>Success!</strong> You have successfully added an individual payment</span>
                        </div>
                        <div class="alert alert-success group_create banner-text" role="alert">
                            <span><strong>Success!</strong> You have successfully created a group payment</span>
                        </div>
                        <div class="col-md-7 align-self-center text-right">
                            <div class="d-flex justify-content-end align-items-center">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><router-link :to="{ name: 'AdminHome' }">Home</router-link></li>
                                    <li class="breadcrumb-item active">Residents payments</li>
                                </ol>
                                <button type="button" class="btn btn-info d-lg-block m-l-15" data-toggle="modal" @click="make_payment"
                                    data-target="#add-payments">+ Add Individual Payment</button>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                    <div class="card-body">
                                            <div class="row m-t-40" style="margin-top: 5px;margin-bottom:-15px;">
                                                <!-- Column -->

                                                <!-- Column -->
                                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                                    <div class="card">
                                                        <div class="box label-success text-center" @click="sortBystatus(1)">
                                                            <h1 class="font-light text-white">&#x20b9;{{paid_amount}}</h1>
                                                            <h6 class="text-white">Paid</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- Column -->
                                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                                    <div class="card">
                                                        <div class="box label-danger text-center" @click="sortBystatus(0)">
                                                            <h1 class="font-light text-white">&#x20b9;{{pending_amount}}</h1>
                                                            <h6 class="text-white">Pending</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- Column -->

                                                <!-- Column -->
                                            </div>

                                           <div v-if="makepayment === true">
                                             <CreateResidentPayment :active_list='active_list' />
                                           </div>
                                           <div v-if="grouppayment === true">
                                             <CreateGroupPayment :appart_list='appart_list' />
                                           </div>

                                <!-- .left-right-aside-column-->
                                <div class="contact-page-aside">
                                    <!-- .left-aside-column-->
                                    <div class="left-aside bg-light-part mobscroll" style="overflow: auto;">
                                        <ul class="list-style-none">
                                                <li class="box-label"><a href="javascript:void(0)" data-toggle="modal"
                                                    data-target="#myModal"  @click="create_group_payment" style="margin-bottom:10px;" class="btn btn-info text-white">+ Create Payment
                                                    Group</a></li>
                                             <li class="box-label"><a href="javascript:void(0)">All Payments
                                                <span>{{all_payment_count}}</span></a></li>
                                            <li class="divider"></li>

                                            <div v-for="item in payment_group_members " :key ="item.id" >
                                            <li><a href="javascript:void(0)" >{{item.group_name}}<span>{{item.member_count}}</span></a></li>
                                            <li class="divider"  ></li>
                                            </div>
                                        </ul>
                                    </div>


                                    <div class="right-aside ">
                                        <div class="right-page-header">
                                            <div class="d-flex">
                                                <div class="align-self-center">
                                                    <h4 class="card-title m-t-10">

                                                    </h4>
                                                </div>
                                                <!-- <div>
                                                    <button type="button" class="btn btn-info d-none d-lg-block m-l-15"
                                                        data-toggle="modal" data-target="#add-member" style="display: block !important;">+
                                                        Add New Member</button>
                                                </div> -->

                                            </div>
                                        </div>
                                        <div id="add-member" class="modal fade in" tabindex="-1" role="dialog"
                                            aria-labelledby="myModalLabel" aria-hidden="true">
                                          <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h4 class="modal-title" id="myModalLabel">Add Members to this group</h4>
                                                        <button type="button" class="close" data-dismiss="modal"
                                                            aria-hidden="true">×</button>
                                                    </div>
                                                    <div class="card-body" style="padding-top: 0px;">
                                            <div class="card" style="margin-bottom: 0px;">
                                                <ul class="nav nav-tabs customtab" role="tablist">
                                                    <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#userrequest" role="tab" aria-selected="true"><span class="hidden-sm-up"></span> <span class="hidden-xs-down">Add a Member</span></a> </li>
                                                    <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#grouprequest" role="tab" aria-selected="false"><span class="hidden-sm-up"><span class="hidden-xs-down">Remove Member</span></span></a> </li>
                                                </ul>
                                                <!-- Tab panes -->

                                                <input id="selected" name="group_id" type="hidden" value="<%=@key.to_i%>"/>
                                                <div class="tab-content" style="padding: 10px 14px;">
                                                    <div class="tab-pane active" id="userrequest" role="tabpanel">
                                                            <div class="table-responsive m-t20">
                                                                     <table id="" class="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Flat</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                  <tr>
                                                                  </tr>
                                                                </tbody>
                                                            </table>
                                                                </div>
                                                                <hr>
                                                    <div class="col-sm-12" style="display: contents !important;">
                                                        <div class="form-group">
                                                          <div class="alert alert-danger pass1" style="display: none;"><span>Please Check atleast One</span></div>
                                                            <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0;float:right;margin-right: 0px !important;">

                                                                <button type="submit" data-dismiss="modal" class="btn btn-danger mr-2">Cancel</button>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    </div>
                                                    <div class="tab-pane" id="grouprequest" role="tabpanel">

                                                         <input id="selected" name="payment_group_id" type="hidden" value="<%=@button_val.to_i%>"/>
                                                            <div class="col-sm-12">
                                                                    <div class="form-group row">
                                                                        <label for="example-text-input" class="col-12 col-form-label" style="font-weight: 500;"></label>
                                                                         <div class="table-responsive m-t20">
                                                                     <table id="" class="table table-striped ">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Flat</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                  <tr>

                                                                </tr>


                                                                </tbody>

                                                            </table>

                                                                </div>


                                                                    </div>
                                                                    <hr>
                                                                    <div class="form-group">
                                                                      <div class="alert alert-danger pass2" style="display: none;"><span>Please Check atleast One</span></div>
                                                            <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0;float:right;margin-right: 0px !important;">

                                                                <button type="submit" data-dismiss="modal" class="btn btn-danger mr-2">Cancel</button>
                                                            </div>
                                                        </div>
                                                                </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                     </div>
                                     <!-- /.modal-content -->
                                  </div>
                                  <!-- /.modal-dialog -->
                               </div>

                                <!-- Add individual payment Popup Model -->
                                <div v-if="payment_list.length <= 0">
                                  <div style ="padding-left:15px;padding-right:15px; width:100%">
                                                    <div class="alert alert-dark">
                                                        <span>No data to show</span>
                                                    </div>
                                  </div>
                                </div>
                                <div v-else>
                                  <div class="table-responsive">
                                    <table id="myTable" class="table  table-striped" ref="tables">
                                        <thead>
                                            <tr>
                                                    <th>Invoice</th>
                                                    <th>Flat</th>
                                                    <th>Payment Date</th>
                                                    <th>Description</th>
                                                    <th>Amount</th>
                                                    <th>Status</th>s
                                            </tr>
                                        </thead>
                                        <tbody>
                                             <tr v-for="item in payment_list " :key ="item.id">
                                                <td><span class="viewmore" data-toggle="modal"
                                                data-target="#view_details"  @click = "update_details">{{item.id}}</span></td>
                                                <td><span class="text-muted">{{item.appartment_number}}</span></td>
                                                <td ><span>{{format_date(item.created_at)}}</span></td>
                                                <td>{{item.topic}}</td>
                                                <td>{{format_amount(item.amount)}}</td>
                                                <td><div  v-if="item.status === true">
                                                       <div class="label label-table label-success">Paid</div></div>
                                                    <div v-else-if="item.status === false">
                                                       <div class="label label-table label-danger">Pending</div>
                                                    </div>

                                                </td>


                                            </tr>
                                        </tbody>
                                    </table>
                                  </div>
                                </div>



                                    </div>
                                    <!-- /.left-right-aside-column-->
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <!-- ============================================================== -->
                    <!-- End PAge Content -->
                    <!-- ============================================================== -->
                    <!-- ============================================================== -->
                    <!-- Right sidebar -->
                    <!-- ============================================================== -->
                    <!-- .right-sidebar -->

                    <div id="edit-contact" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title" id="myModalLabel">Edit User</h4>
                                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                                </div>
                                <div class="modal-body">
                                    <form class="form-horizontal form-material">
                                        <div class="form-group">
                                            <div class="col-md-12 m-b-20">
                                                <input type="text" value="Narayanan" class="form-control" placeholder="Type name">
                                            </div>
                                            <div class="col-md-12 m-b-20">
                                                <input type="text" value="narayanan@gmail.com" class="form-control"
                                                    placeholder="Email">
                                            </div>
                                            <div class="col-md-12 m-b-20">
                                                <input type="text" value="+91 9745182816" class="form-control" placeholder="Phone">
                                            </div>
                                            <div class="col-md-12 m-b-20">
                                                <input type="text" value="26" class="form-control" placeholder="Age">
                                            </div>
                                            <div class="col-md-12 m-b-20">
                                                <input type="text" value="03/03/2020" class="form-control" placeholder="Date of joining">
                                            </div>
                                            <div class="col-md-12 m-b-20">
                                                <input type="text" value="Narayanaalayam Nivas" class="form-control"
                                                    placeholder="Address">
                                            </div>
                                            <div class="col-md-12 m-b-20">
                                                <div class="fileupload btn btn-danger btn-rounded waves-effect waves-light">
                                                    <span><i class="ion-upload m-r-5"></i>Update
                                                        Contact
                                                        Image</span>
                                                    <input type="file" class="upload"> </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-info waves-effect" data-dismiss="modal">Update</button>
                                    <button type="button" class="btn btn-default waves-effect" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                            <!-- /.modal-content -->
                        </div>
                        <!-- /.modal-dialog -->
                    </div>
                    <!-- ============================================================== -->
                    <!-- End Right sidebar -->
                    <!-- ============================================================== -->
                </div>
            </div>
              <footer class="footer">
      © 2020 www.ontash.net
  </footer>

</div>

        </body>
</template>

<script>
// @ is an alias to /src
import AdminHeader from '@/components/headers/AdminHeader.vue'
import AdminSidebar from '@/components/sidebars/AdminSidebar.vue'
import CreateResidentPayment from '@/components/payment/CreateResidentPayment.vue'
import UpdatePaymentDetails from '@/components/payment/UpdatePaymentDetails.vue'
import ShowPaymentDetails from '@/components/payment/ShowPaymentDetails.vue'
import CreateGroupPayment from '@/components/payment/CreateGroupPayment.vue'
import {mapState,mapActions,mapMutations} from 'vuex'
import moment from 'moment'
export default {
  name: 'AdminPayment',
  components: {
    AdminHeader,
    AdminSidebar,
    CreateResidentPayment,
    UpdatePaymentDetails,
    ShowPaymentDetails,
    CreateGroupPayment
  },
   data(){
       return{
        makepayment: false,
        viewpayment: false,
        showpayment: false,
        grouppayment: false,
        active_list: '',
        appart_list: ''

       }

   },
   computed:{
     ...mapState('payment',['paid_amount','pending_amount','all_payments','paid_payments','pending_payments','payment_groups','all_payment_count','active_flats','appartment_user','payment_group_members','payment_list']),


   },
   created(){
        this.$store.dispatch('payment/residents_payment')
        const taskid = new URLSearchParams()
        taskid.append('page', "ResidencePayments")
        taskid.append('building_id', localStorage.building_id)
        this.$store.dispatch('notification/change_notification_status_admin_taskid',taskid)
        this.showalerts();
      },
   methods: {
    ...mapMutations('payment',['sortBystatus']),
   	make_payment()
   	{

       this.active_list = this.active_flats
       this.makepayment = true

   	},
   	update_details(){
   		this.viewpayment = true
   	},
   	show_details() {
   		this.showpayment = true
   	},
    create_group_payment(){
        this.appart_list = this.appartment_user
       this.grouppayment = true
    },
    format_date(value){
       if (value) {
         return moment(String(value)).format('DD-MM-YYYY')
        }
    },
    format_amount(amt){
      if (amt) {
        return amt.toFixed(2)
       }
    },
    list_paid(){
    },
    showalerts(){
      if (localStorage.status == "user")
      {
        $('.user_create').delay(500).slideDown(500);
        $('.user_create').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if(localStorage.status == "group"){
        $('.group_create').delay(500).slideDown(500);
        $('.group_create').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
    }

   },
   watch: {
     payment_list() {
      $(this.$refs.tables).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.tables).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    }
 }

}
</script>
<style scoped>
.banner-text{
  font-weight:400;
  position:absolute;
  width: 98%;
  margin-top: -5px;
  z-index: 10;
  text-align: center;
  display: none;
}
</style>
