import axios from 'axios'
import baseurl from './axioscredentials'
axios.defaults.withCredentials = true
import router from '@/router'
const token = {
  namespaced: true,
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {
    VerifyTokenExpiry(){
      var datetime_now = new Date();
      var accessexpiry = new Date(localStorage.access_expiry);
      var refreshexpiry = new Date(localStorage.refresh_expiry);
      if(refreshexpiry <= datetime_now){
        axios.post(baseurl+'/logout',{},
                {headers:{'Authorization':localStorage.token}}).then(
          res => {
            localStorage.clear();
            router.push({ name: 'Login'})
          }).catch(error => console.log(error))
      }
      else{
        if(accessexpiry <= datetime_now){
          axios.post(baseurl+'/refresh',{},
                  {headers:{'Authorization':localStorage.token}}).then(
            res => {
              localStorage["token"]=res.data.token.access
              localStorage["access_expiry"]=res.data.token.access_expires_at
            }).catch(error => console.log(error))
        }
      }
    },
    savetoken({ commit, state}){
      if( localStorage.firebase_token){
      axios.post(baseurl+'/add_firebase_token',{user_id: localStorage.user_id,devise_type: "web",devise_token: localStorage.firebase_token},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
        }).catch(error => console.log(error))

      }
    },
  }
}
export default token
