<template>
  <div id="update-events" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                    aria-hidden="true" style="top:1%;">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="myModalLabel">Update Event</h4>
                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                        <form class="form" @submit.prevent="update_event">
                        <div class="card-body" style="padding: 10px 50px;">
                                     <div class="row" style="justify-content: center;">
                                    <div class="col-md-12 ">
                                    <div class="form-group">
                                        <label>Event ID</label>
                                        <input type="text" class="form-control" v-model="eventid" readonly>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 ">
                                    <div class="form-group">
                                        <label>Event Name</label>
                                        <input type="text" class="form-control" v-model="eventname" :class="{ error: v$.eventname.$error }" placeholder="Enter Event Name">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 ">
                                    <div class="form-group">
                                        <label>Organizer</label>
                                        <input type="text" class="form-control" v-model="org" :class="{ error: v$.org.$error }" placeholder="Enter Organizer">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 ">
                                    <div class="form-group">
                                        <label>Venue</label>
                                        <input type="text" class="form-control" v-model="ven" :class="{ error: v$.ven.$error }" placeholder="Enter Venue" >
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 ">
                                    <div class="form-group">
                                        <label>Description</label>
                                        <textarea class="form-control" rows="3" v-model="desc" :class="{ error: v$.desc.$error }" placeholder="Enter Description" ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class='col-md-12'>
                                    <div class="form-group">
                                        <label>Start Time</label>
                                        <div class="input-group ">
                                           <input class="form-control edit-date" type="date" v-model="start_date" :class="{ error: v$.start_date.$error }" ref="eventinputdate1">
                                           <vue-timepicker format="h:mm a" v-model="start_time" :class="{ error: v$.start_time.$error }"></vue-timepicker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class='col-md-12'>
                                    <div class="form-group">
                                        <label>End Time</label>
                                        <div class="input-group">
                                           <input class="form-control edit-date" type="date" v-model="end_date" :class="{ error: v$.end_date.$error }" ref="eventinputdate2">
                                           <vue-timepicker format="h:mm a" v-model="end_time" :class="{ error: v$.end_time.$error }"></vue-timepicker>
                                        </div>
                                    </div>
                                </div>
                            
                        </div>
                         <hr>
                          <div class="col-sm-12">
                            <div class="form-group">
                           
                            <button type="submit" class="btn btn-info label-success" id="save">Update</button>
                            <button type="button"  @click="delete_event"  class="btn btn-info waves-effect" data-dismiss="modal" data-toggle="modal" data-target="#delete-event" style="background-color: #e46a76;border-color: white;">Delete Event</button>
                             <button type="button" class="btn btn-info waves-effect" data-dismiss="modal" style="border-color: white;">Cancel</button>
                            
                        </div>
                      </div>
                    </div>
                      </form>
                    </div>
                </div>
            </div>
             <div v-if="del_event === true">
                   <DeleteEvent :e_id='e_ids'/>
                </div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import DeleteEvent from '@/components/events/DeleteEvent.vue'
import Datepicker from 'vue3-datepicker'
import VueTimepicker from 'vue3-timepicker'
import 'vue3-timepicker/dist/VueTimepicker.css'
import { parseISO } from 'date-fns'
import { ref } from 'vue'
import moment from 'moment'
export default {
  name: 'UpdateEvent',
    components: {
    DeleteEvent,
    Datepicker,
    VueTimepicker

  },
     setup () {
    return { v$: useVuelidate() }
  },
  data(){
      return{
       del_event: false,
        e_ids: '',


      }

    },

  props:['e_id'],

  mounted(){
    this.get_event();
    this.disable_date();
  },

   computed:{
    eventid: {
      get () {
        return this.$store.state.event.event_id
      },
    },
    eventname: {
      get () {
        return this.$store.state.event.event_name
      },
      set (value) {
        this.$store.commit('event/updatename', value)
      }
    },
    org: {
      get () {
        return this.$store.state.event.organizer
      },
      set (value) {
        this.$store.commit('event/updateorganizer', value)
      }
    },
    ven: {
      get () {
        return this.$store.state.event.venue
      },
      set (value) {
        this.$store.commit('event/updatevenue', value)
      }
    },
    desc: {
      get () {
        return this.$store.state.event.description
      },
      set (value) {
        this.$store.commit('event/updatedescription', value)
      }
    },
    start_date: {
      get () {
        return this.$store.state.event.start_at
      },
      set (value) {
        this.$store.commit('event/updatestart_at', value)
      }
    },
    start_time: {
      get () {
        return this.$store.state.event.start_time

      },
      set (value) {
        this.$store.commit('event/updatestart_time', value)
      }
    },
    end_date: {
      get () {
       return this.$store.state.event.end_at
      },
      set (value) {
        this.$store.commit('event/updateend_at', value)
      }
    },
    end_time: {
      get () {
        return this.$store.state.event.end_time
      },
      set (value) {
        this.$store.commit('event/updateend_time', value)
      }
    },
  },
  validations () {
    return {
      eventname: { required },
      org: { required },
      ven: { required } ,
      desc: { required },
      start_date: { required },
      start_time: { required },
      end_date: { required },
      end_time: { required },
    }
  },
  methods: {
    get_event(){
        const event_id = new URLSearchParams()
        event_id.append('id', this.e_id)
        this.$store.dispatch('event/get_event_on_id',event_id)
    },
     format_date(value){
         if (value) {
           return moment(String(value)).format('DD-MM-YYYY')
          }
    },
    disable_date(){
      var date = new
      Date().toISOString().slice(0,10);
      $(this.$refs.eventinputdate1).attr('min',date);
      $(this.$refs.eventinputdate2).attr('min',date);
    },
    update_event(){
       this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        var dbs = this.format_date(this.start_date) + ' ' + this.start_time
        var dbe = this.format_date(this.end_date) + ' ' + this.end_time
        const event = new URLSearchParams()
        event.append('eventid', this.eventid)
        event.append('eventname', this.eventname)
        event.append('org', this.org)
        event.append('ven', this.ven)
        event.append('desc', this.desc)
        event.append('startat', dbs)
        event.append('endat', dbe)
        this.$store.dispatch('event/edit_event',event)
      }

    },
    delete_event(){
      this.del_event = true
      this.e_ids = this.eventid

    },
     closeModal(){
      this.buttonactive = false
    },
  },
  watch:{
    e_id(){
      this.get_event();
    }
  }

}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
