import baseurl from './axioscredentials'
import axios from 'axios'
axios.defaults.withCredentials = true
import router from '@/router'
const flat = {
  namespaced: true,
  state: {
    otp: '',
    appartmentlist: [],
    appartmentstatus: ''
  },
  getters: {

  },
  mutations: {
    GET_APPARTMENT_CHECKOUT(state,data){
      state.appartmentlist = data.appartments
    },
    GET_APPARTMENT_STATUS(state,data){
      state.appartmentstatus = data.appartment_status
    },
    Get_apartment(state,data){
     var appartment_list = {}
     appartment_list = JSON.parse(JSON.stringify(data['available_appartments']), function(key, value) {
       return value;
     });
     var resArr = [];
     appartment_list.forEach(function(item){
       var i = resArr.findIndex(x => x.building_id == item.building_id);
       if(i <= -1){
         resArr.push({id: item.id, building_id: item.building_id,building_name: item.building_name});
       }
     });
     state.building_id = resArr
     state.flat_id = appartment_list

    },
      Get_apartment_login(state,data){
     var appartment_list = {}

     appartment_list = JSON.parse(JSON.stringify(data['appartment_details']), function(key, value) {
       return value;
     });
     var resArr = [];
     appartment_list.forEach(function(item){
       var i = resArr.findIndex(x => x.building_id == item.building_id);
       if(i <= -1){
         resArr.push({building_id: item.building_id,building_name: item.building_name});
       }
     });
     state.building_id = resArr
     state.flat_login_id = appartment_list

    },
    Get_apartment_details(state,data){
     var appartment_list = {}
     appartment_list = JSON.parse(JSON.stringify(data['available_appartments']), function(key, value) {
       return value;
     });
     var resArr = [];
     appartment_list.forEach(function(item){
       var i = resArr.findIndex(x => x.building_id == item.building_id);
       if(i <= -1){
         resArr.push({id: item.id, building_id: item.building_id,building_name: item.building_name});
       }
     });
     state.building_id = resArr
     state.flat_id = appartment_list

    },
    get_login(state,data){
      localStorage.flat_name = data.appartment.number
      localStorage.building_name = data.building.name
      localStorage["user_name"]=data.user.name
      localStorage.flat_id = data.appartment.id
      localStorage.building_id = data.building.id
    },
  },
  actions: {

    addflat_req({ commit, state},flat_no){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/add_flat_request',flat_no,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
        if (res.data.notice == "Flat allocated"){
          router.push({ name: 'AddFlat'})
          localStorage.status = "Flat_allocated"
        }
        else{
        commit('get_login',res.data)
        this.dispatch('login/addLoginTrack', null, {root:true})
        router.push({ name: 'UserHome'})
        }

      }).catch(error => console.log(error))
    },
    get_user_details({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})

      axios.post(baseurl+'/api/v1/get_user_details',{user_id: localStorage.user_id},
              {headers:{'Authorization':localStorage.token}}).then(
      res => {

        commit('Get_apartment',res.data)
      }).catch(error => console.log(error))
    },
    get_details({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})

      axios.post(baseurl+'/api/v1/get_available_appartments',{user_id: localStorage.user_id},
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
        commit('Get_apartment_details',res.data)
      }).catch(error => console.log(error))
    },
    get_login_details({commit,state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})

      axios.post(baseurl+'/api/v1/get_login_details',{user_id: localStorage.user_id},
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
         commit('Get_apartment_login',res.data)
      }).catch(error => console.log(error))
    },
    get_appartment_details_checkout({commit,state},appartment){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_appartment_details_for_checkout',appartment,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
         commit('GET_APPARTMENT_CHECKOUT',res.data)
      }).catch(error => console.log(error))
    },
    getAppartmentUserStatus({commit,state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_appartment_user_status',{flat_id: localStorage.flat_id},
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
         commit('GET_APPARTMENT_STATUS',res.data)
      }).catch(error => console.log(error))
    }
  }
}
export default flat
