<template>
<!-- Add New Service Popup Model -->
<div id="update-flat" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="top:10%;">
   <div class="modal-dialog">
      <div class="modal-content">
         <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel">Appartment Information</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" @click="closeModal()">×</button>
         </div>
         <div class="card-body">
            <h4 class="fonthead" style="float: right;">
              <i class="fa fa-edit" aria-hidden="true"  @click="display_edit" style="color: black;"></i>
                &nbsp;
              <i class="fa fa-trash " style="color: red;" @click="delete_pop" data-toggle="modal" data-target="#verticalcenter" data-dismiss="modal"></i>
            </h4>
            <div class="row nesthead" style="padding:0px 40px;">
               <h4 class="fonthead">
                  <i class="fa fas fa-caret-right fa-lg" aria-hidden="true"></i>
                  <span class="group-head">Flat Details</span>
               </h4>
            </div>
            <div class="row" style="padding:7px 60px;display:flex;">
               <div class="col-6">
                  <label>Flat Number: </label>
                  <input type="text" class="form-control flatNo No" v-model="number" placeholder="Enter Flat Number" :class="{ error: v$.number.$error }"  :disabled="id_categoria === 1">
               </div>
               <div class="col-6">
                  <label>Area: </label>
                  <label class="Area"></label>
                  <input type="text" class="form-control flatArea Area" v-model="area"  placeholder="Enter Area" :class="{ error: v$.area.$error }" :disabled="id_categoria === 1">
               </div>
               <div class="col-6">
                  <label>BHK: </label>
                  <!-- <input type="text" class="form-control " value="" disabled> -->
                  <select class="form-control custom-select flatBHK BHK" v-model="bhk"  :class="{ error: v$.bhk.$error }" autocomplete="off" :disabled="id_categoria === 1">
                     <option value="2BHK">2BHK</option>
                     <option value="3BHK">3BHK</option>
                     <option value="4BHK">4BHK</option>
                  </select>
               </div>
               <div class="col-6">
                  <label>Interior: </label>
                  <select class="form-control custom-select flatInt Int" v-model="interior" :class="{ error: v$.interior.$error }" autocomplete="off" :disabled="id_categoria === 1"  >
                     <option value="Not-Furnished">Not-Furnished</option>
                     <option value="Semi-Furnished">Semi-Furnished</option>
                     <option value="Furnished">Furnished</option>
                  </select>
               </div>
               <div class="col-6">
                 <label>Parking</label>
                  <input type="text" class="form-control custom-select" autocomplete="off"  :disabled="id_categoria === 1"  v-model="ref_text" placeholder="Select Parking" >
                   <select  :disabled="id_categoria === 1" class="form-control custom-select" v-model="flat_no" @change="getselectedvalue($event.target.selectedIndex)" >
                   <option >Select Reference</option>
                   <option v-for="item in val" :value="item" :key ="item.id">{{item['name']}}</option>
                  </select>
               </div>
            </div>
            <button type="button" v-if="buttonactive == true"  @click="updateflat" class="btn btn-info flatupdate" style="float: right;">Update Flat</button>
         </div>
         <div class="card-body" id="set_user_profile">
            <div class="row nesthead" style="padding:0px 40px;">
               <h4 class="fonthead">
                  <i class="fa fas fa-caret-right fa-lg" aria-hidden="true"></i>
                  <span class="group-head">User Details</span>
               </h4>
            </div>
            <div class="row" style="padding:7px 60px;display:flex;">
               <div class="col-6">
                  <label>User Name: </label>
                  <label class="Name"> {{username}}</label>
               </div>
               <div class="col-6">
                  <label>Phone: </label>
                  <label class="Phone"> {{phone}}</label>
               </div>
               <div class="col-6">
                  <label>Joining Date: </label>
                  <label class="Joining">{{format_date(joining_date)}}</label>
               </div>
               <div class="col-6">
                  <label>Address: </label>
                  <label class="Address"></label>
               </div>
            </div>
            <div class="modal-footer">
               <button type="button" class="btn btn-danger mr-2" data-dismiss="modal">Cancel</button>
            </div>
         </div>
         <div class="card-body" id ="set_user_body" style="display: none;">
            <div class="row nesthead" style="padding:0px 40px;">
               <h4 class="fonthead">
                  <i class="fa fas fa-caret-right fa-lg" aria-hidden="true"></i>
                  <span class="group-head">Users</span>
               </h4>
            </div>
            <div class="row" style="padding:7px 60px;display:flex;">
               <div class="col-">
                  <label>Search User</label>
                  <div class="col-md-12 m-b-20">
                     <input type="text" class="form-control" id="myInput" name="number" placeholder="Serarch user">
                  </div>
               </div>
            </div>
            <div class="modal-footer">
               <button class="btn btn-success mr-2">Save</button>
               <button type="button" class="btn btn-danger mr-2" data-dismiss="modal">Cancel</button>
            </div>
         </div>
         <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
      <!-- /.modal-dialog -->
   </div>
</div>
<div v-if="deleteactive == true">
  <DeleteFlat :flat_id="flat_id"></DeleteFlat>
</div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import DeleteFlat from '@/components/flat/DeleteFlat.vue'
import moment from 'moment'
import {mapState,mapActions} from 'vuex'
export default {
  name: 'UpdateFlat',
   components: {
    DeleteFlat
  },
   setup () {
    return { v$: useVuelidate() }
  },
  data(){
    return{
      complaint_status: this.status_id,
      buttonactive: false,
      id_categoria: 1,
      deleteactive: false,
      flat_no: 'Select Reference',
      parkings: [],
      parking_id: []
    }
  },
   props:['flat_id'],
  created(){
    this.getappart();
    this.$store.dispatch('adminflatgroup/get_non_assigned_parking')
  },
   computed:{
     ...mapState('adminflatgroup',['parking_list','parking_array','parking_id_list']),

    number: {
      get () {
        return this.$store.state.adminflatgroup.appartment_num
      },
      set (value) {
        this.$store.commit('adminflatgroup/updateAppartmentnum', value)
      }
    },
    area: {
      get () {
        return this.$store.state.adminflatgroup.area
      },
      set (value) {
        this.$store.commit('adminflatgroup/updateArea', value)
      }
    },
    interior: {
      get () {
        return this.$store.state.adminflatgroup.interior
      },
      set (value) {
        this.$store.commit('adminflatgroup/updateInterior', value)
      }
    },
    bhk: {
      get () {
        return this.$store.state.adminflatgroup.bhk
      },
      set (value) {
        this.$store.commit('adminflatgroup/updateBHK', value)
      }
    },
    username: {
      get () {
        return this.$store.state.adminflatgroup.username
      },

    },
    phone: {
      get () {
        return this.$store.state.adminflatgroup.phone
      },

    },
    joining_date: {
      get () {
        return this.$store.state.adminflatgroup.joining_date
      },

    },
    ref_text: {
      get () {
        return this.$store.state.adminflatgroup.parking_array
      },
      set (value) {
        this.$store.commit('adminflatgroup/update_ref_text', value)
      }

    },
    joining_date: {
      get () {
        return this.$store.state.adminflatgroup.joining_date
      },

    },
    val: {
     get () {
        return this.$store.state.adminflatgroup.parking_list
      },
    }
    

  },
   validations () {
    return {
      number: { required }, // Matches this.firstName
      area: { required } ,// Matches this.lastName
      interior: { required }, // Matches this.firstName
      bhk: { required }
    }
  },
   methods:{
    format_date(value){
         if (value) {
           return moment(String(value)).format('DD-MM-YYYY')
          }
    },
    display_edit(){
     this.buttonactive = true
      this.id_categoria = 0
    },
    getappart(){
       const appart_id = new URLSearchParams()
       appart_id.append('id', this.flat_id)
       this.$store.dispatch('adminflatgroup/get_appartment_details',appart_id)
    },
     getselectedvalue(selectedIndex)
    {

      var selectedIdea = this.parking_list[selectedIndex-1];
      this.parkings.push(selectedIdea.name)
      this.parking_id_list.push(selectedIdea.id)
      this.flat_no = 'Select Reference'
      this.ref_text.push(selectedIdea.name)
      this.parking_list.splice(selectedIndex-1, 1)

    },
    updateflat(){
         this.v$.$touch()
      if(!this.v$.$invalid) {
       this.$store.commit('spinner/showLoading', true)
       const appartment = new URLSearchParams()
       appartment.append('id', this.flat_id)
       appartment.append('No', this.number)
       appartment.append('area', this.area)
       appartment.append('bhk', this.bhk)
       appartment.append('inte', this.interior)
       appartment.append('building_id',localStorage.building_id)
       this.parking_id_list.forEach((item) => {
                 appartment.append('parking_ids[]', item);
              });
       this.$store.dispatch('adminflatgroup/update_appartment',appartment)
     }
    },
    closeModal(){
      this.buttonactive = false
    },
    delete_pop(){
      this.deleteactive = true
    }
  },
  watch:{
    flat_id(){
      this.getappart();
    }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
