<template>
<div id="change-status-servrequest" class="modal" tabindex="-1" role="dialog" aria-labelledby="vcenter"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="vcenter">Change Status</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body" style="padding-left: 30px;">
                            <div class="row">
                                <div class="col-6">
                                    <label>Appartment/Group</label>
                                </div>
                                <div class="col-6">
                                    <label>{{ flat_group_number }}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label>Service Name</label>
                                </div>
                                <div class="col-6">
                                    <label>{{ service_name }}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label>Service Charge</label>
                                </div>
                                <div class="col-6">
                                  <input type="text" :disabled="charge == false" class="form-control" v-model="service_charge" placeholder="" :class="{ error: v$.service_charge.$error }" >
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label>Service Date</label>
                                </div>
                                <div class="col-6">
                                    <label>{{ service_date }}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label>Service Time</label>
                                </div>
                                <div class="col-6">
                                    <label>{{ service_time }}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label>Description</label>
                                </div>
                                <div class="col-6">
                                    <label>{{ description }}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label>Status</label>
                                </div>
                                <div class="col-6">
                                  <select class="form-control custom-select" v-model="status" @change="activeEditAmount">
                                    <option class="status_new">New</option>
                                    <option>Pending</option>
                                    <option>Complete</option>
                                    <option>Closed</option>
                                  </select>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success mr-2" @click="updateAdminStatus">Update Status</button>
                <button type="button" class="btn btn-danger mr-2" data-dismiss="modal">Cancel</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {mapState} from 'vuex'
export default {
  name: 'AdminUpdateServiceStatus',
  components: {

  },
  setup () {
    return { v$: useVuelidate() }
  },
  props:['servicerequest_id','flat_group_number'],
  data(){
    return{

      charge: false,
      deleteactive: false
    }
  },
  computed:{
    service_name: {
      get () {
        return this.$store.state.servicerequest.service_name
      },
      set (value) {
        this.$store.commit('servicerequest/UpdateServiceName', value)
      }
    },
    service_charge: {
      get () {
        return this.$store.state.servicerequest.service_charge
      },
      set (value) {
        this.$store.commit('servicerequest/UpdateServiceCharge', value)
      }
    },
    service_date: {
      get () {
        return this.$store.state.servicerequest.service_date
      },
      set (value) {
        this.$store.commit('servicerequest/UpdateServiceDate', value)
      }
    },
    service_time: {
      get () {
        return this.$store.state.servicerequest.service_time
      },
      set (value) {
        this.$store.commit('servicerequest/UpdateServiceTime', value)
      }
    },
    description: {
      get () {
        return this.$store.state.servicerequest.description
      },
      set (value) {
        this.$store.commit('servicerequest/updateDescription', value)
      }
    },
    status: {
      get () {
        return this.$store.state.servicerequest.status
      },
      set (value) {
        this.$store.commit('servicerequest/updateStatus', value)
      }
    }
  },
  created(){
    this.getAdminServiceRequestForStatus();
  },
  validations(){
    return {
      service_charge: { required }
    }
  },
  methods:{
    getAdminServiceRequestForStatus(){
      const servicerequest_id = new URLSearchParams()
      servicerequest_id.append('servicerequest_id', this.servicerequest_id)
      servicerequest_id.append('building_id', localStorage.building_id)
      this.$store.dispatch('servicerequest/getAdminServiceRequestForStatus',servicerequest_id)
    },
    activeEditAmount(){
      if(this.status == "Complete"){
        this.charge = true
      }
      else{
        this.charge = false
      }
    },
    updateAdminStatus(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const servicerequest = new URLSearchParams()
        servicerequest.append('servicerequest_id', this.servicerequest_id)
        servicerequest.append('status', this.status)
        servicerequest.append('service_charge', this.service_charge)
        servicerequest.append('building_id', localStorage.building_id)
        this.$store.dispatch('servicerequest/updateAdminStatus',servicerequest)
      }
    }
  },
  watch:{
    servicerequest_id(){
      this.getAdminServiceRequestForStatus();
    }
  }
}
</script>

<style scoped>
.error {
    border: 1px solid red;
  }
</style>
