<template>
  <html>
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=ISO-8859-1">
    <title>Ooops! Something went wrong!</title>
  </head>
  <body>
  <div class="centered">
    <img id="img" src="assets/images/oops.png"><br>
  </div>
</body>
</html>
</template>
<script>
// @ is an alias to /src

export default {
  name: 'FileNotFound',
  components: {

  }
}
</script>

<style scoped>
#img{
  height: 110px;
  width: 250px;
}
.centered{
  transform: translate(-50%,50%);
  top: 30%;
  left: 50%;
  position: fixed;
  animation-name: errormessage;
  animation-duration :0.5s;
}
@keyframes errormessage{

  from{top:100%;}
  to{top:30%;}
}
.report{
  transform: translate(45%, 170px);
}
a{
  cursor:help;
}
.success{
  transform: translate(20%, 163px);
}
</style>
