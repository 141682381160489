<template>
  <body class="skin-default fixed-layout">s

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->


        <div id="main-wrapper">
          <UserHeader/>
          <UserSidebar/>
            <div class="page-wrapper">
                <div class="container-fluid">

                        <div id="confirm-payment" class="modal1" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="top:23%;">

                            <div class="modal-dialog">
                                <div class="modal-content">
                                      <div class="modal-header">
                                        <h4 class="modal-title"  style="margin-left: 5%;opacity: 60%;" id="myModalLabel">Payment Status</h4>
                                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                                    </div>
                                    <div class="card-body" style="padding: 10px 50px;">
                                        <div class="row" style="justify-content: center;">
                                           <h4>{{payment_status}}</h4>
                                        </div>
                                            <hr>
                                            <div class="col-sm-12" style="text-align: center;">
                                                <div class="form-group">
                                                    <div class="custom-control custom-checkbox mr-sm-2">
                                                        <div class="row">
                                                            <div class="col-sm-12">
                                                               <button type="button" @click="hellooo" class="btn btn-info label-success" data-dismiss="modal">OK</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                </div>
                                <!-- /.modal-content -->
                            </div>
                            <!-- /.modal-dialog -->
                        </div>
                    </div>
                </div>

            <footer class="footer">
                © 2020 www.ontash.net
            </footer>
        </div>
    </body>




</template>

<script>
// @ is an alias to /src
import UserSidebar from '@/components/sidebars/UserSidebar.vue'
import UserHeader from '@/components/headers/UserHeader.vue'
import {mapState} from 'vuex'
export default {
  name: 'WalletStatus',
  components: {
    UserSidebar,
    UserHeader,
  },
  computed:{

      ...mapState('payment',['payment_status']),
    },
  data() {
    return{
       order_id: this.$route.params.order_id,
    }
  },
  mounted() {
      this.check_status()
  },
  methods:{
    check_status(){
      const order = new URLSearchParams()
      order.append('order_id', localStorage["order_id"])
      order.append('flat_id', localStorage["flat_id"])
      this.$store.dispatch('payment/get_order_details',order)
    },
    hellooo(){
      this.$router.push({ name: 'Wallet' })
    }
  }
}
</script>
