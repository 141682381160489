import axios from 'axios'
import baseurl from './axioscredentials'
axios.defaults.withCredentials = true
import router from '@/router'
const service = {
  namespaced: true,
  state: {
    services: [],
    service_name: '',
    service_charge: '',
    description: ''

  },
  getters: {

  },
  mutations: {

    SET_SERVICES(state,data){
      state.services = data.services
    },
    SET_SERVICE(state,data){
      state.service_name = data.service.name,
      state.service_charge = data.service.charge,
      state.description = data.service.description
    },

    updateServiceName(state,payload){
      state.service_name = payload
    },
    updateServiceCharge(state,payload){
      state.service_charge = payload
    },
    updateDescription(state,payload){
      state.description = payload
    }
  },
  actions: {
    getServices({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_admin_services',{building_id: localStorage.building_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_SERVICES',res.data)
        }).catch(error => console.log(error))
    },
    getService({ commit, state},service_id){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/show_admin_service',service_id,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_SERVICE',res.data)
        }).catch(error => console.log(error))
    },

    createService({ commit, state},service){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/create_admin_service',service,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: 'AdminService'})
          localStorage.status = "created"
        }).catch(error => console.log(error))
    },
    updateService({ commit, state},service){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/update_admin_service',service,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: 'AdminService'})
          localStorage.status = "updated"
        }).catch(error => console.log(error))
    },
    deleteService({ commit, state},service){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/delete_admin_service',service,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: 'AdminService'})
          localStorage.status = "deleted"
        }).catch(error => console.log(error))
    }

  }
}
export default service
