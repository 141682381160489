<template>
  <html>
  <body class="skin-default card-no-border">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <section id="wrapper">
      <div class="login-register" style="background-image:url(assets/images/background/login-register.jpg);">
        <div class="login-box card">
          <div class="card-body">
            <form @submit.prevent="requestOtp">
              <h3 class="text-center m-b-20">Sign In</h3>
              <img id="croppingImage" class="img-circle" src="@/assets/images/login_logo.png" style="width: 100%">
            <!--  <div class="alert alert-info signout" style="text-align: center;" role="alert">
                <span style="font-weight:400;">Successfully Signed Out!</span>
              </div> -->
              <div class="form-group ">
                <div class="col-xs-12">
                  <div class="row" style="margin-left: 0px; justify-content: center;">
                    <div class="col-sm-3" style="padding: 0px;">
                      <select class="form-control" v-model="country_code" @change="get_code($event.target.selectedIndex)" >
                      <option v-for="data in code" selectedIndex=0>({{data.code}}){{data.dial_code}}</option>
                     </select>
                    </div>
                    <div class="col-sm-8">
                    <input class="form-control" type="number" placeholder="Enter Phone Number" v-model="phone_no" :class="{ error: v$.phone_no.$error }">
                  </div>
                </div>
                </div>
              </div>

                <div class="form-group text-center">
                  <div class="col-xs-12 p-b-20">
                    <button class="btn btn-block btn-lg btn-info btn-rounded" type="submit">Next >></button>
                  </div>
                </div>

              </form>
              <div class="form-group text-center">
                <div class="col-xs-12 ">
                  <span style="font-size: 10px;">Powered by:&nbsp;&nbsp;</span>
                  <a href="https://ontash.net" type="submit"><span style="font-size: 10px;">Ontash India Technologies Pvt. Ltd</span></a>
                </div>
              </div>
              </div>
            </div>
          </div>

        </section>

      </body>

      </html>
    </template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required,numeric,minLength,maxLength } from '@vuelidate/validators'
import {mapState} from 'vuex'
import json from '@/assets/json/CountryCode.json'
export default {
  name: 'Login',
  components: {

  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return{
      phone_no: '',
      myJson: json,
      code: {},
      country_code: '(IN)+91',
      ctry_code : '+91'
    }
  },
  validations () {
    return {
      phone_no: { required, minLength: minLength(8), maxLength: maxLength(12)}

    }
  },
  mounted(){
    $(".modal-backdrop").remove();
  },
  created(){
    var booked_list = {}
    booked_list= JSON.parse(JSON.stringify(this.myJson['Country']), function(key, value) {
      return value;
    });
    this.code = booked_list
  },
  methods:{
    requestOtp(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        const mobile = new URLSearchParams()
        var number = this.ctry_code.slice(1) + this.phone_no
        mobile.append('phone_no', number)
        this.$store.dispatch('login/requestOtp',mobile)
      }
    },
    get_code(selectedIndex)
    {
      this.ctry_code = this.code[selectedIndex].dial_code

    },
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
