<template>
 <div id="wallet-payment" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
              <div class="modal-content">
                  <div class="modal-header">
                      <h4 class="modal-title" id="myModalLabel">Add to your wallet</h4>
                      <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                  </div>
                  <div class="card-body" style="padding: 25px 50px !important;">
                      <form class="form" @submit.prevent="add_money">
                         <div class="col-sm-12">
                              <div class="form-group row">
                                 <label for="example-text-input" class="col-12 col-form-label" style="font-weight: 500;">Enter Amount</label>
                                    <input v-model="ad_amount" :class="{ error: v$.ad_amount.$error }" type="number" style="margin-left: 10px;width:96%;" class="form-control" placeholder="&#x20b9;">

                              </div>
                         </div>
                         <div class="col-sm-12">
                              <div class="form-group">
                                  <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                      <button type="submit" class="btn btn-success mr-2" >Add Money</button>
                                      <button type="button" class="btn btn-danger mr-2" data-dismiss="modal"
                                          aria-hidden="true">Cancel</button>
                                  </div>
                              </div>
                          </div>

                      </form>
                  </div>
              </div>
              <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
      </div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'Addwallet',
  data() {
            return {
                ad_amount: ''
            }
        },
  setup () {
    return { v$: useVuelidate() }
  },
  validations () {
    return {
      ad_amount: { required } // Matches this.lastName
    }
  },
  methods: {
    add_money(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
      this.$store.commit('spinner/showLoading', true)
      const amount = new URLSearchParams()
      amount.append('flat_id', localStorage["flat_id"])
      amount.append('amount', this.ad_amount)
      amount.append('user_id', localStorage["user_id"])
      this.$store.dispatch('wallet/add_wallet',amount)
      this.closeAccountSelectModal();
      }
    },
    closeAccountSelectModal() {
        $('#wallet-payment').modal('hide');
    }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
