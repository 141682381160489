<template>
<!-- Add New Service Popup Model -->
  <div id="make_payment" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="myModalLabel">Payments</h4>
                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div class="card-body" style="padding-top: 0px;">
                                <div class="card" style="margin-bottom: 0px;">
                                    <form class="form" @submit.prevent="create_payment">
                                        <div class="col-sm-12 reference_nest">
                                            <div class="form-group row">
                                                <label for="example-text-input" class="col-4 col-form-label" style="font-weight: 500;">Reference</label>
                                                <div class="col-sm-12">
                                                    <div>
                                                        <input type="text" class="form-control ref_text"
                                                            autocomplete="off"  v-model="ref_text" v-on:keydown="find" placeholder="Type 's' for ServiceRequest and 'c' for complaints" :class="{ error: v$.ref_text.$error }">
                                                            <select v-if="buttonactive == true"  class="form-control" v-model="flat_no" @change="getselectedvalue($event.target.selectedIndex)" >
                                                               <option >Select Reference</option>
                                                               <option v-for="item in val" :value="item" :key ="item.id">{{item['appartment_group']}} ]</option>
                                                            </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 ref_nest">

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group row">
                                                <label for="example-text-input" class="col-6 col-form-label"  style="font-weight: 500;">Payment
                                                    Date</label>
                                                <div class="col-12">
                                                    <input class="form-control" type="date" v-model="date"  :class="{ error: v$.date.$error }" id="example-date-input" ref="inputdatepayment">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group row">
                                                <label for="example-text-input" class="" style=" padding-left: 10px; padding-right: 10px;">Estimate
                                                    Amount (based on service requests only)</label>
                                                    <div class="input-group mb-3">
                                                            <div class="input-group-prepend">
                                                              <span class="input-group-text">&#x20b9;</span>
                                                              <input type="number" v-model="estimate_amount"  placeholder="Enter Estimate Amount" :class="{ error: v$.estimate_amount.$error }" style="margin-left: 10px;width:96%;" class="form-control">
                                                                  
                                                            </div>
                                                        </div>

                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group row">
                                                <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Payment
                                                    Amount</label>
                                                  <div class="input-group mb-3">
                                                          <div class="input-group-prepend">
                                                            <span class="input-group-text">&#x20b9;</span>
                                                            <input type="text" style="margin-left: 10px;width:96%;"  placeholder="Enter Payment Amount" v-model="amount" class="form-control" :class="{ error: v$.amount.$error }">
                                                                

                                                          </div>
                                                      </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group row">
                                                <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Payee
                                                    Name</label>
                                                <input type="text" style="margin-left: 10px;width:96%;" v-model="name"  placeholder="Enter Payee Name" :class="{ error: v$.name.$error }"  class="form-control">
                                                    

                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group row">
                                                <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Phone
                                                    Number</label>
                                                <input type="number" style="margin-left:  10px;width:96%;"  placeholder="Enter Phone Number" v-model="number" :class="{ error: v$.number.$error }" class="form-control">
                                                    
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group row">
                                                <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Description</label>
                                                <div class="col-12">
                                                    <textarea class="form-control" v-model="desc"  placeholder="Enter Description" :class="{ error: v$.desc.$error }" rows="5"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group row" style="margin-left: 0px;">
                                                <div class="custom-control custom-checkbox mr-sm-2 mb-3">
                                                    <input type="checkbox" class="custom-control-input" v-model="chkbx" id="checkbox0"
                                                        value="check">
                                                    <label class="custom-control-label" for="checkbox0">Settled</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                                    <button type="submit" class="btn btn-success mr-2">Submit</button>
                                                    <button type="button" class="btn btn-danger mr-2" data-dismiss="modal">Cancel</button>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                        <!-- /.modal-content -->
                    </div>
                    <!-- /.modal-dialog -->
                </div>

</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'CreateAdminPayment',
  setup () {
    return { v$: useVuelidate() }
  },
  props: ['service','complaint'],
  data(){
    return{
       ref_text: '',
       date: '',
       amount: '',
       name: '',
       number: '',
       desc: '',
       chkbx: false,
       cb: false,
       flat_no: '---Select Reference---',
       buttonactive: false,
       services: [],
       ServiceRequest: [],
       estimate_amount: 0,
       key: '',
       complaints: [],
       val: '',
       serv_id: [],
       comp_id: []

    }

  },
  mounted (){
    this.disable_date();
  },
  validations () {
    return {

      ref_text: { required },
      date: { required } ,
      name: { required },
      amount: { required },
        desc: { required },
        estimate_amount: { required ,
        isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === 0) return false
        else return true


         } },
        number: { required },

    }
  },
   methods: {
    find: function(keyEvent) {
       if (keyEvent.key == 's' || keyEvent.key == 'S' ){
          this.buttonactive = true
          this.key = 's'
          this.val = this.service
       }
       else if (keyEvent.key == 'c' || keyEvent.key == 'C' ){
         this.buttonactive = true
         this.key = 'c'
         this.val = this.complaint
       }

    },
    create_payment(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const pay = new URLSearchParams()
        pay.append('date', this.date)
        pay.append('paid_amt', this.amount)
        pay.append('payee', this.name)
        pay.append('payee_no', this.number)
        pay.append('desc', this.desc)
        if(this.chkbx == true){
            this.cb = "checked"

        }
        pay.append('cb', this.cb)
        pay.append('estimate_amt', this.estimate_amount)
        this.serv_id.forEach((item) => {
           pay.append('serv_ids[]', item);
        });
        this.comp_id.forEach((item) => {
           pay.append('comp_ids[]', item);
        });
        pay.append('building_id',localStorage.building_id)
        this.$store.dispatch('payment/createpayAdmin',pay)
      }
    },
    getselectedvalue(selectedIndex)
    {
      if (this.key == 's'){
      var selectedIdea = this.val[selectedIndex-1];
      this.services.push(selectedIdea.appartment_group)
      this.serv_id.push(selectedIdea.service_id)
      this.estimate_amount = this.estimate_amount+selectedIdea.service_charge
      this.ref_text = this.services
      this.flat_no = '---Select Reference---'
      this.val.splice(selectedIndex-1, 1)

     }
     else if (this.key == 'c'){

      var selectedIdea = this.val[selectedIndex-1];
      this.complaints.push(selectedIdea.appartment_group)
      this.comp_id.push(selectedIdea.id)
      this.ref_text = this.complaints
      this.flat_no = '---Select Reference---'
      this.val.splice(selectedIndex-1, 1)

     }
    },
    disable_date(){
      var date = new
      Date().toISOString().slice(0,10);
      $(this.$refs.inputdatepayment).attr('max',date);
    }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
