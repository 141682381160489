<template>
  <html>
  <body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <AdminHeader/>
      <AdminSidebar/>
  <div class="page-wrapper">
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="row page-titles">
            <div class="col-md-5 align-self-center">
                <h4 class="text-themecolor">Terms and Conditions</h4>
            </div>
            <div class="col-md-7 align-self-center text-right">
                <div class="d-flex justify-content-end align-items-center">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><router-link :to="{ name: 'AdminHome' }">Home</router-link></li>
                        <li class="breadcrumb-item active">Terms and Conditions</li>
                    </ol>
                    <!-- <button type="button" class="btn btn-info d-none d-lg-block m-l-15"><i class="fa fa-plus-circle"></i>
                        Create New</button> -->
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Info box -->
        <!-- ============================================================== -->
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row info-icon">
                            <img src="../../assets/images/login_logo.png" alt="homepage" class="dark-logo imagesize"/>
                        </div>
                        <div class="row info-icon">
                                    <h6>Terms and Conditions</h6><br>
                                </div>
                                <hr>
                                <h6 style="color:gray;margin-left:5px;">Release Date: 1st-September-2020</h6>
                                <div class="row contents">
                                    <span>
                                        <h4>
                                            DEFINITIONS
                                        </h4>
                                    </span>
                                </div>
                                <div class="row contents">
                                    <span>
                                        "Ontash India Technologies", a subsidiary of Ontash Systems USA. <br>
                                        "Products" imply all offerings of Ontash India Technologies, that are SaaS
                                        (Software as a Service) Products including but not limited to the Web
                                        Applications: willows.in and the Mobile Apps: Willows.<br>
                                        "You" addresses the individual User reading and agreeing to these Terms.<br>
                                        "Apartment Complex" means a Villa Complex or an Apartment Complex or a
                                        Commercial Complex. It can also imply any such Organisation of Users utilising
                                        the Products.<br>
                                        "Willows" means the specific Portal of the specific Apartment Complex,
                                        accessible to Residents and Office Staff.<br>
                                        Subscription Term: Period for which Subscription Payment has been made.<br>
                                        The following paragraphs contain further information about our Terms of
                                        Service, Privacy Policy, Payment Gateway Terms. Please make sure you read
                                        through this carefully. Please proceed with the use of the Products upon
                                        agreement with the Terms.<br>

                                    </span>
                                </div>
                                <div class="row contents">
                                    <span>
                                        <h4>
                                            TERMS OF SERVICE
                                        </h4>
                                    </span>
                                </div>
                                <div class="row contents">
                                    <span style="font-weight: 500;">
                                        Ontash India Technologies provides its service to you, subject to the following
                                        Terms of Service ("TOS"), which may be updated by us from time to time with
                                        information to you.

                                    </span>
                                </div>
                                <div class="row contents">
                                    <ol>
                                        <li>
                                            <span>
                                                <strong>
                                                    DESCRIPTION OF SERVICE
                                                </strong>
                                            </span><br>
                                            <span>
                                                Willows provide all services for people who live in flats (also
                                                building associations, cooperative apartments, housing complexes with
                                                shared services) to coordinate activities such as requesting help on
                                                maintenance issues, paying association dues, getting alerts etc.

                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <strong>
                                                    Ontash India Technologies PRIVACY POLICY
                                                </strong>
                                            </span><br>
                                            <span>
                                                Personal information about you are subject to our PRIVACY POLICY which
                                                is a section in these Terms.
                                            </span>
                                        </li>

                                        <li>
                                            <span>
                                                <strong>
                                                    USER ACCOUNT, PASSWORD AND SECURITY
                                                </strong>
                                            </span><br>
                                            <span>

                                                    You are responsible for maintaining the confidentiality of the
                                                    UserID and password you choose when you create your account, and
                                                    are fully responsible for all activities that occur under your
                                                    account. You agree to immediately notify Ontash India Technologies
                                                    of any unauthorized use of your password. Ontash India Technologies
                                                    will not be liable for any consequence arising from your failure to
                                                    comply with this Section.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <strong>
                                                    ABUSE OF SERVICE
                                                </strong>
                                            </span><br>

                                                <span>
                                                    You understand that all information (such as data files, written
                                                    text, audio files, images or any other media) which you may have
                                                    access to as part of, or through your use of the Products of Ontash
                                                    India Technologies are the sole responsibility of the person from
                                                    which such content originated. Ontash India Technologies takes no
                                                    responsibility for abusive content, and it is the responsibility of
                                                    the users to regulate such content. Ontash India Technologies takes
                                                    no responsibility for any data generated within any of its Products
                                                    and published or distributed outside by the user.<br>
                                                    Ontash India Technologies reserves the right to suspend its service
                                                    to Users involved in service abuse.
                                                  </span>
                                        </li>
                                        <li>
                                            <span>
                                                <strong>
                                                    LIST OF PROHIBITED ITEMS
                                                </strong>
                                            </span><br>
                                            <span>
                                                Users are prohibited from aiding in the sale / exchange of any item
                                                present in the below list. This is only a partial list and items can be
                                                added as and when prohibited to law or brought to the notice of Ontash
                                                India Technologies.<br>
                                                Airline tickets that restrict transfer, other unauthorized ticket sale
                                                - Alcohol or tobacco products - Blood, bodily fluids or parts - Bulk
                                                email or mailing lists that contain names, addresses, phone numbers, or
                                                other personal identifying info Burglary tools, including but not
                                                limited to lock-picks or motor vehicle keys - Controlled substances or
                                                illegal drugs, substances and items used to manufacture controlled
                                                substances and drug paraphernalia -Counterfeit currency, coins and
                                                stamps, tickets, as well as equipment designed to make them -
                                                Counterfeit, replica, or knock-off brand name goods - Coupons or gift
                                                cards that restrict transfer, other unauthorized coupons or gift cards
                                                - False identification cards, forged certificates - Fireworks, firearms
                                                and explosives - Gambling items, including but not limited to lottery
                                                tickets - Material that infringes copyright, including but not limited
                                                to software or other unauthorized digital goods sale - Pornography -
                                                Pet animal parts, blood, or fluids - Prescription drugs and medical
                                                devices, including but not limited to prescription or contact lenses,
                                                defibrillators, hypodermic needles or hearing aids - Stolen property,
                                                or property with serial number removed or altered.<br>
                                                Items can be included in the above list as and when prohibited.
                                            </span>


                                        </li>
                                        <li>
                                            <span>
                                                <strong>
                                                    NO RESALE OF SERVICE
                                                </strong>
                                            </span><br>
                                            <span>
                                                You agree not to reproduce, duplicate, copy, sell, resell or exploit
                                                for any commercial purposes, any portion of the Products, use of the
                                                Products, or access to the Products.
                                            </span>



                                        </li>
                                        <li>
                                            <span>
                                                <strong>
                                                    MODIFICATIONS TO SERVICE
                                                </strong>
                                            </span><br>
                                            <span>

                                                As SaaS products, all of Ontash India Technologies offerings will
                                                undergo continuous modifications in its Features and Service. In case
                                                any feature or service is phased out, adequate time and notice will be
                                                given to you, so that you may retain the Information assets created by
                                                you as part of that Feature. Beyond the time given, you agree that
                                                Ontash India Technologies shall not be liable to you or to any third
                                                party.
                                            </span>



                                        </li>
                                        <li>
                                            <span>
                                                <strong>
                                                    Ontash India Technologies PROPRIETARY RIGHTS
                                                </strong>
                                            </span><br>
                                            <span>

                                                You acknowledge and agree that the Products contain proprietary and
                                                confidential information that is protected by applicable intellectual
                                                property and other laws. Except as expressly authorized by Ontash India
                                                Technologies, you agree not to modify, rent, lease, loan, sell,
                                                distribute or create derivative works based on the Products, in whole
                                                or in part. You also agree not to extract the code or reverse-engineer
                                                it in any way. Any attempt at hacking or unlawful use of the Products
                                                can and will invite the maximum prosecution allowable under the law.

                                            </span>



                                        </li>
                                        <li>
                                            <span>
                                                <strong>
                                                    WARRANTIES AND DISCLAIMERS
                                                </strong>
                                            </span><br>
                                            <span>

                                                Our Warranties. We warrant that
                                                <ul>
                                                    <li>
                                                        the Products shall perform materially in accordance with the
                                                        Functionality listed in Features List, and

                                                    </li>
                                                    <li>
                                                        the functionality of the Products will not be materially
                                                        decreased during a subscription term. A Subscription Term is
                                                        defined by the term for which the Subscription Payment is
                                                        already made. For any breach of either such warranty, your
                                                        exclusive remedy shall be Termination of Use including Refund
                                                        of Advance Payments made for the Subscription Term.


                                                    </li>
                                                </ul>
                                                For integrations of the Product with 3rd Party Software or Hardware,
                                                Ontash India Technologies warrants that it will perform due diligence
                                                while selecting the 3rd Party Software or Hardware. However, Ontash
                                                India Technologies does not warrant error-free functioning of such 3rd
                                                Party Software or Hardware.
                                            </span>



                                        </li>
                                        <li>
                                            <span>
                                                <strong>
                                                    REFUND
                                                </strong>
                                            </span><br>
                                            <span>

                                                Refund can be claimed, due to the Service Provider warrants that a) the functionality of the Products will not be materially decreased during a Subscription Term. For the purpose of this clause, a Subscription Term is defined by the term for which the subscription payment is already made. For any breach of either such warranty, the Customer’s exclusive remedy shall be termination of Subscription including refund of advance payments made for the Subscription Term. b) For integrations of the Product with third party software or hardware, the Service Provider will perform due diligence while selecting the third-party software or hardware. However, the Service Provider does not warrant error-free functioning or discontinuation of service by such 3rd Party software or hardware., for Advance Payments of Annual or more, if initiated within thirty (30) days of the acknowledgement of payment by Service Provider.
                                            </span>



                                        </li>
                                        <li>
                                            <span>
                                                <strong>
                                                    LIMITATION OF LIABILITY
                                                </strong>
                                            </span><br>
                                            <span>

                                                In no event shall either party’s aggregate liability arising out of or
                                                related to these Terms/Agreement, whether in contract, tort or under
                                                any other theory of liability, exceed the total amount paid by you
                                                under the current active Subscription Term.<br>
                                                In specific Ontash India Technologies is not liable to respond to
                                                queries from third parties about Apartment Complex use of the Product.
                                                All queries must come from the officially designated Users of the
                                                Apartment Complex itself.<br>
                                                By using this Product You confirm that You have the legal authority of
                                                uploading and sharing the specific data that You would upload or share
                                                on this Product. You indemnify Ontash India Technologies of any
                                                disputes regarding the ownership of such data.
                                            </span>



                                        </li>
                                        <li>
                                            <span>
                                                <strong>
                                                    Support SLA
                                                </strong>
                                            </span><br>
                                            <span>
                                                All Support queries are to be lodged using the "Help" feature present
                                                on the Products. If the issue is related to log in, support email can
                                                be sent to info@willows.in.
                                            </span>



                                        </li>
                                        <li>
                                            <span>
                                                <strong>
                                                    Exit of Subscription
                                                </strong>
                                            </span><br>
                                            <span>
                                                This agreement is subject to review by either party at any time during
                                                the Subscription Term. If either party requires terminating this
                                                agreement, 30 days notice must be given and the data will be available
                                                for the customer to be extracted during this period.​ The notice period
                                                has to be within the subscription paid duration (Subscription Term).<br>
                                                After such a 30-day period, The Service Provider shall have no
                                                obligation to maintain or provide any of the Customer’s Data and shall
                                                thereafter, unless legally prohibited, delete all of Customer’s Data in
                                                the Service Provider’s systems or otherwise in its possession or under
                                                its control.<br>
                                                Upon non-payment of Subscription Dues within the Subscription Invoice
                                                Due Date, the Subscription is considered terminated and Access to the
                                                product is discontinued for the Customer.

                                            </span>



                                        </li>
                                    </ol>
                                    <div class="row contents">
                                        <span>
                                            <h4>
                                                PRIVACY POLICY
                                            </h4>
                                        </span>
                                    </div>
                                    <div class="row contents">
                                        <span>
                                            Ontash India Technologies does not sell, rent or otherwise make available
                                            the personal information of the Users, Visitors, Association Staff,
                                            Domestic Staff or the common information of the Apartment Complex to any
                                            third-party, without their explicit approval.

                                        </span>
                                    </div>
                                    <div class="row contents">
                                        <span>
                                            <h4>
                                                REGISTRATION
                                            </h4>
                                        </span>
                                    </div>
                                    <div class="row contents">
                                        <span>
                                            In order to use the Products, a user must first complete the registration
                                            form. During registration a user is required to give their contact
                                            information and proof of being associated with the Multi Dwelling Unit. The
                                            registration information given by the user must always be accurate, correct
                                            and up to date. This ensures the User can get the maximum benefit from the
                                            Product.
                                        </span>
                                    </div>
                                    <div class="row contents">
                                        <span>
                                            <h4>
                                                YOUR PERSONAL INFORMATION
                                            </h4>
                                        </span>
                                    </div>
                                    <div class="row contents">
                                        <span>
                                            The User stores information about himself/herself under his/her User
                                            Profile. Information provided is available to the User and the
                                            Administrative Users belonging to the same Apartment Complex as the user.
                                            The Vendor information entered is available to the Administrative Users
                                            belonging to the specific Apartment Complex. Administrative Users may also
                                            enter Personal Information of the Users. Ontash India Technologies takes no
                                            responsibility for the accuracy of such data entered by the Users or
                                            Administrative Users.<br>
                                            These Personal Information are retained for the period covered by the
                                            Subscription Term, unless the Administrative Users choose to delete the
                                            Personal Information.
                                        </span>
                                    </div>
                                    <div class="row contents">
                                        <span>
                                            <h4>
                                                LINKS
                                            </h4>
                                        </span>
                                    </div>
                                    <div class="row contents">
                                        <span>
                                            Links to other sites may be present in the Products. Please be aware that
                                            we are not responsible for the privacy practices of such other sites.
                                            Please be aware when you leave the Product site and visit these other
                                            sites, to read the privacy statements of such sites especially in case they
                                            collect personally identifiable information. This privacy policy applies
                                            solely to information collected by the Products of Ontash India
                                            Technologies.
                                        </span>
                                    </div>
                                    <div class="row contents">
                                        <span>
                                            <h4>
                                                MOBILE APPS
                                            </h4>
                                        </span>
                                    </div>
                                    <div class="row contents">
                                        <span>
                                            The Mobile Apps of the Product may seek access to data stores in your
                                            Mobile Phone, such as SMS, Photos, Contact List etc. The purpose of this
                                            access is solely towards complete functionality of relevant Product
                                            Features. The Mobile Apps will not run to their full potential if you
                                            choose to not provide the necessary access.
                                        </span>
                                    </div>
                                    <div class="row contents">
                                        <span>
                                            <h4>
                                                SECURITY
                                            </h4>
                                        </span>
                                    </div>
                                    <div class="row contents">
                                        <span>
                                            Ontash India Technologies takes every precaution to protect users'
                                            information. When users submit sensitive information via the portal, the
                                            information is protected both online and off-line. We have enabled SSL
                                            encryption so as to ensure that your data remains encrypted during
                                            transmission to and from the site. These precautions, though industry
                                            standards are nonetheless only as secure as the end-user's practice. To
                                            ensure that unauthorised parties do not get access to information on this
                                            portal, we encourage all users to make sure that they log-off from all
                                            sessions - especially from public computers such as at internet cafes.<br>
                                            As part of our disaster recovery strategy, we have regular backups taken to
                                            protect you from sudden data loss. If you have any questions about the
                                            security at our portal, you can send an email to info@willows.in.

                                        </span>
                                    </div>
                                    <div class="row contents">
                                        <span>
                                            <h4>
                                                SITE AND SERVICE UPDATES

                                            </h4>
                                        </span>
                                    </div>
                                    <div class="row contents">
                                        <span>
                                            We also send the user site and service announcement updates. Users may
                                            choose to unsubscribe from receiving these emails by following the
                                            instructions that will be part of each announcement email.

                                        </span>
                                    </div>
                                    <div class="row contents">
                                            <span>
                                                <h4>
                                                        NOTIFICATION OF CHANGES
                                                </h4>
                                            </span>
                                        </div>
                                        <div class="row contents">
                                            <span>
                                                    Any change made to the Privacy Policy will be posted on the Homepage so our users are always kept aware. Also they will be notified via Email about any such change.


                                            </span>
                                        </div>
                                        <div class="row contents">
                                                <span>
                                                    <h4>
                                                            PRIVACY AND SECURITY OF PAYMENT INFORMATION

                                                    </h4>
                                                </span>
                                            </div>
                                            <div class="row contents">
                                                <span>
                                                        Ontash India Technologies makes it possible for the User to pay various charges via its Products. Details of securing the Payment Information is available in a separate Terms knows as the Payment Gateway Terms



                                                </span>
                                            </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer">
            © 2020 www.ontash.net
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>

</body>
</html>
</template>

<script>
// @ is an alias to /src
import AdminHeader from '@/components/headers/AdminHeader.vue'
import AdminSidebar from '@/components/sidebars/AdminSidebar.vue'
export default {
  name: 'AdminTermsAndConditions',
  components: {
    AdminHeader,
    AdminSidebar
  }
}
</script>

<style scoped>
.info-icon{
  justify-content: center;
}
.img-icon{
  height: 70px;
  margin-bottom: 5px;
}
.contents{
  padding: 15px 15px 0px 15px;
}

.imagesize{
  width: 350px;
}

.content ul{
  margin-right: 25px;
}
h4{
  font-weight: 500 !important;
}
strong{
  font-weight: 500 !important;
}
h6{
  font-weight: 400;
}
</style>
