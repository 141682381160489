import axios from 'axios'
import baseurl from './axioscredentials'
axios.defaults.withCredentials = true
import router from '@/router'
const wallet = {
  namespaced: true,
  state: {
    wallet_balance: '',
    trans: [],
    payment_list: [],
    payments: [],
    pending_count: 0

  },
  getters: {

  },
  mutations: {
    SET_BAL(state,data){
      state.current_bal = data.current_bal
      state.total_credited = data.total_credited
      state.total_debited = data.total_debited
      state.payment_account_status = data.payment_account_status
      state.transactions = data.transactions
      state.credited_transaction = data.credited_transactions
      state.debited_transactions = data.debited_transactions
      state.trans = data.transactions

    },
    set_payment(state,data){
      state.wallet_bal = data.wallet_bal
      state.payments = data.payments
      state.payment_list = data.payments
      state.pending_count = state.payment_list.filter(s => s.status == 0).length;
      console.log(state.payment_list)
    },
    sortBystatus(state,payload){
      if (payload == 0){
        state.trans = state.transactions

      }
      else if(payload == 1){
         state.trans = state.credited_transaction
      }
      else if(payload == 2){
          state.trans = state.debited_transactions
      }
    },
    sortBypending(state,payload){
      state.payment_list = state.payments.filter(s => s.status == payload);
    },
  },
  actions: {
    getWalletBalance({ commit, state},flat){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post('http://localhost:3000/api/v1/get_wallet_balance', flat).then(
        res => {
          commit('SET_BAL',res.data)
          router.push({ name: 'Wallet', params: { wallet_balance: res.data.wallet_bal } })
        }).catch(error => console.log(error))
    },
    mywallet({ commit, state}){
        this.dispatch('token/VerifyTokenExpiry', null, {root:true})
        var flat_id = localStorage["flat_id"]
        axios.post(baseurl+'/api/v1/mywallet', {flat_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_BAL',res.data)
        }).catch(error => console.log(error))

    },
    user_payment({ commit, state}){
        this.dispatch('token/VerifyTokenExpiry', null, {root:true})
        var flat_id = localStorage["flat_id"]
        axios.post(baseurl+'/api/v1/user_payments', {flat_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('set_payment',res.data)

        }).catch(error => console.log(error))

    },
    add_wallet({ commit, state}, ampunt){
        this.dispatch('token/VerifyTokenExpiry', null, {root:true})
        axios.post(baseurl+'/api/v1/store_wallet_payment', ampunt,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
            if (res.data.error == "Please update your email Id"){
              router.go({ name: 'Wallet'})
              localStorage.status = "update_email"
            }
            else if (res.data.orderid && res.data.amount){
              const url = new URL(baseurl+'/api/v1/wallet_payment_success');
              const amount = new URLSearchParams()
              amount.append('orderid', res.data.orderid)
              amount.append('amount', res.data.amount)
              amount.append('user_id', localStorage.user_id)
              amount.append('return_url',url )
              axios.post(baseurl+'/api/v1/make_payment_order', amount,
                    {headers:{'Authorization':localStorage.token}}).then(
              res => {
                router.push({ name: 'ConfirmPayment', params: { payment_link: res.data.payment_link , order_id: res.data.order_id} })
                this.commit('spinner/showLoading',false)
              }).catch(error => console.log(error))
            }

        }).catch(error => console.log(error))

    },

  }
}
export default wallet

// inputtype="datetime-local"
