<template>
  <div id="edit-email" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
              <div class="modal-content">
                  <div class="modal-header">
                      <h4 class="modal-title" id="myModalLabel">Change Email Address</h4>
                      <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                  </div>
                  <div class="card-body" style="padding: 25px 50px !important;">
                      <form class="form" @submit.prevent="updateemail">
                          <div class="col-sm-12">
                              <div class="form-group row">
                                  <div class="col-md-12">
                                     <input type="text"  v-model="email1" readonly="true" :placeholder="[[ email_ ]]" class="form-control form-control-line" >
                                  </div>
                              </div>
                          </div>
                          <div class="col-sm-12">
                              <div class="form-group row">
                                  <div class="col-md-12">
                                      <label for="example-text-input" class="col-4 col-form-label" style="">New Email</label>
                                      <input type="email" v-model="email" placeholder="" class="form-control form-control-line"
                                          :class="{ error: v$.email.$error }">
                                  </div>
                              </div>
                          </div>

                          <div class="col-sm-12 otp" v-if="displayotp == true">
                              <div class="form-group row">
                                  <div class="col-md-12">
                                      <input type="text" v-model="otp" placeholder="OTP" class="form-control form-control-line"
                                          required>
                                  </div>
                                  <div class="col-md-12" style="padding: 15px;">
                                      <span style="font-size:13px;color:red;">An OTP has been sent to your email account. Please enter here!</span>
                                  </div>
                              </div>
                          </div>
                          <div class="col-md-12" style="padding: 15px;" v-if="error_tab">
                              <span style="font-size:13px;color:red;">{{errormessage}}</span>
                          </div>
                          <div class="form-group row" style="text-align: center;font-size: 21px;">
                            <div class="col-md-12" >
                              <vue-countdown class="timerClass" v-if="counting " :time="180000" @end="onCountdownEnd" v-slot="{ minutes, seconds }"> {{ minutes }} : {{ seconds }} </vue-countdown>
                              <a v-if="otplink " @click="resendOtp()" style="color: #fb9678">Resend OTP</a>
                            </div>
                          </div>

                          <div class="col-sm-12">
                              <div class="form-group">
                                  <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                    <button type="submit" class="btn btn-info mr-2" v-if="displayotp == true">Update</button>
                                      <button type="button" class="btn btn-success mr-2" v-if="displayotp == false" @click="sendotp" >Sent OTP</button>
                                      <button type="button" class="btn btn-danger mr-2" data-dismiss="modal"
                                          aria-hidden="true">Cancel</button>
                                  </div>
                              </div>
                          </div>

                      </form>
                  </div>
              </div>
              <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
      </div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required} from '@vuelidate/validators'
import {mapState} from 'vuex'
import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
  name: 'UpdateEmail',
  components: {
    VueCountdown
  },
   props:['phone_','email_'],
   data() {
    return {
      email: '',
      displayotp: false,
      otplink:false,
      error_tab: false
    }
  },
   setup () {
    return { v$: useVuelidate() }
  },
  validations: {
    email: {
      required,
    }
  },
  computed:{
    ...mapState('profile',['errormessage']),
    error_status: {
      get () {
        return this.$store.state.profile.error_status
      },
      set (value) {
        this.$store.commit('profile/updateErrorStatus', value)
      }
    },
    counting: {
      get () {
        return this.$store.state.profile.counting
      },
      set (value) {
        this.$store.commit('profile/updateCounting', value)
      }
    }

  },
   mounted(){
    this.startCountdown();
  },

  methods:{

    UpdateName(){

        this.$store.dispatch('profile/updatename',this.name)
    },
       startCountdown() {
      console.log(this.counting )
      if (this.counting == true){
        this.otplink = false;
      }
    },
    onCountdownEnd() {
      console.log("hello")
      this.counting = false;
      this.error_status = false;
      this.otplink = true;
    },
    sendotp(){
      this.v$.$touch()
       if(!this.v$.$invalid){
        this.displayotp = true
      const mobile = new URLSearchParams()
      mobile.append('phone_no', this.phone_)
      mobile.append('email', this.email)
       this.$store.dispatch('profile/requestOtpEmail',mobile)
     }
    },
    closeModal(){
      this.buttonactive = false
    },
    resendOtp(){
      const mobile = new URLSearchParams()
      mobile.append('phone_no', this.phone_)
      mobile.append('email', this.email)
      this.$store.dispatch('profile/resendOtpemail',mobile)
      this.error_tab = false;
      this.otplink = false;
      this.counting = true;
      this.displayotp = true;
    },
    updateemail(){
      // this.$store.commit('spinner/showLoading', true)
      const mobile = new URLSearchParams()
      mobile.append('Phone', this.phone_)
      mobile.append('otp', this.otp)
      mobile.append('email', this.email)
      this.$store.dispatch('profile/update_email',mobile)

    }
  },
   watch: {
    error_status(){
      if (this.error_status == true){
        console.log(this.errormessage)
        this.onCountdownEnd();
        this.error_tab = true;
        this.displayotp = false;
        this.otp = ""
        this.v$.$reset()
      }
      
    }
  },

}

</script>
<style scoped>
.error {
    border: 1px solid red;
  }
    .timerClass{
  color: #fb9678;
  font-weight: bold;
}
</style>
