import axios from 'axios'
import baseurl from './axioscredentials'
axios.defaults.withCredentials = true
import router from '@/router'
const login = {
  namespaced: true,
  state: {
    otp: '',
    logintracks: [],
    error_status: false,
    errormessage: '',
    counting: false
  },
  getters: {

  },
  mutations: {

    SET_COUNTING(state,data){
      state.counting = true
    },
    SET_LOGIN_TRACKS(state,data){
      state.logintracks = data.login_tracks
    },
    SET_ERROR_MESSAGE(state,data){

      state.errormessage = data
      state.error_status = true
      state.counting = false
    },
    updateErrorStatus(state,payload){
      state.error_status = payload
    },
    updateCounting(state,payload){
      state.counting = payload
    }
  },
  actions: {
    requestOtp({ commit, state},mobile){
      axios.post(baseurl+'/generate_otp', mobile).then(
        res => {
          commit('SET_COUNTING',res.data)
          console.log(res.data)
          router.push({ name: 'RequestOtp', params: { phone_no: res.data.phone_no } })
        }).catch(error => console.log(error))
    },
    resendOtp({ commit, state},mobile){
      axios.post(baseurl+'/generate_otp', mobile).then(
        res => {
        }).catch(error => console.log(error))
    },
    submitOtp({ commit, state},mobile_otp){
      axios.post(baseurl+'/login', mobile_otp).then(
        res => {
          var sets = JSON.stringify(res.data)
          if (sets.includes("errormessage")){
            commit('SET_ERROR_MESSAGE',res.data.errormessage)
          }
          else{
            localStorage["token"]=res.data.tokens.access
            localStorage["user_id"]=res.data.user_id
            localStorage["user_name"]=res.data.username
            localStorage["SignedIn"]=true
            localStorage["access_expiry"]=res.data.tokens.access_expires_at
            localStorage["refresh_expiry"]=res.data.tokens.refresh_expires_at
            this.dispatch('token/savetoken')

            if(res.data.user_roles.length == 2){
              if (sets.includes("appartment_details")){
                localStorage["appartment_details"]=true
              }
              else{
                localStorage["appartment_details"]=false
              }
              router.push({ name: 'SelectRole' })
            }
            else if (res.data.user_roles.length == 1 && res.data.user_roles[0].name == "SuperAdmin"){
              localStorage["user_role"] = "SuperAdmin"
              router.push({ name: 'SuperAdminHome' })
            }
            else if (res.data.user_roles.length == 1 && res.data.user_roles[0].name == "Admin"){
              if (sets.includes("appartment_details")){
                localStorage["appartment_details"]=true
              }
              else{
                localStorage["appartment_details"]=false
              }
              router.push({ name: 'SelectRole' })
            }
            else{
              localStorage["user_role"] = "User"
              if (sets.includes("appartment_details")){
                router.push({ name: 'SelectFlat' })
              }
              else
              {
                router.push({ name: 'AddFlat' })
              }
            }
          }
        }).catch(error => console.log(error))
    },
    setAdminRole(){
      localStorage["user_role"] = "Admin"
      axios.post(baseurl+'/api/v1/get_building',{user_id: localStorage.user_id},
              {headers:{'Authorization':localStorage.token}}).then(
        resp => {
          if(resp.data.status == "Inactive"){
            localStorage.clear();
            router.push({ name: 'Login'})
          }
          else{
            localStorage["building_id"]=resp.data.building.id
            localStorage["building_name"]=resp.data.building.name
            localStorage["user_name"]=resp.data.user.name
            axios.post(baseurl+'/api/v1/add_login_track',{user_id: localStorage.user_id, building_id: localStorage.building_id},
                    {headers:{'Authorization':localStorage.token}}).then(
              resp => {
              }).catch(error => console.log(error))
            router.push({ name: 'AdminHome'})
          }
        }).catch(error => console.log(error))
    },
    addLoginTrack(){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/add_login_track',{user_id: localStorage.user_id, building_id: localStorage.building_id},
              {headers:{'Authorization':localStorage.token}}).then(
        resp => {
        }).catch(error => console.log(error))
    },
    logoutUser({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/logout',{user_id: localStorage.user_id, building_id: localStorage.building_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {

        }).catch(error => console.log(error))
        localStorage.clear();
        router.push({ name: 'Login'})
    },
    logoutUserSuperAdmin({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
        localStorage.clear();
        router.push({ name: 'Login'})
    },
    getLoginTracks({ commit, state}){
      axios.post(baseurl+'/api/v1/get_audit_features',{},
              {headers:{'Authorization':localStorage.token}}).then(
        resp => {
          commit('SET_LOGIN_TRACKS',resp.data)
        }).catch(error => console.log(error))
    }

  }
}
export default login
