<template>
  <body class="skin-default fixed-layout">
    <div id="main-wrapper">
      <UserHeader/>
      <UserSidebar/>
      <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
          <!-- ============================================================== -->
          <!-- Bread crumb and right sidebar toggle -->
          <!-- ============================================================== -->
          <div class="row page-titles">
            <div class="col-md-5 align-self-center">
              <h4 class="text-themecolor">Report Issues</h4>
            </div>
            <div class="alert alert-success signout_create banner-text" role="alert">
                <span><strong>Success!</strong> You have successfully reported the issue</span>
            </div>
            <div class="col-md-7 align-self-center text-right">
              <div class="d-flex justify-content-end align-items-center">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><router-link :to="{ name: 'UserHome' }">Home</router-link></li>
                  <li class="breadcrumb-item active">Report Issues</li>
                </ol>

              </div>
            </div>
          </div>
          <!-- ============================================================== -->
          <!-- End Bread crumb and right sidebar toggle -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Info box -->
          <!-- ============================================================== -->
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Report Issues</h4>
                  <h6 class="card-subtitle">Report Your Issues Here</h6>
                  <hr>
                  <div class="row">
                    <form style="width:100%" @submit.prevent="createUserReportIssue()">
                      <div class="card-body">


                        <div class="row">
                          <div class="col-md-6 ">
                            <div class="form-group">
                              <label>Issue Type</label>
                              <div class="col-12">
                                <select class="form-control custom-select" v-model="topic" :class="{ error: v$.topic.$error }">
                                  <option >Select Issue Type</option>
                                  <option >Repot Bug</option>
                                  <option >Improvement</option>
                                  <option >Others</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 ">
                            <div class="form-group">
                              <label>Description</label>
                              <div class="col-12">
                                <textarea class="form-control" rows="3" placeholder="Enter Description" v-model="description" :class="{ error: v$.description.$error }"></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 ">
                            <div class="form-group">
                              <label>Image</label>
                              <div class="col-12">
                                <input type="file" ref="inputfile" multiple @change="handleFilesUpload()"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="modal-footer">
                        <button type="submit" class="btn btn-success mr-2">Submit</button>
                        <button type="button" class="btn btn-danger mr-2" @click="goHome()" aria-hidden="true">Cancel</button>
                      </div>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ============================================================== -->
          <!-- End Info box -->
          <!-- ============================================================== -->

          <!-- ============================================================== -->
          <!-- Review -->
          <!-- ============================================================== -->

          <!-- ============================================================== -->
          <!-- End Review -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Comment - chats -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- End Comment - chats -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- End Page Content -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Right sidebar -->
          <!-- ============================================================== -->
          <!-- .right-sidebar -->

          <!-- ============================================================== -->
          <!-- End Right sidebar -->
          <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
      </div>
      <!-- ============================================================== -->
      <!-- End Page wrapper  -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- footer -->
      <!-- ============================================================== -->
      <footer class="footer">
        © 2020 www.ontash.net
      </footer>
      <!-- ============================================================== -->
      <!-- End footer -->
      <!-- ============================================================== -->
    </div>
  </body>

</template>

<script>
// @ is an alias to /src
import UserSidebar from '@/components/sidebars/UserSidebar.vue'
import UserHeader from '@/components/headers/UserHeader.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  name: 'UserReportIssue',
  components: {
    UserHeader,
    UserSidebar
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data(){
    return{
      topic: 'Select Issue Type',
      description: '',
      images: ''
    }

  },
  validations () {
    return {
      topic: { required,
        isUnique(value) {
          // standalone validator ideally should not assume a field is required
          if (value === 'Select Issue Type') return false
          else return true
           }
      },
      description: { required }
    }
  },
  mounted(){
    this.showalerts();
  },
  methods:{
     handleFilesUpload(){
      this.images = this.$refs.inputfile.files[0];
     },
     createUserReportIssue(){
       this.v$.$touch()
       if(!this.v$.$invalid) {
         this.$store.commit('spinner/showLoading', true)
         const reportissue = new FormData()
         reportissue.append('image', this.images)
         reportissue.append('topic', this.topic)
         reportissue.append('description', this.description)
         reportissue.append('flat_id', localStorage.flat_id)
         reportissue.append('user_id', localStorage.user_id)
         this.$store.dispatch('reportissue/createUserReportIssue',reportissue)
       }
     },
     showalerts(){
       if (localStorage.status == "createdissue")
       {
         $('.signout_create').delay(500).slideDown(500);
         $('.signout_create').delay(4000).slideUp(500);
         localStorage.removeItem('status')
       }
     },
     goHome(){
       this.$router.push('UserHome')
     }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }

.banner-text{
  font-weight:400;
  position:absolute;
  width: 98%;
  margin-top: -5px;
  z-index: 10;
  text-align: center;
  display: none;
}
</style>
