//firebase.js

import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

var firebaseConfig = {
        apiKey: "AIzaSyC0uuqqa6aWtaLWxMwShBWBgCNONhk9hT8",
        authDomain: "willowspushnotification.firebaseapp.com",
        projectId: "willowspushnotification",
        storageBucket: "willowspushnotification.appspot.com",
        messagingSenderId: "433868862233",
        appId: "1:433868862233:web:a6256684eccb2e5963cb88",
        measurementId: "G-KNEBE2BQ85"
}

firebase.initializeApp(firebaseConfig)


const messaging = firebase.messaging();
 messaging
   .requestPermission()
   .then(function () {

     // get the token in the form of promise
     return messaging.getToken({ vapidKey:  "BNTo3OhP_UAh507_jdT4dE7b0HpCjoKM-d7JxbDUgNOF-3s38xrmS-b6aqGy_5RaJZN0gLWNa37dGle5RZccVvo" })
   })
   .then(function(token) {

      localStorage["firebase_token"] = token
     // print the token on the HTML page
   })
   .catch(function (err) {
   console.log("Unable to get permission to notify.", err);
 });

 messaging.onMessage(function(payload){
 });

 export default firebase.messaging()
