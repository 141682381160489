<template>
<!-- Add New Service Popup Model -->
<div id="create-subscription-charge" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="top:10%;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">Create Subscription Charge</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="card-body">
                    <form class="form" @submit.prevent="create_subscription_charge">
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label for="example-text-input" class="col-4 col-form-label" style="font-weight: 500;">Building Name</label>
                                    <div class="col-12">
                                        <select class="form-control custom-select" v-model="flat_id" @change="get_code($event.target.selectedIndex)" :class="{ error: v$.flat_id.$error }">
                                            <option>---Select Building---</option>
                                            <option v-for="building in building" :key="building.id" :value="building.id">{{building.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label for="example-text-input" class="col-4 col-form-label" style="font-weight: 500;">Subscription Charge</label>
                                    <div class="col-12">
                                      <input type="text" class="form-control" v-model="charge" placeholder="Enter Subscription Charge" :class="{ error: v$.charge.$error }">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Subscription Month</label>
                                    <div class="col-12">
                                        <input  type="text" class="form-control" placeholder="Enter Subscription Month" v-model="month" :class="{ error: v$.month.$error }">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                        <button type="submit" class="btn btn-success mr-2">Submit</button>
                                        <button type="button" class="btn btn-danger mr-2" data-dismiss="modal" aria-hidden="true">Cancel</button>
                                    </div>
                                </div>
                            </div>

                        </form>
           </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {mapState,mapActions} from 'vuex'
export default {
  name: 'CreateSubscriptionCharge',
  setup () {
    return { v$: useVuelidate() }
  },
  components: {

  },
  data(){
    return{
      flat_id: '---Select Building---',
      charge: '',
      month: '',
      building_id: ''
    }

  },
  computed:{
    ...mapState('subscriptioncharge',['building']),
  },
  mounted(){
    this.getbuilding_list();
  },
  validations () {
    return {
      flat_id: { required,
       isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === '---Select Building---') return false
        else return true


         }


      },
      charge: { required }, // Matches this.firstName
      month: { required } // Matches this.lastName
    }
  },
  methods:{
    ...mapActions('subscriptioncharge',['getbuilding_list']),
    get_code(selectedIndex)
    {
      this.building_id = this.building[selectedIndex-1].id

    },
    create_subscription_charge(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const charge = new URLSearchParams()
        charge.append('building_id', this.building_id)
        charge.append('subs_charge', this.charge)
        charge.append('month', this.month)
        this.$store.dispatch('subscriptioncharge/createsubscriptioncharge',charge)
      }
    }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
