<template>
  <div id="edit-profile-pic" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
<div class="modal-dialog modal-lg" >
<div class="modal-content">
<div class="modal-header">
<h4 class="modal-title" id="myModalLabel">Update Profile Picture</h4>
<button type="button" class="close" data-dismiss="modal" aria-hidden="true" @click="closeModal()">×</button>
</div>

<div class="card-body" style="padding: 25px 50px;">
  <div>
    <input
      ref="input"
      type="file"
      name=""
      accept="image/*"
      @change="setImage"
    />

    <div class="content" >
      <section class="cropper-area">
        <div class="img-cropper">
          <vue-cropper
            ref="cropper"
            :aspect-ratio="1 / 1"
            :src="imgSrc"
            preview=".preview"
            :output-size="0.1"
            :max-img-size="500"
          />
        </div>
        <div class="actions">
          <a
            href="#"
            role="button"
            @click.prevent="showFileChooser"
          >
            Upload Image
          </a>
          <a
            href="#"
            role="button"
            @click.prevent="cropImage"
            v-if="showcropbutton == true">
            Crop
          </a>
          <a
            href="#"
            role="button"
            @click.prevent="setAsProfile"
          v-if="showupload == true">
            Set Profile Image
          </a>
        </div>
      </section>
      <section class="preview-area" v-if="showcroparea == true">

        <p>Cropped Image</p>
        <div class="cropped-image">
          <img
            v-if="cropImg"
            :src="cropImg"
            alt="Cropped Image"
          />
          <div v-else class="crop-placeholder" />
        </div>
      </section>
    </div>
  </div>
</div>
</div>
</div>
</div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import router from '@/router'
export default {
  name: 'ProfilePicture',
  components: {
    VueCropper,
  },
  props:['image_url'],
  data() {
    return {
      imgSrc: this.image_url,
      cropImg: '',
      data: null,
      showcropbutton: false,
      showupload:false,
      showcroparea: false
    };
  },
  methods: {
    cropImage() {
      this.showcropbutton = false;
      this.showupload = true;
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', (20 / 100));
    },
    setImage(e) {
      this.showupload = false;
      this.showcropbutton = true;
      this.showcroparea = true;
      this.cropImg = ''
      const file = e.target.files[0];
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    setAsProfile(){
      const profilepic = new URLSearchParams()
      profilepic.append('image', this.cropImg)
      profilepic.append('user_id', localStorage.user_id)
      this.$store.dispatch('profile/setAsProfile',profilepic)
    },
    closeModal(){
      this.$router.go({})
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: Arial, Helvetica, sans-serif;
  width: 1024px;
  margin: 0 auto;
}
input[type="file"] {
  display: none;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 5px 0;
}
.header h2 {
  margin: 0;
}
.header a {
  text-decoration: none;
  color: black;
}
.content {
  display: flex;
  justify-content: space-between;
}
.cropper-area {
  width: 614px;
}
.actions {
  margin-top: 1rem;
}
.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062CC;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.preview-area {
  width: 307px;
}
.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}
.preview-area p:last-of-type {
  margin-top: 1rem;
}
.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}
.cropped-image img {
  max-width: 100%;
}
</style>
