import axios from 'axios'
import baseurl from './axioscredentials'
axios.defaults.withCredentials = true
import router from '@/router'
const servicerequest = {
  namespaced: true,
  state: {
    new_service_count: "",
    pending_service_count: "",
    complete_service_count: "",
    closed_service_count: "",
    new_count: "",
    pending_count: "",
    complete_count: "",
    closed_count: "",
    all_servicerequests: [],
    servicerequests: [],
    admin_all_servicerequests: [],
    admin_servicerequests: [],
    services: [],
    adminappartments: [],
    adminservices: [],
    admingroups:[],
    service_name: '',
    service_charge: '',
    service_date: '',
    service_time: '',
    description: '',
    service_requestable_type: '',
    service_requestable_id: '',
    status:''
  },
  getters: {

  },
  mutations: {

    SET_ADMIN_COUNTS(state,data){
      state.new_count = data.new_count,
      state.pending_count = data.pending_count,
      state.complete_count = data.complete_count,
      state.closed_count = data.closed_count
    },
    SET_ADMIN_SERVICEREQUESTS(state,data){
      state.admin_all_servicerequests = data.service_requests,
      state.admin_servicerequests = data.service_requests
    },
    SET_COUNTS(state,data){
      state.new_service_count = data.new_service_requests_count,
      state.pending_service_count = data.pending_service_requests_count,
      state.complete_service_count = data.completed_service_requests_count,
      state.closed_service_count = data.closed_service_requests_count
    },
    SET_SERVICEREQUESTS(state,data){
      state.all_servicerequests = data.service_requests,
      state.servicerequests = data.service_requests
    },
    SET_ADMIN_SERVICES(state,data){
      state.adminservices = data.services
    },
    SET_SERVICES(state,data){
      state.services = data.services
    },
    SET_SERVICEREQUEST(state,data){
      state.service_name = data.servicerequest[0].service_name,
      state.service_charge = data.servicerequest[0].service_charge,
      state.service_date = data.servicerequest[0].service_date,
      state.service_time = data.servicerequest[0].service_time,
      state.description = data.servicerequest[0].description
      state.service_requestable_type = data.servicerequest[0].service_requestable_type
      state.service_requestable_id = data.servicerequest[0].service_requestable_id
    },
    SET_ADMIN_APPARTMENTS(state,data){
      state.adminappartments = data.appartments
    },
    SET_ADMIN_GROUPS(state,data){
      state.admingroups = data.group
    },
    SET_SERVICE_REQUEST_FOR_STATUS(state,data){
      if(data.servicerequest[0].status_id == 1){
        state.status = "New"
      }
      else if (data.servicerequest[0].status_id == 2) {
        state.status = "Pending"
      }
      else if(data.servicerequest[0].status_id == 3){
        state.status = "Complete"
      }
      else if(data.servicerequest[0].status_id == 4){
        state.status = "Closed"
      }
    },
    sortByStatus(state,payload){
      state.servicerequests = state.all_servicerequests.filter(s => s.status_id == payload);
    },
    sortByStatusAdmin(state,payload){
      state.admin_servicerequests = state.admin_all_servicerequests.filter(s => s.status_id == payload);
    },
    UpdateServiceName(state,payload){
      state.service_name = payload
    },
    UpdateServiceCharge(state,payload){
      state.service_charge = payload
    },
    UpdateServiceDate(state,payload){
      state.service_date = payload
    },
    UpdateServiceTime(state,payload){
      state.service_time = payload
    },
    updateDescription(state,payload){
      state.description = payload
    },
    updateStatus(state,payload){
      state.status = payload
    },
  },
  actions: {
    getServiceRequests({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_service_requests',{flat_id: localStorage.flat_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_COUNTS',res.data),
          commit('SET_SERVICEREQUESTS',res.data)
        }).catch(error => console.log(error))
    },
    getAdminServiceRequests({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/admin_servicerequests_with_count',{building_id: localStorage.building_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_ADMIN_COUNTS',res.data),
          commit('SET_ADMIN_SERVICEREQUESTS',res.data)
        }).catch(error => console.log(error))
    },
    getServices({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/show_services',{flat_id: localStorage.flat_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_SERVICES',res.data)
        }).catch(error => console.log(error))
    },
    getAdminServices({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_admin_services',{building_id: localStorage.building_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_ADMIN_SERVICES',res.data)
        }).catch(error => console.log(error))
    },
    getAdminAppartments({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/user_assigned_appartments',{building_id: localStorage.building_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_ADMIN_APPARTMENTS',res.data)
        }).catch(error => console.log(error))
    },
    getAdminGroups({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/list_groups',{building_id: localStorage.building_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_ADMIN_GROUPS',res.data)
        }).catch(error => console.log(error))
    },
    getServiceRequest({ commit, state},servicerequest_id){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_service_request',servicerequest_id,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_SERVICEREQUEST',res.data)
        }).catch(error => console.log(error))
    },
    getAdminServiceRequest({ commit, state},servicerequest_id){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/show_admin_servicerequest',servicerequest_id,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_SERVICEREQUEST',res.data)
        }).catch(error => console.log(error))
    },
    getAdminServiceRequestForStatus({ commit, state},servicerequest_id){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/show_admin_servicerequest',servicerequest_id,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_SERVICEREQUEST',res.data),
          commit('SET_SERVICE_REQUEST_FOR_STATUS',res.data)
        }).catch(error => console.log(error))
    },
    createServiceRequest({ commit, state},servicerequest){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/create_service_request',servicerequest,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: 'UserServiceRequests'})
          localStorage.status = "created"
        }).catch(error => console.log(error))
    },
    UpdateServiceRequest({ commit, state},servicerequest){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/update_service_request',servicerequest,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: 'UserServiceRequests'})
          localStorage.status = "updated"
        }).catch(error => console.log(error))
    },
    deleteServiceRequest({ commit, state},servicerequest){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/delete_service_request',servicerequest,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: 'UserServiceRequests'})
          localStorage.status = "deleted"
        }).catch(error => console.log(error))
    },
    createAdminServiceRequestUser({ commit, state},servicerequest){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/create_admin_servicerequest_user',servicerequest,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: 'AdminServiceRequests'})
          localStorage.status = "created"
        }).catch(error => console.log(error))
    },
    createAdminServiceRequestGroup({ commit, state},servicerequest){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/create_admin_servicerequest_group',servicerequest,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: 'AdminServiceRequests'})
          localStorage.status = "created"
        }).catch(error => console.log(error))
    },
    updateAdminServiceRequest({ commit, state},servicerequest){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/update_admin_servicerequest',servicerequest,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: 'AdminServiceRequests'})
          localStorage.status = "updated"
        }).catch(error => console.log(error))
    },
    deleteAdminServiceRequest({ commit, state},servicerequest){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/delete_admin_servicerequest',servicerequest,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: 'AdminServiceRequests'})
          localStorage.status = "deleted"
        }).catch(error => console.log(error))
    },
    updateAdminStatus({ commit, state},servicerequest){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/change_servicerequest_status',servicerequest,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: ''})
          localStorage.status = "status_updated"
        }).catch(error => console.log(error))
    }

  }
}
export default servicerequest
