import axios from 'axios'
import baseurl from './axioscredentials'
axios.defaults.withCredentials = true
import router from '@/router'
const directory = {
  namespaced: true,
  state: {
    directory: [],
    directory_admin: []
  },
  getters: {

  },
  mutations: {
    Get_directory(state,data){
      var directory_list = {}

     directory_list = JSON.parse(JSON.stringify(data['directory']), function(key, value) {
       return value;
     });
      state.directory = directory_list
    },
    Get_directory_admin(state,data){

      state.directory_admin = data.directory
    },
    set_contact_details(state,data){

      state.contact_name = data.contact_details.name
      state.contact_number = data.contact_details.phone
      state.contact_email = data.contact_details.email
      state.contact_job_title = data.contact_details.job_title
      state.contact_address = data.contact_details.address

    },
    Updatename(state,payload){
      state.contact_name = payload
    },
    updatenumber(state,payload){
      state.contact_number = payload
    },
    updateemail(state,payload){
      state.contact_email = payload
    },
    updatejob(state,payload){
      state.contact_job_title = payload
    },
    updateaddress(state,payload){
      state.contact_address = payload
    },


  },
  actions: {
  	list_directory({commit,state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      var flat_id = localStorage["flat_id"]
      var tokens = ''
      axios.post(baseurl+'/api/v1/list_phone_directory',{flat_id},
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
      commit('Get_directory',res.data)
      }).catch(error => console.log(error))
    },
    list_contacts_for_admin({commit,state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      var building_id = localStorage["building_id"]
      var tokens = ''
      axios.post(baseurl+'/api/v1/admin_directory',{building_id: building_id},
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
      commit('Get_directory_admin',res.data)
      }).catch(error => console.log(error))
    },
    create_directory({commit,state},directory){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/create_new_contact',directory,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
      router.go({ name: 'AdminDirectory'})
      localStorage.status = "created"
      }).catch(error => console.log(error))
    },
    get_directory_on_id({commit,state},contact_id){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/contact_details',contact_id,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
       commit('set_contact_details',res.data)
      }).catch(error => console.log(error))
    },
    edit_contact({commit,state},contact){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/update_contact',contact,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
      router.go({ name: 'AdminDirectory'})
      localStorage.status = "updated"
      }).catch(error => console.log(error))
    },
    delete_contact({commit,state},contact){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/delete_contact',contact,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
      router.go({ name: 'AdminDirectory'})
      localStorage.status = "deleted"
      }).catch(error => console.log(error))
    },
  }
}
export default directory
