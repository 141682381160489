import axios from 'axios'
import baseurl from './axioscredentials'
axios.defaults.withCredentials = true
import router from '@/router'
const adminflatgroup = {
  namespaced: true,
  state: {
    app_list: [],
    grp_list: [],
    appartment_num: "",
    area:"",
    interior: "",
    bhk: "",
    username: "",
    phone: "",
    joining_date: "",
    parking_list: "",
    grp_name: "",
    parking_array: [],
    parking_unassigned: '',
    parking_id_list: [],
    parking_list: ''
   },
  getters: {

  },
  mutations: {
    Get_appartlist(state,data){
      state.appartment_lists = data.appartment_list
      state.appartment_count = data.appartment_count
       state.app_list = data.appartment_list
       state.dup_app_list = data.appartment_list
    },
    Get_appart(state,data){
      state.grp_list = data.appartment_list
     

    },
    Get_groups(state,data){
      state.group_lists = data.group
    },
    Get_non_parking(state,data){
      state.parking_list = data.parkings
    },
    set_group(state,data){
       state.grp_name = data.group_details.name
    },
    set_appartment_details(state,data){
       var parking_list = {}
       parking_list = JSON.parse(JSON.stringify(data.parking), function(key, value) {
         return value;
       });
       parking_list.forEach(function(item){
           state.parking_array.push(item.name)
           state.parking_id_list.push(item.id)
       });
       state.appartment_num = data.appartment_details.number
       state.area = data.appartment_details.area
       state.interior = data.appartment_details.interior
       state.bhk = data.appartment_details.bhk
       state.username = data.users.name
       state.phone = data.users.phone
       state.joining_date = data.users.created_at
       
    },
    set_appartment_values(state,data){
       state.appart_details =  data.appartment_list
    },
    updateGroupname(state,payload){
      state.grp_name = payload
    },
     updateAppartmentnum(state,payload){
      state.appartment_num = payload
    },
    updateArea(state,payload){
      state.area = payload
    },
    updateInterior(state,payload){
      state.interior = payload
    },
    updateBHK(state,payload){
      state.bhk = payload
    },
    list_all_appartment(state,payload){
       state.app_list = state.appartment_lists
    },
    update_ref_text(state,payload){
       state.parking_array = payload
    },

  },
  actions: {
    add_new_flat({commit,state},add_flat){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/add_new_flat_admin',add_flat,
              {headers:{'Authorization':localStorage.token}}).then(
       res => {
          router.go({ name: 'AdminUserList'})
           localStorage.status = "created"
        }).catch(error => console.log(error))
    },
    list_appartment({commit,state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      var building_id = localStorage["building_id"]
      var tokens = ''
      axios.post(baseurl+'/api/v1/appartment_list',{building_id},
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
      commit('Get_appartlist',res.data)
      }).catch(error => console.log(error))
    },
    list_group({commit,state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      var building_id = localStorage["building_id"]
      var tokens = ''
      axios.post(baseurl+'/api/v1/list_groups',{building_id},
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
      commit('Get_groups',res.data)
      }).catch(error => console.log(error))
    },
    get_non_assigned_parking({commit,state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      var building_id = localStorage["building_id"]
      var tokens = ''
      axios.post(baseurl+'/api/v1/get_non_assigned_parking',{building_id},
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
      commit('Get_non_parking',res.data)
      }).catch(error => console.log(error))
    },
    add_new_grp({commit,state},add_grp){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/add_new_group',add_grp,
              {headers:{'Authorization':localStorage.token}}).then(
       res => {
          router.go({ name: 'AdminUserList'})
          if (res.data.notice == "Group Name Already Exists"){
             localStorage.status = "group_exist"
          }
          else{
          localStorage.status = "group_created"
         }
        }).catch(error => console.log(error))
    },
    get_group({commit,state},group_id){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/group_details',group_id,
              {headers:{'Authorization':localStorage.token}}).then(
       res => {
        commit('set_group',res.data)
        }).catch(error => console.log(error))
    },
    deletegroup({commit,state},group){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/delete_grp',group,
              {headers:{'Authorization':localStorage.token}}).then(
       res => {
        router.go({ name: 'AdminUserList'})
        localStorage.status = "group_deleted"
        }).catch(error => console.log(error))
    },
    update_group({commit,state},group){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/update_grp',group,
              {headers:{'Authorization':localStorage.token}}).then(
       res => {
        router.go({ name: 'AdminUserList'})
        localStorage.status = "group_updated"
        }).catch(error => console.log(error))
    },
     get_appartment_details({commit,state},appart_id){
       this.dispatch('token/VerifyTokenExpiry', null, {root:true})
       axios.post(baseurl+'/api/v1/show_appartment',appart_id,
              {headers:{'Authorization':localStorage.token}}).then(
       res => {
        commit('set_appartment_details',res.data)
        }).catch(error => console.log(error))
    },
    update_appartment({commit,state},appartment){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/update_flat',appartment,
              {headers:{'Authorization':localStorage.token}}).then(
       res => {
        router.go({ name: 'AdminUserList'})
        localStorage.status = "updated"
        }).catch(error => console.log(error))
    },
    delete_appartment({commit,state},apartment_id){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/delete_flat',apartment_id,
              {headers:{'Authorization':localStorage.token}}).then(
       res => {
        router.go({ name: 'AdminUserList'})
        localStorage.status = "deleted"
        }).catch(error => console.log(error))
    },
    inactivate_flat({commit,state},flat_id){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/flat_deactive',flat_id,
              {headers:{'Authorization':localStorage.token}}).then(
       res => {
        router.go({ name: 'AdminUserList'})
        }).catch(error => console.log(error))
    },
    activate_flat({commit,state},flat_id){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/flat_active',flat_id,
              {headers:{'Authorization':localStorage.token}}).then(
       res => {
        router.go({ name: 'AdminUserList'})
        }).catch(error => console.log(error))
    },
    pending_flat({commit,state},flat_id){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/flat_pending',flat_id,
              {headers:{'Authorization':localStorage.token}}).then(
       res => {
        router.go({ name: 'AdminUserList'})
        }).catch(error => console.log(error))
    },
    list_app_details({commit,state},grp_details){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/list_groups_appartment_web',grp_details,
              {headers:{'Authorization':localStorage.token}}).then(
       res => {
        commit('Get_appart',res.data)
        }).catch(error => console.log(error))
    },
    get_non_appartment_details({commit,state},flat){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/admin_non_group_members',flat,
              {headers:{'Authorization':localStorage.token}}).then(
       res => {
        commit('set_appartment_values',res.data)
        }).catch(error => console.log(error))
    },
    add_new_member({commit,state},flat){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/add_group_members',flat,
              {headers:{'Authorization':localStorage.token}}).then(
       res => {
        router.go({ name: 'AdminUserList'})
        localStorage.status = "member_added"
        }).catch(error => console.log(error))
    },
    remove_flat_member({commit,state},flat){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/remove_from_group',flat,
              {headers:{'Authorization':localStorage.token}}).then(
       res => {
        router.go({ name: 'AdminUserList'})
        localStorage.status = "member_deleted"
        }).catch(error => console.log(error))
    },
  }
}
export default adminflatgroup
