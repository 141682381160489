<template>
  <header class="topbar" ref="myTopnav">
    <nav class="navbar top-navbar navbar-expand-md navbar-dark" >
      <!-- ============================================================== -->
      <!-- Logo -->
      <!-- ============================================================== -->
    <div class="navbar-header">
      <router-link :to="{ name: 'AdminHome' }" class="navbar-brand">
        <!-- Logo icon -->
        <b>
          <img src="../../assets/images/logo_only.png" alt="homepage" class="dark-logo" />
        </b>
        <!--End Logo icon -->
        <!-- Logo text -->
        <span>
          <img src="../../assets/images/logo-text.png" alt="homepage" class="dark-logo" />
        </span>
      </router-link>
    </div>
    <!-- ============================================================== -->
    <!-- End Logo -->
    <!-- ============================================================== -->
    <div class="navbar-collapse" ref="test2">
      <!-- ============================================================== -->
      <!-- toggle and nav items -->
      <!-- ============================================================== -->
      <ul class="navbar-nav mr-auto" >
        <!-- This is  -->
        <li class="nav-item"> <a class="nav-link nav-toggler d-block d-md-none waves-effect waves-dark" ref="navtogg" @click="navbartoggleclick"><i class="ti-menu"></i></a> </li>
        <li class="nav-item"> <a class="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark" ref="sdbrtogg" @click="sidebartoggleclick"><i class="icon-menu"></i></a> </li>
        <!-- ============================================================== -->
        <!-- Search -->
        <!-- ============================================================== -->
      </ul>
      <!-- ============================================================== -->
      <!-- User profile and search -->
      <!-- ============================================================== -->
      <ul class="navbar-nav my-lg-0">
        <!-- ============================================================== -->
        <!-- Comment -->
        <!-- ============================================================== -->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle waves-effect waves-dark" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="fa fa-bell"></i>
            <div class="notify" v-if="unread_count >=1"> <span class="heartbit"></span> <span class="point"></span> </div>
          </a>
          <div class="dropdown-menu dropdown-menu-right mailbox animated bounceInDown">
            <ul>
              <li>
                <div class="drop-title">Notifications</div>
              </li>
              <li>
                <div class="message-center">
                  <!-- Message -->
                  <div v-for="item in header_notifications" >
                  <div v-if="item.read === true" style="background-color: #edf1f5;border-color: #edf1f5">
                  <a href="javascript:void(0)" @click="find(item.id,item.link)">

                    <div class="btn btn-danger btn-circle"><i class="fa fa-link"></i></div>
                    <div class="mail-contnet">
                      <h5>{{item.subject}} </h5> <span class="mail-desc">{{item.description}}</span> <span class="time">{{format_date(item.created_at)}}</span>  </div>


                       </a>
                     </div>
                     <div v-else-if="item.read === false" >
                     <a href="javascript:void(0)" @click="find(item.id,item.link)">
                           <div class="btn btn-danger btn-circle"><i class="fa fa-link"></i></div>
                               <div class="mail-contnet">
                                    <h5>{{item.subject}}</h5> <span class="mail-desc">{{item.description}}</span> <span class="time">{{format_date(item.created_at)}}</span>
                                </div>
                     </a>
                   </div>
                    </div>
                    <!-- Message -->
                        </div>
                      </li>
                      <li>
                        <router-link :to="{ name: 'AdminNotifications' }">
                          <a class="nav-link text-center link" href="javascript:void(0);"> <strong>Check all notifications</strong> <i class="fa fa-angle-right"></i> </a>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- ============================================================== -->
                <!-- End Comment -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->

              <li class="nav-item right-side-toggle">
                  <router-link :to="{ name: 'AdminAbout' }" class="nav-link  waves-effect waves-light">
                  <i class="fa fa-info-circle"></i>
                </router-link>
              </li>
            </ul>
         </div>
      </nav>
   </header>
</template>

<script>
// @ is an alias to /src
import {mapState} from 'vuex'
import moment from 'moment'
export default {
  name: 'AdminHeader',
  components: {

  },
  data(){
    return{
      show: true
    }
  },
   computed:{
    ...mapState('notification',['header_notifications','unread_count']),

  },
    mounted(){
      this.$store.dispatch('notification/list_notification_admin_header')
      $('.scroll-sidebar, .right-side-panel, .message-center, .right-sidebar').perfectScrollbar();
      if ($("body").hasClass("mini-sidebar")) {
          $('.navbar-brand span').hide();
      }
  },
   methods: {
      format_date(value){
         if (value) {
           return moment(String(value)).format('DD/MM/YYYY')
          }
      },
      find(id,link){
        this.$store.dispatch('notification/change_notification_status_admin',id)
        this.$router.push(link);
      },
      sidebartoggleclick(){
        if ($("body").hasClass("mini-sidebar")) {
            this.set();
            $("body").removeClass("mini-sidebar");
            $('.navbar-brand span').show();
        }
        else {
            this.set();
            $("body").addClass("mini-sidebar");
            $('.navbar-brand span').hide();
        }
      },
      navbartoggleclick() {
        if ($("body").hasClass("show-sidebar")) {
          this.set();
          $("body").removeClass("show-sidebar");
          $(".nav-toggler i").removeClass("ti-close");
          $(".nav-toggler i").addClass("ti-menu");
          $('.navbar-brand span').hide();
        }
        else {
          this.set();
          $("body").addClass("show-sidebar");
          $(".nav-toggler i").removeClass("ti-menu");
          $(".nav-toggler i").addClass("ti-close");
          $('.navbar-brand span').hide();
        }
      },
      set() {
          var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
          var topOffset = 55;
          if (width < 1170) {
              $("body").addClass("mini-sidebar");
              $('.navbar-brand span').hide();
              $(".sidebartoggler i").addClass("ti-menu");
          }
          else {
              $("body").removeClass("mini-sidebar");
              $('.navbar-brand span').show();
          }
           var height = ((window.innerHeight > 0) ? window.innerHeight : this.screen.height) - 1;
          height = height - topOffset;
          if (height < 1) height = 1;
          if (height > topOffset) {
              $(".page-wrapper").css("min-height", (height) + "px");
          }
      }
   },

   watch(){

   }

}

</script>
