<template>
<div id="update-service-request" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="top:10%;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">Complaints</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" @click="closeModal()">×</button>
            </div>
            <div class="card-body">
                    <form class="form" @submit.prevent="updateAdminComplaint">
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label for="example-text-input" class="col-4 col-form-label" style="font-weight: 500;">Complaint Topic</label>
                                    <div class="col-12">
                                        <input type="text" class="form-control" v-model="topic" placeholder="Enter Complaint Topic" :disabled="buttonactive == false" :class="{ error: v$.topic.$error }">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Description</label>
                                    <div class="col-12">
                                        <textarea class="form-control" placeholder="Enter Description" rows="5" :disabled="buttonactive == false" v-model="description" :class="{ error: v$.description.$error }">Description</textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                        <button type="button" class="btn btn-success mr-2 edit-complaint" v-if="complaint_status == 1" @click="activeEditButton">Click Here To Edit</button>
                                        <button type="button" class="btn btn-danger mr-2" v-if="complaint_status == 1" data-dismiss="modal" aria-hidden="true" @click="closeModal">Cancel</button>
                                        <button type="submit" class="btn btn-info mr-2 " v-if="buttonactive == true">Update</button>
                                        <button type="button" class="btn btn-danger mr-2" data-toggle="modal" data-target="#confirm-deleted" data-dismiss="modal" v-if="buttonactive == true" @click="activeDelete">Delete</button>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
           </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>

<div v-if="deleteactive == true">
  <AdminDeleteComplaint :complaint_id="complaint_id"></AdminDeleteComplaint>
</div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import AdminDeleteComplaint from '@/components/complaints/AdminDeleteComplaint.vue'
import {mapState} from 'vuex'
export default {
  name: 'AdminUpdateComplaint',
  components: {
    AdminDeleteComplaint
  },
  setup () {
    return { v$: useVuelidate() }
  },
  props:['complaint_id','status_id'],
  data(){
    return{
      complaint_status: this.status_id,
      buttonactive: false,
      deleteactive: false
    }
  },
  computed:{
    topic: {
      get () {
        return this.$store.state.complaint.topic
      },
      set (value) {
        this.$store.commit('complaint/updateTopic', value)
      }
    },
    description: {
      get () {
        return this.$store.state.complaint.description
      },
      set (value) {
        this.$store.commit('complaint/updateDescription', value)
      }
    }
  },
  mounted(){
    this.getAdminComplaint();
  },
  validations () {
    return {
      topic: { required }, // Matches this.firstName
      description: { required } // Matches this.lastName
    }
  },
  methods:{
    getAdminComplaint(){
      const complaint_id = new URLSearchParams()
      complaint_id.append('complaint_id', this.complaint_id)
      complaint_id.append('building_id', localStorage.building_id)
      this.$store.dispatch('complaint/getAdminComplaint',complaint_id)
    },
    activeEditButton(){
      this.complaint_status = 0
      this.buttonactive = true
    },
    updateAdminComplaint(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const complaint = new URLSearchParams()
        complaint.append('complaint_id', this.complaint_id)
        complaint.append('topic', this.topic)
        complaint.append('description', this.description)
        complaint.append('building_id', localStorage.building_id)
        this.$store.dispatch('complaint/updateAdminComplaint',complaint)
      }
    },
    activeDelete(){
      this.complaint_status = 0
      this.deleteactive = true
    },
    closeModal(){
      this.buttonactive = false
      this.complaint_status = this.status_id
    }
  },
  watch:{
    complaint_id(){
      this.getAdminComplaint();
    },
    status_id(){
      this.complaint_status = this.status_id
    }
  }

}

</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
