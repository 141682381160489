<template>
  <div id="edit-address" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
              <div class="modal-content">
                  <div class="modal-header">
                      <h4 class="modal-title" id="myModalLabel">Change Email Address</h4>
                      <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                  </div>
                  <div class="card-body" style="padding: 25px 50px !important;">
                      <form class="form" @submit.prevent="update_address">
                          <div class="col-sm-12">
                              <div class="form-group row">
                                  <div class="col-md-12">
                                     <input type="text"  v-model="address" readonly="true" :placeholder="[[ address_ ]]" class="form-control form-control-line" >
                                  </div>
                              </div>
                          </div>
                          <label for="example-text-input" class="col-4 col-form-label" style="">New Address</label>
                          <div class="col-sm-12">
                              <div class="form-group row">
                                  <div class="col-md-12">
                                      <input type="text" v-model="change_address" placeholder="Enter New Address" class="form-control form-control-line" :class="{ error: v$.change_address.$error }">
                                  </div>
                              </div>
                          </div>


                          <div class="col-sm-12">
                              <div class="form-group">
                                  <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                    <button type="submit" class="btn btn-info mr-2">Update</button>
                                      <button type="button" class="btn btn-danger mr-2" data-dismiss="modal"
                                          aria-hidden="true">Cancel</button>
                                  </div>
                              </div>
                          </div>

                      </form>
                  </div>
              </div>
              <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
      </div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required} from '@vuelidate/validators'
import {mapState} from 'vuex'
export default {
  name: 'UpdateEmail',
  components: {

  },
  props:['address_'],
   data() {
    return {
      address: '',
      change_address: ''
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations: {

    change_address: {
      required

    }
  },
  computed:{

  },

  methods:{
    closeModal(){
      this.buttonactive = false
    },
    update_address(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const address = new URLSearchParams()
        address.append('address', this.change_address)
        address.append('building_id',localStorage["building_id"])
        this.$store.dispatch('profile/update_address',address)
      }
    }
  },
}

</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
