<template>
  <body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <AdminHeader/>
      <AdminSidebar/>
      <div class="page-wrapper">
          <!-- ============================================================== -->
          <!-- Container fluid  -->
          <!-- ============================================================== -->
          <div class="container-fluid">
              <!-- ============================================================== -->
              <!-- Bread crumb and right sidebar toggle -->
              <!-- ============================================================== -->
              <div class="row page-titles">
                  <div class="col-md-5 align-self-center">
                      <h4 class="text-themecolor">Banks</h4>
                  </div>
                  <div class="alert alert-success signout_create banner-text" role="alert">
                      <span><strong>Success!</strong> You have successfully added new bank details</span>
                  </div>
                  <div class="alert alert-danger signout_delete banner-text" role="alert">
                      <span><strong>Deleted!</strong> You have successfully deleted the bank details</span>
                  </div>
                  <div class="alert alert-info already_exist banner-text" role="alert">
                      <span><strong>Warning!</strong> A bank account already exists. Please delete the existing account to create a new account.</span>
                  </div>
                  <div class="col-md-7 align-self-center text-right">
                      <div class="d-flex justify-content-end align-items-center">
                          <ol class="breadcrumb">
                              <li class="breadcrumb-item"><router-link :to="{ name: 'AdminHome' }">Home</router-link></li>
                              <li class="breadcrumb-item active">Banks</li>
                          </ol>
                          <button type="button" class="btn btn-info d-none d-lg-block m-l-15" data-toggle="modal" data-target="#add-bank" @click="loadCreateForm">
                            + Create</button>
                      </div>
                  </div>
              </div>
              <div v-if="createform === true">
                <CreateBank></CreateBank>
              </div>
              <!-- ============================================================== -->
              <!-- End Bread crumb and right sidebar toggle -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- Info box -->
              <!-- ============================================================== -->

              <div class="row">
                  <div class="col-12">
                      <div class="card">
                          <div class="card-body">
                               <div class="card-body" v-if="bank.bene_id">
                                   <div class="row">
                                       <div class="col-md-12 ">
                                           <div class="form-group">
                                               <label><b>Beneficiary ID:</b> {{bank.bene_id}} </label>
                                           </div>
                                       </div>
                                   </div>
                                   <div class="row">
                                       <div class="col-md-12 ">
                                           <div class="form-group">
                                               <label><b>Beneficiary Name:</b> {{bank.bene_name}}</label>

                                           </div>
                                       </div>
                                   </div>
                                   <div class="row">
                                       <div class="col-md-12 ">
                                           <div class="form-group">
                                               <label><b>Beneficiary Phone:</b> {{bank.bene_phone}}</label>

                                           </div>
                                       </div>
                                   </div>
                                   <div class="row">
                                       <div class="col-md-12 ">
                                           <div class="form-group">
                                               <label><b>Beneficiary Email:</b> {{bank.bene_email}} </label>

                                           </div>
                                       </div>
                                   </div>
                                   <div class="row">
                                       <div class="col-md-12 ">
                                           <div class="form-group">
                                               <label><b>Bank Account:</b>{{bank.bank_account}}</label>

                                           </div>
                                       </div>
                                   </div>
                                   <div class="row">
                                       <div class="col-md-12 ">
                                           <div class="form-group">
                                               <label><b>IFSC:</b>{{bank.ifsc}}</label>

                                           </div>
                                       </div>
                                   </div>
                                   <div class="row">
                                       <div class="col-md-12 ">
                                           <div class="form-group">
                                               <label><b>VPA:</b>{{bank.vpa}}</label>

                                           </div>
                                       </div>
                                   </div>
                                   <div class="row">
                                       <div class="col-md-12 ">
                                           <div class="form-group">
                                               <label><b>Card Number:</b>{{bank.card_no}}</label>

                                           </div>
                                       </div>
                                   </div>
                                   <div class="row">
                                       <div class="col-md-12 ">
                                           <div class="form-group">
                                               <label><b>Address1:</b>{{bank.address1}}</label>

                                           </div>
                                       </div>
                                   </div>
                                   <div class="row">
                                       <div class="col-md-12 ">
                                           <div class="form-group">
                                               <label><b>Address2:</b>{{bank.address2}}</label>

                                           </div>
                                       </div>
                                   </div>
                                   <div class="row">
                                       <div class="col-md-12 ">
                                           <div class="form-group">
                                               <label><b>City:</b>{{bank.city}}</label>

                                           </div>
                                       </div>
                                   </div>
                                   <div class="row">
                                       <div class="col-md-12 ">
                                           <div class="form-group">
                                               <label><b>State:</b>{{bank.state}}</label>

                                           </div>
                                       </div>
                                   </div>
                                   <div class="row">
                                       <div class="col-md-12 ">
                                           <div class="form-group">
                                               <label><b>Pincode:</b>{{bank.pincode}}</label>

                                           </div>
                                       </div>
                                   </div>
                                   <div class="row">
                                       <div class="col-md-12 ">
                                           <div class="form-group">
                                               <button type="button" class="btn btn-danger d-none d-lg-block m-l-15" data-toggle="modal"
                                                           data-target="#delete-bank" @click="loadDeleteForm(bank.id)">Delete</button>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                               <div class="card-body" v-else>
                                    <h3>No Bank Account Added Yet!</h3>
                                </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div v-if="deleteform === true">
                <DeleteBank :bank_id='bank_id'/>
              </div>

              <!-- ============================================================== -->
              <!-- End Info box -->
              <!-- ============================================================== -->

              <!-- ============================================================== -->
              <!-- Review -->
              <!-- ============================================================== -->

              <!-- ============================================================== -->
              <!-- End Review -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- Comment - chats -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- End Comment - chats -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- End Page Content -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- Right sidebar -->
              <!-- ============================================================== -->
              <!-- .right-sidebar -->

              <!-- ============================================================== -->
              <!-- End Right sidebar -->
              <!-- ============================================================== -->
          </div>
          <!-- ============================================================== -->
          <!-- End Container fluid  -->
          <!-- ============================================================== -->
      </div>
      <!-- ============================================================== -->
      <!-- End Page wrapper  -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- footer -->
      <!-- ============================================================== -->
      <footer class="footer">
          © 2020 www.ontash.net
      </footer>
      <!-- ============================================================== -->
      <!-- End footer -->
      <!-- ============================================================== -->
  </div>
</body>
</template>
<script>
// @ is an alias to /src
import AdminHeader from '@/components/headers/AdminHeader.vue'
import AdminSidebar from '@/components/sidebars/AdminSidebar.vue'
import CreateBank from '@/components/bank/CreateBank.vue'
import DeleteBank from '@/components/bank/DeleteBank.vue'
import {mapState,mapActions,mapMutations} from 'vuex'
export default {
  name: 'AdminBanks',
  components: {
    AdminHeader,
    AdminSidebar,
    CreateBank,
    DeleteBank
  },
  computed:{
    ...mapState('bank',['bank']),
  },
  data(){
    return{
      createform: false,
      deleteform: false,
      bank_id: ''
    }

  },
  mounted(){
    this.getBanks();
    this.showalerts();
  },
  methods:{
    ...mapActions('bank',['getBanks']),
    loadCreateForm(){
      if(this.bank.bene_id){
        localStorage.status = "already_exist"
        this.showalerts();
      }
      else
      {
        this.createform = true
      }

    },
    loadDeleteForm(bank_id){
      this.deleteform = true
      this.bank_id = bank_id
    },
    showalerts(){
      if (localStorage.status == "created")
      {
        $('.signout_create').delay(500).slideDown(500);
        $('.signout_create').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "deleted")
      {
        $('.signout_delete').delay(500).slideDown(500);
        $('.signout_delete').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
      else if (localStorage.status == "already_exist")
      {
        $('.already_exist').delay(500).slideDown(500);
        $('.already_exist').delay(4000).slideUp(500);
        localStorage.removeItem('status')
      }
    }
  },
  watch: {
    banks() {
      $(this.$refs.tables).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.tables).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    }
  }
}
</script>
<style scoped>
.banner-text{
  font-weight:400;
  position:absolute;
  width: 98%;
  margin-top: -5px;
  z-index: 10;
  text-align: center;
  display: none;
}
</style>
