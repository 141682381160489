import axios from 'axios'
import baseurl from './axioscredentials'
axios.defaults.withCredentials = true
import router from '@/router'
const complaint = {
  namespaced: true,
  state: {
    new_comp_count: "",
    pending_comp_count: "",
    complete_comp_count: "",
    closed_comp_count: "",
    new_count: "",
    pending_count: "",
    complete_count: "",
    closed_count: "",
    all_complaints: [],
    complaints: [],
    admin_all_complaints: [],
    admin_complaints: [],
    appartments: [],
    topic: "",
    description: "",
    status: ""

  },
  getters: {

  },
  mutations: {
    SET_COUNTS(state,data){
      state.new_comp_count = data.new_complaints_count,
      state.pending_comp_count = data.pending_complaints_count,
      state.complete_comp_count = data.completed_complaints_count,
      state.closed_comp_count = data.closed_complaints_count
    },
    SET_COMPLAINTS(state,data){
      state.all_complaints = data.complaints,
      state.complaints = data.complaints
    },
    SET_COMPLAINT(state,data){
      state.topic = data.complaint[0].topic,
      state.description = data.complaint[0].description
    },
    SET_COMPLAINT_USER(state,data){
      state.topic = data.complaint.topic,
      state.description = data.complaint.description
    },
    SET_COMPLAINT_FOR_STATUS(state,data){
      if(data.complaint[0].status_id == 1){
        state.status = "New"
      }
      else if (data.complaint[0].status_id == 2) {
        state.status = "Pending"
      }
      else if(data.complaint[0].status_id == 3){
        state.status = "Complete"
      }
      else if(data.complaint[0].status_id == 4){
        state.status = "Closed"
      }
    },
    sortByStatus(state,payload){
      state.complaints = state.all_complaints.filter(s => s.complaint_status_id == payload);
    },
    updateTopic(state,payload){
      state.topic = payload
    },
    updateDescription(state,payload){
      state.description = payload
    },
    updateStatus(state,payload){
      state.status = payload
    },
    SET_COUNTS_ADMIN(state,data){
      state.new_count = data.new_count,
      state.pending_count = data.pending_count,
      state.complete_count = data.complete_count,
      state.closed_count = data.closed_count
    },
    SET_COMPLAINTS_ADMIN(state,data){
      state.admin_all_complaints = data.complaints,
      state.admin_complaints = data.complaints
    },
    sortByStatusAdmin(state,payload){
      state.admin_complaints = state.admin_all_complaints.filter(s => s.status_id == payload);
    },
    SET_APPARTMENTS(state,data){
      state.appartments = data.appartments
    }
  },
  actions: {
    getComplaints({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_complaints',{flat_id: localStorage.flat_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_COUNTS',res.data),
          commit('SET_COMPLAINTS',res.data)
        }).catch(error => console.log(error))
    },

    getComplaint({ commit, state},complaint_id){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_complaint',complaint_id,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_COMPLAINT_USER',res.data)
        }).catch(error => console.log(error))
    },

    createComplaint({ commit, state},complaint){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/create_complaint',complaint,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: 'UserComplaints'})
          localStorage.status = "created"
        }).catch(error => console.log(error))

    },
    updateComplaint({ commit, state},complaint){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/update_complaint',complaint,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: 'UserComplaints'})
          localStorage.status = "updated"
        }).catch(error => console.log(error))
    },
    deleteComplaint({ commit, state},complaint){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/delete_complaint',complaint,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: 'UserComplaints'})
          localStorage.status = "deleted"
        }).catch(error => console.log(error))
    },

    //actions for admin complaints
    getComplaintsAdmin({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_admin_complaints_with_count_web',{building_id: localStorage.building_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_COUNTS_ADMIN',res.data),
          commit('SET_COMPLAINTS_ADMIN',res.data)
        }).catch(error => console.log(error))
    },
    getAdminComplaint({ commit, state},complaint_id){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/show_admin_complaint',complaint_id,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_COMPLAINT',res.data)
        }).catch(error => console.log(error))
    },
    getAdminComplaintForStatus({ commit, state},complaint_id){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/show_admin_complaint',complaint_id,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_COMPLAINT',res.data),
          commit('SET_COMPLAINT_FOR_STATUS',res.data)
        }).catch(error => console.log(error))
    },
    getAppartments({ commit, state},complaints){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/user_assigned_appartments',{building_id: localStorage.building_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_APPARTMENTS',res.data)
        }).catch(error => console.log(error))
    },
    createComplaintAdmin({ commit, state},complaint){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/create_admin_complaint',complaint,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: 'AdminComplaints'})
          localStorage.status = "created"

        }).catch(error => console.log(error))
    },
    updateAdminComplaint({ commit, state},complaint){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/update_admin_complaint',complaint,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: 'AdminComplaints'})
          localStorage.status = "updated"

        }).catch(error => console.log(error))
    },
    deleteAdminComplaint({ commit, state},complaint){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/delete_admin_complaint',complaint,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: 'AdminComplaints'})
          localStorage.status = "deleted"

        }).catch(error => console.log(error))
    },
    updateAdminStatus({ commit, state},complaint){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/change_complaint_status',complaint,{headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: ''})
          localStorage.status = "status_updated"
          
        }).catch(error => console.log(error))
    },
    
  }
}
export default complaint
