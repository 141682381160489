<template>
  <div id="view-pass" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel">Pass Details</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
      </div>
      <div class="card-body" id="photo">
        <div class="row">
          <div class="col-sm-12" style="text-align: center;">
            <qrcode-vue :value="qr_token" :size="200" level="H" />

          </div>
          <div class="col-sm-12" style="text-align: center;">
            <h3>{{qr_token}}</h3>
            <hr>
          </div>
          <div class="col-sm-12">
            <table class="table table-bordered table-striped">
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>{{visitor_name_admin}}</td>
                </tr>
                <tr>
                  <th>Phone</th>
                  <td>{{phone_admin}}</td>
                </tr>
                <tr>
                  <th>Number of People</th>
                  <td>{{visitor_count_admin}}</td>
                </tr>
                <tr>
                  <th>Purpose</th>
                  <td>{{purpose_admin}}</td>
                </tr>
                <tr>
                  <th>Visit Date</th>
                  <td>{{visit_date_admin}}</td>
                </tr>
                <tr>
                  <th>Time</th>
                  <td>{{find_time(visit_time_admin)}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-info waves-effect"  @click="print()">Share to whatsapp</button>
        <button type="button" class="btn btn-default waves-effect" data-dismiss="modal">Cancel</button>
      </div>
    </div>
    <img :src="output">
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
</template>

<script>
// @ is an alias to /src
import {mapState} from 'vuex'
import QrcodeVue from 'qrcode.vue'
export default {
  name: 'ViewAdminPass',
  components: {
    QrcodeVue
  },
  props:['visitor_id','qr_token'],
  data(){
    return{
      output: null
    }
  },
  computed:{
    ...mapState('visitor',['visitor_name_admin','phone_admin','visitor_count_admin','purpose_admin','visit_date_admin','visit_time_admin'])
  },
  mounted(){
    this.showAdminVisitor();
  },
  methods:{
    showAdminVisitor(){
      const visit_id = new URLSearchParams()
      visit_id.append('visitor_id', this.visitor_id)
      visit_id.append('building_id', localStorage.building_id)
      this.$store.dispatch('visitor/show_admin_visitor',visit_id)
    },
    find_time(value){
        if (value) {
           value = value.split("T")[1]
           const parts = value.split(":");

           if (parts[0] > 12)
           {
           return +parts[0]-12 + " : " +parts[1]+' PM' ;
           }
           else if (parts[0] == 12){
           return +parts[0]+ " : " +parts[1]+' PM' ;
           }
           else
           {
             if (parts[0] == 0)
             {
               return +"12"+ " : " +parts[1]+' AM' ;
             }
             else{
               return +parts[0]+ " : " +parts[1]+' AM' ;
             }

           }
         }

     },
    print() {

      var header = "Hi "+this.visitor_name_admin +", Your Visiting pass on "+localStorage.building_name+" is confirmed."+"\r\n"
      var params = "QRcode Token: "+this.qr_token+"\r\n"+"Visitor Name: "+this.visitor_name_admin+"\r\n"+"Phone: "+this.phone_admin+"\r\n"+"Number of Peoples: "+this.visitor_count_admin+"\r\n"+"Puropose: "+this.purpose_admin+"\r\n"+"Visiting Date: "+this.visit_date_admin+"\r\n"+"Visiting Time: "+this.find_time(this.visit_time_admin)+"\r\n"
      var footer = "Please use this message for GatePass Verification.Thank you"
      var url = 'https://api.whatsapp.com/send?text='+encodeURIComponent(header)+encodeURIComponent(params)+encodeURIComponent(footer)
      window.open(url,'_blank');
    }
  },
  watch:{
    visitor_id(){
      this.showAdminVisitor();
    }
  }
}
</script>
