<template>
  <html>
  <body class="skin-default card-no-border">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <section id="wrapper">
      <div class="login-register" style="">
        <div class="login-box card">
          <div class="card-body">
            <form  @submit.prevent="selectRole">
              <h3 class="text-center m-b-20">Select Role </h3>
              <img id="croppingImage" class="img-circle" src="@/assets/images/login_logo.png" style="width: 100%">
            <!--  <div class="alert alert-info signout" style="text-align: center;" role="alert">
                <span style="font-weight:400;">Successfully Signed Out!</span>
              </div> -->
              <div class="form-group ">
                <div class="col-xs-12">
                  <input type="radio" name="availability" value="admin" v-model="role" ><label>Admin  </label>&nbsp;
                  <input type="radio" name="availability" value="user" v-model="role" ><label>User  </label>
                </div>
              </div>
                <div class="form-group row">
                  <div class="col-md-12">

                  </div>
                </div>
                <div class="form-group text-center">
                  <div class="col-xs-12 p-b-20">
                    <button class="btn btn-block btn-lg btn-info btn-rounded" type="submit">Next >></button>
                  </div>
                </div>

              </form>
              </div>
            </div>
          </div>
        </section>
      </body>

      </html>
    </template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
export default {
  name: 'SelectRole',
  components: {

  },

  computed:{


  },
  data() {
    return{
      role: 'admin'
    }
  },

  methods:{
    selectRole(){
      if(this.role == "admin"){
        this.$store.dispatch('login/setAdminRole')
      }
      else{
        localStorage["user_role"] = "User"
        if (localStorage.appartment_details == "true"){
          localStorage.removeItem('appartment_details')
          this.$router.push('SelectFlat')
        }
        else{
          localStorage.removeItem('appartment_details')
          this.$router.push('AddFlat')
        }
      }
    }
  }
}
</script>
