import axios from 'axios'
import baseurl from './axioscredentials'
axios.defaults.withCredentials = true
import router from '@/router'
import moment from 'moment'
import { parseISO } from 'date-fns'
const event = {
  namespaced: true,
  state: {
    events: []
  },
  getters: {

  },
  mutations: {
  	  Get_events(state,data){
      var event_list = {}

     event_list = JSON.parse(JSON.stringify(data['event_details']), function(key, value) {
       return value;
     });
      state.events = event_list
    },

    Get_events_admin(state,data){
      state.admin_events = data.events
    },
    set_event(state,data){
      state.event_id = data.event_details.id
      state.event_name = data.event_details.event_name
      state.organizer = data.event_details.organizer
      state.venue = data.event_details.venue
      state.description = data.event_details.description
      state.start = moment(data.event_details.start_at,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD h:mm a')
      state.end = moment(data.event_details.end_at,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD h:mm a')



    },
    set_start_date(state,data){
      var str = data.event_details.start_at
      var date = moment.utc(str);
      var dateComponent = date.format('YYYY-MM-DD');
       state.start_at  = dateComponent
    },
     set_start_time(state,data){
       var str = data.event_details.start_at
        var date = moment(str);
        var timeComponent = date.format('h:mm a');
        state.start_time  =  timeComponent
    },
    set_end_date(state,data){
      var str = data.event_details.end_at
      var date = moment.utc(str);
      var dateComponent = date.format('YYYY-MM-DD');
       state.end_at  =  dateComponent
    },
     set_end_time(state,data){
     var str = data.event_details.end_at
     var date = moment(str);
     var timeComponent = date.format('h:mm a');
       state.end_time = timeComponent
    },
    updatename(state,payload){
      state.event_name = payload
    },
    updateorganizer(state,payload){
      state.organizer = payload
    },
    updatevenue(state,payload){
      state.venue = payload
    },
    updatedescription(state,payload){
      state.description = payload
    },
    updatestart_at(state,payload){
      state.start_at = payload
    },
    updateend_at(state,payload){
      state.end_at = payload
    },
    updatestart_time(state,payload){
      state.start_time = payload
    },
    updateend_time(state,payload){
      state.end_time = payload
    },
  },
  actions: {
  	list_events({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      var flat_id = localStorage["flat_id"]
      axios.post(baseurl+'/api/v1/list_events',{flat_id},
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
        commit('Get_events',res.data)
      }).catch(error => console.log(error))
    },
    list_event_for_admin({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      const event = new URLSearchParams()
      event.append('flat_id', localStorage["flat_id"])
      event.append('building_id',localStorage.building_id)
      axios.post(baseurl+'/api/v1/admin_events',event,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {

       commit('Get_events_admin',res.data)
      }).catch(error => console.log(error))
    },
    create_event({ commit, state},event){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/create_new_event_web',event,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
        router.go({ name: 'AdminEvent'})
        localStorage.status = "created"
      }).catch(error => console.log(error))
    },
    get_event_on_id({ commit, state},event_id){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/show_event_details',event_id,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
        commit('set_event',res.data)
        commit('set_start_date',res.data)
        commit('set_start_time',res.data)
        commit('set_end_date',res.data)
        commit('set_end_time',res.data)
      }).catch(error => console.log(error))
    },
    edit_event({ commit, state},event){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/update_event_web',event,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
       router.go({ name: 'AdminEvent'})
       localStorage.status = "updated"
      }).catch(error => console.log(error))
    },
    delete_event({ commit, state},event){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/delete_event',event,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
       router.go({ name: 'AdminEvent'})
       localStorage.status = "deleted"
      }).catch(error => console.log(error))
    },

  }
}
export default event
