<template>
  <body class="skin-default card-no-border">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <section id="wrapper">
        <div class="login-register" style="background-image:url(assets/images/background/login-register.jpg);">
            <div class="login-box card" style="top:-30px;">
                <div class="card-body">
                    <form  id="loginform" @submit.prevent="selectflat">
                        <h3 class="text-center m-b-20" style="font-weight: 400;">Select Your Flat</h3>
                        <div class="row" style="justify-content: center;">
                        </div>
                        <div class="form-group row" style="padding: 15px 30px 10px 30px;margin-bottom:0px !important;">
                            <label for="example-text-input" class="col-form-label" style="font-weight: 500;margin-left: 10px;">Building Name</label>
                            <div class="col-12">
                                <select class="form-control" v-model="building_no"  @change="get_flat_no($event.target.selectedIndex)" :class="{ error: v$.building_no.$error }">
                                   <option >Select Building</option>
                                    <option v-for="item in building_id" :value="item">{{item['building_name']}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row" style="padding: 10px 30px 10px 30px;">
                            <label for="example-text-input" class="col-form-label" style="font-weight: 500;margin-left: 10px;">Flat</label>
                            <div class="col-12">
                                <select  class="form-control" v-model="flat_no" @change="getselectedvalue($event.target.selectedIndex)"  :class="{ error: v$.flat_no.$error } ">
                                  <option >Select Flat</option>
                                   <option v-for="item in flats" :value="item" :key ="item.id">{{item['appartment_number']}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row" style="justify-content: center;">
                            <div class="form-group text-center" style="width: 65%;margin-bottom:0px !important;">
                                <div class="col-xs-12 p-b-20">
                                   <button class="btn btn-block btn-lg btn-info btn-rounded" type="submit">Next >></button>
                             </div>
                            </div>
                        </div>
                        <div class="form-group m-b-0" style="margin-bottom:15px;">
                            <div class="col-sm-12 text-center">
                               Add Your Flat<a href="#" @click="add_flat()" class="text-info m-l-5" data-toggle="modal"><b>Here</b></a><br>
                                <a href="#" @click="logoutUser">Sign Out</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>


        </div>

    </section>
</body>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required} from '@vuelidate/validators'
import {mapState} from 'vuex'
export default {
  name: 'SelectFlat',
  components: {

  },
  computed:{
    ...mapState('flat',['building_id','flat_login_id'])


  },
  data() {
    return{
       flats: [],
       building_no: 'Select Building',
       flat_no: 'Select Flat',
       building:'',
       flat_id: '',
       appartment_id: '',
       building_name: ''
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations () {
    return {
      building_no: { required,
         isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === 'Select Building') return false
        else return true


         } },
      flat_no: {required ,
        isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === 'Select Flat') return false
        else return true


         }}

    }
  },
  created: function(){

      this.$store.dispatch('flat/get_login_details')

    },
  methods:{
     get_flat_no(selectedIndex)
    {
      this.flats = []
      var appart = []
      var selectedIdea = this.building_id[selectedIndex-1];
      this.building = selectedIdea.building_id
      this.building_name = selectedIdea.building_name
      this.flat_login_id.forEach((item) => {
       if (selectedIdea.building_id == item.building_id){
        appart.push(item.appartment_number);
        var search = item.appartment_number
        var count = appart.reduce(function(n, val) {
            return n + (val === search);
        }, 0);
        if (count > 1){
        }
        else{
         this.flats.push({appartment_number: item.appartment_number})
        }
       }
      // do something
      });


    },
    getselectedvalue(selectedIndex)
    {

      var selectedIdea = this.flats[selectedIndex-1];
      this.flat_id =  selectedIdea.appartment_number
       this.flat_login_id.forEach((item) => {
       if (selectedIdea.appartment_number == item.appartment_number){
         this.appartment_id = item.appartment_id
       }
      // do something
      });

    },
    add_flat(){
      this.$router.push('/addnewflat');
    },
    logoutUser(){
      this.$store.dispatch('login/logoutUser')
    },
    selectflat(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
       localStorage["building_id"]=this.building
       localStorage["flat_name"]= this.flat_id
       localStorage["flat_id"] = this.appartment_id
       localStorage["building_name"]=this.building_name
       this.$store.dispatch('login/addLoginTrack')
      this.$router.push('UserHome')

    }
    }

  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
