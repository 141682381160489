<template>
  <div id="reject-parking" class="modal" tabindex="-1" role="dialog" aria-labelledby="vcenter"
                              aria-hidden="true">
                              <div class="modal-dialog modal-dialog-centered">
                                  <div class="modal-content">
                                      <div class="modal-header">
                                          <h4 class="modal-title" id="vcenter">Reject</h4>
                                          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                                      </div>
                                      <div class="card-body" style="padding: 10px 50px;">
                                        <div class="row" style="justify-content: center;">
                                          <h4 style="margin-left: 20px"><strong>Are you sure?</strong> Do you want to reject this parking request?</h4>

                                        </div>
                                        <hr>
                                      <div class="col-sm-12">
                                        <div class="form-group">
                                          <button type="submit" class="btn btn-success mr-2 " data-dismiss="modal" aria-hidden="true" @click="rejectparking" >OK</button>

                                          <button type="button" class="btn btn-danger waves-effect"  data-dismiss="modal" style="border-color: white;">Cancel</button>
                                          
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                  <!-- /.modal-content -->
                              </div>
                              <!-- /.modal-dialog -->
                          </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'RejectParking',
  components: {

  },
  props: ['request_id' , 'flat_id'],
  methods:{
    rejectparking(){
      this.$store.commit('spinner/showLoading', true)
      const parking = new URLSearchParams()
      parking.append('request_id', this.request_id)
      parking.append('flat_id', this.flat_id)
      this.$store.dispatch('parking/reject_parking',parking)
    }
  }
}
</script>
