<template>
   <div id="show-events" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="showEventModal"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" id="showEventModal">Show Event</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                   <div class="card-body" style="padding: 10px 50px;">
                                     <div class="row" style="justify-content: center;">
                                            <div class="col-4">
                                             <b><label>Event ID</label></b>
                                             </div>
                                             <div class="col-8">
                                               <input v-model="eventid" readonly>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                             <b><label>Event Name</label></b>
                                         </div>
                                         <div class="col-8">
                                             <input v-model="eventname" readonly>
                                        </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                               <b><label>Organizer</label></b>
                                            </div>
                                            <div class="col-8">
                                                 <input v-model="org" readonly>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                               <b><label>Venue</label></b>
                                            </div>
                                            <div class="col-8">
                                                 <input v-model="ven" readonly>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <b><label>Description</label></b>
                                            </div>
                                            <div class="col-8">
                                               <input v-model="desc" readonly style="font-style: italic;">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                            <b><label>Start Date</label></b>
                                         </div>
                                         <div class="col-8">
                                           <input v-model="startdate" readonly>
                                           <input id="l8" type="hidden">
                                       </div>
                                       </div>
                                       <div class="row">
                                            <div class="col-4">
                                            <b><label>End Date</label></b>
                                            </div>
                                           <div class="col-8">
                                               <input v-model="enddate" readonly>
                                               <label id="l9" type="hidden"></label>
                                           </div>
                                       </div>
                                    </div>
                                </div>
                            </div>
                        
                        <hr>
                          <div class="col-sm-12">
                            <div class="form-group">
                             <button type="button" @click="update_event" class="btn btn-info label-success edit_event" data-dismiss="modal" data-toggle="modal" data-target="#update-events" style="border-color: white;margin-left: 10px">Update Event</button>

                            <button type="button"  @click="delete_event"  class="btn btn-info waves-effect" data-dismiss="modal" data-toggle="modal" data-target="#delete-event" style="background-color: #e46a76;border-color: white;">Delete Event</button>

                           
                            <button type="button" class="btn btn-info waves-effect" data-dismiss="modal"
                            style="border-color: white;">Cancel</button>
                        </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
             <div v-if="updateevnt === true">
               <UpdateEvent :e_id='e_id'/>
            </div>
            <div v-if="del_event === true">
               <DeleteEvent :e_id='e_id'/>
            </div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import UpdateEvent from '@/components/events/UpdateEvent.vue'
import DeleteEvent from '@/components/events/DeleteEvent.vue'
export default {
  name: 'ShowEvents',
  components: {
    UpdateEvent,   
    DeleteEvent
  
  },
  data(){
      return{
       updateevnt: false,
       del_event: false,
        e_id: '',
       
      }

    },
  props:['event_id'],

  created(){
    this.get_event();
  },
   computed:{
    eventid: {
      get () {
        return this.$store.state.event.event_id
      },
    },
    eventname: {
      get () {
        return this.$store.state.event.event_name
      },
      set (value) {
        this.$store.commit('event/updatename', value)
      }
    },
    org: {
      get () {
        return this.$store.state.event.organizer
      },
      set (value) {
        this.$store.commit('event/updateorganizer', value)
      }
    },
    ven: {
      get () {
        return this.$store.state.event.venue
      },
      set (value) {
        this.$store.commit('event/updatevenue', value)
      }
    },
    desc: {
      get () {
        return this.$store.state.event.description
      },
      set (value) {
        this.$store.commit('event/updatedescription', value)
      }
    },
    startdate: {
      get () {
        return this.$store.state.event.start
      },
     
    },
    enddate: {
      get () {
        return this.$store.state.event.end
      },
        }
  },
  methods: {
    get_event(){
        const event_id = new URLSearchParams()
        event_id.append('id', this.event_id)
        this.$store.dispatch('event/get_event_on_id',event_id)
    },
    update_event(){

      this.e_id = this.eventid
      this.updateevnt = true

    },
    delete_event(){
       this.del_event = true
       this.e_id = this.eventid
    }
  },
   watch:{
    event_id(){
      this.get_event();
    }
  }

}
</script>






  