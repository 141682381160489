import axios from 'axios'
import baseurl from './axioscredentials'
axios.defaults.withCredentials = true
import router from '@/router'
const notification = {
  namespaced: true,
  state: {
    notifications: [],
    notification: [],
    header_notifications:[],
    header_notification:[],
    notification_count: '',
    admin_flatgroup_count: '',
    admin_complaint_count: '',
    admin_servicerequest_count: '',
    admin_facility_count: '',
    admin_residencepayment_count: '',
    admin_parking_count: '',
    complaint_count: '',
    service_count: '',
    event_count: '',
    facility_count: '',
    group_count: ''

  },
  getters: {

  },
  mutations: {
  	  Get_notification(state,data){
      var notification_list = {}
     notification_list = JSON.parse(JSON.stringify(data['notification_details']), function(key, value) {
       return value;
     });
      state.notification = notification_list
      state.notification_count = data.notification_count
      state.complaint_count = notification_list.filter(s => s.page == 'Complaint' && s.read == false).length
      state.service_count = notification_list.filter(s => s.page == 'ServiceRequest' && s.read == false).length
      state.event_count = notification_list.filter(s => s.page == 'Events' && s.read == false).length
      state.facility_count = notification_list.filter(s => s.page == 'CommonFacility' && s.read == false).length
      state.group_count = notification_list.filter(s => s.page == 'Groups' && s.read == false).length

    },
    Get_notification_admin(state,data){
     state.notifications = data.notifications
     state.unread_count = data.unread_count
     state.admin_flatgroup_count =  data.notifications.filter(s => s.page == 'FlatOrGroups' && s.read == false).length
     state.admin_complaint_count =  data.notifications.filter(s => s.page == 'Complaint' && s.read == false).length
     state.admin_servicerequest_count =  data.notifications.filter(s => s.page == 'ServiceRequest' && s.read == false).length
     state.admin_facility_count =  data.notifications.filter(s => s.page == 'CommonFacility' && s.read == false).length
     state.admin_residencepayment_count =  data.notifications.filter(s => s.page == 'ResidencePayments' && s.read == false).length
     state.admin_parking_count =  data.notifications.filter(s => s.page == 'AdminParking' && s.read == false).length
    },
    Get_notification_admin_header(state,data){
      state.header_notifications = data.notifications.slice(0,5)
      state.unread_count = data.unread_count
    },
    Get_notification_header(state,data){
      state.header_notification = data.notification_details.slice(0,5)
      state.notification_count = data.notification_count
   }
  },
  actions: {
  	list_notifications({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      var count = 3
      axios.post(baseurl+'/api/v1/get_notifications', {user_id: localStorage.user_id, flat_id: localStorage.flat_id},
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
        commit('Get_notification',res.data)
      }).catch(error => console.log(error))
    },
    list_notifications_header({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_notifications', {user_id: localStorage.user_id, flat_id: localStorage.flat_id},
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
        commit('Get_notification_header',res.data)
      }).catch(error => console.log(error))
    },
    list_notification_admin({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      const notification = new URLSearchParams()
      notification.append('building_id', localStorage["building_id"])
      notification.append('user_id',localStorage.user_id)
      axios.post(baseurl+'/api/v1/admin_notifications', notification,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
       commit('Get_notification_admin',res.data)
      }).catch(error => console.log(error))
    },
    list_notification_admin_header({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      const notification = new URLSearchParams()
      notification.append('building_id', localStorage["building_id"])
      notification.append('user_id',localStorage.user_id)
      axios.post(baseurl+'/api/v1/admin_notifications', notification,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
       commit('Get_notification_admin_header',res.data)
      }).catch(error => console.log(error))
    },
    change_notification_status({ commit, state},id){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/change_notification_status', {id},
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
      }).catch(error => console.log(error))
    },
    change_notification_status_user_task_id({ commit, state},notify){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/change_notification_status_taskid', notify,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
        axios.post(baseurl+'/api/v1/get_notifications', {user_id: localStorage.user_id, flat_id: localStorage.flat_id},
                {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('Get_notification',res.data)
        }).catch(error => console.log(error))
      }).catch(error => console.log(error))
    },
    change_notification_status_admin({ commit, state},id){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/change_notification_status_admin', {id},
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
      }).catch(error => console.log(error))
    },
    change_notification_status_admin_taskid({ commit, state},taskid){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/change_notification_status_taskid_admin', taskid,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
        const notification = new URLSearchParams()
        notification.append('building_id', localStorage["building_id"])
        notification.append('user_id',localStorage.user_id)
        axios.post(baseurl+'/api/v1/admin_notifications', notification,
                {headers:{'Authorization':localStorage.token}}).then(
        res => {
         commit('Get_notification_admin',res.data)
        }).catch(error => console.log(error))
      }).catch(error => console.log(error))
    }
  }
}
export default notification
