<template>
  <html>
  <body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <UserHeader/>
      <UserSidebar/>
  <div class="page-wrapper">
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
        <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="row page-titles">
            <div class="col-md-5 align-self-center">
                <h4 class="text-themecolor">Profile</h4>
            </div>
            <div class="col-md-7 align-self-center text-right">
                <div class="d-flex justify-content-end align-items-center">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><router-link :to="{ name: 'UserHome' }">Home</router-link></li>
                        <li class="breadcrumb-item active">Profile</li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Start Page Content -->
        <!-- ============================================================== -->
        <!-- Row -->

        <div class="row">
            <!-- Column -->
            <div class="row" style="display:flex;margin-left:0px;width: 100%;">
                <div class="col-sm-4">
                    <div class="card" style="width:100%;height: 94%;">
                        <div class="card-body">
                            <center class="m-t-30">
                                <div class="image-container">
                                    <div class="book">

                                        <div class="imagehere">
                                            <div class="containers"   data-toggle="modal" data-target="#edit-profile-pic">
                                                <img :src="userprofilepicture" class="img-circle" width="150"/>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <h4 class="card-title m-t-10">{{userdetails.name}}</h4>
                                <h6 class="card-subtitle">{{userappartments.number}} / ID:{{userdetails.id}}</h6>
                            </center>
                        </div>
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="card" style="width:100%;height:94%;">
                        <div class="card-body" style="display: grid;">
                            <small class="text-muted">Email address </small>
                            <h6>{{userdetails.email}}</h6> <small class="text-muted p-t-30 db">Phone</small>
                            <h6><i class="fa fas fa-phone"></i><span class="blurphone"></span>{{userdetails.phone}}</h6> <small class="text-muted p-t-30 db"></small>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="display:flex;margin-left:10px;width: 100%;">
                <div class="card" style="width:100%;">
                    <div class="card-body">
                        <div class="map-box">
                            <iframe src="" width="100%" height="150" frameborder="0" style="border:0" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Column -->
            <!-- Column -->
            <!-- Column -->
        </div>
        <!-- Row -->
        <!-- ============================================================== -->
        <!-- End PAge Content -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Right sidebar -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- End Right sidebar -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Page wrapper  -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- footer -->
<!-- ============================================================== -->
<footer class="footer">
    © 2020 www.ontash.net
</footer>
<!-- ============================================================== -->
<!-- End footer -->
<!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- All Jquery -->
<!-- ============================================================== -->

</body>

</html>
</template>

<script>
// @ is an alias to /src
import UserHeader from '@/components/headers/UserHeader.vue'
import UserSidebar from '@/components/sidebars/UserSidebar.vue'
import {mapState,mapActions} from 'vuex'
export default {
  name: 'UserGroupMemberDetails',
  components: {
    UserHeader,
    UserSidebar
  },
  computed:{
    ...mapState('group',['userdetails','userprofile','userappartments','userprofilepicture'])
  },
  data(){
    return{

    }

  },
  mounted(){
    this.getUserDetails();
  },
  methods:{
    getUserDetails(){
      const userid = new URLSearchParams()
      userid.append('user_id',this.$route.params.user_id)
      this.$store.dispatch('group/getUserDetails',userid)
    }
  }
}
</script>
