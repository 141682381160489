import axios from 'axios'
import baseurl from './axioscredentials'
import router from '@/router'
const home = {
  namespaced: true,
  state: {
    wallet_bal: "",
    notifications_count: "",
    complaints_count: "",
    servicerequests_count: "",
    new_count: "",
    pending_count: "",
    complete_count: "",
    closed_count: "",
    complaints: [],
    servicerequests: [],
    admincomplaints: [],
    adminservicerequests: [],
    all_servicerequests: [],
    all_complaints: []
  },
  getters: {

  },
  mutations: {
    SET_COUNTS(state,data){
      state.wallet_bal = data.wallet_bal,
      state.notifications_count = data.notification_count,
      state.complaints_count = data.complaints_count,
      state.servicerequests_count = data.service_requests_count
    },
    SET_SERVICEREQUESTS(state,data){
      state.servicerequests = data.service_requests
    },
    SET_COMPLAINTS(state,data){
      state.complaints = data.complaints
    },
    SET_COUNTS_ADMIN(state,data){
      state.new_count = data.new_count,
      state.pending_count = data.pending_count,
      state.complete_count = data.complete_count,
      state.closed_count = data.closed_count
    },
    SET_SERVICEREQUESTS_ADMIN(state,data){
      state.adminservicerequests = data.service_requests,
      state.all_servicerequests = data.service_requests
    },
    SET_COMPLAINTS_ADMIN(state,data){
      state.admincomplaints = data.complaints,
      state.all_complaints = data.complaints
    },
    sortByStatusAdmin(state,payload){
      state.adminservicerequests = state.all_servicerequests.filter(s => s.status_id == payload);
      state.admincomplaints = state.all_complaints.filter(s => s.status_id == payload);
    },

  },
  actions: {
    getUserHome({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/userhome_with_count',{flat_id: localStorage.flat_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_COUNTS',res.data),
          commit('SET_SERVICEREQUESTS',res.data),
          commit('SET_COMPLAINTS',res.data)
        }).catch(error => console.log(error))
    },
    getAdminHome({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/adminhome_with_count',{building_id: localStorage.building_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_COUNTS_ADMIN',res.data),
          commit('SET_SERVICEREQUESTS_ADMIN',res.data),
          commit('SET_COMPLAINTS_ADMIN',res.data)
        }).catch(error => console.log(error))
    }

  }
}
export default home
