<template>
   <div id="add-payments" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="myModalLabel">Add Individual Payment</h4>
                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div class="card-body" style="padding-top: 0px;">
                                <div class="card" style="margin-bottom: 0px;">
                                    <form class="form" @submit.prevent="create_contact">
                                        <div class="col-sm-12 reference_nest">
                                            <div class="form-group row">
                                                <label for="example-text-input" class="col-4 col-form-label" style="font-weight: 500;">Reference</label>
                                                <div class="col-sm-12">
                                                        <div class="form-group row">
                                                            <label for="example-text-input" class="col-4 col-form-label" style="font-weight: 500;">Appartment</label>
                                                            <div class="col-12">
                                                                <select class="form-control custom-select" @change="getselectedvalue($event.target.selectedIndex)" v-model="select_flat_name" autocomplete="off"  :class="{ error: v$.select_flat_name.$error }">
                                                                  <option >Select Appartment</option>
                                                                   <option v-for="item in active_list" :value="item" :key ="item.id">{{item.number}}</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <div class="form-group row">
                                                            <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Amount to be paid</label>
                                                            <div class="input-group mb-3">
                                                                    <div class="input-group-prepend">
                                                                      <span class="input-group-text">&#x20b9;</span>
                                                                      <input type="text" style="margin-left: 10px;width:96%;" v-model="amount" :class="{ error: v$.amount.$error }" autocomplete="off"  placeholder="Enter Amount to be paid"
                                                                        >
                                                                    </div>
                                                                </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <div class="form-group row">
                                                            <label for="example-text-input" class="col-6 col-form-label" style="font-weight: 500;">Description</label>
                                                            <input type="text" v-model="desc" :class="{ error: v$.desc.$error }" style="margin-left: 10px;width:96%;" autocomplete="off"
                                                                class="form-control"   placeholder="Enter Description">

                                                        </div>
                                                    </div>
                                                  </div>
                                                </div>
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0">
                                                    <button type="submit" class="btn btn-success mr-2">Submit</button>
                                                    <button type="button" class="btn btn-danger mr-2" data-dismiss="modal">Cancel</button>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                        <!-- /.modal-content -->
                    </div>
                    <!-- /.modal-dialog -->
                </div>



</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'CreateResidentPayment',
  setup () {
    return { v$: useVuelidate() }
  },
  props:['active_list'],
  data(){
    return{
      select_flat_name: 'Select Appartment',
      amount: '',
      desc: '',
      flat_id: ''

    }

  },
   validations () {
    return {

      amount: { required },
      desc: { required } ,
        select_flat_name: { required ,
        isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === 'Select Appartment') return false
        else return true


         } },

    }
  },
  methods:{
    getselectedvalue(selectedIndex)
    {

      var selectedIdea = this.active_list[selectedIndex-1];
      this.flat_id =  selectedIdea.id


    },
    create_contact(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const pay = new URLSearchParams()

        pay.append('flat_id', this.flat_id)
        pay.append('amount', this.amount)
        pay.append('topic', this.desc)
        pay.append('building_id', localStorage["building_id"])
       this.$store.dispatch('payment/create_individual_payment',pay)
      }
      }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
</style>
