<template>
  <body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <AdminHeader/>
      <AdminSidebar/>
      <div class="page-wrapper">
          <!-- ============================================================== -->
          <!-- Container fluid  -->
          <!-- ============================================================== -->
          <div class="container-fluid">
              <!-- ============================================================== -->
              <!-- Bread crumb and right sidebar toggle -->
              <!-- ============================================================== -->
              <div class="row page-titles">
                  <div class="col-md-5 align-self-center">
                      <h4 class="text-themecolor">Subscription Charges</h4>
                  </div>
                  <div class="col-md-7 align-self-center text-right">
                      <div class="d-flex justify-content-end align-items-center">
                          <ol class="breadcrumb">
                              <li class="breadcrumb-item"><router-link :to="{ name: 'AdminHome' }">Home</router-link></li>
                              <li class="breadcrumb-item active">Subscription Charges</li>
                          </ol>
                      </div>
                  </div>
              </div>

              <!-- ============================================================== -->
              <!-- End Bread crumb and right sidebar toggle -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- Info box -->
              <!-- ============================================================== -->

              <div class="row">
                  <div class="col-12">
                      <div class="card">
                          <div class="card-body">
                            <div v-if="subscription_charges.length <= 0">
                              <div style ="padding-left:15px;padding-right:15px; width:100%">
                                                <div class="alert alert-dark">
                                                    <span>No data to show</span>
                                                </div>
                              </div>
                            </div>
                            <div v-else>
                              <div class="table-responsive">
                                  <table id="myTable" class="table table-striped" ref="tables">
                                      <thead>
                                          <tr>
                                          <th>ID</th>
                                          <th>Monthly charge</th>
                                          <th>Payment created On</th>
                                          <th>Paid date</th>
                                          <th>Action</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr v-for="subscriptioncharge in subscription_charges" :key="subscriptioncharge.id">
                                              <td> {{subscriptioncharge.id}}</td>
                                              <th>{{subscriptioncharge.amount}}</th>
                                              <td>{{format_date(subscriptioncharge.created_at)}}</td>
                                              <td>{{subscriptioncharge.paid_date}}</td>
                                              <td v-if="subscriptioncharge.status == true"><button class="btn btn-success mr-2" >Paid</button></td>
                                            	<td v-else><button class="btn btn-danger mr-2" data-toggle="modal" data-target="#confirm-payment" @click="getAmount(subscriptioncharge.amount,subscriptioncharge.id)">Pay</button></td>
                                          </tr>

                                      </tbody>
                                  </table>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div id="confirm-payment" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="top:23%;">
                  <div class="modal-dialog">
                      <div class="modal-content">
                          <div class="modal-header">
                              <h4 class="modal-title"  style="margin-left: 5%;opacity: 60%;" id="myModalLabel">Confirm Your Payment</h4>
                              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                          </div>
                          <div class="card-body" style="padding: 10px 50px;">
                              <div class="row" style="justify-content: center;">
                                  <span> {{amount}} &#x20b9;</span>
                              </div>
                                  <hr>
                                  <div class="col-sm-12" style="text-align: center;">
                                      <div class="form-group">
                                          <div class="custom-control custom-checkbox mr-sm-2">
                                              <button type="button" class="btn btn-success mr-2" data-dismiss="modal" @click="confirmPayment">Confirm Payment</button>
                                              <button type="button" data-dismiss="modal" style="margin-bottom: 5px;" class="btn btn-danger mr-2">Cancel</button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <hr>
                      </div>
                      <!-- /.modal-content -->
                  </div>
                  <!-- /.modal-dialog -->
              </div>
              <!-- ============================================================== -->
              <!-- End Info box -->
              <!-- ============================================================== -->

              <!-- ============================================================== -->
              <!-- Review -->
              <!-- ============================================================== -->

              <!-- ============================================================== -->
              <!-- End Review -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- Comment - chats -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- End Comment - chats -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- End Page Content -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- Right sidebar -->
              <!-- ============================================================== -->
              <!-- .right-sidebar -->

              <!-- ============================================================== -->
              <!-- End Right sidebar -->
              <!-- ============================================================== -->
          </div>
          <!-- ============================================================== -->
          <!-- End Container fluid  -->
          <!-- ============================================================== -->
      </div>
      <!-- ============================================================== -->
      <!-- End Page wrapper  -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- footer -->
      <!-- ============================================================== -->
      <footer class="footer">
          © 2020 www.ontash.net
      </footer>
      <!-- ============================================================== -->
      <!-- End footer -->
      <!-- ============================================================== -->
  </div>
</body>
</template>
<script>
// @ is an alias to /src
import AdminHeader from '@/components/headers/AdminHeader.vue'
import AdminSidebar from '@/components/sidebars/AdminSidebar.vue'
import {mapState,mapActions} from 'vuex'
import moment from 'moment'
import baseurl from '@/store/module/axioscredentials'
export default {
  name: 'AdminSubscriptionCharge',
  components: {
    AdminHeader,
    AdminSidebar
  },
  computed:{
    ...mapState('subscriptioncharge',['subscription_charges'])
  },
  data(){
    return{
      subscription_id: '',
      amount: ''
    }

  },
  mounted(){
    this.getSubscriptionCharges();
  },
  methods:{
    ...mapActions('subscriptioncharge',['getSubscriptionCharges']),
    getAmount(amount,subscription_id){
      this.amount = amount
      this.subscription_id = subscription_id
    },
    format_date(value){
       if (value) {
         return moment(String(value)).format('DD-MM-YYYY')
        }
    },
    confirmPayment(){
      const subscription = new URLSearchParams()
      const url = new URL(baseurl+"/api/v1/subscription_charge_sucess");
      subscription.append('subscription_id', this.subscription_id)
      subscription.append('amount', this.amount)
      subscription.append('building_id', localStorage.building_id)
      subscription.append('user_id', localStorage.user_id)
      subscription.append('returnpath', url)
      this.$store.dispatch('subscriptioncharge/confirmPayment',subscription)
    }

  },
  watch: {
    subscription_charges() {
      $(this.$refs.tables).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.tables).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    }
  }
}
</script>
<style scoped>

</style>
