<template>
  <body class="skin-default fixed-layout">
    <div id="main-wrapper">
      <AdminHeader/>
      <AdminSidebar/>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Phone Directory</h4>
                    </div>
                      <div class="alert alert-success signout_create banner-text" role="alert">
                        <span><strong>Success!</strong> You have successfully add a new contact</span>
                    </div>
                                <div class="alert alert-success signout_update banner-text" role="alert">
                                    <span><strong>Success!</strong> You have successfully updated the contact details </span>
                                </div>
                                <div class="alert alert-danger signout_delete banner-text" role="alert">
                                    <span><strong>Deleted!</strong> You have successfully deleted the contact</span>
                   </div>
                    <div class="col-md-7 align-self-center text-right">
                        <div class="d-flex justify-content-end align-items-center">
                            <ol class="breadcrumb">
                                        <li class="breadcrumb-item">Home </li>
                                <li class="breadcrumb-item active">Phone Directory</li>

                            </ol>
                            <button type="button" @click="add_contact" class="btn btn-info d-lg-block m-l-15" data-toggle="modal"
                                                                      data-target="#add-new-request">
                                                                      + New Contact</button>
                        </div>
                    </div>
                </div>
                 <div v-if="addcontact === true">
                  <CreateDirectory></CreateDirectory>
                </div>
                 <div v-if="updatecontact === true">
                   <UpdateDirectory :contact_id='contact_id'/>
                </div>
                 <div v-if="deletecontact === true">
                   <DeleteDirectory :contact_id='contact_id'/>
                </div>

                <div class="row">
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="card-body">
                                              <div v-if="directory_admin.length <= 0">
                                                <div style ="padding-left:15px;padding-right:15px; width:100%">
                                                                  <div class="alert alert-dark">
                                                                      <span>No data to show</span>
                                                                  </div>
                                                </div>
                                              </div>
                                              <div v-else>
                                                    <div class="table-responsive">
                                                         <table id="myTable" class="table  table-striped" ref="tables">
                                                            <thead>
                                                                <tr>
                                                                    <th>Contact Name</th>
                                                                    <th>Contact Number</th>
                                                                    <th>Email</th>
                                                                    <th>Job Title</th>
                                                                    <th>Address</th>
                                                                    <th>Edit</th>
                                                                    <th>Delete</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                 <tr v-for="item in directory_admin " :key ="item.id">
                                                                    <td>{{ item.name }}</td>
                                                                    <td>{{ item.phone }}</td>
                                                                    <td>{{ item.email }}</td>
                                                                    <td>{{ item.job_title }}</td>
                                                                    <td>{{ item.address }}</td>
                                                                    <td><a href="" @click="update_contact(item.id)" class="label label-table label-info" data-toggle="modal" data-target="#edit-contact">Edit</a></td>
                                                                    <td><a href=""  @click="delete_contact(item.id)" class="label label-table label-danger"  data-toggle="modal" data-target="#delete-contact">Delete</a></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                  </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

            </div>
        </div>
    </div>
    <footer class="footer">
      © 2020 www.ontash.net
    </footer>
</body>
</template>
<script>
// @ is an alias to /src
import AdminHeader from '@/components/headers/AdminHeader.vue'
import AdminSidebar from '@/components/sidebars/AdminSidebar.vue'
import CreateDirectory from '@/components/Directory/CreateDirectory.vue'
import UpdateDirectory from '@/components/Directory/UpdateDirectory.vue'
import DeleteDirectory from '@/components/Directory/DeleteDirectory.vue'
import {mapState} from 'vuex'
export default {
  name: 'AdminDirectory',
  components: {
    AdminHeader,
    AdminSidebar,
    CreateDirectory,
    UpdateDirectory,
    DeleteDirectory
  },
  data(){
       return{
        addcontact: false,
        updatecontact: false,
        contact_id: '',
        deletecontact: false

       }

   },
   computed:{
     ...mapState('directory',['directory_admin']),

   },
   mounted(){
    this.geteventadmin();
     this.showalerts();
   },

  methods:{
     add_contact(){
        this.addcontact = true
     },
     update_contact(id){
      this.updatecontact = true
      this.contact_id = id
     },
     delete_contact(id){
      this.deletecontact = true
      this.contact_id = id
     },
     geteventadmin(){
      this.$store.dispatch('directory/list_contacts_for_admin')
    },
     showalerts(){
        if (localStorage.status == "created")
        {
          $('.signout_create').delay(500).slideDown(500);
          $('.signout_create').delay(4000).slideUp(500);
          localStorage.removeItem('status')
        }
        else if (localStorage.status == "updated")
        {
          $('.signout_update').delay(500).slideDown(500);
          $('.signout_update').delay(4000).slideUp(500);
          localStorage.removeItem('status')
        }
        else if (localStorage.status == "deleted")
        {
          $('.signout_delete').delay(500).slideDown(500);
          $('.signout_delete').delay(4000).slideUp(500);
          localStorage.removeItem('status')
        }
      }



   },

   watch: {
     directory_admin() {
      $(this.$refs.tables).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.tables).DataTable({
           destroy: true,
          "ordering":false
        });
      });
    }
 }
}
</script>
  <style>
    .banner-text{
    font-weight:400;
    position:absolute;
    width: 98%;
    margin-top: -5px;
    z-index: 10;
    text-align: center;
    display: none;
}

</style>
