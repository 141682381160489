<template>
<aside class="left-sidebar">
<!-- Sidebar scroll-->
  <div class="scroll-sidebar">
  <!-- User Profile-->
  <div class="user-profile">
  <div class="user-pro-body">
  <div>
    <img :src="profilepic" alt="user-img" class="img-circle" style="width:60%;" data-toggle="modal" data-target="#edit-profile-pic" @click="viewImageUpload">
  </div>
  <div class="dropdown">

    <a href="javascript:void(0)" class="dropdown-toggle u-dropdown link hide-menu" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
      Ontash India
      <span class="caret"><br/></span>
    </a>
  <div class="dropdown-menu animated flipInY">
    <!-- text-->
      <a href="javascript:void(0)" class="dropdown-item">
        <i class="ti-user"></i> My Profile</a>
            <div class="dropdown-item" @click="logoutUser">
              <i class="fa fa-power-off"></i>Logout</div>
              <!-- text-->
            </div>
          </div>
        </div>
      </div>
      <!-- Sidebar navigation-->
      <nav class="sidebar-nav">
        <ul id="sidebarnav">
          <li>
            <router-link :to="{ name: 'SuperAdminAuditFeature' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa fas fa-list-alt"></i>
              <span class="hide-menu">Audit Feature</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'SuperAdminBuildingBanks' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa fa-university"></i>
              <span class="hide-menu">Building Banks</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'SuperAdminSubscriptionCharge' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa fa-credit-card"></i>
              <span class="hide-menu">Subscription Charge</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'SuperAdminReportIssues' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa fas fa-bug"></i>
              <span class="hide-menu">Report Issues</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'SuperAdminActivitySummary' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa fas fa-copy"></i>
              <span class="hide-menu">Activity Summary</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'SuperAdminTrailPaidSummary' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa fas fa-copy"></i>
              <span class="hide-menu">Trail/Paid Summary</span>
            </router-link>
          </li>

          <li>
            <router-link :to="{ name: 'SuperAdminHelpAndFeedback' }" class="has-arrow waves-effect waves-dark" aria-expanded="false">
              <i class="fa fas fa-question-circle"></i>
              <span class="hide-menu">Help & Feedback</span>
            </router-link>
          </li>

        </ul>
      </nav>
      <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
  </aside>
  <div v-if="imagemodal == true">
    <ProfilePicture :image_url = 'profilepic'></ProfilePicture>
  </div>
</template>

<script>
// @ is an alias to /src
import ProfilePicture from '@/components/sidebars/ProfilePicture.vue'
import {mapState,mapActions} from 'vuex'
export default {
  name: 'SuperAdminSidebar',
  components: {
    ProfilePicture
  },
  data(){
    return{
      imagemodal: false
    }

  },
  computed:{
    ...mapState('profile',['profilepic'])

  },
  mounted(){
    this.getProfileImage();

  },
  methods:{
    ...mapActions('profile',['getProfileImage']),

    logoutUser(){
      this.$store.dispatch('login/logoutUserSuperAdmin')
    },
    viewImageUpload(){
      this.imagemodal = true;
    }

  }
}
</script>

<style>
    @media screen and (min-width: 992px) {
        .flat{
    display:none;
    color:#01c0c8!important;
    width: 100%;
    padding:6px 25px;
    }
    .flat:hover{
    color:tomato!important;
}
}
@media screen and (max-width: 600px){
    .flat{
    display:inline-flex !important;
    color:#01c0c8!important;
    width: 100%;
    padding:6px 25px !important;
}
}
@media screen and (max-width: 768px) {
    .flat{
    display:inline-flex !important;
    color:#01c0c8!important;
    width: 100%;
    padding:6px 25px !important;
}
}
</style>
