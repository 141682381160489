<template>
 <div id="make-payment-individual" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true" style="top:20%">
     <div class="modal-dialog">
         <div class="modal-content">
             <div class="modal-header">
                 <h4 class="modal-title" id="myModalLabel">Payments</h4>
                 <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
             </div>
              <div class="card-body" style="padding: 25px 50px;">
                                    <div class="table-responsive">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Topic</th>
                                                    <th class="tabitem">Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{{topic}}</td>
                                                    <td  class="tabitem">{{amount}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <hr>
                                    <div class="col-sm-12" style="display: contents !important;">
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox mr-sm-2" style="padding-left:0;float:right;margin-right: 0px !important;">
                                                <button type="submit" data-dismiss="modal" class="btn btn-danger mr-2">Cancel</button>
                                                <button type="button" @click="make_payment" class="btn btn-success mr-2" data-dismiss="modal" data-toggle="modal" data-target="#confirm-deleted" >Make Payment</button>
                                            </div>
                                        </div>
                                    </div>
                           </div>

         </div>
         <!-- /.modal-content -->
     </div>
     <!-- /.modal-dialog -->
 </div>
 <div v-if="confirmpayment == true">
  <ConfirmPayment :status="status" :payment="payment"></ConfirmPayment>
</div>

</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {mapState} from 'vuex'
import ConfirmPayment from '@/components/payment/ConfirmPayment.vue'
export default {
  name: 'CreateUserPayment',
  data(){
    return{
      status: '',
      confirmpayment: false
    }

  },
  components: {
    ConfirmPayment
  },
  computed:{
    ...mapState('payment',['topic' , 'amount'])

  },
  props: ['payment_id','walletblnz'],
  created(){
    this.getpayment();
  },
  methods: {
    getpayment(){
       const payment_id = new URLSearchParams()
       payment_id.append('id', this.payment_id)
       this.$store.dispatch('payment/get_user_payment',payment_id)
    },
    make_payment(){
      if (this.walletblnz >= this.amount){
        this.status = true
      }
      else{
        this.status = false
      }
       const payment = new URLSearchParams()
       payment.append('payment_id', this.payment_id)
       payment.append('topic', this.topic)
       payment.append('amount', this.amount)
       payment.append('flat_id', localStorage["flat_id"])
       this.payment = payment
       this.confirmpayment = true

    },

  },
   watch:{
    payment_id(){
      this.getpayment();
    }
  }
}
</script>
