<template>
  <body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <SuperAdminHeader/>
      <SuperAdminSidebar/>
      <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
          <!-- ============================================================== -->
          <!-- Bread crumb and right sidebar toggle -->
          <!-- ============================================================== -->
          <div class="row page-titles">
            <div class="col-md-5 align-self-center">
              <h4 class="text-themecolor">Audit Feature</h4>
            </div>
            <div class="col-md-7 align-self-center text-right">
              <div class="d-flex justify-content-end align-items-center">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><router-link :to="{ name: 'SuperAdminHome' }">Home</router-link></li>
                  <li class="breadcrumb-item active">Audit Feature</li>
                </ol>
              </div>
            </div>
          </div>

          <!-- ============================================================== -->
          <!-- End Bread crumb and right sidebar toggle -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Info box -->
          <!-- ============================================================== -->
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row m-t-40">
                  </div>
                  <div v-if="logintracks.length <= 0">
                    <div style ="padding-left:15px;padding-right:15px; width:100%">
                                      <div class="alert alert-dark">
                                          <span>No data to show</span>
                                      </div>
                    </div>
                  </div>
                  <div v-else>
                  <div class="table-responsive">
                    <table id="myTable" class="table table-striped"  ref="tables">
                      <thead>
                        <tr>
                          <th>Username</th>
                          <th>Building Name</th>
                          <th>Login Date</th>
                          <th>Login Time</th>
                          <th>Logout Date</th>
                          <th>Logout Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="logintrack in logintracks" :key="logintrack.id">
                          <td>{{logintrack.username}}</td>
                          <td> {{logintrack.building_name}}</td>
                          <td> {{logintrack.login_date}}</td>
                          <td>{{logintrack.login_time}}</td>
                          <td>{{logintrack.logout_date}}</td>
                          <td>{{logintrack.logout_time}}</td>
                        </tr>
                    </tbody>
                    </table>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ============================================================== -->
          <!-- End Info box -->
          <!-- ============================================================== -->

          <!-- ============================================================== -->
          <!-- Review -->
          <!-- ============================================================== -->

          <!-- ============================================================== -->
          <!-- End Review -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Comment - chats -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- End Comment - chats -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- End Page Content -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Right sidebar -->
          <!-- ============================================================== -->
          <!-- .right-sidebar -->

          <!-- ============================================================== -->
          <!-- End Right sidebar -->
          <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
      </div>
      <!-- ============================================================== -->
      <!-- End Page wrapper  -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- footer -->
      <!-- ============================================================== -->
      <footer class="footer">
        © 2020 www.ontash.net
      </footer>
      <!-- ============================================================== -->
      <!-- End footer -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Wrapper -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- All Jquery -->
    <!-- ============================================================== -->
  </body>
</template>

<script>
// @ is an alias to /src
import SuperAdminHeader from '@/components/headers/SuperAdminHeader.vue'
import SuperAdminSidebar from '@/components/sidebars/SuperAdminSidebar.vue'

import {mapState,mapActions,mapMutations} from 'vuex'
import moment from 'moment'
export default {
  name: 'SuperAdminAuditFeature',
  components: {
    SuperAdminHeader,
    SuperAdminSidebar,
  },
  data(){
    return{

    }

  },
  computed:{
    ...mapState('login',['logintracks'])
  },
  mounted(){
    this.getLoginTracks();
  },
  methods:{
    ...mapActions('login',['getLoginTracks'])
  },
  watch: {
    logintracks() {
      $(this.$refs.tables).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.tables).DataTable({
           destroy: true,
          "ordering":false
        }).draw();
      });
    }
  }
}
</script>
<style scoped>

</style>
