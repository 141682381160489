<template>
<!-- Add New Service Popup Model -->
<div id="add-bank" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                    aria-hidden="true" style="top:5%;">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="myModalLabel">Add Bank</h4>
                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <form @submit.prevent="createBank">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label class="required">Beneficiary Name<span class="requires">*</span></label>
                                                <input type="text" class="form-control" v-model="bene_name" placeholder="Enter Beneficiary Name" :class="{ error: v$.bene_name.$error }">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label class="required">Email<span class="requires">*</span></label>
                                                <input type="email" class="form-control" placeholder="Enter Email" v-model="bene_email" :class="{ error: v$.bene_email.$error }">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label class="required">Phone Number<span class="requires">*</span></label>
                                                <input type="tel" class="form-control" placeholder="Enter Phone Number" v-model="bene_phone" :class="{ error: v$.bene_phone.$error }">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label class="required">Bank Account Number<span class="requires">*</span></label>
                                                <input type="text" class="form-control" placeholder="Enter Bank Account Number" v-model="bank_account" :class="{ error: v$.bank_account.$error }">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label class="required">IFSC<span class="requires">*</span></label>
                                                    <input type="text" class="form-control"  placeholder="Enter IFSC " v-model="ifsc" :class="{ error: v$.ifsc.$error }">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>VPA</label>
                                                    <input type="text" class="form-control" v-model="vpa"  placeholder="Enter vpa" >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>Card Number</label>
                                                    <input type="text" class="form-control" v-model="card_no"  placeholder="Enter Card Number" >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label class="required">Address1<span class="requires">*</span></label>
                                                    <input type="text" class="form-control" placeholder="Enter Address1" v-model="address1" :class="{ error: v$.address1.$error }">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>Address2</label>
                                                    <input type="text" class="form-control" v-model="address2" placeholder="Enter Address2" >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>City</label>
                                                    <input type="text" class="form-control" v-model="city" placeholder="Enter City" >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>State</label>
                                                    <input type="text" class="form-control" v-model="state" placeholder="Enter State" >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>Pincode</label>
                                                    <input type="text" class="form-control" v-model="pincode" placeholder="Enter Pincode" >
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="modal-footer">
                                    <button type="submit" class="btn btn-success waves-effect">Submit</button>
                                    <button type="button" class="btn btn-default waves-effect" data-dismiss="modal">Cancel</button>
                                </div>
                            </form>
                        </div>
                        <!-- /.modal-content -->
                    </div>
                    <!-- /.modal-dialog -->
                </div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'CreateComplaint',
  setup () {
    return { v$: useVuelidate() }
  },
  components: {

  },
  data(){
    return{
      bene_name: '',
      bene_phone: '',
      bene_email: '',
      bank_account: '',
      ifsc: '',
      vpa: '',
      card_no: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      pincode: '',
    }

  },
  validations () {
    return {
      bene_name: { required },
      bene_phone: { required },
      bene_email: { required },
      bank_account: { required },
      ifsc: { required },
      address1: { required }
    }
  },
  methods:{
    createBank(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const bank = new URLSearchParams()
        bank.append('bene_name', this.bene_name)
        bank.append('bene_phone', this.bene_phone)
        bank.append('bene_email', this.bene_email)
        bank.append('bank_account', this.bank_account)
        bank.append('ifsc', this.ifsc)
        bank.append('vpa', this.vpa)
        bank.append('card_no', this.card_no)
        bank.append('address1', this.address1)
        bank.append('address2', this.address2)
        bank.append('city', this.city)
        bank.append('state', this.state)
        bank.append('pincode', this.pincode)
        bank.append('building_id', localStorage.building_id)
        this.$store.dispatch('bank/createBank',bank)
      }
    }
  }
}
</script>
<style scoped>
.error {
    border: 1px solid red;
  }
  .requires {
    color: red;
    }
</style>
