import axios from 'axios'
import baseurl from './axioscredentials'
axios.defaults.withCredentials = true
import router from '@/router'
import moment from 'moment'
const visitor = {
  namespaced: true,
  state: {
    visitor_list: [],
    appartment_details: [],
    visitors_admin: [],
    visitor_name: '',
    phone: '',
    visitor_count: '',
    purpose: '',
    visit_date: '',
    visit_time: '',
    visitor_name_admin: '',
    phone_admin: '',
    visitor_count_admin: '',
    purpose_admin: '',
    visit_date_admin: '',
    visit_time_admin: ''
  },
  getters: {

  },
  mutations: {
    SET_VISITOR_DETAIL(state,data){
      state.visitor_name = data.visitor.visitor_name
      state.phone = data.visitor.phone
      state.visitor_count = data.visitor.visitor_count
      state.purpose = data.visitor.purpose
      state.visit_date = moment(String(data.visitor.visit_date)).format('DD-MM-YYYY')
      state.visit_time = data.visitor.visit_time
    },
    SET_VISITOR_DETAIL_admin(state,data){
      state.visitor_name_admin = data.visitor.visitor_name
      state.phone_admin = data.visitor.phone
      state.visitor_count_admin = data.visitor.visitor_count
      state.purpose_admin = data.visitor.purpose
      state.visit_date_admin = moment(String(data.visitor.visit_date)).format('DD-MM-YYYY')
      state.visit_time_admin = data.visitor.visit_time
    },
    get_list(state,data){
      state.visitor_list = data.visitors

    },
    SET_appartment_details(state,data){
      state.appartment_details = data.appartment_details
    },
    get_list_admin(state,data){
      state.visitors_admin = data.visitors
    },

  },
  actions: {
     createvisitor({ commit,state},pass){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/create_user_visitor',pass,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          router.go({ name: 'UserVisitorPass'})
          localStorage.status = "created"
        }).catch(error => console.log(error))
    },
    get_visitors_list({ commit,state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_user_visitors',{flat_id:  localStorage.flat_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('get_list',res.data)
        }).catch(error => console.log(error))
    },
    deletevisitor({ commit,state},visitor){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/delete_user_visitor',visitor,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
           router.go({ name: 'UserVisitorPass'})
          localStorage.status = "deleted"
        }).catch(error => console.log(error))
    },
    get_appartment_details({ commit, state},building_id){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_appartment_details',{building_id:  localStorage.building_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {

          commit('SET_appartment_details',res.data)
        }).catch(error => console.log(error))
    },
    createvisitoradmin({ commit,state},pass){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/create_admin_visitor',pass,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          console.log(res.data)
          router.go({ name: 'AdminVisitorPass'})
          localStorage.status = "created"
        }).catch(error => console.log(error))
    },
    get_visitors_list_admin({ commit,state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_admin_visitors',{building_id:  localStorage.building_id},
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('get_list_admin',res.data)
        }).catch(error => console.log(error))
    },
    deleteadminvisitor({ commit,state},visitor){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/delete_admin_visitor',visitor,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
           router.go({ name: 'AdminVisitorPass'})
          localStorage.status = "deleted"
        }).catch(error => console.log(error))
    },
    showUserVisitor({ commit,state},visit_id){
     this.dispatch('token/VerifyTokenExpiry', null, {root:true})
     axios.post(baseurl+'/api/v1/show_user_visitor',visit_id,
             {headers:{'Authorization':localStorage.token}}).then(
       res => {
         commit('SET_VISITOR_DETAIL',res.data)
       }).catch(error => console.log(error))
   },
   show_admin_visitor({ commit,state},visit_id){
     this.dispatch('token/VerifyTokenExpiry', null, {root:true})
     axios.post(baseurl+'/api/v1/show_admin_visitor',visit_id,
             {headers:{'Authorization':localStorage.token}}).then(
       res => {
         commit('SET_VISITOR_DETAIL_admin',res.data)
       }).catch(error => console.log(error))
   },

  }
}
export default visitor
