<template>
  <!-- Add New Service Popup Model -->
<div id="add-service" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    aria-hidden="true" style="top:10%;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">Add New Service</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="card-body" style="padding: 10px 50px;">
              <div class="row" style="justify-content: center;">
                    <div class="col-md-12 ">
                        <div class="form-group">
                            <label>Service Name</label>
                            <input type="text" class="form-control" placeholder="Enter Service Name" v-model="service_name" autocomplete="off" :class="{ error: v$.service_name.$error }">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 ">
                        <div class="form-group">
                            <label> Description</label>
                            <textarea class="form-control" rows="3" v-model="description" placeholder="Enter Description"  autocomplete="off" :class="{ error: v$.description.$error }"></textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                <div class="col-md-12 ">
                    <div class="form-group">
                        <label>Service Charge</label>
                        <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">&#x20b9;</span>
                                <input type="number" class="form-control" aria-label="Text input with dropdown button" placeholder="Enter Service Charge " v-model="service_amount" autocomplete="off" :class="{ error: v$.service_amount.$error }">

                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
               <hr>
            <div class="col-sm-12">
              <div class="form-group">

              <button type="submit" class="btn btn-success mr-2" @click="createService">Submit</button>
              <button type="button" class="btn btn-danger mr-2" data-dismiss="modal" aria-hidden="true">Cancel</button>
            </div>
        </div>
      </div>
      </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
</template>

<script>
// @ is an alias to /src
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'CreateService',
  components: {

  },
  setup () {
    return { v$: useVuelidate() }
  },
  data(){
    return{
      service_name: '',
      service_amount: '',
      description: ''
    }

  },
  validations () {
    return {
      service_name: { required },
      service_amount: { required },
      description: { required }
    }
  },
  methods:{
    createService(){
      this.v$.$touch()
      if(!this.v$.$invalid) {
        this.$store.commit('spinner/showLoading', true)
        const service = new URLSearchParams()
        service.append('service_name', this.service_name)
        service.append('service_charge', this.service_amount)
        service.append('description', this.description)
        service.append('building_id', localStorage.building_id)
        this.$store.dispatch('service/createService',service)
      }
    }
  }
}
</script>

<style scoped>
.error {
    border: 1px solid red;
  }
</style>
