<template>
  <body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <SuperAdminHeader/>
      <SuperAdminSidebar/>
      <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
          <!-- ============================================================== -->
          <!-- Bread crumb and right sidebar toggle -->
          <!-- ============================================================== -->
          <div class="row page-titles">
            <div class="col-md-5 align-self-center">
              <h4 class="text-themecolor">Building Banks</h4>
            </div>
            <div class="col-md-7 align-self-center text-right">
              <div class="d-flex justify-content-end align-items-center">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><router-link :to="{ name: 'SuperAdminHome' }">Home</router-link></li>
                  <li class="breadcrumb-item active">Building Banks</li>
                </ol>
              </div>
            </div>
          </div>

          <!-- ============================================================== -->
          <!-- End Bread crumb and right sidebar toggle -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Info box -->
          <!-- ============================================================== -->
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row m-t-40">
                  </div>
                  <div v-if="banksdetails.length <= 0">
                    <div style ="padding-left:15px;padding-right:15px; width:100%">
                                      <div class="alert alert-dark">
                                          <span>No data to show</span>
                                      </div>
                    </div>
                  </div>
                  <div v-else>
                  <div class="table-responsive">
                    <table id="myTable" class="table table-striped"  ref="tables">
                      <thead>
                        <tr>
                          <th>Building Name</th>
                          <th>Benificiary ID</th>
                          <th>Benificiary Name</th>
                          <th>Bank Account</th>
                          <th>IFSC</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="banksdetail in banksdetails" :key="banksdetail.bank_id">
                          <td><a  @click="loadShowForm(banksdetail.bank_id)" data-toggle="modal" data-target="#show-bank">{{banksdetail.building_name}}</a></td>
                          <td> {{banksdetail.bene_id}}</td>
                          <td> {{banksdetail.bene_name}}</td>
                          <td>{{banksdetail.bank_account}}</td>
                          <td>{{banksdetail.ifsc}}</td>
                        </tr>
                    </tbody>
                    </table>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="showform === true">
            <ShowBuildingBank :bank_id ='bank_id' />
          </div>

          <!-- ============================================================== -->
          <!-- End Info box -->
          <!-- ============================================================== -->

          <!-- ============================================================== -->
          <!-- Review -->
          <!-- ============================================================== -->

          <!-- ============================================================== -->
          <!-- End Review -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Comment - chats -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- End Comment - chats -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- End Page Content -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Right sidebar -->
          <!-- ============================================================== -->
          <!-- .right-sidebar -->

          <!-- ============================================================== -->
          <!-- End Right sidebar -->
          <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
      </div>
      <!-- ============================================================== -->
      <!-- End Page wrapper  -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- footer -->
      <!-- ============================================================== -->
      <footer class="footer">
        © 2020 www.ontash.net
      </footer>
      <!-- ============================================================== -->
      <!-- End footer -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Wrapper -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- All Jquery -->
    <!-- ============================================================== -->
  </body>
</template>

<script>
// @ is an alias to /src
import SuperAdminHeader from '@/components/headers/SuperAdminHeader.vue'
import SuperAdminSidebar from '@/components/sidebars/SuperAdminSidebar.vue'
import ShowBuildingBank from '@/components/bank/ShowBuildingBank.vue'

import {mapState,mapActions,mapMutations} from 'vuex'
import moment from 'moment'
export default {
  name: 'SuperAdminBuildingBanks',
  components: {
    SuperAdminHeader,
    SuperAdminSidebar,
    ShowBuildingBank
  },
  data(){
    return{
      showform: false,
      bank_id: ''
    }

  },
  computed:{
    ...mapState('bank',['banksdetails'])
  },
  mounted(){

    this.getBuildingBanks();
  },
  methods:{
    ...mapActions('bank',['getBuildingBanks']),

    loadShowForm(bnk_id){
      console.log("Hello")
      this.bank_id = bnk_id
      this.showform = true
    }
  },
  watch: {
    banksdetails() {
      $(this.$refs.tables).DataTable().destroy();
      this.$nextTick(() => {
        $(this.$refs.tables).DataTable({
           destroy: true,
          "ordering":false
        }).draw();
      });
    }
  }
}
</script>
<style scoped>

</style>
