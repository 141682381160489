import axios from 'axios'
import baseurl from './axioscredentials'
axios.defaults.withCredentials = true
import router from '@/router'
import moment from 'moment'
const parking = {
  namespaced: true,
  state: {
    parkings: '',
    name: '',
    category: '',
    parking_req: '',
    pending_count: ''

  },
  getters: {

  },
  mutations: {
  	  GET_PARKING_DETAILS(state,data){
      state.name = data.parking[0].name,
      state.category = data.parking[0].category
    },

    GET_ALL_PARKINGS(state,data){
      state.parkings = data.parkings
    },
     update_name(state,payload){
       state.name = payload
     },
     update_category(state,payload){
       state.category = payload
     },
     GET_ALL_PARKINGS_REQUEST(state,data){
      state.parking_req = data.parking_req
      state.pending_count = data.pending_count
    },
  },
  actions: {
    create_parking({commit,state},parking){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/create_admin_parking',parking,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
            var sets = JSON.stringify(res.data)
            if (sets.includes("error"))
              {
                router.go({ name: 'AdminParking'})
                localStorage.status = "parking_exist"
              }
            else{
              router.go({ name: 'AdminParking'})
              localStorage.status = "created"
            }

        }).catch(error => console.log(error))
    },
    update_parking({commit,state},parking){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
       axios.post(baseurl+'/api/v1/update_admin_parking',parking,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
            router.go({ name: 'AdminParking'})
            localStorage.status = "updated"
        }).catch(error => console.log(error))

    },
    delete_parking({commit,state},parking){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
       axios.post(baseurl+'/api/v1/delete_admin_parking',parking,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
             router.go({ name: 'AdminParking'})
             localStorage.status = "deleted"
        }).catch(error => console.log(error))

    },
    show_parking({ commit, state},parking){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/show_admin_parking',parking,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
         commit('GET_ALL_PARKINGS',res.data)
       // parkings
      }).catch(error => console.log(error))
    },
    show_parking_request({ commit, state},parking){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/show_parking_requests',parking,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
        commit('GET_ALL_PARKINGS_REQUEST',res.data)
       // parkings
      }).catch(error => console.log(error))
    },
    get_parking({ commit, state},parking_id){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_admin_parking',parking_id,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
        commit('GET_PARKING_DETAILS',res.data)
      }).catch(error => console.log(error))
    },
    add_parking({ commit, state},parking){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/create_user_parking_request',parking,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
         localStorage.status = "requested"
          router.go({ name: 'UserProfile'})
      }).catch(error => console.log(error))
    },
    delete_parking_request({ commit, state},parking){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/create_delete_parking_request',parking,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
         localStorage.status = "requested_delete"
          router.go({ name: 'UserProfile'})
      }).catch(error => console.log(error))
    },
    approve_parking({ commit, state},parking){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/approve_parking_request',parking,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
         localStorage.status = "approved"
          router.go({ name: 'AdminParking'})
      }).catch(error => console.log(error))
    },
    approve_delete_parking({ commit, state},parking){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/remove_parking_request',parking,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
         localStorage.status = "approved_deleted"
          router.go({ name: 'AdminParking'})
      }).catch(error => console.log(error))
    },
    reject_parking({ commit, state},parking){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/reject_parking_request',parking,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
         localStorage.status = "rejected"
          router.go({ name: 'AdminParking'})
      }).catch(error => console.log(error))
    },

  }
}
export default parking
