import axios from 'axios'
import baseurl from './axioscredentials'
axios.defaults.withCredentials = true
import router from '@/router'
import moment from 'moment'
const facility = {
  namespaced: true,
  state: {
    booked_for: "",
    facility_type: "",
    facilities: [],
    booking_requests: [],
    booked_facilities: [],
    booked_list: [],
    facilities_count: '',
    booking_requests_count: '',
    booked_facilities_count: '',
    facility_name: '',
    description: '',
    status: ''

  },
  getters: {

  },
  mutations: {
  	  Get_facility(state,data){
      var booked_list = {}
     booked_list= JSON.parse(JSON.stringify(data['booked_slots']), function(key, value) {
       return value;
     });
     var facility_list = {}

     facility_list = JSON.parse(JSON.stringify(data['common_facilities']), function(key, value) {
       return value;
     });
      state.booked_list = booked_list
      state.facility_list = facility_list
    },

    SET_booking(state,data){
      state.booked_for = data.booked_slots[0].booked_for
      //state.start_time = moment(data.booked_slots[0].start_time,'HH:mm:ss').format("hh:mm A")
      //state.end_time = data.booked_slots[0].end_time
      var k = data.booked_slots[0].common_facilities_id
      var facility_list = {}
      facility_list = JSON.parse(JSON.stringify(data['common_facilities']), function(key, value) {
        return value;
      });
       facility_list.forEach((item) => {
       if ( k == item.id){
         state.facility_type = item.name
       }
      });
      state.fac_id = k
    },
     update_facility(state,payload){
       state.facility_type = payload
     },
     updatebookedfor(state,payload){
       state.booked_for = payload
     },
     update_start(state,payload){
       state.start_time = payload
     },
     update_end(state,payload){
      state.end_time = payload
    },
    updateFacilityName(state,payload){

      state.facility_name = payload
    },
    updateDescription(state,payload){
      state.description = payload
    },
    updateStatus(state,payload){
      state.status = payload
    },
    GET_ADMIN_FACILITY(state,data){
      state.facility_name = data.facility.name
      state.description = data.facility.description
      state.status = data.facility.available
    },
    GET_ADMIN_FACILITIES(state,data){
      state.facilities = data.facilities
      state.booking_requests = data.booking_requests
      state.booked_facilities = data.booked_facilities
      state.facilities_count = data.facilities_count
      state.booking_requests_count = data.booking_requests_count
      state.booked_facilities_count = data.booked_facilities_count
    }
  },
  actions: {
  	list_facility({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      var flat_id = localStorage["flat_id"]
      axios.post(baseurl+'/api/v1/get_booked_slots',{flat_id},
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
       commit('Get_facility',res.data)
      }).catch(error => console.log(error))
    },
    getfacility({commit,state},booking_id){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_booking',booking_id,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
          commit('SET_booking',res.data)
        }).catch(error => console.log(error))
    },
    updatefacility({commit,state},booking){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
       axios.post(baseurl+'/api/v1/update_booking_slots',booking,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {

            router.go({ name: 'CommonFacilities'})
            localStorage.status = "updated"
        }).catch(error => console.log(error))

    },
    deletefacility({commit,state},booking){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
       axios.post(baseurl+'/api/v1/delete_booking_slots',booking,
              {headers:{'Authorization':localStorage.token}}).then(
        res => {
             router.go({ name: 'UserComplaints'})
             localStorage.status = "deleted"
        }).catch(error => console.log(error))

    },
    createfacility({ commit, state},facility){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/create_booking_slots',facility,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
       router.go({ name: 'CommonFacilities'})
       localStorage.status = "created"
      }).catch(error => console.log(error))
    },
    getAdminFacilities({ commit, state}){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/get_admin_facilities_web',{building_id: localStorage.building_id},
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
       commit('GET_ADMIN_FACILITIES',res.data)
      }).catch(error => console.log(error))
    },
    getAdminFacility({ commit, state},facility_id){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/show_admin_facility',facility_id,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
        commit('GET_ADMIN_FACILITY',res.data)
      }).catch(error => console.log(error))
    },
    createAdminFacility({ commit, state},facility){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/create_admin_facility',facility,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
        if (res.data.error  == "Common Facility Name Already Exist"){
            localStorage.status = "exist"
        }
        else{
            localStorage.status = "created"
        }
        router.go({ name: 'AdminCommonFacilities'})

      }).catch(error => console.log(error))
    },
    UpdateAdminFacility({ commit, state},facility){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/update_admin_facility_web',facility,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
       router.go({ name: 'AdminCommonFacilities'})
       localStorage.status = "updated"
      }).catch(error => console.log(error))
    },
    updateFacilityBookingStatus({ commit, state},booking){
      this.dispatch('token/VerifyTokenExpiry', null, {root:true})
      axios.post(baseurl+'/api/v1/approve_reject_booking_status',booking,
              {headers:{'Authorization':localStorage.token}}).then(
      res => {
       router.go({ name: 'AdminCommonFacilities'})
      }).catch(error => console.log(error))
    }
  }
}
export default facility
