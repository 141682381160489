<template>
  <html>
  <body class="skin-default fixed-layout">

    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <UserHeader/>
      <UserSidebar/>
      <div class="page-wrapper">
          <!-- ============================================================== -->
          <!-- Container fluid  -->
          <!-- ============================================================== -->
          <div class="container-fluid">
              <!-- ============================================================== -->
              <!-- Bread crumb and right sidebar toggle -->
              <!-- ============================================================== -->
              <div class="row page-titles">
                  <div class="col-md-5 align-self-center">
                      <h4 class="text-themecolor">Our Groups</h4>
                  </div>
                  <div class="col-md-7 align-self-center text-right">
                      <div class="d-flex justify-content-end align-items-center">
                          <ol class="breadcrumb">
                              <li class="breadcrumb-item"><router-link :to="{ name: 'UserHome' }">Home</router-link></li>
                              <li class="breadcrumb-item active">Our Groups </li>
                          </ol>
                      </div>
                  </div>
              </div>
              <!-- ============================================================== -->
              <!-- End Bread crumb and right sidebar toggle -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- Info box -->
              <!-- ============================================================== -->
              <div class="row el-element-overlay">
                  <div class="col-sm-12 " v-if="usergroups =='NO groups for user'">
                    <div class="alert alert-info signout_update banner-text" role="alert">
                        <span>You are not a Member of any group</span>
                    </div>
                  </div>
                  <div v-else class="col-sm-12 " v-for="usergroup in usergroups" :key="usergroup.group_id">
                      <div class="row nesthead" style="padding:0px 40px;" @click="showDetails(usergroup.group_id)" :ref="'test'+usergroup.group_id">
                          <div class="card card-body">
                              <h4 class="fonthead">
                                  <i class="fa fas fa-caret-right fa-lg" aria-hidden="true"></i>
                                  <span class="group-head">{{usergroup.group_name}}</span>
                              </h4>
                          </div>
                      </div>
                      <div class="row nestbody">
                          <div class="row" style="display:flex;margin-left:40px;margin-right:20px;">
                              <div class="col-lg-2 col-md-6" style="padding:0px 20px 0px 0px;" v-for="groupmember in usergroup.group_members" :key="groupmember.user_id">
                                  <div class="card" @click="showProfile(groupmember.profile_privacy,groupmember.user_id)" v-bind:data-toggle="[groupmember.profile_privacy == false ? 'modal' : '']" v-bind:data-target="[groupmember.profile_privacy == false ? '#privacy-alert' : '']">
                                      <div class="el-card-item">
                                          <div class="el-card-avatar el-overlay-1">
                                              <img :src="groupmember.profile_picture" alt="user" style="width:100%"/>
                                          </div>
                                          <div class="el-card-content">
                                              <h4 class="fonthead">{{groupmember.username}}</h4>
                                              <small>{{groupmember.appartment_number}}</small>
                                              <br />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="row show-all" v-if="usergroup.members_count > 6">
                                  <button type="submit" class="btn btn-danger mr-2" @click="showGroupMembers(usergroup.group_id)" style="height: 40px;">Show All >>>></button>
                              </div>
                          </div>
                      </div>
                  </div>
                  </div>

              <div id="privacy-alert" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="top:20%;">
                  <div class="modal-dialog">
                      <div class="modal-content">
                          <div class="modal-header" style="border-bottom: none;!important">
                              <h4 class="modal-title"  style="margin-left: 5%;opacity: 60%;" id="myModalLabel"></h4>
                              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                          </div>
                           <div class="card-body" style="padding: 10px 50px;">
                              <div class="row" style="justify-content: center;">
                                  <span class="total_payment"><img src="@/assets/images/private.png" class="private-account"></span>
                              </div>

                                  <div class="col-sm-12 private-text" >
                                      <span><strong>Privacy Issue!</strong> The profile is locked!</span>
                                  </div>
                              </div>

                      </div>
                      <!-- /.modal-content -->
                  </div>
                  <!-- /.modal-dialog -->
              </div>

              <!-- ============================================================== -->
              <!-- End Info box -->
              <!-- ============================================================== -->

              <!-- ============================================================== -->
              <!-- Review -->
              <!-- ============================================================== -->

              <!-- ============================================================== -->
              <!-- End Review -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- Comment - chats -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- End Comment - chats -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- End Page Content -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- Right sidebar -->
              <!-- ============================================================== -->
              <!-- .right-sidebar -->

              <!-- ============================================================== -->
              <!-- End Right sidebar -->
              <!-- ============================================================== -->
          </div>
          <!-- ============================================================== -->
          <!-- End Container fluid  -->
          <!-- ============================================================== -->
      </div>
      <!-- ============================================================== -->
      <!-- End Page wrapper  -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- footer -->
      <!-- ============================================================== -->
      <footer class="footer">
          © 2020 www.ontash.net
      </footer>
      <!-- ============================================================== -->
      <!-- End footer -->
      <!-- ============================================================== -->
  </div>
  <!-- ============================================================== -->
  <!-- End Wrapper -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- All Jquery -->
  <!-- ============================================================== -->
</body>
</html>
</template>

<script>
// @ is an alias to /src
import UserHeader from '@/components/headers/UserHeader.vue'
import UserSidebar from '@/components/sidebars/UserSidebar.vue'
import {mapState,mapActions} from 'vuex'
export default {
  name: 'UserGroups',
  components: {
    UserHeader,
    UserSidebar
  },
  computed:{
    ...mapState('group',['usergroups'])
  },
  mounted(){
    this.getUserGroups();
  },
  methods: {
    ...mapActions('group',['getUserGroups']),
    showDetails(group_id){
      const notify = new URLSearchParams()
      notify.append('task_id', group_id)
      notify.append('page', 'Groups')
      notify.append('flat_id',localStorage.flat_id)
      this.$store.dispatch('notification/change_notification_status_user_task_id',notify)
      var test = 'test'+group_id
      if($(this.$refs[test]).find("i").attr('class')=="fa fas fa-caret-right fa-lg"){
          $(this.$refs[test]).find("i").removeClass("fa fas fa-caret-right fa-lg");
          $(this.$refs[test]).find("i").addClass("fa fas fa-caret-down fa-lg");
      }
      else{
          $(this.$refs[test]).find("i").removeClass("fa fas fa-caret-down fa-lg");
          $(this.$refs[test]).find("i").addClass("fa fas fa-caret-right fa-lg");
      }
      $(this.$refs[test]).next().slideToggle();
    },
    showGroupMembers(group_id){
      this.$router.push({ name: 'UserGroupMembers', params: { flat_id: localStorage.flat_id, group_id: group_id} })
    },
    showProfile(profile_privacy,user_id){
      if (profile_privacy == true){
        this.$router.push({ name: 'UserGroupMemberDetails', params: { user_id: user_id} })
      }
    }

  }
}
</script>

<style scoped>

</style>
